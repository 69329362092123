// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router';
// import { getRestaurantAdmins, getRestaurantDetails } from '../../Actions/getRestaurantActions';
// import './restaurant.css';
// import { showToastNotification } from '../../Common/showToastNotification';
// import store from '../../store';
// import { DESTROY_NOTIFICATION, SET_PROFILE_REQUEST_TYPE } from '../../Actions/types';
// import { restaurantTranslations } from '../../Translations/RestaurantTranslations'
// import isEmpty from '../../Utilities/isEmpty';
// import { getAnnouncements, getDrivers } from '../../Actions/authActions'
// import GeneralSettings from './GeneralSettings';
// import HoursOfOperation from './HoursOfOperation';
// import { GeneralSettingsSkelton, ContactsSkelton, HoursOfOperationSkeleton } from './SettingsPageSkeleton';
// import RestaurantContacts from './RestaurantContacts';
// import RestaurantDrivers from './RestaurantDrivers';
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import parse from 'html-react-parser';
// import { getLocalStorageData } from '../../Utilities/getLocalStorageData';
// import { Announcement, GetDrivers, SaveProfile } from '../../Interfaces/PayloadsAndResponses/Auth';
// import { GetRestaurantAdmins, Restaurant } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';
// import { WithTranslation } from 'react-i18next';
// import { Params } from '../Dashboard/Dashboard';

// export type WeekDays = 'sunday' | 'monday' | 'saturday' | 'thursday' | 'tuesday' | 'wednesday' | 'friday';

// type SettingsProps = {
//   // mapStateToProps
//   announcements: Announcement[];
//   restaurant_details: Restaurant | null;
//   restaurant_details_loading: boolean;
//   restaurant_admins: GetRestaurantAdmins | null;
//   errors: any;
//   profile: SaveProfile | null;
//   request_type: string | null;
//   profile_request_type: '' | 'profile' | null;
//   driver_list: GetDrivers | null;
//   driver_loading: boolean;
//   i18n: WithTranslation | null;
//   // mapDispatchToProps
//   getRestaurantDetails: (id: string | number) => void;
//   getAnnouncements: () => void;
//   getDrivers: (id: string | number, driverId?: number) => void;
//   getRestaurantAdmins: (id: string | number) => void;
// } & RouteComponentProps<Params>;

// class Settings extends Component<SettingsProps, {}> {

//   getSettingPageDetailsAPIs = () => {
//     const { restaurantId } = this.props.match.params
//     if (restaurantId) {
//       const { isSelfServiceEnabled } = getLocalStorageData()
//       this.props.getAnnouncements();
//       this.props.getRestaurantDetails(restaurantId);
//       this.props.getRestaurantAdmins(restaurantId);
//       if (isSelfServiceEnabled) {
//         this.props.getDrivers(restaurantId);
//         store.dispatch({ type: 'GET_DRIVER_LOADING', payload: null });
//       }
//     }
//   }

//   componentDidMount() {
//     restaurantTranslations();
//     this.getSettingPageDetailsAPIs();
//   }

//   componentDidUpdate(prevProps: SettingsProps) {
//     if (this.props.match.params.restaurantId !== prevProps.match.params.restaurantId) {
//       this.getSettingPageDetailsAPIs();
//     }
//   }

//   componentWillReceiveProps(nextProps: SettingsProps) {
//     // if (this.state.stepNumber === 4 && nextProps?.restaurant_details?.address?.enable_self_service === false) {
//     //   this.handleStepNumbers(1);
//     // }
//     if (nextProps.profile && nextProps.profile.user) {
//       if (nextProps.profile_request_type === 'profile') {
//         localStorage.setItem('user', JSON.stringify(nextProps.profile.user));
//         showToastNotification('Vendor updated successfully', 'Success!');
//         store.dispatch({ type: SET_PROFILE_REQUEST_TYPE, payload: null });
//       }
//     }
//     if (nextProps.errors && nextProps.errors.message) {
//       showToastNotification(JSON.parse(nextProps.errors.message), 'Alert!')
//     }
//     if (nextProps.restaurant_details && nextProps.restaurant_details.address.schedules) {
//       let updatedScheduales = nextProps.restaurant_details.address.schedules;
//       let weekDays: WeekDays[] =
//         ['sunday', 'monday', 'saturday', 'thursday', 'tuesday', 'wednesday', 'friday'];
//       weekDays.forEach(day => {
//         if (isEmpty(updatedScheduales[day])) {
//           updatedScheduales[day].push({
//             end_time: '00:00',
//             start_time: '00:00',
//             label: day.charAt(0).toUpperCase() + day.slice(1)
//           })
//         }
//       });
//       if (nextProps.request_type === 'editContact') {
//         showToastNotification('Contact updated successfully!', 'Success!')
//         store.dispatch({ type: DESTROY_NOTIFICATION, payload: null });
//       } else if (nextProps.request_type === 'deleteContact') {
//         showToastNotification('Contact deleted successfully!', 'Success!')
//         store.dispatch({ type: DESTROY_NOTIFICATION, payload: null });
//       } else if (nextProps.request_type === 'editSchedule') {
//         showToastNotification('Schedule updated successfully!', 'Success!')
//         store.dispatch({ type: DESTROY_NOTIFICATION, payload: null });
//       }
//     }
//   }
//   // onUpdate = () => {
//   //   this.props.getDrivers(this.props.match.params.restaurantId);
//   //   store.dispatch({ type: 'GET_DRIVER_LOADING', payload: null });
//   // }

//   // handleStepNumbers = (step: any) => {
//   //   this.setState({ stepNumber: step });
//   // }
//   // deleteDriverHandler = (driver: any) => {
//   //   store.dispatch({ type: 'GET_DRIVER_LOADING', payload: null });
//   //   this.props.updateDriver(this.props.match.params.restaurantId, { status: 'deleted', ...driver }, driver.id)
//   // }

//   render() {
//     const {
//       driver_list,
//       announcements,
//       driver_loading,
//       restaurant_admins,
//       restaurant_details,
//       restaurant_details_loading,
//     } = this.props

//     const address = restaurant_details?.address;
//     return (
//       <div className='card'>
//         <div className='card-body p-0'>
//           {isEmpty(announcements) ? null :
//             <div className='col-12 px-0'>
//               {announcements?.map((announcement) =>
//                 <div className='callout callout-success bg-white cuttoff-passed-mobile'>
//                   <h4>{announcement?.title}</h4>
//                   {parse(announcement?.description)}
//                 </div>
//               )}
//             </div>
//           }
//           <div id='show-notification' className='float-right'></div>
//           {isEmpty(address) || restaurant_details_loading ?
//             <React.Fragment>
//               <GeneralSettingsSkelton />
//               <ContactsSkelton />
//             </React.Fragment> :
//             <React.Fragment>
//               <GeneralSettings restaurant={address!} />
//               <RestaurantContacts
//                 contacts={address?.contacts ?? []}
//                 restaurantAdmins={restaurant_admins}
//               />
//             </React.Fragment>
//           }
//           {!address?.enable_self_service ? null :
//             <RestaurantDrivers
//               drivers={driver_list?.drivers}
//               driverLoading={driver_loading}
//             />
//           }
//           {restaurant_details_loading ?
//             <HoursOfOperationSkeleton /> :
//             <HoursOfOperation schedules={address?.schedules!} />}
//         </div>
//       </div>
//     )
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   announcements: state.auth.announcements,
//   restaurant_details: state.restaurant_data.restaurant_details,
//   restaurant_details_loading: state.restaurant_data.restaurant_details_loading,
//   restaurant_admins: state.restaurant_data?.restaurant_admins,
//   errors: state.errors.errors,
//   profile: state.auth.profile,
//   request_type: state.restaurant_data.request_type,
//   profile_request_type: state.auth.profile_request_type,
//   driver_list: state.auth.driver_list,
//   driver_loading: state.auth.driver_loading,
//   i18n: state.i18n.i18n
// })

// export type SettingsRef = React.ElementRef<typeof Settings>;

// export default connect(mapStateToProps, { getRestaurantDetails, getAnnouncements, getDrivers, getRestaurantAdmins })(withRouter(Settings));

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRestaurantAdmins, getRestaurantDetails } from '../../Actions/getRestaurantActions';
import './restaurant.css';
import { showToastNotification } from '../../Common/showToastNotification';
import store from '../../store';
import { DESTROY_NOTIFICATION, SET_PROFILE_REQUEST_TYPE } from '../../Actions/types';
import { restaurantTranslations } from '../../Translations/RestaurantTranslations'
import isEmpty from '../../Utilities/isEmpty';
import { getAnnouncements, getDrivers } from '../../Actions/authActions'
import GeneralSettings from './GeneralSettings';
import HoursOfOperation from './HoursOfOperation';
import { GeneralSettingsSkelton, ContactsSkelton, HoursOfOperationSkeleton } from './SettingsPageSkeleton';
import RestaurantContacts from './RestaurantContacts';
import RestaurantDrivers from './RestaurantDrivers';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import parse from 'html-react-parser';
import { getLocalStorageData } from '../../Utilities/getLocalStorageData';  

export type WeekDays = 'sunday' | 'monday' | 'saturday' | 'thursday' | 'tuesday' | 'wednesday' | 'friday';

const Settings = () => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const dispatch = useDispatch<any>();
  // mapStateToProps
  const { announcements, restaurant_details, restaurant_details_loading, restaurant_admins, errors, profile, request_type, profile_request_type, driver_list, driver_loading } = useSelector((state: ReduxRootState) => ({
    announcements: state?.auth?.announcements,
    restaurant_details: state?.restaurant_data?.restaurant_details,
    restaurant_details_loading: state?.restaurant_data?.restaurant_details_loading,
    restaurant_admins: state?.restaurant_data?.restaurant_admins,
    errors: state?.errors?.errors,
    profile: state?.auth?.profile,
    request_type: state?.restaurant_data?.request_type,
    profile_request_type: state?.auth?.profile_request_type,
    driver_list: state?.auth?.driver_list,
    driver_loading: state?.auth?.driver_loading,
    i18n: state?.i18n,
  }));
  const address = restaurant_details ? restaurant_details?.address : null
  useEffect(() => {
    restaurantTranslations();
    if (restaurantId) {
      const { isSelfServiceEnabled } = getLocalStorageData()
      dispatch(getAnnouncements());
      dispatch(getRestaurantDetails(restaurantId));
      dispatch(getRestaurantAdmins(restaurantId));
      if (isSelfServiceEnabled) {
        dispatch(getDrivers(restaurantId));
        store.dispatch({ type: 'GET_DRIVER_LOADING', payload: null });
      }
    }
  }, [dispatch, restaurantId]);

  useEffect(() => {
    if (profile && profile?.user) {
      if (profile_request_type === 'profile') {
        localStorage.setItem('user', JSON.stringify(profile.user));
        showToastNotification('Vendor updated successfully', 'Success!');
        store.dispatch({ type: SET_PROFILE_REQUEST_TYPE, payload: null });
      }
    }
    if (errors && errors?.message) {
      showToastNotification(JSON.parse(errors.message), 'Alert!')
    }
    if (restaurant_details && restaurant_details?.address.schedules) {
      let updatedScheduales = restaurant_details?.address.schedules;
      let weekDays: WeekDays[] =
        ['sunday', 'monday', 'saturday', 'thursday', 'tuesday', 'wednesday', 'friday'];
      weekDays?.forEach(day => {
        if (isEmpty(updatedScheduales[day])) {
          updatedScheduales[day]?.push({
            end_time: '00:00',
            start_time: '00:00',
            label: day?.charAt(0)?.toUpperCase() + day?.slice(1),
            time_zone: address?.time_zone
          })
        }
      });
     if (request_type === 'editContact') {
        showToastNotification('Contact updated successfully!', 'Success!')
        store.dispatch({ type: DESTROY_NOTIFICATION, payload: null })
      } else if (request_type === 'deleteContact') {
        showToastNotification('Contact deleted successfully!', 'Success!')
        store.dispatch({ type: DESTROY_NOTIFICATION, payload: null });
      } else if (request_type === 'editSchedule') {
        showToastNotification('Schedule updated successfully!', 'Success!')
        store.dispatch({ type: DESTROY_NOTIFICATION, payload: null });
      }
    }
    //eslint-disable-next-line 
  }, [dispatch, errors, profile, profile_request_type, request_type, restaurant_details])
  return (
      <div className='card'>
        <div className='card-body'>
          {isEmpty(announcements) ? null :
            <div className='col-12 px-0'>
              {announcements?.map((announcement) =>
                <div className='callout callout-success bg-white cuttoff-passed-mobile'>
                  <h4>{announcement?.title}</h4>
                  {parse(announcement?.description)}
                </div>
              )}
            </div>
          }
          <div id='show-notification' className='float-right'></div>
          {isEmpty(address) || restaurant_details_loading ?
            <React.Fragment>
              <GeneralSettingsSkelton />
              <ContactsSkelton />
            </React.Fragment> :
            <React.Fragment>
              <GeneralSettings restaurant={address!} />
              <RestaurantContacts
                contacts={address?.contacts ?? []}
                restaurantAdmins={restaurant_admins}
              />
            </React.Fragment>
          }
          {!address?.enable_self_service ? null :
            <RestaurantDrivers
              drivers={driver_list?.drivers}
              driverLoading={driver_loading}
            />
          }
          {restaurant_details_loading ?
            <HoursOfOperationSkeleton /> :
            <HoursOfOperation schedules={address?.schedules!} />}
        </div>
      </div>
    )
  }
  export default Settings;
