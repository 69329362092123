import React, { FC, useState } from "react";
import OptionGroupDeletePopup from "./OptionGroupDeletePopup";
import BuffetDishSizeEditPopup from "./BuffetDishSizeEditPopup";
import { DishSize } from "../../../Interfaces/PayloadsAndResponses/Menu";
import { useParams } from "react-router";
import { Params } from "../../Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import { deleteBuffetDishsize } from "../../../Actions/menuActions";

type BuffetTableProps = {
  dishsizes: DishSize;
  dragProps: any;
}
const BuffetTable: FC<BuffetTableProps> = ({ dragProps, dishsizes }) => {

  const { restaurantId } = useParams<Params>();
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? ''
  const foodItemId = localStorage.getItem('fooditemid') ?? ''
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';
  const dispatch = useDispatch<any>();

  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [editBuffetDishSize, setEditBuffetDishSize] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const loaderHandler = () => {
    setLoading(!loading)
  }

  const handleClosePopup = () => {
    setOpenDeletePopup(false);
  }

  const handleDeleteBuffetDishSize = () => {
    loaderHandler();
    restaurantId && selectedMenuId && (dispatch(deleteBuffetDishsize(+restaurantId, +selectedMenuId, sectionid, +foodItemId, dishsizes?.id, loaderHandler, handleClosePopup)))
  }
  const handleSelectedBuffetDishSizes = () => {
    setEditBuffetDishSize(true);
  }
  const closeModal = () => {
    setEditBuffetDishSize(false);
  }
  return (
    <>
      <BuffetDishSizeEditPopup
        editBuffetDishSize={editBuffetDishSize}
        setEditBuffetDishSize={setEditBuffetDishSize}
        dishsizes={dishsizes}
        closeModal={closeModal}
      />
      {/* Delete Dish Size data  */}
      <OptionGroupDeletePopup
        openDeletePopup={openDeletePopup}
        setOpenDeletePopup={setOpenDeletePopup}
        onDeleteConfirmed={handleDeleteBuffetDishSize}
        loading={loading}
      />
      <hr className="table_seprator"></hr>
      <div className="menu_items_lists d-flex align-items-center py-3">
        <div className="col-lg-1" {...dragProps}>
          <span className="ps-0 pe-2 cursor-pointer">
            <i className="fa-regular fa-grip-dots-vertical fa-lg drage_buttons"></i>
          </span>
        </div>
        <div className="col-lg-5">{dishsizes?.title}</div>
        <div className="col-lg-2">{dishsizes?.serve_count}</div>
        <div className="col-lg-2">{dishsizes?.price}</div>
        <div className="col-lg-2 pr-5">
          <div className="d-flex flex-wrap align-items-center menu_cta_button_wrapper">
            <div className="d-flex justify-content-between align-items-center menu_cta_button_wrapper ms-4 mt-2 mt-md-0 mx-auto mx-md-0 mt-2 mt-md-0 mx-auto mx-md-0">
              <button className="btn btn-custom-danger btn-sm rounded-circle p-2" title='Delete' onClick={() => setOpenDeletePopup(true)}>
                <i className="far fa-trash-alt" style={{ width: "18px", height: "20px" }} onClick={handleDeleteBuffetDishSize}></i>
              </button>
              <button className="btn btn-custom-light btn-sm rounded-pill ms-3 px-4" onClick={handleSelectedBuffetDishSizes}>Edit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BuffetTable;