import axios from 'axios';
import isEmpty from "../Utilities/isEmpty";
import { getError } from "./errorActions";
import { ACCEPT_ORDERS, GET_MEETINGS, GET_MEETINGS_LOADING, GET_SINGLE_ORDER_DETAILS, LOADING, SET_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING, STOP_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING } from "./types";
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { downloadFile } from '../Utilities/DownloadFile';
import dayjs from 'dayjs';
const queryString = require('query-string');

export const getAllLocationsOrders = (id: string | number, startDate: string, endDate: string, address_ids: number[] = []) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  if (isEmpty(address_ids)) return;
  dispatch({
    type: GET_MEETINGS_LOADING,
  })
  axios.get(`/vendor/restaurants/${id}/meetings?start_date=${startDate}&end_date=${endDate}`,
    {
      params: { 'address_ids[]': address_ids },
      paramsSerializer: function (params) {
        return queryString.stringify(params, { indices: false, skipNull: true, skipEmptyString: true })
      },
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_MEETINGS,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getSingleOrderDetails = (meetingId: number, restaurantId: string | number, queryParams: string, isSorting: boolean = false) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  !isSorting && dispatch(setLoading());
  axios.get(`vendor/restaurants/${restaurantId}/meetings/${meetingId}/orders${queryParams || ''}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_SINGLE_ORDER_DETAILS,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

// export const getBulkMeetingSummaryPDF = (id: any, date: any, addresses: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//   axios.get(`vendor/restaurants/${id}/summary_pdf.pdf?pickup=${date}`,
//     {
//       params: { "address_ids": addresses },
//       responseType: 'blob',
//       headers: JSON.parse(localStorage.getItem('headers') || '{}')
//     }
//   )
//     .then(res => {
//       const file = new Blob(
//         [res.data],
//         { type: 'application/zip' });
//       const fileURL = URL.createObjectURL(file);
//       // pdfDownloadedCallback && pdfDownloadedCallback()
//       window.open(fileURL);
//     })
//     .catch(err => {
//       // pdfDownloadedCallback && pdfDownloadedCallback('An unexpected error occured while downloading.')
//       console.log('Error', err)
//     }
//     );
// };

// Both bulk Summary And Bulk Labels API Request Method
export const getBulkMeetingPDFs = (id: any, date: any, addresses: any, apiType: any, fileName: any, DownloadingPDF: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.get(`vendor/restaurants/${id}/${apiType}?pickup=${date}`,
    {
      params: { "address_ids": addresses },
      responseType: 'blob',
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      const file = new Blob(
        [res.data],
        { type: 'application/zip' });
      const fileURL = URL.createObjectURL(file);
      const LabelsDate = dayjs(date).format('MMM DD YYYY')
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute(
        "download",
        `${fileName} ${LabelsDate}.zip`
      );
      document.body.appendChild(link);
      link.click();
      // pdfDownloadedCallback && pdfDownloadedCallback()
      // window.open(fileURL);
      DownloadingPDF && DownloadingPDF();
    })
    .catch(err => {
      // pdfDownloadedCallback && pdfDownloadedCallback('An unexpected error occured while downloading.')
      console.log('Error', err)
    }
    );
};

export const getMeetingPDF = (id: string | number, meetingId: number, pdfDownloadedCallback: (errMsg?: string) => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.get(`vendor/restaurants/${id}/meetings/${meetingId}/get_summary_pdf.pdf`,
    {
      responseType: 'blob',
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      const file = new Blob(
        [res.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      pdfDownloadedCallback && pdfDownloadedCallback()
      window.open(fileURL);
    })
    .catch(err => {
      pdfDownloadedCallback && pdfDownloadedCallback('An unexpected error occured while downloading.')
      console.log('Error', err)
    }
    );
};

export const getMeetingLabels = (id: string | number, meetingId: number, labelDownloadedCallback: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.get(`vendor/restaurants/${id}/meetings/${meetingId}/get_labels.pdf`,
    {
      responseType: 'blob',
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      const file = new Blob(
        [res.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      labelDownloadedCallback && labelDownloadedCallback();
      downloadFile(fileURL, `Meeting_Labels(${id ?? '-'}).pdf`);
    })
    .catch(err =>
      console.log('Error', err)
    );
};

export const getMultipleMeetingsExcelSummary = (restaurant_address_ids: (string | number)[], date: string, DownloadingPDF: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setMultipleMeetingsExcelSummarydLoading(date));
  axios.get(`/vendor/restaurants/summary_excel.xlsx`,
    {
      responseType: 'blob',
      headers: JSON.parse(localStorage.getItem('headers') || '{}'),
      params: { 'restaurant_address_ids[]': restaurant_address_ids, delivery_date: date },
      paramsSerializer: function (params) {
        return queryString.stringify(params, { indices: false, skipNull: true, skipEmptyString: true })
      },
    }
  )
    .then(res => {
      dispatch(stopDeliveryInvoiceDownloadLoading(date));
      DownloadingPDF && DownloadingPDF()
      const file = new Blob(
        [res.data],
        { type: 'application/xlsx' });
      const fileURL = URL.createObjectURL(file);
      const LabelsDate = dayjs(date).format('MMM DD YYYY')
      downloadFile(fileURL, `Chowmill Orders ${LabelsDate}.xlsx`);
    })
    .catch(err => {
      dispatch(stopDeliveryInvoiceDownloadLoading(date));
      console.log('Error', err)
    }
    );
};

// export const getMeetingExcelSummary = (restaurant_id: string | number, meetingId: any, labelDownloadedCallback: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//   axios.get(`/vendor/restaurants/${restaurant_id}/meetings/${meetingId}/summary_excel.xlsx`,
//     {
//       responseType: 'blob',
//       headers: JSON.parse(localStorage.getItem('headers') || '{}')
//     }
//   )
//     .then(res => {
//       const file = new Blob(
//         [res.data],
//         { type: 'application/xlsx' });
//       const fileURL = URL.createObjectURL(file);
//       labelDownloadedCallback && labelDownloadedCallback()
//       let link = document.createElement('a');
//       link.href = fileURL;
//       link.download = 'meeting_label.xlsx';
//       link.click();
//     })
//     .catch(err =>
//       console.log('Error', err)
//     );
// };

export const updateOrderStatus = (message: { message: string, type: 'accept' }, meetingId: number, restaurantId: string | number, order_confirmed_callback: () => void, token?: string) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/vendor/restaurants/${restaurantId}/meetings/${meetingId}/orders_status`, token ? { token } : message,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      // if (startDate && endDate) {
      //   dispatch({
      //     type: UPDATE_MEETING_STATUS,
      //     payload: meetingId
      //   });
      // }
      order_confirmed_callback && order_confirmed_callback();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const updateBulkOrderStatus = (restaurantId: string | number, meetingId: any, type: any, WaitingtoConfirm: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/vendor/restaurants/update_order_status`,
    {
      "selected_meetings": meetingId,
      "type": "accept"
    },
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      WaitingtoConfirm && WaitingtoConfirm()
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const acceptOrders = (type: string, id: string | number, token = '') => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.put(token ? `/vendor/schedules/1/acknowledge?type=${type}&&token=${token}` : `/vendor/schedules/${id}/acknowledge?type=${type}`, {},
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: ACCEPT_ORDERS,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const setLoading = (type = LOADING) => {
  return {
    type: type
  };
}

export const setMultipleMeetingsExcelSummarydLoading = (date: string) => {
  return {
    type: SET_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING,
    payload: date
  };
}

export const stopDeliveryInvoiceDownloadLoading = (date: string) => {
  return {
    type: STOP_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING,
    payload: date
  };
}