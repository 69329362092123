import { GET_FRESHDESK_LOGS, FRESHDESK_LOODING, POST_HELP_AND_SUPPORT, POST_LOGS_LOADING } from '../Actions/types';
import { HelpReducer } from '../Interfaces/ReduxInterface/ReduxRootState';
import { Action } from './ActionInterface';
const initialState: HelpReducer = {
  logs: null,
  help_loading: false,
  post_logs_loading: false
};
const helpReducers = (state: HelpReducer = initialState, action: Action): HelpReducer => {
  switch (action.type) {
    case FRESHDESK_LOODING:
      return {
        ...state,
        help_loading: true
      }
    case POST_LOGS_LOADING:
      return {
        ...state,
        post_logs_loading: true
      }
    case GET_FRESHDESK_LOGS:
      return {
        ...state,
        logs: action.payload,
        help_loading: false,
      }
    case POST_HELP_AND_SUPPORT:
      return {
        ...state,
        logs: action.payload,
        help_loading: false,
        post_logs_loading: false
      }
    default:
      return state;
  }
};

export default helpReducers;