import { GET_BILLINGS, GET_BILLING, BILLING_LOADING } from '../Actions/types';
import { BillingReducer } from '../Interfaces/ReduxInterface/ReduxRootState';
import { Action } from './ActionInterface';
const initialState: BillingReducer = {
  billings: null,
  single_order_billing_details: null,
  billing_loading: false
};

const billingReducer = (state: BillingReducer = initialState, action: Action): BillingReducer => {
  switch (action.type) {
    case BILLING_LOADING:
      return {
        ...state,
        billing_loading: true
      }
    case GET_BILLINGS:
      return {
        ...state,
        billings: action.payload,
        billing_loading: false
      }
    case GET_BILLING:
      return {
        ...state,
        single_order_billing_details: action.payload,
        billing_loading: false
      }
    default:
      return state;
  }
};

export default billingReducer;