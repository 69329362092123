import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const NotFoundRoutes = () => {
    const headers = localStorage.getItem('headers');
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        history.push(!!headers ? '/dashboard/restaurant' : { pathname: '/', state: { to: location } })
    });
    return <></>
}
export default NotFoundRoutes;