import React, { CSSProperties, FC } from 'react';

type Props = {
  label: string;
  onModalClose: (() => void) | null;
  style?: CSSProperties;
};

const ModalHeader: FC<Props> = ({ label, style, onModalClose }) => {
  return (
    <div className='modal-header'>
      <h5 className='modal-title text-capitalize' id='modal-title' style={style}>
        {label}
      </h5>
      {onModalClose &&
        <button
          type='button'
          onClick={onModalClose}
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>×</span>
        </button>
      }
    </div>
  );
};

export default ModalHeader;