import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import restaurantReducer from './restaurantReducer';
import menuReducer from './menuReducer';
import billingReducer from './billingReducer';
import invoicesReducers from './invoicesReducers';
import helpReducers from './helpReducers';
import i18nReducer from './i18nReducer';
import reportsReducer from './reportsReducer';

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    restaurant_data: restaurantReducer,
    menus: menuReducer,
    billing: billingReducer,
    invoices: invoicesReducers,
    help: helpReducers,
    i18n: i18nReducer,
    reports: reportsReducer,
});