import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { unlockUserAccount } from '../../Actions/authActions';

const UnlockUserAccount = () => {

  const dispatch: any = useDispatch();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  useEffect(() => {
    dispatch(unlockUserAccount(token, (message) => {
      history.push('/', { resetMessage: message });
    }));
  }, [history, dispatch, token])

  return (
    <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
      <h3>Unlocking your account...</h3>
    </div>
  )
}

export default UnlockUserAccount;