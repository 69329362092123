import React, { CSSProperties, FC, useEffect } from 'react';
type Props = {
  onPopUpClose: () => void;
  backdrop?: boolean;
  backdropStyles?: CSSProperties;
  popUpBodyStyles?: CSSProperties;
  backdropClassName?: string;
  popUpBodyClassName?: string;
  popUpClassName?: string;
  allowScrolling?: boolean;
  hideBackDrop?: boolean;
  hideBody?: boolean;
  children: React.ReactNode;
}
const CustomPopUp: FC<Props> = ({
  children,
  backdrop = true,
  onPopUpClose,
  popUpClassName = '',
  backdropClassName = '',
  backdropStyles = {},
  popUpBodyClassName = '',
  popUpBodyStyles = {},
  allowScrolling = false,
  hideBackDrop = false,
  hideBody = false }) => {

  useEffect(() => {
    const scrollerHandler = () => {
      window.scrollTo(0, 0)
    }
    if (!allowScrolling) {
      window.addEventListener('scroll', scrollerHandler)
    }
    return () => { window.removeEventListener('scroll', scrollerHandler) }
  }, [allowScrolling]);

  return <div className={`${popUpClassName}`} onClick={e => e.stopPropagation()}>
    {backdrop && !hideBackDrop &&
      <div
        className={`${backdropClassName}`}
        style={{ backgroundColor: 'black', background: '#00000035', position: 'fixed', inset: 0, zIndex: 1100, ...backdropStyles, }} onClick={e => onPopUpClose()}></div>
    }
    {!hideBody &&
      <div className={`${popUpBodyClassName}`} style={{ position: 'absolute', zIndex: 1101, ...popUpBodyStyles, }}>
        {
          children
        }
      </div>}
  </div>
}
export default CustomPopUp;