import { GET_MENUS, LOADING, UPDATING_FOODITEM, SET_DISHSIZE_LOADING, REMOVE_DISHSIZE_LOADING, GET_FOOD_ITEMS, GET_MENUS_SECTIONS, GET_SELECTED_MENU_TYPE, GET_SELECTED_RESTAURANT_ID, UPDATE_SECTIONS, SECTION_LOADING, SECTION_FOODITEMS, GET_SYSTEM_DIETRIES, GET_SYSTEM_INGREDIENTS, SELECT_EXISTING_FOOD_ITEMS, SECTION_FOODITEMS_LOADING, SELECT_EXISTING_OPTION_SETS, ADD_FOOD_ITEM_LOADING } from '../Actions/types';
import { MenusReducer } from '../Interfaces/ReduxInterface/ReduxRootState';
import { Action } from './ActionInterface';

const initialState: MenusReducer = {
  menus: null,
  menuTypeSections: null,
  food_item_data: null,
  menu_loading: false,
  loading_foodItem_status: false,
  loading_foodItem_title: false,
  loading_foodItem_section: false,
  loading_foodItem_image: false,
  loading_foodItem_restrictions: false,
  loading_foodItem_dietaries: false,
  loading_foodItem_ingredients: false,
  loading_foodItem_nutritions: false,
  loading_foodItem_optionsets: false,
  loading_delete_optionsets: false,
  loading_menu_optionsets: false,
  dishsize_loading_list: {},
  selectedMenuType: '',
  selected_restaurant_id: '',
  updatedSections: null,
  loading_section: false,
  selectedSectionFoodItems: null,
  systemDietries: [],
  systemIngredients: [],
  selectExistingFoodItems: null,
  sectionFoodItemLoading: false,
  selectExistingOptionsets: [],
  foodItemPageLoader: false
};

const menuReducer = (state: MenusReducer = initialState, action: Action): MenusReducer => {
  switch (action.type) {
    case GET_MENUS:
      return {
        ...state,
        menus: action.payload,
        menu_loading: false,
        loading_foodItem_status: false,
        loading_foodItem_title: false,
        loading_foodItem_section: false,
        loading_foodItem_image: false,
        loading_foodItem_restrictions: false,
        loading_foodItem_dietaries: false,
        loading_foodItem_ingredients: false,
        loading_foodItem_nutritions: false,
        loading_foodItem_optionsets: false,
        loading_delete_optionsets: false,
        loading_menu_optionsets: false,
      }
    case GET_MENUS_SECTIONS:
      return {
        ...state,
        menuTypeSections: action?.payload,
      }
    case GET_SYSTEM_DIETRIES:
      return {
        ...state,
        systemDietries: action?.payload,
      }
    case GET_SYSTEM_INGREDIENTS:
      return {
        ...state,
        systemIngredients: action?.payload,
      }
    case GET_SELECTED_MENU_TYPE:
      return {
        ...state,
        selectedMenuType: action?.payload,
      }
    case GET_SELECTED_RESTAURANT_ID:
      return {
        ...state,
        selected_restaurant_id: action?.payload,
      }
    case SECTION_FOODITEMS:
      return {
        ...state,
        selectedSectionFoodItems: action?.payload,
        sectionFoodItemLoading: false,
      }
    case SECTION_FOODITEMS_LOADING:
      return{
        ...state,
        sectionFoodItemLoading: action?.payload
      }
    case SECTION_LOADING:
      return {
        ...state,
        loading_section: action?.payload
      }
    case UPDATE_SECTIONS:
      return {
        ...state,
        loading_section: false,
        updatedSections: action?.payload,
      }
    // SELECT_EXISTING_FOOD_ITEMS
    case SELECT_EXISTING_FOOD_ITEMS:
      return {
        ...state,
        selectExistingFoodItems: action?.payload,
      }
    case GET_FOOD_ITEMS:
      return {
        ...state,
        menu_loading: false,
        food_item_data: action.payload,
        loading_foodItem_status: false,
        loading_foodItem_title: false,
        loading_foodItem_section: false,
        loading_foodItem_image: false,
        loading_foodItem_restrictions: false,
        loading_foodItem_dietaries: false,
        loading_foodItem_ingredients: false,
        loading_foodItem_nutritions: false,
        loading_foodItem_optionsets: false,
        loading_delete_optionsets: false,
        loading_menu_optionsets: false,
        foodItemPageLoader: false,
      }

    case UPDATING_FOODITEM:
      return {
        ...state,
        menu_loading: false,
        [action.payload]: true
      }
    // SELECT_EXISTING_OPTION_SETS
    case SELECT_EXISTING_OPTION_SETS:
      return {
        ...state,
        selectExistingOptionsets: action?.payload,
      }
    case LOADING:
      return {
        ...state,
        menu_loading: true
      }
    case SET_DISHSIZE_LOADING:
      return {
        ...state,
        dishsize_loading_list: { ...state.dishsize_loading_list, [action.payload]: true }
      }
    case REMOVE_DISHSIZE_LOADING:
      return {
        ...state,
        dishsize_loading_list: { ...state.dishsize_loading_list, [action.payload]: false }
      }
    case ADD_FOOD_ITEM_LOADING:
      return {
        ...state,
        foodItemPageLoader: action?.payload
      }
    default:
      return state;
  }
};

export default menuReducer;