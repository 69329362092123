import React, { FC, useEffect, useState } from "react";
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import { draganddrop, editOptionSets, getSelectExistingOptionsets } from "../../../Actions/menuActions";
import { useParams } from "react-router";
import { Params } from "../../Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import OptionGroupDeletePopup from "./OptionGroupDeletePopup";
import { OptionSet, OptionSetOption } from "../../../Interfaces/PayloadsAndResponses/Menu";
import Spinner from "../../../Common/Spinner";
import SelectExistingPopup from "./SelectExistingPopup";
import { useSelector } from "react-redux";
import { ReduxRootState } from "../../../Interfaces/ReduxInterface/ReduxRootState";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { customStyles } from "./MenuSectionsPage";

type OptionGroupPopup = {
  editoptionSetsPopup: boolean;
  setEditOptionSetsPopup: (status: boolean) => void;
  optionSetSelectedData?: OptionSet;
  selectExistingPopUp?: boolean;
  setSelectExistingPopUp?: (status: boolean) => void;
}
type OptionGroupFormEditError = {
  optionGroupName: string,
  optionGroupMinValue: string,
  optionGroupMaxValue: string,
}
type OptionSetFormData = {
  optionGroupMinValue: string | number | undefined;
  optionGroupMaxValue: string | number | undefined;
  optionGroupName: string | number | undefined;
  options: OptionSetOption[] | undefined;
};


const EditOptionGroupPopup: FC<OptionGroupPopup> = ({ editoptionSetsPopup, setEditOptionSetsPopup, optionSetSelectedData }) => {
  const dispatch = useDispatch<any>();
  //Get id from params
  const { restaurantId } = useParams<Params>();
  const foodItemId = localStorage.getItem('fooditemid') ?? ''
  //data from local storage
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? '';
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';
  const selectExistingOptionSets = useSelector((state: ReduxRootState) => state?.menus?.selectExistingOptionsets)
  //States
  const [selectedOptionFormValue, setSelectedOptionFormValue] = useState<OptionSetFormData>({
    optionGroupMinValue: optionSetSelectedData?.start_limit,
    optionGroupMaxValue: optionSetSelectedData?.end_limit,
    optionGroupName: optionSetSelectedData?.name,
    options: optionSetSelectedData?.options
  });
  const [optionGroupErrors, setOptionGroupErrors] = useState<OptionGroupFormEditError>({
    optionGroupName: "",
    optionGroupMinValue: "",
    optionGroupMaxValue: ""
  });
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [selectExistingPopUp, setSelectExistingPopUp] = useState<boolean>(false)
  const [dragListItem, setDragListItem] = useState<any>(optionSetSelectedData?.options);

  useEffect(() => {
    setDragListItem(optionSetSelectedData?.options);
    //eslint-disable-next-line
  }, [optionSetSelectedData?.options?.length])


  const loaderHandler = () => {
    setEditOptionSetsPopup(false)
    setLoading(false)
  }
  const handleSelectExistingOptionSets = () => {
    setSelectExistingPopUp(true);
    restaurantId && selectedMenuId && foodItemId && dispatch(getSelectExistingOptionsets(+restaurantId, +selectedMenuId, +foodItemId))
  }
  // Function to show the delete popup for a specific index
  const showDeletePopup = (index: number) => {
    setDeleteIndex(index);
    setOpenDeletePopup(true);
  };

  // Function to hide the delete popup
  const hideDeletePopup = () => {
    setDeleteIndex(null);
    setOpenDeletePopup(false);
  };
  //Handle Change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setSelectedOptionFormValue((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  }
  //Handle delete div
  const handleDeleteDiv = (index: number) => {
    const deletedOption = selectedOptionFormValue?.options?.[index]; // Retrieve the option being deleted
    const updatedOptions = selectedOptionFormValue?.options?.filter((value, i) => i !== index);
    const deletedOptionId = deletedOption?.id; // Get the id of the deleted option
    optionSetSelectedData?.options?.forEach((option) => {
      if (option?.id === deletedOptionId) {
        option.parent_status = 1;
      }
    });
    setSelectedOptionFormValue((prevData) => ({
      ...prevData,
      options: updatedOptions
    }));
  };

  const handleDeleteConfirmed = () => {
    if (deleteIndex !== null) {
      handleDeleteDiv(deleteIndex);
      hideDeletePopup();
    }
  };

// Handle Add Div for Option Sets
const handleAddOptionSets = () => {
  setSelectedOptionFormValue((prevData: any) => ({
    ...prevData,
    options: [...(prevData.options || []), { optionsName: '', optionsValue: '' }]
  }));
};

  // Validation function for the option sets form Edit data
  const validateOptionSetsFormEditData = (selectedOptionFormValue: any) => {
    const { optionGroupMinValue = '', optionGroupMaxValue = '', optionGroupName = '', options= '' } = selectedOptionFormValue;
    const errors: any = {};

    // Validate Option Group Name
    if (!optionGroupName) {
      errors.optionGroupName = "Option group name is required.";
    }
    // Validate Minimum Value
    if (optionGroupMinValue === "") {
      errors.optionGroupMinValue = "Minimum value is required.";
    }
    // Validate Maximum Value
    if (optionGroupMaxValue === "") {
      errors.optionGroupMaxValue = "Maximum value is required.";
    } else if (parseFloat(optionGroupMaxValue) <= parseFloat(optionGroupMinValue)) {
      errors.optionGroupMaxValue = "Maximum value must be greater than the minimum value.";
    }
    // Validate Options
    if (options?.length === 0) {
      errors.options = "At least one option is required.";
    }
    return errors;
  };
  // Handle input change for New options
  const handleOptionInputChange = (index: number, field: string, value: string | number) => {
    setSelectedOptionFormValue((prevData) => {
      const optionsCopy = [...(prevData.options || [])];
      optionsCopy[index] = { ...optionsCopy[index], [field]: value };
      return { ...prevData, options: optionsCopy };
    });
  };

  const handleOnDragEnd = (result: any) => {
    if (!result?.destination) return;
    const items = Array?.from(dragListItem);
    const [reorderedItem] = items?.splice(result?.source?.index, 1);
    items?.splice(result?.destination?.index, 0, reorderedItem);
    setDragListItem(items);
    const destination = result?.destination;
    const newPosition = dragListItem && dragListItem[destination?.index]?.position;
    const draggableOptionSet = dragListItem?.find(
      (sectionId: any) => sectionId?.id === parseInt(result?.draggableId)
    );
    const payload = {
      optionset:
      {
        id: optionSetSelectedData?.id,
        options_attributes: [
          {
            id: draggableOptionSet?.id,
            position: newPosition,
          },
        ],
      },
    };
    // Dispatch your action with the payload
    selectedMenuId &&
      restaurantId &&
      dispatch(
        draganddrop(
          payload,
          selectedMenuId,
          +restaurantId,
          sectionid,
          +foodItemId,
          optionSetSelectedData?.id,
          loaderHandler
        )
      );
  };

  //Handle Submit form data for Edit
  const submitHandlerForEdit = () => {
    const { optionGroupMinValue, optionGroupMaxValue, optionGroupName } = selectedOptionFormValue;
    const optionGroupErrors = validateOptionSetsFormEditData(selectedOptionFormValue);
    // If there are any errors, update the state with the error messages and return
    if (Object?.keys(optionGroupErrors)?.length > 0) {
      setOptionGroupErrors(optionGroupErrors);
      return;
    }
    const optionSetPayload = {
        optionset: {
          name: optionGroupName,
          start_limit: optionGroupMinValue,
          end_limit: optionGroupMaxValue,
          options_attributes: selectedOptionFormValue?.options,
        }
    };
    selectedMenuId && restaurantId && (dispatch(editOptionSets(optionSetPayload, +selectedMenuId, +restaurantId, sectionid, +foodItemId, optionSetSelectedData?.id, loaderHandler, () => {} )))
  };

  return (
    <>
      {editoptionSetsPopup &&
        <CustomPopUp
          backdropStyles={{ opacity: 1, cursor: 'default' }}
          onPopUpClose={() => setEditOptionSetsPopup(false)}
          popUpBodyStyles={{ ...customStyles, top: '18%' }}
          allowScrolling={true}
        >
          <div className="modal-content ">
            <div className="menu-item-modal-header border-bottom border-2">
              <h3 className="fw-600 m-0 text-medium-black">Edit Option Group</h3>
              <button type="button" onClick={() => setEditOptionSetsPopup(false)} className="btn-close modal-close-btn text-dark" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="selection_modal_body">
              <div className="row">
                <div className="col-lg-4 border-end border-2 option_group">
                  <h5 className="fs-18 fw-600 pb-4 m-0">Option Group</h5>
                  <form>
                    <div className="mb-3">
                      <label htmlFor="optionGroupName" className="form-label menu-item-modal-labels">Option Group Name</label>
                      <input
                        type="email"
                        name="optionGroupName"
                        className="form-control form-control-sm rounded-1"
                        id="optionGroupName"
                        placeholder="Cheese Topping"
                        value={selectedOptionFormValue?.optionGroupName}
                        onChange={handleInputChange}
                      />
                      {optionGroupErrors.optionGroupName && <div className="invalid-feedback-password">{optionGroupErrors?.optionGroupName}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="optionGroupMinValue" className="form-label menu-item-modal-labels">Must Select (Min)</label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control form-control-sm rounded-1"
                          name='optionGroupMinValue'
                          min={0}
                          value={selectedOptionFormValue?.optionGroupMinValue}
                          onChange={handleInputChange}
                        />
                        {optionGroupErrors.optionGroupMinValue && <div className="invalid-feedback-password">{optionGroupErrors?.optionGroupMinValue}</div>}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="optionGroupMaxValue" className="form-label menu-item-modal-labels">Upto (Max)</label>
                      <div>
                        <input
                          type="number"
                          className="form-control form-control-sm rounded-1"
                          name='optionGroupMaxValue'
                          min={0}
                          value={selectedOptionFormValue.optionGroupMaxValue}
                          onChange={handleInputChange}
                        />
                        {optionGroupErrors.optionGroupMaxValue && <div className="invalid-feedback-password">{optionGroupErrors?.optionGroupMaxValue}</div>}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-8 edit_menu_options edit-option-popup">
                  <div className="d-flex justify-content-between align-items-start pb-4 m-0">
                    <h5 className="fs-18 fw-600 m-0">Options</h5>
                    <div className="btn-group">
                      <button type="button" className="px-2 py-1 btn btn-custom-outline-light rounded-pill dropdown-toggle btn-secondary" disabled data-bs-toggle="dropdown" aria-expanded="false">
                        New Option
                      </button>
                      <ul className="dropdown-menu">
                        <li><span className="dropdown-item" onClick={handleAddOptionSets}>Add Item</span></li>
                        <li><span className="dropdown-item" onClick={handleSelectExistingOptionSets}>Select Existing</span></li>
                      </ul>
                    </div>
                  </div>
                  <div className="row ">
                    <form>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="characters">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {dragListItem && dragListItem?.map((options: any, index: number) => (
                                <React.Fragment key={index}>
                                  <Draggable draggableId={String(options?.id)} index={index}>
                                    {(provided) => (
                                      <div {...provided.draggableProps} ref={provided.innerRef}>
                                        <div key={index} className="row d-flex align-items-baseline pb-3 option_wrapper">
                                          <div className="col-lg-1 form-check mb-4 d-flex justify-content-center">
                                            <span><i className="fa-regular fa-circle-dot"></i></span>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="mb-3">
                                              <input
                                                type="text"
                                                className="form-control form-control-sm rounded-1"
                                                id="optionsName"
                                                name='optionsName'
                                                disabled
                                                value={options?.description}
                                                onChange={(e) => handleOptionInputChange(index, 'description', e.target.value)}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mb-3">
                                              <input
                                                type="number"
                                                className="form-control form-control-sm rounded-1"
                                                placeholder="$ 3.00"
                                                name='optionsValue'
                                                disabled
                                                value={options.price}
                                                onChange={(e) => handleOptionInputChange(index, 'price', e?.target?.value)}
                                              />
                                            </div>
                                          </div>
                                          {/* removed the delete icon for already saved option sets */}
                                          <div className="col-lg-1 px-0 d-flex justify-content-center">
                                            <button type="button" className="btn rounded-circle p-2 delete-button border-0 btn-secondary" title="Delete" data-bs-dismiss="modal" disabled onClick={() => showDeletePopup(index)} >
                                              <span><i className="fa-regular fa-trash-can"></i></span>
                                            </button>
                                          </div>
                                          <div {...provided.dragHandleProps} className="col-lg-1">
                                            <span><i className="fa-solid fa-grip-dots-vertical"></i></span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                </React.Fragment>
                              ))}
                              {provided?.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <OptionGroupDeletePopup
                        openDeletePopup={openDeletePopup}
                        setOpenDeletePopup={setOpenDeletePopup}
                        onDeleteConfirmed={handleDeleteConfirmed}
                      />
                    </form>
                    {/* We cannot add new options for existing optionsets  */}
                    {/* <div>
                      <div className=" p-4 menu_list_add_area edit_menu position-relative">
                        <svg className="svg-inline--fa fa-plus fa-lg" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 256C432 264.8 424.8 272 416 272h-176V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"></path></svg>
                        <div className="d-flex align-items-center justify-content-center menu_list_button_area">
                          <button className="btn btn-lite-primary-sm">Add Item</button>
                          <button className="btn btn-lite-primary-sm">Select Existing</button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-item-modal-footer justify-content-end border-top border-2">
              <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill px-4" data-bs-dismiss="modal" onClick={() => setEditOptionSetsPopup(false)}>Cancel</button>
              <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32" onClick={submitHandlerForEdit}>{!loading ? 'Save' : <Spinner />}</button>
            </div>
          </div>
          {/* Select Existing Popup */}
          <SelectExistingPopup
            selectExistingPopUp={selectExistingPopUp}
            setSelectExistingPopUp={setSelectExistingPopUp}
            selectExistingOptionSets={selectExistingOptionSets}
          />
        </CustomPopUp>
      }
    </>
  )
}

export default EditOptionGroupPopup;