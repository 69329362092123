import React from 'react'
import Skeleton from 'react-loading-skeleton'

function MenuPageSkeleton() {
  const card = <div
    className='col-lg-6 col-md-12 col-sm-12 cursor-pointer override_row_padding my-2 py-1'
  >
    <Skeleton className='card menu-card rounded-10 menu_card_img_lunc menu_card_main_img' height={350} width={530} />
  </div>
  return (
    <div className='d-flex flex-wrap'>
      {[1, 2, 3, 4]?.map((val, index) => {
        return <React.Fragment key={index}>
          {card}
        </React.Fragment>
      })}
    </div>
  )
}

export default MenuPageSkeleton