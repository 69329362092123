import i18n from "i18next";

export const billingTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Number": "Number",
      "Total Items": "Total Items",
      "Orders From": "Orders From",
      "Orders To": "Orders To",
      "Food Total": "Food Total",
      "Commission": "Commission",
      "Total Payout": "Total Payout",
      "Status": "Status",
      "Details": "Details",

      "Delivery Date": "Delivery Date",
      "Sales Tax": "Sales Tax",
      "Bill Number": "Bill Number",
      "Payment includes orders from": "Payment includes orders from",
      "Total": "Total",
      "Due Date": "Due Date"
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Number": "نمبر",
      "Total Items": "کل اشیا",
      "Orders From": "سے آرڈر",
      "Orders To": "پر احکامات",
      "Food Total": "کھانا کل",
      "Commission": "کمیشن",
      "Total Payout": "کل ادائیگی",
      "Status": "حالت",
      "Details": "تفصیلات",

      "Delivery Date": "ادئیگی کی تاریخ",
      "Sales Tax": "سیلز ٹیکس",
      "Bill Number": "بل نمبر",
      "Payment includes orders from": "ادائیگی میں سے آرڈر بھی شامل ہیں",
      "Total": "کل",
    });
};