import React, { FC, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Skeleton from 'react-loading-skeleton';
import isEmpty from '../../Utilities/isEmpty';
import { RestaurantSettingAddress } from '../../Pages/Settings/Popups/GeneralSettingsPopUp';
import { getTimeZoneByLocation } from '../../Actions/getRestaurantActions';

export type AddressChangeHandler = (address: RestaurantSettingAddress) => void;

type Props = {
  handleAddress: AddressChangeHandler,
  value?: string,
  errors?: any
};

const GooglePlacesAutocomplete: FC<Props> = ({
  handleAddress,
  value,
  errors
}) => {

  const [addressLine, setAddressLine] = useState<string | undefined>(value);
  const [loadingPlace, setLoadingPlace] = useState<boolean>(false);

  const handleChange = (address: string) => {
    setAddressLine(address);

    if (isEmpty(address)) {
      handleAddress({ address_line: '' });
    };
  };

  const handleSelect = (address: string) => {
    setLoadingPlace(true);
    let city: string, state: string, street_number: string, zip: string, street: string;
    geocodeByAddress(address)
      .then(async results => {
        results && results[0] && results[0].address_components.map((item) => {
          item.types?.forEach(type => {
            if (type === 'locality') {
              city = item.long_name;
            }

            if (type === 'administrative_area_level_1') {
              state = item.short_name;
            }

            if (type === 'street_number') {
              street_number = item.short_name;
            }

            if (type === 'route') {
              street = item.short_name;
            }

            if (type === 'postal_code') {
              zip = item.short_name;
            }
          })
          return true;
        });

        let latLang = await getLatLng(results[0])
        let response = await getTimeZoneByLocation(latLang.lat, latLang.lng);
        let timezone = response?.timeZoneId;
        handleAddress({
          city,
          state,
          zip,
          street_number,
          street,
          address_line: address,
          longitude: latLang.lng,
          latitude: latLang.lat,
          timezone: timezone
        });
        handleChange(address);
        setLoadingPlace(false);
      })
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={addressLine}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          {loadingPlace ? <Skeleton height={38} /> : <input
            {...getInputProps({
              name: 'addressLine',
              placeholder: 'Search Places',
              className: `form-control ${errors?.addressLine ? 'is-invalid' : ''
                }`,
            })}
          />}
          <div className='position-absolute'>
            {suggestions?.map((suggestion, index) => {
              const className = suggestion?.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion?.active
                ? {
                  backgroundColor: '#dee2e6',
                  cursor: 'pointer',
                  padding: '7px',
                  width: '97.5%',
                }
                : {
                  backgroundColor: '#ffffff',
                  cursor: 'pointer',
                  padding: '7px',
                  width: '97.5%',
                };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                    key: index,
                    onClick: handleSelect
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutocomplete;