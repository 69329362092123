import React, { CSSProperties, FC } from 'react';

export type TableColumn = {
  label: string,
  columnName?: string;
  sortable?: boolean;
  hidden?: boolean;
  className?: string;
  style?: CSSProperties,
  id?: string;
}

type Props = {
  columns: TableColumn[];
  sortingColumn?: string | string[];
  handleSorting?: (sortType: string) => void;
  inlineHeader?: boolean
};

const TableHeader: FC<Props> = ({ columns, sortingColumn, handleSorting, inlineHeader }) => {
  return (
    <thead id='table-header'>
      <tr className='bg-dark text-white'>
        {columns.map(
          (column, index) =>
            !column.hidden && (
              <th
                className={`${column.sortable ? 'clickable' : ''}`}
                style={{ padding: '12px' }}
                onClick={() => {
                  (column.sortable && column.columnName && handleSorting) && handleSorting(column.columnName)
                }
                }
                key={index}
                id={column.id}
              >
                <span className={`${inlineHeader ? 'd-flex text-nowrap' : ''}`} style={{ gap: 10 }}>
                  {column.label}
                  {column.sortable && (
                    <span className='icon-span'>
                      <i
                        className={`fas fa-sort sort-icon float-right ${column.columnName === sortingColumn ? 'sorted-column' : ''
                          }`}
                        aria-hidden='true'
                      />
                    </span>
                  )}
                </span>
              </th>
            )
        )}
      </tr>
    </thead>
  );
}

export default TableHeader;