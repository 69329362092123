import React, { FC, useState } from 'react'
import CustomPopUp from '../../../Common/Popups/CustomPopUp'
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import Spinner from '../../../Common/Spinner';
import { editMenuFoodItem } from '../../../Actions/menuActions';
import { Params } from '../../Dashboard/Dashboard';
import { useParams } from 'react-router';
import isEmpty from '../../../Utilities/isEmpty';
import { Dietary, FoodItem, Ingredient } from '../../../Interfaces/PayloadsAndResponses/Menu';
import parse from 'html-react-parser';

type foodItemFormData = {
  name: string,
  price: string,
  description: string,
  position: number,
  section_visibility: boolean,
  image: any,
  dietary_ids: Dietary[],
  ingredient_ids: Ingredient[]
}

type EditFoodItemPopUpProps = {
  editFoodItemPopUP: boolean;
  setEditFoodItemPopUp: (status: boolean) => void;
  singleFoodItem: FoodItem;
  foodItemDiet: Dietary[];
  foodItemIng: Ingredient[];
  getUpdatedFooditem?: () => void;
}

const EditFoodItemPopUp: FC<EditFoodItemPopUpProps> = ({ editFoodItemPopUP, setEditFoodItemPopUp, singleFoodItem, foodItemDiet, foodItemIng, getUpdatedFooditem }) => {
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? ''
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';
  const getMenutype = localStorage.getItem('menuType')
  const { restaurantId } = useParams<Params>();
  const dispatch: any = useDispatch()
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [selectedFoodItem, setSelectedFoodItem] = useState<foodItemFormData>({
    name: singleFoodItem?.name ?? '',
    price: singleFoodItem?.price ?? '',
    description: singleFoodItem?.description ?? '',
    position: 1,
    section_visibility: !singleFoodItem?.is_hidden,
    image: selectedImage,
    dietary_ids: foodItemDiet,
    ingredient_ids: foodItemIng
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const foodItemImageHandler = (acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader?.result as string; // Get the Base64 encoded string
        setSelectedImage(
          new File([acceptedFiles[0]], base64String, { type: acceptedFiles[0]?.type })
        );
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  const handleChangeForEdit = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e?.target;
    const fieldValue = type === 'checkbox' ? (e.target as HTMLInputElement)?.checked : value;
    setSelectedFoodItem(prevData => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const dietariesListChangeHandler = (index: number) => {
    const updatedDiet = [...selectedFoodItem?.dietary_ids]
    updatedDiet[index] = { ...updatedDiet[index], selected: !updatedDiet[index]?.selected };
    const updatedSelectedFoodItem = {
      ...selectedFoodItem,
      dietary_ids: updatedDiet
    };
    setSelectedFoodItem(updatedSelectedFoodItem);
  }
  const ingredientsListChangeHandler = (index: number) => {
    const updatedIngrd = [...selectedFoodItem?.ingredient_ids]
    updatedIngrd[index] = { ...updatedIngrd[index], selected: !updatedIngrd[index]?.selected };
    const updatedSelectedFoodItem = {
      ...selectedFoodItem,
      ingredient_ids: updatedIngrd
    }
    setSelectedFoodItem(updatedSelectedFoodItem)
  }

  const handleChangeEditCheckbox = () => {
    setSelectedFoodItem(prevData => ({
      ...prevData,
      section_visibility: !selectedFoodItem.section_visibility,
    }));
  };
  const loaderChange = () => {
    setEditFoodItemPopUp(false)
    setIsLoading(false)
    getUpdatedFooditem?.()
  }

  const foodItemUpdateSubmitHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true);
    e?.preventDefault();
    let selectedDietaries: number[] = [], selectedIngredients: number[] = [];
    selectedFoodItem?.dietary_ids?.forEach(dietary => { dietary?.selected && selectedDietaries?.push(dietary?.id); })
    selectedFoodItem?.ingredient_ids?.forEach(ingredient => { ingredient?.selected && selectedIngredients?.push(ingredient?.id) });
    const imageBase64 = selectedImage ? selectedImage.name : null;
    const foodItemPayload = {
     fooditem: {
       name: selectedFoodItem?.name,
       ...(getMenutype === 'buffet' ? {} : { price: selectedFoodItem?.price }),
       price: selectedFoodItem?.price,
       description: selectedFoodItem?.description,
       is_hidden: !selectedFoodItem?.section_visibility,
       dietary_ids: selectedDietaries,
       ingredient_ids: selectedIngredients,
       image: imageBase64
     }
   }
    restaurantId && dispatch(editMenuFoodItem(foodItemPayload, selectedMenuId, +restaurantId, sectionid, `${singleFoodItem?.id}`, loaderChange))
  }



  return (
    <div>
      {editFoodItemPopUP &&
        <CustomPopUp
          popUpClassName="food-item-popup"
          backdropStyles={{ opacity: 1, cursor: 'default' }}
          onPopUpClose={() => setEditFoodItemPopUp(false)}
          allowScrolling={true}

        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content ">
              <div className="menu-item-modal-header">
                <h3 className="fw-600 m-0 text-medium-black">Edit Food Item</h3>
                <button type="button" className="btn-close modal-colose-btn text-dark" onClick={() => setEditFoodItemPopUp(false)} data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body py-0" style={{ height:'50vh'}}>
                <p className="fw-normal fs-16 mb-1">Images</p>
                <Dropzone
                  onDrop={foodItemImageHandler}
                  // @ts-ignore
                  accept='image/jpg, image/jpeg, image/png'
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className='bg-light d-flex justify-content-center align-items-center'
                      style={{ border: '2px dashed #0087F7', borderRadius: '5px', height: '175px' }}>
                      <div className='h-100 w-100'  {...getRootProps()} >
                        <input {...getInputProps()} />
                        {singleFoodItem?.image && selectedImage === null ?
                          <div className='fooditem-image h-100 w-100 d-flex justify-content-center align-items-center'>
                            {!isEmpty(singleFoodItem?.image?.url ?? singleFoodItem?.image?.medium?.url ?? singleFoodItem?.image?.thumb?.url) ? <img alt='food item' src={singleFoodItem?.image?.url ?? singleFoodItem?.image?.medium?.url ?? singleFoodItem?.image?.thumb?.url ?? ''}
                              className='p-0 img-fluid rounded-10 h-100 w-100' /> :
                              <img src="/imgs/menu-page-place-holder1.svg" alt="Place Holder" className="p-0 img-fluid rounded-10 h-100 w-100" />
                            }
                          </div>
                          : selectedImage ? (
                            <div className='fooditem-image h-100 w-100 d-flex justify-content-center align-items-center'>
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt='fooditem'
                                style={{ width: '200px', height: 'auto', objectFit:'contain' }}
                              />
                            </div>
                          ) : (
                            null
                          )
                        }

                      </div>
                    </section>
                  )}
                </Dropzone>
                <form>
                  <div className="row mb-3 mt-3">
                    <div className="col">
                      <label className="fs-16 fw-600 text-medium-gray m-0">Name</label>
                      <input type="text" name="name" value={selectedFoodItem?.name} onChange={(e) => handleChangeForEdit(e)} className="form-control form-control-sm rounded-1" aria-label="First name" />
                    </div>
                    <div className={getMenutype === 'buffet' ? 'col-12' : 'col'}>
                      {getMenutype !== 'buffet' &&
                        <div>
                          <label className="fs-16 fw-600 text-medium-gray m-0">Price</label>
                          <div className="input-group input-group-sm">
                            <span className="input-group-text" id="basic-addon1">$</span>
                            <input type="number" name="price" value={selectedFoodItem?.price} onChange={(e) => handleChangeForEdit(e)} className="form-control form-control-sm" aria-label="price" aria-describedby="basic-addon1" />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="fs-16 fw-600 text-medium-gray m-0">Description</label>
                    <textarea name='description' value={selectedFoodItem?.description} onChange={(e) => handleChangeForEdit(e)} className="form-control form-control-sm rounded-1" rows={4} id="exampleFormControlTextarea1"></textarea>
                  </div>
                  <div className="mb-3">
                    <p className="fs-16 fw-600 text-medium-gray m-0">Dietaries</p>
                    <div className="d-flex flex-wrap align-items-center">
                      {selectedFoodItem?.dietary_ids?.map((dietary, index) => {
                        return <span key={index}>
                          <button type='button'
                            key={dietary?.id}
                            className={`btn btn-cuisine py-2 px-3 d-flex gap-2 align-items-center m-1 ${dietary?.selected ? 'btn-dark' : 'btn-outline-dark'}`}
                            name={`${dietary?.name}-${dietary?.id}`}
                            value={dietary?.id}
                            onClick={() => dietariesListChangeHandler(index)}
                            id={`dietary-${dietary?.id}`}>
                            {dietary?.logo ? (
                              <div className="d-inline-flex justify-content-center align-items-center ml-1 tag-pills-svgs">
                                {parse(dietary?.logo)}
                              </div>
                            ) : (
                              <div className="text-uppercase tagging-text">{dietary?.name?.charAt(0)}</div>
                            )}
                            {/* <img src="/imgs/beef.svg" className="cuisines-diet-img" alt="dietary" /> */}
                            {dietary?.name}
                          </button>
                        </span>
                      })}
                    </div>
                    <p className="fs-16 fw-600 text-medium-gray m-0 mt-3">Ingredients</p>
                    <div className="d-flex flex-wrap align-items-center">
                    {selectedFoodItem?.ingredient_ids?.map((ingredient, index) => {
                        return <span key={index}>
                          <button type='button'
                            key={ingredient.id}
                            className={`btn btn-cuisine py-2 px-3 d-flex gap-2 align-items-center m-1 ${ingredient?.selected ? 'btn-dark' : 'btn-outline-dark'}`}
                            name={`${ingredient?.name}-${ingredient?.id}`}
                            value={ingredient?.id}
                            onClick={() => ingredientsListChangeHandler(index)}
                            id={`ingredient-${ingredient?.id}`}>
                            {ingredient?.logo ? (
                              <div className="d-inline-flex justify-content-center align-items-center ml-1 tag-pills-svgs">
                                {parse(ingredient?.logo)}
                              </div>
                            ) : (
                              <div className="text-uppercase tagging-text">{ingredient?.name?.charAt(0)}</div>
                            )}
                            {/* <img src="/imgs/beef.svg" className="cuisines-diet-img" alt="ingregient" /> */}
                            {ingredient?.name}
                          </button>
                        </span>
                      })}
                    </div>
                  </div>
                  <p className="fs-16 fw-600 text-medium-gray m-0 mb-1">Visibility</p>
                  <div className="mb-3 form-check d-flex align-items-center ps-0">
                    <input type="checkbox" className="form-check-input m-0" name='food-item-visibility' checked={selectedFoodItem?.section_visibility} onChange={handleChangeEditCheckbox} id="food-item-visibility" />
                    <label className="fs-16 fs-600 text-medium-black ps-4 m-0" htmlFor='food-item-visibility'>Visible for Customers</label>
                  </div>
                </form>
              </div>
              <div className="menu-item-modal-footer justify-content-end">
                <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill py-2 px-32" onClick={() => setEditFoodItemPopUp(false)} data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32" onClick={(e) => foodItemUpdateSubmitHandler(e)}>{!isLoading ? 'Save' : <Spinner />}</button>
              </div>
            </div>
          </div>
        </CustomPopUp>
      }
    </div>
  )
}

export default EditFoodItemPopUp