import React from "react";

// Add sizes to whitelist and register them
// const Size = Quill.import("formats/size");
// Size.whitelist = ["extra-small", "small", "medium", "large"];
// Quill.register(Size, true);

// Add fonts to whitelist and register them
// const Font = Quill.import("formats/font");
// Font.whitelist = [
//   "arial",
//   "comic-sans",
//   "courier-new",
//   "georgia",
//   "helvetica",
//   "lucida"
// ];
// Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats mx-0">
      <select className="ql-font"></select>
      <select className="ql-size"></select>
    </span>
    <span className='mx-2 text-muted'>|</span>
    <span className="ql-formats mx-0">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
    </span>
    <span className='mx-2 text-muted'>|</span>
    <span className="ql-formats mx-0">
      <select className="ql-color"></select>
      <select className="ql-background"></select>
    </span>
    <span className='mx-2 text-muted'>|</span>
    <span className="ql-formats mx-0">
      <button className="ql-script" value="sub"></button>
      <button className="ql-script" value="super"></button>
    </span>
    <span className='mx-2 text-muted'>|</span>
    <span className="ql-formats mx-0">
      <button className="ql-header" value="1"></button>
      <button className="ql-header" value="2"></button>
      <button className="ql-blockquote"></button>
      <button className="ql-code-block"></button>
    </span>
    <span className='mx-2 text-muted'>|</span>
    <span className="ql-formats mx-0">
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-indent" value="-1"></button>
      <button className="ql-indent" value="+1"></button>
    </span>
    <span className='mx-2 text-muted'>|</span>
    <span className="ql-formats mx-0">
      <button className="ql-direction" value="rtl"></button>
      <select className="ql-align"></select>
    </span>
    <span className='mx-2 text-muted'>|</span>
    <span className="ql-formats mx-0">
      <button className="ql-link"></button>
      <button className="ql-image"></button>
      <button className="ql-video"></button>
      <button className="ql-formula"></button>
    </span>
    <span className='mx-2 text-muted'>|</span>
    <span className="ql-formats mx-0">
      <button className="ql-clean"></button>
    </span>
  </div>
);

export default QuillToolbar;
