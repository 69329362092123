import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getRedirectURL } from '../../Actions/authActions';
import Spinner from '../../Common/Spinner';

const ShortURL = () => {
  const dispatch: any = useDispatch();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  useEffect(() => {
    dispatch(getRedirectURL(token, history))
  }, [dispatch, history, token])

  return <div className='min-vw-100 min-vh-100 d-flex justify-content-center align-items-center'>
    <Spinner />
  </div>
}
export default ShortURL;