export const SET_CURRENT_USER_AUTHENTICATION = 'SET_CURRENT_USER_AUTHENTICATION';
export const GET_ERRORS = 'GET_ERRORS';
export const GET_CONNECTION_STATUS = 'GET_CONNECTION_STATUS';
export const LOADING = 'LOADING';
export const GET_MEETINGS_LOADING = 'GET_MEETINGS_LOADING';
export const GET_RESTAURANT_DETAILS = 'GET_RESTAURANT_DETAILS';
export const GET_RESTAURANT_DETAILS_LOADING = 'GET_RESTAURANT_DETAILS_LOADING';
export const EMPTY_RESTAURANT_DETAILS = 'EMPTY_RESTAURANT_DETAILS';
export const GET_RESTAURANT_ADMINS = 'GET_RESTAURANT_ADMINS';
export const UPDATE_RESTAURANT_ADMINS = 'UPDATE_RESTAURANT_ADMINS';
export const UPDATING_FOODITEM = 'UPDATING_FOODITEM';
export const SET_DISHSIZE_LOADING = 'SET_DISHSIZE_LOADING';
export const REMOVE_DISHSIZE_LOADING = 'REMOVE_DISHSIZE_LOADING';
export const GET_MEETINGS = 'GET_MEETINGS';
export const GET_CUISINES = 'GET_CUISINES';
export const GET_RESTAURANT_DRIVERS = 'GET_RESTAURANT_DRIVERS';
export const SET_DRIVER_LOADING = 'SET_DRIVER_LOADING';
export const GET_SINGLE_ORDER_DETAILS = 'GET_SINGLE_ORDER_DETAILS';
export const SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL';
export const RESET_STATUS = 'RESET_STATUS';
export const ACCEPT_ORDERS = 'ACCEPT_ORDERS';
export const UPDATE_MEETING_STATUS = 'UPDATE_MEETING_STATUS';
export const GET_USERS_LIST_FOR_CONFIRMATION = 'GET_USERS_LIST_FOR_CONFIRMATION';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const GET_MENUS = 'GET_MENUS';
export const GET_MENUS_SECTIONS ='GET_MENUS_SECTIONS';
export const GET_SELECTED_MENU_TYPE = 'GET_SELECTED_MENU_TYPE';
export const GET_SELECTED_RESTAURANT_ID = 'GET_SELECTED_RESTAURANT_ID';
export const GET_SYSTEM_DIETRIES = 'GET_SYSTEM_DIETRIES';
export const GET_SYSTEM_INGREDIENTS = 'GET_SYSTEM_INGREDIENTS';
export const UPDATE_SECTIONS = 'UPDATE_SECTIONS';
export const SECTION_LOADING = 'SECTION_LOADING';
export const SELECT_EXISTING_FOOD_ITEMS = 'SELECT_EXISTING_FOOD_ITEMS';
export const SECTION_FOODITEMS = 'SECTION_FOODITEMS';
export const SECTION_FOODITEMS_LOADING = "SECTION_FOODITEMS_LOADING";
export const GET_FOOD_ITEMS = 'GET_FOOD_ITEMS';
export const SELECT_EXISTING_OPTION_SETS = 'SELECT_EXISTING_OPTION_SETS';
export const GET_BILLINGS = 'GET_BILLINGS';
export const GET_BILLING = 'GET_BILLING';
export const BILLING_LOADING = 'BILLING_LOADING';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_SINGLE_INVOICE_DETAIL = 'GET_SINGLE_INVOICE_DETAIL';
export const GET_INVOICE_EMAIL = 'GET_INVOICE_EMAIL';
export const INVOICES_LOADING = 'INVOICES_LOADING';
export const GET_FRESHDESK_LOGS = 'GET_FRESHDESK_LOGS';
export const FRESHDESK_LOODING = 'FRESHDESK_LOODING';
export const GET_RESTAURANTS_LOCATIONS = 'GET_RESTAURANTS_LOCATIONS';
export const LOG_OUT = 'LOG_OUT';
export const DESTROY_NOTIFICATION = 'DESTROY_NOTIFICATION';
export const SET_PROFILE_REQUEST_TYPE = 'SET_PROFILE_REQUEST_TYPE';
export const TRANSLATE_I18N = 'TRANSLATE_I18N';
export const SIGN_UP_USER = 'SIGN_UP_USER';
export const POST_HELP_AND_SUPPORT = 'POST_HELP_AND_SUPPORT';
export const SAVE_DRIVER = 'SAVE_DRIVER';
export const GET_DRIVERS = 'GET_DRIVERS';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';
export const POST_LOGS_LOADING = 'POST_LOGS_LOADING';
export const DELETE_RESTAURANT_ADMINS = 'DELETE_RESTAURANT_ADMINS';
export const IS_CONNECTED_STRIPE = 'IS_CONNECTED_STRIPE';
export const GET_STATISTICS_REPORT = 'GET_STATISTICS_REPORT';
export const GET_STATISTICS_REPORT_LOADING = 'GET_STATISTICS_REPORT_LOADING';
export const SET_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING = 'SET_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING';
export const STOP_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING = 'STOP_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING';
export const ADD_FOOD_ITEM_LOADING = 'ADD_FOOD_ITEM_LOADING'