import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-modal';
import ReactQuill from 'react-quill';
// import { ReactMultiEmail, isEmail } from 'react-multi-email';
// import 'react-multi-email/style.css';
import QuillToolbar, { modules, formats } from '../QuillToolbar';
import 'react-quill/dist/quill.snow.css';
import '../invoices.css'
import isEmpty from '../../../Utilities/isEmpty';
import { sendInvoiceEmail } from '../../../Actions/invoicesActions';
import { emailBodyHTML } from '../InvoiceUtility';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '../../../Common/Spinner';
import { showToastNotification } from '../../../Common/showToastNotification';
import { InvoiceEmailData } from '../../../Interfaces/PayloadsAndResponses/Invoice';
import { emailPattern } from '../../../Utilities/validationPatterns';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '692px',
    width: '90vw',
    padding: '0',
  }
};
type props = {
  modalIsOpen: boolean,
  closeModal: () => void,
  emailData: InvoiceEmailData | null
}

const InvoiceEmailPopup: FC<props> = ({ modalIsOpen, closeModal, emailData }) => {
  const dispatch: any = useDispatch();
  const { id: invoiceId, restaurantId } = useParams<{ id: string, restaurantId: string }>();
  const resturantAdminEmail = JSON.parse(localStorage.getItem('headers') || '{}')?.uid as string;
  // const [emails, setEmails] = useState<string[]>([]);
  // const [ccEmails, setCCEmails] = useState<string[]>([]);
  const [emailSubject, setemailSubject] = useState<string>(``);
  const [emailBody, setEmailBody] = useState<string>(``);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [emailSubmitting, setEmailSubmitting] = useState<boolean>(false);

  const [emails, setEmails] = useState<string[]>([]);
  const [ccEmails, setCCEmails] = useState<string[]>([]);
  // const [items, setItems] = useState<string[]>([]);
  const [emailValue, setEmailValue] = useState<string>('');
  const [ccValue, setCCValue] = useState<string>('');

  // const [error, setError] = useState<string | null>(null);

  const handleKeyDown = (evt: any) => {

    if(['Enter', 'Tab', ',', ' '].includes(evt.key)) {
      evt.preventDefault();

      const type = evt.target.name

      if(type === 'email') {
      var inputEmailValue: string = emailValue.trim();

        if(emailValue && isValid(inputEmailValue, type)) {
          setEmails([...emails, emailValue]);
          setEmailValue('');
        }
      } else if (type === 'ccEmail') {
        
        if(ccValue && isValid(ccValue.trim(), type)) {
          setCCEmails([...ccEmails, ccValue]);
          setCCValue('');
        }
      }

      // var inputValue: string = evt.target.name === 'email' ? emailValue.trim() : ccValue.trim();
      // if(emailValue && isValid(inputValue)) {
      //   setEmails([...emails, emailValue]);
      //   setEmailValue('');
      // }
    }
  };

  const isValid = (email: string, type: 'email' | 'ccEmail') => {
    let error = null;

    if(isInList(email, type) && type === 'email') {
      // error = `${email} has already been added. `;
      setEmailValue('');
      return false;
    } else if(isInList(email, type) && type === 'ccEmail') {
      setCCValue('');
      return false;
    }

    if(!isEmail(email)) {
      error = `${email} is not a valid email address. `;
    }

    if(error) {
      // setError(error);
      return false;
    }
    return true;
  };

  const isInList = (email: string, type: 'email' | 'ccEmail') => {
    if (type === 'email') {
      return emails.includes(email);
    } else {
      return emails.includes(email);
    }
  }

  const isEmail = (email: string) => {
    return emailPattern.test(email);
  }

  const emailSubmittingHandler = () => {
    setEmailSubmitting(false);
    showToastNotification('Email sent successfully.', 'Success')
    closeModal();
  }
  const emailHandler = () => {
    setFormSubmitted(true);
    if (!isEmpty(emails) && !isEmpty(ccEmails) && !isEmpty(emailSubject) && !isEmpty(emailBody)) {
      const mail_body = emailBodyHTML(emailBody);
      const emailData = {
        recipients: emails.join(','),
        sender: 'finance@chowmill.com',
        cc: ccEmails.join(', '),
        subject: emailSubject,
        mail_body,
      }
      setEmailSubmitting(true);
      dispatch(sendInvoiceEmail(emailData, invoiceId, restaurantId, emailSubmittingHandler));
    }
  }
  useEffect(() => {
    if (!isEmpty(emailData)) {
      setEmails(!isEmpty(emailData?.recipients) ? [emailData?.recipients!] : []);
      setCCEmails(!isEmpty(emailData?.cc) ? [emailData?.cc!, resturantAdminEmail] : [resturantAdminEmail]);
      setemailSubject(emailData?.subject!);
      setEmailBody(`<p>Dear ${emailData?.company_name!},</p><br><p>Please see attached due invoice(s) for service from ${emailData?.shipping_body}</p><br><p>We look forward to an early resolution</p><br><p>Have a great day!</p><br><p>Best,</p><p>Chowmill</p>`)
    }
  }, [emailData, resturantAdminEmail])
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Example Modal'>
      <div className='modal-header p-2'>
        <h5 className='modal-title' id='modal-title'>Forward Email</h5>
        <button type='button' onClick={closeModal} className='close m-0 p-0' data-dismiss='modal' aria-label='Close'><span aria-hidden='true' style={{ color: 'red' }}>×</span></button>
      </div>
      <div id='invoicesEmailPopup' className='modal-body p-2' style={{ width: '100%' }}>
        <form className='p-2'>
          <div className='form-group recipients-container'>
            <label htmlFor='email'><b>Recipients <span style={{ color: 'red' }}>*</span></b></label>
            {/* <ReactMultiEmail
              placeholder='Enter email'
              emails={emails}
              onChange={(_emails: string[]) => setEmails(_emails)}
              // validateEmail={email => isEmail(email)}
              getLabel={(
                email: string,
                index: number,
                removeEmail: (index: number) => void,
              ) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                  </div>
                );
              }}
            /> */}
            <div className='recipients-container-field'>
              {emails.map((email) => (
                <div key={email} className='email-pill'>
                  {email}
                  <button
                    type='button'
                    className=' btn email-button p-0'
                    onClick={() => setEmails( emails.filter((i) => i !== email )) }
                  >
                    &times;
                  </button>
                </div>
              ))}
              <input
                className='email-input'
                name='email'
                value={emailValue}
                placeholder='Enter email'
                onKeyDown={handleKeyDown}
                onChange={(evt) => setEmailValue(evt.target.value)}
              />
            </div>
            {/* {error && <p className='error'>{error}</p>} */}

            {formSubmitted && isEmpty(emails) && <p className='text-danger mt-1'>Email field is required.</p>}
          </div>


          <div className='form-group recipients-container'>
            <label htmlFor='Cc'><b>Cc</b></label>
            {/* <ReactMultiEmail
              placeholder='Cc'
              emails={ccEmails}
              onChange={(_emails: string[]) => setCCEmails(_emails)}
              // validateEmail={email => isEmail(email)}
              getLabel={(
                email: string,
                index: number,
                removeEmail: (index: number) => void,
              ) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => { removeEmail(index) }}>×</span>
                  </div>
                );
              }}
            /> */}
            <div className='recipients-container-field'>
              {ccEmails.map((ccEmail) => (
                <div key={ccEmail} className='email-pill'>
                  {ccEmail}
                  <button
                    type='button'
                    className=' btn email-button p-0'
                    onClick={() => setCCEmails( ccEmails.filter((i) => i !== ccEmail )) }
                  >
                    &times;
                  </button>
                </div>
              ))}
              <input
                className='email-input'
                name='ccEmail'
                value={ccValue}
                placeholder='Enter email'
                onKeyDown={handleKeyDown}
                onChange={(evt) => setCCValue(evt.target.value)}
                // onChange={handleChange}
              />
            </div>
            {/* {error && <p className='error'>{error}</p>} */}
            {formSubmitted && isEmpty(ccEmails) && <p className='text-danger mt-1'>Cc field is required.</p>}

          </div>




          <div className='form-group'>
            
          </div>
          <div className='form-group'>
            <label htmlFor='subject'><b>Subject</b></label>
            <input type='text' value={emailSubject} className='form-control p-2' id='subject' placeholder='Email subject' onChange={e => setemailSubject(e.target.value)} />
            {formSubmitted && isEmpty(emailSubject) && <p className='text-danger mt-1'>Subject field is required.</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='subject'><b>Mail body</b></label>
            <div className='border'>
              <QuillToolbar />
              <div className='mb-4 email-popup-editor px-4 pt-4 d-flex flex-column overflow-auto' style={{ backgroundColor: '#eff3fe', height: '30vh' }}>
                <div className='text-center p-1 mb-4 mx-auto'>
                  <img src='/imgs/chowmill-logo-full-u.png' alt='logo' style={{ width: '200px' }} />
                </div>
                <div className='d-flex justify-content-center w-100 m-auto' style={{ border: '1px solid #ccc' }}>
                  <div className='bg-white' style={{ width: '2rem' }}></div>
                  <ReactQuill
                    placeholder={'Email Body...'}
                    modules={modules}
                    formats={formats}
                    value={emailBody}
                    onChange={(content) => setEmailBody(content)}
                  />
                  <div className='bg-white' style={{ width: '2rem' }}></div>
                </div>
                {formSubmitted && isEmpty(emailBody) && <p className='text-danger mt-1 text-center'>Email body field is required.</p>}
                <div className='w-100'>
                  <table className='email-footer'>
                    <tbody>
                      <tr style={{ verticalAlign: 'top', padding: 0 }}>
                        <td valign='top' className='align-top text-center p-0' align='left'>
                          <p style={{ margin: '20px 0' }}>
                            Chowmill: One platform for all <span className='il'>your</span> workplace meals.  <br />
                            <a href='https://www.chowmill.com'>www.chowmill.com</a> |&nbsp;
                            <a href='mailto:support@chowmill.com'>support@chowmill.com</a> |&nbsp;
                            <span className='text-nowrap'>(408) 883-9415</span>
                          </p>
                          <p style={{ margin: '20px', paddingBottom: '20px' }}></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='modal-footer p-2'>
        <button type='button' className='btn btn-dark' onClick={emailHandler} style={{ minWidth: '85px' }}>
          {emailSubmitting ?
            <Spinner size='spinner-border-sm' /> :
            <b>Send</b>
          }
        </button>
      </div>
    </Modal>)
}

export default InvoiceEmailPopup
