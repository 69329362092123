// import React, { Component } from 'react';
// import Modal from 'react-modal';
// import { connect } from 'react-redux';
// import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { updateOrderStatus } from '../../Actions/orderActions';
// import Spinner from '../Spinner';
// import { Params } from '../../Pages/Dashboard/Dashboard';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '90vw',
//     maxWidth: '600px',
//     padding: '0',
//   }
// };

// const initialState = {
//   isOpen: false,
//   isOrderConfirmed: false,
//   isConfirmingOrder: false,
//   orderDateAndTime: '',
//   messageToCustomer: ''
// };

// type UpdateOrderStatusPopupProps = {
//   onRef: (ref: UpdateOrderStatusPopupRef | null) => void;
//   meetingConfirmedCallback: () => void;
//   updateOrderStatus: (message: {
//     message: string;
//     type: "accept";
//   }, meetingId: number, restaurantId: string | number, order_confirmed_callback: () => void) => void;
// } & RouteComponentProps<Params>;

// Modal.setAppElement('#root');
// Modal!.defaultStyles!.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal!.defaultStyles!.overlay!.zIndex = 1050;

// class UpdateOrderStatusPopup extends Component<UpdateOrderStatusPopupProps, typeof initialState> {
//   state = { ...initialState };

//   componentDidMount() {
//     this.props.onRef(this);
//   };

//   componentWillUnmount() {
//     this.props.onRef(null);
//   };

//   openModal = (orderDateAndTime: string) => {
//     this.setState({
//       isOpen: true,
//       orderDateAndTime,
//     });
//   };

//   closeModal = () => {
//     if (this.state.isOrderConfirmed) {
//       this.props.meetingConfirmedCallback?.();
//     }
//     this.setState({ ...initialState });
//   };

//   callbackMeetingConfirmed = () => {
//     this.setState({ isConfirmingOrder: false, isOrderConfirmed: true });
//   }

//   handleSubmit = () => {
//     const { match: { params: { restaurantId, id: meetingId } } } = this.props;
//     if (meetingId && restaurantId) {
//       this.setState({ isConfirmingOrder: true });
//       this.props.updateOrderStatus({ message: this.state.messageToCustomer, type: 'accept' },
//         +meetingId, restaurantId, this.callbackMeetingConfirmed);
//     }
//   };

//   render() {
//     const {
//       isOpen,
//       isOrderConfirmed,
//       isConfirmingOrder,
//       messageToCustomer,
//       orderDateAndTime
//     } = this.state
//     return (
      // <Modal
      //   isOpen={isOpen}
      //   onRequestClose={this.closeModal}
      //   style={customStyles}
      //   shouldCloseOnOverlayClick={!isConfirmingOrder}
      // >
      //   <div className='order-modal'>
      //     <div className='slide'>
      //       <div className='modal-content cursor-default'>
      //         <div className='modal-header'>
      //           <h3
      //             id='modal-title'
      //             className='modal-title'
      //             style={{ fontWeight: 600, fontSize: 22 }}
      //           >
      //             {isOrderConfirmed ? 'Order Confirmation' : 'Accept Order'}
      //           </h3>
      //           <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'>
      //             <span aria-hidden='true'>×</span>
      //           </button>
      //         </div>
      //         <div>
      //           <div id='order-status-update-popup' className='modal-body py-2'>
      //             {isOrderConfirmed ?
      //               <div className='d-flex flex-column justify-content-center align-items-center' style={{ minHeight: 266, gap: 20 }}>
      //                 <img width={150} height={150} src='/imgs/confirmation-tick.gif' alt='confirmation-tick' />
      //                 {orderDateAndTime ?
      //                   <p style={{ fontSize: 18, color: '#646464' }} className='text-center'>
      //                     Thank you for confirming this order on &nbsp;
      //                     <br />
      //                     <span style={{ fontWeight: 600 }}>{orderDateAndTime}</span>
      //                   </p> : null}
      //               </div> :
      //               <div className='py-3' style={{ minHeight: 266 }}>
      //                 <p style={{ color: '#646464', fontSize: 16 }}>
      //                   Please click button below to accept this order. For any questions, Please reach out to customer directly.
      //                 </p>
      //                 <div className='d-flex justify-content-between align-items-center'>
      //                   <p className='m-0' style={{ color: '#646464', fontSize: 16, fontWeight: 600 }}>
      //                     Message to customer
      //                   </p>
      //                   <p style={{ fontSize: 16, color: '#646464' }}>Optional</p>
      //                 </div>
      //                 <textarea
      //                   id='message'
      //                   rows={4}
      //                   name='message'
      //                   className={`form-control my-3`}
      //                   placeholder={`e.g. Recommendations for replacement items.`}
      //                   value={messageToCustomer}
      //                   onChange={(e) => this.setState({ messageToCustomer: e.target.value })}
      //                 />
      //               </div>
      //             }
      //           </div>
      //           <div className='modal-footer'>
      //             {isOrderConfirmed ?
      //               <button
      //                 onClick={this.closeModal}
      //                 className='btn accept-order-btn border-0'
      //                 style={{ borderRadius: 22, backgroundColor: '#157FFC', padding: '12px 44px' }}
      //               >
      //                 Okay
      //               </button>
      //               :
      //               <button
      //                 onClick={this.handleSubmit}
      //                 disabled={isConfirmingOrder}
      //                 className='accept-order-btn btn-spinner'
      //               >
      //                 {isConfirmingOrder ?
      //                   <Spinner size='spinner-border-sm' /> :
      //                   'Accept Order'
      //                 }
      //               </button>
      //             }
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </Modal>
//     );
//   }
// };

// export type UpdateOrderStatusPopupRef = React.ElementRef<typeof UpdateOrderStatusPopup>;

// export default connect(null, { updateOrderStatus })(withRouter(UpdateOrderStatusPopup));


import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateOrderStatus } from '../../Actions/orderActions';
import Spinner from '../Spinner';
import { Params } from '../../Pages/Dashboard/Dashboard';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '600px',
    padding: '0',
  }
};

Modal.setAppElement('#root');
Modal!.defaultStyles!.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal!.defaultStyles!.overlay!.zIndex = 1050;

//onRef will change when its Parent Component change from class to functional
type UpdateOrderStatusPopupProps = {
  onRef: (ref: UpdateOrderStatusPopupRef | null) => void;
  meetingConfirmedCallback: () => void;
};

export type UpdateOrderStatusPopupRef = {
  openModal: (orderDateAndTime: string) => void;
  closeModal: () => void;
};

const UpdateOrderStatusPopup = ({ onRef, meetingConfirmedCallback }: UpdateOrderStatusPopupProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOrderConfirmed, setIsOrderConfirmed] = useState<boolean>(false);
  const [isConfirmingOrder, setIsConfirmingOrder] = useState<boolean>(false);
  const [orderDateAndTime, setOrderDateAndTime] = useState<string>('');
  const [messageToCustomer, setMessageToCustomer] = useState<string>('');
  const dispatch = useDispatch<any>();
  const { restaurantId, id: meetingId } = useParams<Params>();

  useEffect(() => {
    onRef && onRef({
      openModal,
      closeModal
    });
    //eslint-disable-next-line
  }, []);

  const openModal = (orderDateAndTime: string) => {
    setOrderDateAndTime(orderDateAndTime);
    setIsOpen(true);
  };

  const closeModal = () => {
    if (isOrderConfirmed) {
      meetingConfirmedCallback?.();
    }
    setIsOpen(false);
    setIsOrderConfirmed(false);
    setIsConfirmingOrder(false);
    setMessageToCustomer('');
  };

  const callbackMeetingConfirmed = () => {
    setIsConfirmingOrder(false);
    setIsOrderConfirmed(true);
  }

  const handleSubmit = () => {
    setIsConfirmingOrder(true);
    if(meetingId && restaurantId){
      dispatch(updateOrderStatus({ message: messageToCustomer, type: 'accept' },
        +meetingId, restaurantId, callbackMeetingConfirmed));
    }
  };

  return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={!isConfirmingOrder}
      >
        <div className='order-modal'>
          <div className='slide'>
            <div className='modal-content cursor-default'>
              <div className='modal-header'>
                <h3
                  id='modal-title'
                  className='modal-title'
                  style={{ fontWeight: 600, fontSize: 22 }}
                >
                  {isOrderConfirmed ? 'Order Confirmation' : 'Accept Order'}
                </h3>
                <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div>
                <div id='order-status-update-popup' className='modal-body py-2'>
                  {isOrderConfirmed ?
                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ minHeight: 266, gap: 20 }}>
                      <img width={150} height={150} src='/imgs/confirmation-tick.gif' alt='confirmation-tick' />
                      {orderDateAndTime ?
                        <p style={{ fontSize: 18, color: '#646464' }} className='text-center'>
                          Thank you for confirming this order on &nbsp;
                          <br />
                          <span style={{ fontWeight: 600 }}>{orderDateAndTime}</span>
                        </p> : null}
                    </div> :
                    <div className='py-3' style={{ minHeight: 266 }}>
                      <p style={{ color: '#646464', fontSize: 16 }}>
                        Please click button below to accept this order. For any questions, Please reach out to customer directly.
                      </p>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='m-0' style={{ color: '#646464', fontSize: 16, fontWeight: 600 }}>
                          Message to customer
                        </p>
                        <p style={{ fontSize: 16, color: '#646464' }}>Optional</p>
                      </div>
                      <textarea
                        id='message'
                        rows={4}
                        name='message'
                        className={`form-control my-3`}
                        placeholder={`e.g. Recommendations for replacement items.`}
                        value={messageToCustomer}
                        onChange={(e) => setMessageToCustomer(e.target.value)}
                      />
                    </div>
                  }
                </div>
                <div className='modal-footer'>
                  {isOrderConfirmed ?
                    <button
                      onClick={closeModal}
                      className='btn accept-order-btn border-0'
                      style={{ borderRadius: 22, backgroundColor: '#157FFC', padding: '12px 44px' }}
                    >
                      Okay
                    </button>
                    :
                    <button
                      onClick={handleSubmit}
                      disabled={isConfirmingOrder}
                      className='accept-order-btn btn-spinner'
                    >
                      {isConfirmingOrder ?
                        <Spinner size='spinner-border-sm' /> :
                        'Accept Order'
                      }
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
  );
};


export default UpdateOrderStatusPopup;
