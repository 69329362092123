import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../invoices.css'
import Spinner from '../../../Common/Spinner';
import { UpdateInvoiceHandler } from '../InvoiceDetails';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '692px',
    width: '90vw',
    padding: '0',
  }
};
const Editor = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ],
  clipboard: {
    matchVisual: false,
  }
}
type props = {
  modalIsOpen: boolean,
  closeModal: () => void,
  editInvoice: UpdateInvoiceHandler,
  bill_to: string | undefined,
  invoice_to: string | undefined,
  invoiceUpdating: boolean,
}

const InvoiceEmailPopup: FC<props> = ({ modalIsOpen, closeModal, editInvoice, bill_to, invoice_to, invoiceUpdating }) => {
  const [invoiceTo, setInvoiceTo] = useState<string>('');
  const [billTo, setBillTo] = useState<string>('');
  useEffect(() => {
    setInvoiceTo(invoice_to ?? '');
    setBillTo(bill_to ?? '')
  }, [bill_to, invoice_to])
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Edit Invoice'
    >
      <div className='modal-header p-3'>
        <h5 className='modal-title' id='modal-title'>Edit Invoice</h5>
        <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
      </div>
      <div id='edit-invoice-popup' className='modal-body p-2' style={{ width: '100%' }}>
        <form className='p-2'>
          <div className='form-group'>
            <label htmlFor='billTo'><b>Bill To</b></label>
            <ReactQuill value={billTo} modules={Editor} onChange={(content) => setBillTo(content)} />
          </div>
          <div className='form-group'>
            <label htmlFor='invoiceTo'><b>Invoice To</b></label>
            <ReactQuill value={invoiceTo} modules={Editor} onChange={(content) => setInvoiceTo(content)} />
          </div>
        </form>
      </div>
      <div className='modal-footer p-2'>
        <button type='button' className='btn btn-dark' style={{ minWidth: 80 }} onClick={() => editInvoice('editAddresses', { bill_to: billTo, ship_to: invoiceTo })}>
          {invoiceUpdating ?
            <Spinner size='spinner-border-sm' /> :
            <b> Save </b>
          }
        </button>
      </div>
    </Modal>)
}

export default InvoiceEmailPopup
