import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';

type PrivateRouteProps = {
  component: any;
  exact: boolean;
  path: string | Array<string>;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const location = useLocation();
  const { auth } = useSelector((state: ReduxRootState) => state);
  return <Route
    {...rest}
    render={props =>
      (auth.isAuthenticated === true) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: location } }} />
      )
    }
  />
}

// export default connect(null, null, null, {
//   pure: false,
// })(PrivateRoute);

export default PrivateRoute;