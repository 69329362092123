import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  startOfYear,
  addYears
} from 'date-fns';
import { Range } from 'react-date-range';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfTomorrow: startOfDay(addDays(new Date(), +1)),
  endOfTomorrow: endOfDay(addDays(new Date(), +1)),
  startOfNextWeek: startOfDay(addDays(new Date(), +1)),
  endOfNextWeek: endOfDay(addDays(new Date(), +7)),
  startOfNextMonth: startOfMonth(addMonths(new Date(), +1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), +1)),
  past30Days: addDays(new Date(), -30),
  past6Months: addMonths(new Date(), -6),
  currentYear: startOfYear(addYears(new Date(), 0))

};

type DateRange = {
  startDate: Date,
  endDate: Date,
}
interface range {
  label: string
  range: () => DateRange,
}

const staticRangeHandler = {
  range: {},
  isSelected(range: Range) {
    const { range: newRange } = this as unknown as { range: () => Range};
    const definedRange = newRange && newRange();
    return (
      isSameDay(range.startDate!, definedRange.startDate!) &&
      isSameDay(range.endDate!, definedRange.endDate!)
    );
  },
};

export function createStaticRanges(ranges: range[]) {
  return ranges.map((range: range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Tomorrow',
    range: () => ({
      startDate: defineds.startOfTomorrow,
      endDate: defineds.endOfTomorrow,
    }),
  },
  {
    label: 'Next 7 Days',
    range: () => ({
      startDate: defineds.startOfNextWeek,
      endDate: defineds.endOfNextWeek,
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: 'Next Month',
    range: () => ({
      startDate: defineds.startOfNextMonth,
      endDate: defineds.endOfNextMonth,
    }),
  },
]);

export const defaultStaticRangesForBilling = createStaticRanges([
  {
    label: 'Past 30 Days',
    range: () => ({
      startDate: defineds.past30Days,
      endDate: defineds.startOfToday,
    }),
  },
  {
    label: 'Past 6 Months',
    range: () => ({
      startDate: defineds.past6Months,
      endDate: defineds.startOfToday,
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: defineds.currentYear,
      endDate: defineds.startOfToday,
    }),
  },
]);
