export const emailBodyHTML = (emailBody: string) => `<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
<style type="text/css">body {
    width: 100% !important;
  }
  .ReadMsgBody {
    width: 100%;
  }
  .ExternalClass {
    width: 100%;
  }
  body {
    -webkit-text-size-adjust: none;
  }
  body {
    margin: 0; padding: 0;
  }
  img {
    border: 0; outline: none; text-decoration: none;
  }
  #backgroundTable {
    height: 100% !important; margin: 0; padding: 0; width: 100% !important;
  }
  #emailBody p {
    margin: 0
  }
  #backgroundTable {
    color: #4c4c4c; background-color: #ffffff; font-family: proxima-nova, 'helvetica neue', helvetica, arial, geneva, sans-serif; font-size: 15px; line-height: 150%;
  }
  @media (max-width: 540px) {
    #templateContainer {
      width: 100% !important;
    }
    #templateContainer .templateContainerPadding {
      padding: 0 5% !important;
    }
  }
  /* Food Item Options */
  .foodItemsOptions {
    border: 1px solid transparent;
    margin-left: -7px;
  }
  .foodItemsOptions .optionDes {
    font-weight: normal;
    word-wrap: break-word;
    display: inline-block;
    font-size: 15px;
    width: 50%;
    color: red;
  }
  .foodItemsOptions .optionPrice {
    border-right: 1px solid transparent !important;
    display: inline-block;
    vertical-valign: top;
    font-size: 15px;
    width: calc(20% - 10px);
    font-weight: normal;
    color: red;
  }
  .colLeft {
    border: 1px solid transparent !important;
  }
  .colRight {
    border: 1px solid transparent !important;
  }
</style>
<center>
<table id="backgroundTable" style="border-spacing: 0; border-collapse: collapse; font-family: proxima-nova, 'helvetica neue', helvetica, arial, geneva, sans-serif; width: 100% !important; height: 100% !important; color: #4c4c4c; font-size: 15px; line-height: 150%; background: #EFF3FE; margin: 0; padding: 0; border: 0;">
	<tbody>
		<tr style="vertical-align: top; padding: 0;">
			<td align="center" style="vertical-align: top; padding: 20px;" valign="top">
			<table align="center">
				<tbody>
					<tr>
						<td align="center"><img alt="Chowmill" src="https://app-dev-s3.chowmill.com/logos/chowmill-logo-full-email.png" style="border: 0;"></td>
					</tr>
				</tbody>
			</table>

			<table id="templateContainer" style="border-spacing: 0; border-collapse: collapse; font-family: proxima-nova, 'helvetica neue', helvetica, arial, geneva, sans-serif; width: 600px; color: #4c4c4c; font-size: 15px; line-height: 150%; background: #ffffff; margin: 20px 0; padding: 0; border: 0;">
				<tbody>
					<tr style="vertical-align: top; padding: 0;">
						<td align="center" class="templateContainerPadding" style="vertical-align: top; padding: 0 40px;" valign="top">
						<table id="templateContent" style="border-spacing: 0; border-collapse: collapse; font-family: proxima-nova, 'helvetica neue', helvetica, arial, geneva, sans-serif; width: 100%; background: #ffffff; margin: 0; padding: 0; border: 0; ">
							<tbody>
								<tr style="vertical-align: top; padding: 0;">
                  <td align="left" id="emailBody" style="vertical-align: top; text-align: left; padding-top: calc(20px + 1em); padding-bottom: calc(20px + 1em);" valign="top">
                  ${emailBody}
									</td>
								</tr>
							</tbody>
						</table>
						</td>
					</tr>
				</tbody>
			</table>
			<table id="footerContent" style="text-align:center;border-spacing: 0; border-collapse: collapse; font-family: proxima-nova, 'helvetica neue', helvetica, arial, geneva, sans-serif; width: 70%; border-top-style: solid; border-top-color: #ebeaef; color: #4e4e4e; font-size: 12px; margin: 0; padding: 0; border-width: 1px 0 0;">
				<tbody>
					<tr style="vertical-align: top; padding: 0;">
						<td align="left" style="vertical-align: top; text-align: center; padding: 0;" valign="top">
						<p style="margin: 20px 0;">
              Chowmill: One Platform for all your workplace meals.<br>
              <a href="https://chowmill.com" style="color:#980000">www.chowmill.com</a> | <a href="mailto:support@chowmill.com" style="color:#980000">support@chowmill.com</a> | (408) 883-9415
            </p>
            <p style="margin: 20px; padding-bottom: 20px;"></p>
						</td>
					</tr>
				</tbody>
			</table>
			</td>
		</tr>
	</tbody>
</table>
</center>`