import { AnyAction } from "redux";
import { GET_STATISTICS_REPORT, GET_STATISTICS_REPORT_LOADING } from "../Actions/types";
import { Reports } from "../Interfaces/ReduxInterface/ReduxRootState";

const initialState: Reports = {
  statistics_report_url: null,
  statistics_reports_loading: false,
}

const reportsReducer = (state: Reports = initialState, action: AnyAction): Reports => {
  switch (action.type) {
    case GET_STATISTICS_REPORT:
      return {
        ...state,
        statistics_report_url: action.payload?.iframe_url,
        statistics_reports_loading: false,
      }
    case GET_STATISTICS_REPORT_LOADING:
      return {
        ...state,
        statistics_reports_loading: action.payload,
      }
    default:
      return state;
  }
}

export default reportsReducer;