import React from 'react';
import Skeleton from 'react-loading-skeleton';
let tasks = [1, 2, 3, 4]
export const HelpSkeleton = () => {
  return <React.Fragment>
    <span className="row">
      {tasks.map((ind, task) => (
        <div key={task} className="col-md-4 grid-margin stretch-card"  >
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-row flex-wrap">
                <div className="ml-3">
                  <p className="text-capitalize"><Skeleton height={30} width={100} /></p>
                  <p><Skeleton height={25} width={170} /></p>
                  <p className="text-capitalize"><b /><Skeleton height={30} width={100} /> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
      }
    </span>

  </React.Fragment>
}