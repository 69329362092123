import axios from 'axios';
import { FRESHDESK_LOODING, GET_FRESHDESK_LOGS, POST_HELP_AND_SUPPORT } from './types';
import { getError } from './errorActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { HelpAndSupportPayload } from '../Interfaces/PayloadsAndResponses/Help';

export const getFreshDeskLogs = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`/vendor/freshdesk_logs`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_FRESHDESK_LOGS,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    )
};
export const postHelpAndSupport = (support: HelpAndSupportPayload) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.post(`/vendor/tickets?description=${support.description}&subject=${support.subject}`, {},
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: POST_HELP_AND_SUPPORT,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const setLoading = () => {
  return {
    type: FRESHDESK_LOODING
  };
}