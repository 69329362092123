import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';

type Props = {
  path: string,
  label: string,
  icon: string,
};

const MenuLink: FC<Props> = ({ path, label, icon }) => {
  const i18n = useSelector((state: ReduxRootState) => state.i18n.i18n);
  return (
    <>
      <Link className='dropdown-item profiledropdownitem' to={path}>
        {i18n && i18n.t(label)}
        <i className={`${icon} float-right pt-1`} />
      </Link>
      <div className='dropdown-divider' />
    </>
  );
};

export default MenuLink;