import React from 'react';
import Skeleton from 'react-loading-skeleton'
import isEmpty from '../../Utilities/isEmpty';

type contactsCheckboxFlagProps = {
  checkboxId: string;
  label: string;
  restaurantAdminData?: any;
  checked: boolean;
  disabled?: boolean;
  flagValue?: string;
  flagValueRequired?: boolean;
  updatingFlagLoading: boolean;
  className?: string;
  updateContactFlag: () => void;
  updateRatingsFlag?: () => void;
}

const RestaurantCheckBoxFlag = ({ checkboxId, label, restaurantAdminData = {}, checked, flagValue = '', className = '', updatingFlagLoading = true, flagValueRequired = false, updateContactFlag, updateRatingsFlag, disabled = false }: contactsCheckboxFlagProps) => {
  return (flagValueRequired && !flagValue) ? <td></td> :
    <td className={`text-nowrap ${className}`}>{flagValue}
      {updatingFlagLoading ?
        <div className='mt-2'>
          <Skeleton width={158} height={22} />
        </div> :
        <>
          <div className='form-check form-switch mt-2'>
            <input type='checkbox'
              id={checkboxId}
              checked={checked}
              disabled={disabled}
              onChange={updateContactFlag}
              name={`${checkboxId}-name`}
              className='form-check-input'
              role="switch"
            />
            <label htmlFor={checkboxId} className={`form-check-label mt-1 ${disabled ? 'text-muted' : 'cursor-pointer'}`}  > {label} </label>
          </div>
          {!isEmpty(restaurantAdminData) ? <div className='form-check form-switch mt-2'>
            <input type='checkbox'
              id={`rating_email_notification_${restaurantAdminData?.id}`}
              checked={restaurantAdminData?.rating_email_notification}
              disabled={disabled}
              onChange={updateRatingsFlag}
              name={`rating_email_notification_${restaurantAdminData?.id}-name`}
              className='form-check-input'
              role="switch"
            />
            <label htmlFor={`rating_email_notification_${restaurantAdminData?.id}-name`} className={`form-check-label mt-1 ${disabled ? 'text-muted' : 'cursor-pointer'}`}  > Send Ratings / Reviews Notification Emails </label>
          </div> :
            <></>
          }
        </>
      }
    </td>
}

export default RestaurantCheckBoxFlag;