// import {logoutUser} from './authActions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { GET_ERRORS } from './types';

//get errors
export const getError = (error: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  if (error.response) {
    if (error.response.status === 406) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data.message
      });
    } else if (error.response.status === 401) {
      dispatch(sendError(error.response));
      localStorage.clear();
      window.location.href = "/";
    } else if (error.response.status === 404) {
      dispatch(sendError(error.response.data));
    } else {
      return {
        type: GET_ERRORS,
        payload: error
      };
    }
  }
};

export const sendError = (error: any) => {
  return {
    type: GET_ERRORS,
    payload: error
  };
};