import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getInvoices } from '../../Actions/invoicesActions';
// import DayPickerRange, { DayClickHandler, DaypickerSearchRange } from '../../Common/date-picker/DayPickerRange';
import Pagination from '../../Common/Pagination';
// import DayPicker from 'react-day-picker';
import './invoices.css';
import queryString from 'query-string';
import { billingTranslations } from '../../Translations/BillingTranslations'
import dayjs from 'dayjs';
import { InvoicesSkeleton } from './InvoicesSkeleton';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import CustomPopUp from '../../Common/Popups/CustomPopUp';
import { DateRangePicker, Range } from 'react-date-range';
import { defaultStaticRangesForBilling } from '../../Common/date-picker/rangesList';
import { DateRange } from '../Dashboard/Dashboard';

const Invoices: FC<{}> = () => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const { from: searchFrom, to: searchTo, page } = queryString?.parse(window.location.search);

  // const [range, setRange] = useState<{ from: Date; to: Date; }>({
  //   from: searchFrom ? new Date(`${searchFrom}`) : new Date(dayjs(new Date()).subtract(6, 'month').toDate().toString()),
  //   to: searchTo ? new Date(`${searchTo}`) : new Date()
  // });
  const [range, setRange] = useState<DateRange[]>(
    [
      {
        startDate: searchFrom ? new Date(`${searchFrom}`) : new Date(dayjs(new Date()).subtract(6, 'month').toDate().toString()),
        endDate: searchTo ? new Date(`${searchTo}`) : new Date(),
        key: 'selection'
      }
    ]
  )
  const [isOpenOrderCalendar, setIsOpenOrderCalendar] = useState<boolean>(false);

  const [currentPage, setCurrentPageState] = useState<number>(+(page || 1))
  const dispatch: any = useDispatch();
  const history = useHistory();
  const { i18n } = useSelector((state: ReduxRootState) => state?.i18n)
  const { invoices, invoices_loading, invoices_per_page, invoices_total } = useSelector((state: ReduxRootState) => state?.invoices)
  useEffect(() => {
    const { startDate, endDate } = range[0] || {};
    billingTranslations();
    dispatch(getInvoices(restaurantId, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), currentPage));
    history.push(`/dashboard/restaurant/${restaurantId}/invoices?from=${dayjs(startDate).format('YYYY-MM-DD')}&&to=${dayjs(endDate).format('YYYY-MM-DD')}`);
  }, [restaurantId, dispatch, range, currentPage, history]);

  const handleSelect = (item: { selection: Range }) => {
    //@ts-ignore
    // this.props.dashboardRef.setState({ dateRange: [item.selection] });
    const { startDate, endDate } = item.selection || {};
    setRange(
      [
        {
          startDate: startDate?? new Date(),
          endDate: endDate?? new Date(),
          key: 'selection'
        },
      ]
    )
  };

  // const handleDayClick: DayClickHandler = (day) => {
  //   // const newRange = DayPicker.DateUtils.addDayToRange(day, range);
  //   // const newRange = Interval(day, range);

  //   // setRange(newRange)
  // }
  // const searchRange: DaypickerSearchRange = (past, inTo) => {
  //   $('.show').removeClass('show');
  //   // let startDate = dayjs(new Date()).subtract(past, inTo).toDate();
  //   // setRange({ from: startDate, to: new Date() })
  // }
  // const searchInvoices = () => {
  //   $('.show').removeClass('show');
  //   dispatch(getInvoices(restaurantId, dayjs(range[0].startDate).format('YYYY-MM-DD'), dayjs(range[0].endDate).format('YYYY-MM-DD'), currentPage));
  // }

  const handlePageClick = (e: { selected: number }) => {
    let active = e?.selected + 1;
    setCurrentPageState(active);
  }
  let hasInvoices = !!(invoices && invoices.length > 0);
  let invoicesArea;
  invoicesArea = invoices ? invoices?.map((invoice, index) => (
    <tr key={index}>
      <td>
        {invoice?.id}
      </td>
      <td>
        {invoice?.company_name}
      </td>
      <td>
        {invoice?.total_amount}
      </td>
      <td>
        {invoice?.adjustments}
      </td>
      <td>
        {invoice?.total_amount_due}
      </td>
      <td>
        {invoice?.delivery_fee}
      </td>
      <td>
        {invoice?.status === 'deposited' ? <label style={{ backgroundColor: 'green' }} className='text-capitalize badge badge-pill status-modify-pill'>{invoice?.status} </label> : ''}
        {invoice?.status === 'sent' ? <label style={{ backgroundColor: 'orange' }} className='text-capitalize badge badge-pill status-modify-pill'>{invoice?.status} </label> : ''}
        {invoice?.status === 'generated' ? <label style={{ backgroundColor: '#cacaca' }} className='text-capitalize badge badge-pill status-modify-pill'>{invoice?.status} </label> : ''}
        {invoice?.status === 'paid' ? <label style={{ backgroundColor: 'green' }} className='text-capitalize badge status_tag badge-pill status-modify-pill'>{invoice?.status} </label> : ''}
      </td>
      <td>
        <button className='btn btn-light btn-sm text-primary'
          onClick={() => history.push({ pathname: `/dashboard/restaurant/${restaurantId}/invoices/${invoice?.id}`, state: { from: dayjs(range[0].startDate)?.format('YYYY-MM-DD'), to: dayjs(range[0].endDate)?.format('YYYY-MM-DD') } })}
        >
          <i className='fas fa-chevron-right'></i>
        </button>
      </td>
    </tr>)

  ) : '';
  // const { from, to } = range;
  const { startDate, endDate } = range[0] || {};

  const totalCount = invoices_total;
  const numberOfPages = (totalCount && invoices_per_page) ? Math.ceil(totalCount / +invoices_per_page) : 0;
  const popUpBodyStyles = { maxHeight: 'calc(100vh - 200px)', overflow: 'auto', top: 45, boxShadow: '0px 2px 13px #00000019' }

  return (
    <div className='card'>
      <div className='card-body p-0'>
        {/* <div className='justify-content-between row'> */}
        <div className='justify-content-between row' style={{'paddingLeft':'15px'}}>
          {/* <div className='invoice-left-content col-md-9'> */}
          <div className='invoice-left-content'>
            <h2>Invoices</h2>
            <p className='muted'>Please see your invoices below. <br /></p>
          </div>
          <div className='invoice-config' style={{'marginRight':'15px'}}>
          {/* <div className='invoice-config col-md-3 px-0'> */}
            <div className='p-2 rounded cursor-pointer position-relative'
              onClick={() => setIsOpenOrderCalendar(true)}
              // style={{ backgroundColor: '#147BFC', boxShadow: '0px 2px 3px #0000000D' }}>
              style={{ backgroundColor: '#147BFC', boxShadow: '0px 2px 3px #0000000D',maxWidth:'250px' }}>
              <div className='d-flex align-items-center text-white'>
                <i className={`fas fa-solid fa-calendar`} />
                <span className='ml-3 mr-4'>{dayjs(startDate).format('MM-DD-YYYY')} - {dayjs(endDate).format('MM-DD-YYYY')}</span>
                <i
                  className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenOrderCalendar ? 'down' : 'up'}`}
                  style={{ fontSize: 15 }}
                />
              </div>
              {isOpenOrderCalendar &&
                <CustomPopUp
                  backdropStyles={{ opacity: 0, cursor: 'default' }}
                  onPopUpClose={() => setIsOpenOrderCalendar(false)}
                  popUpClassName='order-history-range-picker'
                  popUpBodyStyles={{ ...popUpBodyStyles, left: 0 }}
                >
                  <DateRangePicker
                    onChange={range => handleSelect(range as { selection: Range })}
                    // showSelectionPreview={true}
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={range}
                    staticRanges={defaultStaticRangesForBilling}
                    className='bg-white w-100 overflow-auto'
                  />
                </CustomPopUp>}
              </div>
            {/* <DayPickerRange
              from={from}
              to={to}
              defaultRange={{ day: 30, month: 6, year: 1 }}
              handleDayClick={handleDayClick}
              searchRange={searchRange}
              searchItems={searchInvoices}
            /> */}
          </div>
        </div>
        {invoices_loading ? <span className='order-spinner'>  <InvoicesSkeleton /> </span> :
          (invoices && hasInvoices) ? <div className='col-12 px-0'>
            <br /><br />
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr className='bg-dark text-white'>
                    <th>
                      {i18n && i18n.t('No')}
                    </th>
                    <th>
                      {i18n && i18n.t('Company')}
                    </th>
                    <th>
                      {i18n && i18n.t('Subtotal')}
                    </th>
                    <th>
                      {i18n && i18n.t('Adjustments')}
                    </th>
                    <th>
                      {i18n && i18n.t('Total Due')}
                    </th>
                    <th>
                      {i18n && i18n.t('Delivery Fee')}
                    </th>
                    <th>
                      {i18n && i18n.t('Status')}
                    </th>
                    <th>
                      {i18n && i18n.t('Details')}
                    </th>
                  </tr>
                </thead>
                {invoices ? <tbody>
                  {invoicesArea}
                </tbody> : <tbody></tbody>}
              </table>
              {numberOfPages > 1 &&
                <div className='mt-4 mb-3'>
                  <Pagination
                    numberOfPages={numberOfPages}
                    page={currentPage}
                    handlePageClick={handlePageClick}
                    loading={false}
                  />
                </div>
              }
            </div>
          </div>
            : ''}
        {(!hasInvoices && !invoices_loading) ? <h2><br /><br />No Invoice Available In This Range.</h2> : ''}
      </div>
    </div>
  )
}

export default Invoices;