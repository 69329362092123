import axios from 'axios';
import { SET_CURRENT_USER_AUTHENTICATION, GET_ERRORS, SEND_FORGOT_PASSWORD_EMAIL, RESET_STATUS, SET_PROFILE_DATA, LOG_OUT, SIGN_UP_USER, GET_DRIVERS, SET_DRIVER_LOADING, GET_ANNOUNCEMENTS, EMPTY_RESTAURANT_DETAILS } from './types';
import { getError } from './errorActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { showToastNotification } from '../Common/showToastNotification';
import { LoginPayload, SaveDriverPayload, UserHeaders, UserProfileSignupPayload } from '../Interfaces/PayloadsAndResponses/Auth';
import { RouteComponentProps } from 'react-router';
import isEmpty from '../Utilities/isEmpty';

export const loginUser = (userData: LoginPayload) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios
    .post('/auth/sign_in', userData)
    .then(res => {
      try {
        const newHeaders: UserHeaders = {
          'access-token': '',
          client: '',
          expiry: '',
          'token-type': '',
          uid: '',
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'en/US'
        };
        Object.keys(res.headers).forEach(function (key, index) {
          switch (key) {
            case 'access-token':
            case 'client':
            case 'expiry':
            case 'token-type':
            case 'uid':
              newHeaders[key] = res.headers[key];
              break;
            default:
              break;
          }
        });
        localStorage.setItem('headers', JSON.stringify(newHeaders));
        localStorage.setItem('user', JSON.stringify(res.data.data.user));
        localStorage.setItem('orderPresent', JSON.stringify(res.data.data.order_presence));
      } catch (e) {
        console.log('error', e);
      }
      dispatch(setCurrentUser(!isEmpty(res.headers)));
    })
    .catch(err => {
      const error = err.response.data;
      dispatch(sendError(error.errors[0]));
    });
};
export const sendError = (error: any) => {
  return {
    type: GET_ERRORS,
    payload: error
  };
};
export const setCurrentUser = (isAuthenticated: boolean) => {
  return {
    type: SET_CURRENT_USER_AUTHENTICATION,
    payload: isAuthenticated
  };
};
// Log user out
export const logoutUser = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios
    .delete('/auth/sign_out',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      console.log('Session Expired')
    })
    .catch(err =>
      dispatch(getError(err))
    );
  localStorage.removeItem('headers');
  localStorage.removeItem('user');
  localStorage.removeItem('orderPresent');
  localStorage.removeItem('isSelfServiceEnable');
  dispatch({
    type: EMPTY_RESTAURANT_DETAILS,
  })
  dispatch({
    type: LOG_OUT,
    payload: false
  });
};
// Send password resset link to user email.
export const resetPasswordLink = (email: string) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios
    .post(`/auth/password?email=${email}&redirect_url=${process.env.REACT_APP_REDIRECT_URL}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: SEND_FORGOT_PASSWORD_EMAIL,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(getError(err));
    });
};
// Send password resset link to user email.
export const resetPassword = (password: string, confirmPassword: string, accessToken: string, client: string, uid: string) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  let newHeaders = {
    'access-token': accessToken,
    'token-type': 'Bearer',
    'Content-Type': 'application/json',
    client: client,
    uid: uid
  };
  axios
    .put(`/auth/password?password=${password}&password_confirmation=${confirmPassword}`, newHeaders
    )
    .then(res =>
      dispatch({
        type: RESET_STATUS,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(getError(err));
    });
};

export const unlockUserAccount = (token: string, successCallback: (msg: any) => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios
    .get(`/auth/unlock?config=default&unlock_token=${token}`)
    .then(res => {
      successCallback?.(res.data)
    })
    .catch(err => {
      dispatch(getError(err));
    });
};

export const saveProfile = (profileData: UserProfileSignupPayload, signup = false, profileSavedCallback?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/users/profile`, profileData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      profileSavedCallback && profileSavedCallback();
      dispatch({
        type: signup ? SIGN_UP_USER : SET_PROFILE_DATA,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const saveDriver = (id: string | number, driver: SaveDriverPayload, driverSavedCallback: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.post(`/vendor/drivers?restaurant_id=${id}`, driver,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      driverSavedCallback && driverSavedCallback()
      showToastNotification('Driver created successfully', 'Success!');
      dispatch(getDrivers(id))
    })
    .catch(err => {
      console.log('error', err)
      driverSavedCallback && driverSavedCallback()
      dispatch({
        type: 'ERROR_UPDATE_DRIVER',
        payload: err.response.data
      })
    }
    );
};
export const getDrivers = (id: string | number, driverId?: number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.get(`/vendor/drivers?restaurant_id=${id}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_DRIVERS,
        payload: { data: res.data, driverId }
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const updateDriver = (id: string | number, driver: SaveDriverPayload, driverId: number | string, driverUpdatedCallback?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  driver.status === 'deleted' && dispatch({ type: SET_DRIVER_LOADING, payload: driverId })
  axios.put(`/vendor/drivers/${driverId}?restaurant_id=${id}`, { driver: driver },
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      driverUpdatedCallback && driverUpdatedCallback();
      showToastNotification('Driver updated successfully', 'Success!');
      dispatch(getDrivers(id, +driverId));
    })
    .catch(err => {
      driverUpdatedCallback && driverUpdatedCallback()
      dispatch({
        type: 'ERROR_UPDATE_DRIVER',
        payload: err.response.data
      })
    });
};

export const getAnnouncements = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios
    .get(`/announcements`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then((res) => {
      dispatch({
        type: GET_ANNOUNCEMENTS,
        payload: res.data
      })
    })
    .catch((err: any) =>
      dispatch(getError(err))
    );
};

export const getRedirectURL = (token: string, history: RouteComponentProps['history']) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios
    .get(`/vendor/shortened_urls/${token}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then((res) => {
      if (res?.data?.url) {
        window.location.href = res?.data?.url
      } else {
        history?.push('/dashboard/restaurant')
      }
    })
    .catch(err => history?.push('/')
    );
};