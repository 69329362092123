import React, { FC, useState } from "react";
import { OptionSet } from "../../../Interfaces/PayloadsAndResponses/Menu";
import EditOptionGroupPopup from "./EditOptionGroupPop";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Params } from "../../Dashboard/Dashboard";
import { unrelateOptionGroup } from "../../../Actions/menuActions";
import OptionGroupDeletePopup from "./OptionGroupDeletePopup";
import ReactTooltip from "react-tooltip";

type OptionGroupCardProps = {
  optionGroupData: OptionSet;
}

const OptionGroupCard: FC<OptionGroupCardProps> = ({ optionGroupData }) => {
  const dispatch = useDispatch<any>();
  //Get id from params
  const { restaurantId } = useParams<Params>();
  //get data from local storage
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? '';
  const foodItemId = localStorage.getItem('fooditemid') ?? ''
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';
  //states
  const [editoptionSetsPopup, setEditOptionSetsPopup] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);

  const handleSelectedOptionSets = () => {
    setEditOptionSetsPopup(true);
  }

  const handleDeleteOptionGroup = () => {
    selectedMenuId && restaurantId && (dispatch(unrelateOptionGroup(+restaurantId, +selectedMenuId, sectionid, +foodItemId, optionGroupData?.id)))
    setOpenDeletePopup(false);
  }

  return (
    <>
      {/* Edit Option Group */}
      <EditOptionGroupPopup
        editoptionSetsPopup={editoptionSetsPopup}
        setEditOptionSetsPopup={setEditOptionSetsPopup}
        optionSetSelectedData={optionGroupData}
      />
      {/* Delete Option Group */}
      <OptionGroupDeletePopup
        openDeletePopup={openDeletePopup}
        setOpenDeletePopup={setOpenDeletePopup}
        onDeleteConfirmed={handleDeleteOptionGroup}
      />
      {/* Option Group Map */}
      <div  className="menu_items_lists d-flex align-items-center ps-2 pe-4  options-list">
        <span className="ps-0 pe-2 cursor-pointer">
        </span>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className="pl-3">
            <h5 className="lead fw-600 text-medium-black m-0 text-nowrap">{optionGroupData?.name}</h5>
            <div className="fs-18 fw-normal flex-wrap text-medium-black m-0 d-flex">
              {optionGroupData?.options?.map((options, index: number) => {
                const isLastItem = index === optionGroupData?.options?.length - 1;
                return (
                  <div key={index} className="pe-2">
                    {options?.description+' '}
                    ${options?.price}
                    {!isLastItem && ','}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center menu_cta_button_wrapper sm:w-100 mt-2 mt-lg-0  mx-lg-0 mr-3">
            <div className="d-flex justify-content-between align-items-center menu_cta_button_wrapper ms-4 mt-2 mt-md-0 mx-auto mx-md-0 mt-2 mt-md-0 mx-auto mx-md-0">
              <span
                data-tip
                data-for='delete'
                className='text-capitalize'>
                <button className="btn btn-custom-danger btn-sm rounded-circle p-2" onClick={() => setOpenDeletePopup(true)}>
                  <i className="far fa-trash-alt" style={{ width: "18px", height: "20px" }}></i>
                </button>
                {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                  <ReactTooltip type='dark' place='top' effect='solid' className='react-tooltip' id='delete'>
                    <span>Delete</span>
                  </ReactTooltip>
                }
              </span>
              <button className="btn btn-custom-light btn-sm rounded-pill ms-3 px-4" onClick={handleSelectedOptionSets}>Edit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OptionGroupCard