import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton';

type Props = {
  page: number;
  handlePageClick: (e: { selected: number }) => void;
  numberOfPages: number;
  loading: boolean;
};

const Pagination: FC<Props> = ({
  page,
  handlePageClick,
  numberOfPages,
  loading,
}) => {

  const handlePageClickWithScrollTop = (e: { selected: number }) => {
    handlePageClick(e);
    setTimeout(() => {
      let windowElem = window;
      windowElem.scrollTo({ top: 195, behavior: 'smooth' });
    }, 200);
  };

  return loading ? (
    <div className='justify-content-end d-flex'>
      <Skeleton height={35} width={420} />
    </div>
  ) : (
    <ReactPaginate
      previousLabel={<span aria-hidden='true'>&laquo;</span>}
      nextLabel={<span aria-hidden='true'>&raquo;</span>}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={numberOfPages ? numberOfPages : 0}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      previousClassName={'page-item'}
      nextClassName={'page-item'}
      pageClassName={'page-item'}
      nextLinkClassName={'page-link clickable'}
      previousLinkClassName={'page-link clickable'}
      pageLinkClassName={'page-link clickable'}
      onPageChange={handlePageClickWithScrollTop}
      containerClassName={'pagination justify-content-end'}
      activeClassName={'active'}
      forcePage={page - 1}
    />
  );
};

export default Pagination;
