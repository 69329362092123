import React, { FC, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import isEmpty from '../../../Utilities/isEmpty'
import { hiddenFoodItem } from '../../../Actions/menuActions'
import { useDispatch } from 'react-redux'
import { FoodItem, Section } from '../../../Interfaces/PayloadsAndResponses/Menu'
import AddEditSectionPopup from './AddEditSectionPopup'
import parse from 'html-react-parser';

type SectionFoodItemCardProps = {
  fooditem: FoodItem;
  itemDetails: (foodItem: FoodItem) => void;
  selectedFoodItemHandler: (fooditem: FoodItem) => void;
  setCheckedFoodItem: () => void
}

const SectionFoodItemCard: FC<SectionFoodItemCardProps> = ({ fooditem, itemDetails, selectedFoodItemHandler, setCheckedFoodItem }) => {
  const { dishsizes } = fooditem;
  // const dietaryIngredients = [...fooditem?.dietaries, ...fooditem?.ingredients]
  const [isDuplicateFoodItem, setIsDuplicateFoodItem] = useState<boolean>(false)
  const [selectedFoodItem, setSelectedFoodItem] = useState<any>([])
  const [isOpenEditFoodItem, setIsOpenEditFoodItem] = useState<boolean>(false)
  const dispatch: any = useDispatch();
  let restaurant_id = JSON.parse(localStorage.getItem('remember_restaurant_id') || '{}');
  let menu_id = JSON.parse(localStorage.getItem('menuTypeid') || '{}');
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}');
  const sectionid = sectionData.id || '';
  const getMenutype = localStorage.getItem('menuType')

  //get min and maximum number
  let dishSizeMinPrice = Infinity;
  let dishSizeMaxPrice = -Infinity;
  if (Array?.isArray(dishsizes) && dishsizes?.length > 0) {
    // Iterate through the array to find the minimum and maximum prices
    dishsizes?.forEach(dishsizedishsizesPrice => {
      const price = parseFloat(dishsizedishsizesPrice?.price);
      if (!isNaN(price)) {
        if (price < dishSizeMinPrice) {
          dishSizeMinPrice = price;
        } else if (price > dishSizeMaxPrice) {
          dishSizeMaxPrice = price;
        }
      }
    });
  }

  const visibilityHandler = (food: any) => {
    let hiddenStateChange: boolean = !food?.is_hidden
    setCheckedFoodItem()
    dispatch(hiddenFoodItem(restaurant_id, menu_id, sectionid, food?.id, hiddenStateChange))
  }

  const addEditSectionPopup = (type: string, section?: Section) => {
    if (type === 'add') {
      // setIsOpenNewSection(true)
    } else if (type === 'edit' || type === 'duplicate') {
      type === 'edit' && setIsOpenEditFoodItem(true)
      type === 'duplicate' && setIsDuplicateFoodItem(true)
      section && setSelectedFoodItem(section)
    }
  }

  const onPopUpCloseAddEditSection = () => {
    setIsOpenEditFoodItem(false)
    setIsDuplicateFoodItem(false)
  }

  // const deleteFoodItemHandler = (fooditem: FoodItem) => {
  //   dispatch(deleteItem(restaurant_id, +menu_id, sectionid, +fooditem?.id, true))
  // }
  return (
    <div className="menu_items_lists d-flex align-items-center py-3 ps-2 pe-4">
      <span className="ps-0 pe-2 cursor-pointer">
        {/* <i className="fa-regular fa-grip-dots-vertical fa-lg drage_buttons"></i> */}
      </span>
      <div className="d-flex flex-wrap md:flex-nowrap justify-content-between align-items-center w-100">
        <div className="d-flex align-items-center cursor-pointer" onClick={() => itemDetails(fooditem)}>
          {!isEmpty(fooditem?.image?.url ?? fooditem?.image?.medium?.url ?? fooditem?.image?.thumb?.url) ? <img alt='food item' src={fooditem?.image?.url ?? fooditem?.image?.medium?.url ?? fooditem?.image?.thumb?.url ?? ''}
            className='rounded-10 img-fluid image-wrapper' /> :
            <img src="/imgs/menu-page-place-holder1.svg" alt="food item" className="rounded-10 img-fluid image-wrapper " />
          }
          <div className="pl-3">
            <div className='d-flex align-items-center'>
            <h5 className="lead fw-600 text-medium-black m-0 text-nowrap section-food-Item" >{fooditem?.name}</h5>
               {fooditem?.is_hidden && 
            <span className="ps-2" data-tip data-for='hidden-section-tooltip'>
              <i className="fas fa-eye-slash hidden-eye-class"></i>
              </span>}
              {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                <ReactTooltip place='top' type='dark' id='hidden-section-tooltip' className='react-tooltip' >
                  <span>
                    Not Visible to Comsumers
                  </span>
                </ReactTooltip>
              }
              {/* <h5 className="lead fw-600 text-medium-black m-0 text-nowrap section-food-Item">{fooditem?.name}</h5> */}
              {/* <h5 className="lead fw-600 m-0"> {`${section?.name}`}</h5> */}

            </div>
            {getMenutype === 'buffet' ? (
              <p className="fs-18 fw-normal text-medium-black m-0 mb-2">
                {(isFinite(dishSizeMinPrice) && isFinite(dishSizeMaxPrice)) ? `$ ${dishSizeMinPrice} - $ ${dishSizeMaxPrice}` : 'No dishsize available'}
              </p>
            ) : <p className="fs-18 fw-normal text-medium-black m-0">$ {fooditem?.price}</p>
          }
            <div className="menu_dish_rating">
              <div className="d-flex align-items-center">
                {fooditem?.average_rating > 0 && <div className='menu_items_card_rating d-flex align-items-center' >
                  {[1, 2, 3, 4, 5].map((star) =>
                    <div key={star}>
                      {(fooditem?.average_rating ?? 0) < star ? <img src="/imgs/star-solid (1).svg" alt="Start_rating" className='d-inline-flex mr-2' /> : <img src="/imgs/star-solid.svg" alt="Start_rating" className='d-inline-flex mr-2' />}
                    </div>
                  )}
                  {fooditem?.average_rating > 0 &&
                    fooditem && <h5 className='ml-2 mb-0 fw-normal text-medium-black'>{`${Number(fooditem?.average_rating)?.toFixed(2)}`}</h5>
                  }
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center menu_cta_button_wrapper sm:w-100 mt-2 mt-lg-0  mx-auto mx-lg-0 cursor-pointer">
          <div className="d-flex align-items-center border-end mr-3">
            {fooditem?.dietaries?.splice(0, 3)?.map((dietary, index) => {
              return (
                <span
                  key={index}
                  className='menu_card_ingradient_wrapper d-inline-flex justify-content-center align-items-center me-2'
                  data-tip
                  data-for={`dietary-${index}`} // Use a unique identifier for each tooltip
                >
                  {dietary?.logo ? (
                    <div className="menu_card_ingradient_wrapper_item_page d-inline-flex justify-content-center align-items-center ml-1">
                      {parse(dietary?.logo)}
                    </div>
                  ) : (
                    <div className="text-uppercase tagging-text">{dietary?.name?.charAt(0)}</div>
                  )}
                  {window.navigator.userAgent.match(/MSIE|Trident/) === null && (
                    <ReactTooltip key={`tooltip-${index}`} type='dark' place='top' effect='solid' className='react-tooltip' id={`dietary-${index}`}>
                      <span>{dietary?.name}</span>
                    </ReactTooltip>
                  )}
                </span>
              );
            })}
            {
              fooditem?.dietaries?.length > 2 && <span
                data-tip
                data-for='dietaries'
                className='menu_card_ingradient_wrapper d-inline-flex justify-content-center align-items-center me-2 text-capitalize'
              >
                +{fooditem?.dietaries?.length - 3}
                {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                  <ReactTooltip type='dark' place='top' effect='solid' className='react-tooltip' id='dietaries'>
                    <span>{fooditem?.dietaries?.slice(3).map((dietary, index) => {
                      return <span key={index} >
                        {dietary?.name}
                      </span>
                    })}</span>
                  </ReactTooltip>
                }
              </span>
            }
          </div>
          <div className="d-flex justify-content-between align-items-center menu_cta_button_wrapper ms-4 mt-2 mt-md-0 mx-auto mx-md-0 mt-2 mt-md-0 mx-auto mx-md-0">
            {/* <button className="btn btn-custom-danger btn-sm rounded-circle p-2 mr-3" onClick={() => deleteFoodItemHandler(fooditem)}>
              <span
                data-tip
                data-for='delete'
                className='text-capitalize'>
                <i className="far fa-trash-alt"></i>
                {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                  <ReactTooltip type='dark' place='top' effect='solid' className='react-tooltip' id='delete'>
                    <span>Delete</span>
                  </ReactTooltip>
                }
              </span>
            </button> */}
            {fooditem?.is_hidden ?
              <span
                data-tip
                data-for='un-hide'
                className='text-capitalize'>
                <button className="btn btn-custom-light btn-sm rounded-circle p-2" onClick={() => visibilityHandler(fooditem)}>
                  <i className="fa-solid fa-eye menu_icon_size"></i>
                </button>
                {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                  <ReactTooltip type='dark' place='top' effect='solid' className='react-tooltip' id='un-hide'>
                    <span>Un-Hide</span>
                  </ReactTooltip>
                }
              </span> :
              <span
                data-tip
                data-for='hide'
                className='text-capitalize'>
                <button className="btn btn-custom-light btn-sm rounded-circle p-2" onClick={() => visibilityHandler(fooditem)}>
                  <i className="fa-solid fa-eye-slash menu_icon_size"></i>
                </button>
                {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                  <ReactTooltip type='dark' place='top' effect='solid' className='react-tooltip' id='hide'>
                    <span>Hide</span>
                  </ReactTooltip>
                }
              </span>
            }
            <span
              data-tip
              data-for='duplicate'
              className='text-capitalize'>
              <button className="btn btn-custom-light btn-sm rounded-circle ms-3 p-2" onClick={() => addEditSectionPopup?.('duplicate')}>
                <i className="fa-solid fa-clone menu_icon_size"></i>
              </button>
              {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                <ReactTooltip type='dark' place='top' effect='solid' className='react-tooltip' id='duplicate'>
                  <span>Duplicate</span>
                </ReactTooltip>
              }
            </span>
            {/* <button className="btn btn-custom-light btn-sm rounded-pill ms-3 px-4" onClick={() => selectedFoodItemHandler(fooditem)}>Edit</button> */}
          </div>
        </div>
      </div>
      {(isDuplicateFoodItem) && <AddEditSectionPopup foodItem={fooditem} sectionFoodItemCard={true} section={selectedFoodItem} type={isOpenEditFoodItem ? 'Edit' : 'Duplicate'} onPopUpClose={onPopUpCloseAddEditSection} fromSectionFoodItemCard={true} />}
    </div>
  )
}

export default SectionFoodItemCard