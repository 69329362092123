import React, { useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '600px',
    padding: '0',
  }
};

const UpdateMultipleOrderStatusPopup = ({
  StatusConfirmed,
  bulkAcceptArray,
  resId }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const closeModal = () => {
    setIsOpen(false)
    StatusConfirmed(false)
    window.location.reload()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className='order-modal'>
        <div className='slide'>
          <div className='modal-content cursor-default'>
            <div className='modal-header'>
              <h3
                id='modal-title'
                className='modal-title'
                style={{ fontWeight: 600, fontSize: 22 }}
              >
              Order Confirmation
              </h3>
              <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div>
              <div id='order-status-update-popup' className='modal-body py-2'>
                  <div className='d-flex flex-column justify-content-center align-items-center' style={{ minHeight: 266, gap: 20 }}>
                    <img width={150} height={150} src='/imgs/confirmation-tick.gif' alt='confirmation-tick' />
                    <p style={{ fontSize: 20, color: '#646464' }} className='text-center'>
                      Thank you for confirming order(s)
                      <br />
                    </p>
                  </div>
              </div>
              <div className='modal-footer'>
                  <button
                    onClick={closeModal}
                    className='btn accept-order-btn border-0'
                    style={{ borderRadius: 22, backgroundColor: '#157FFC', padding: '12px 44px' }}
                  >
                    Okay
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateMultipleOrderStatusPopup;