// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import Modal from 'react-modal';
// import '../../../Common/Popups/popup.css'
// import { saveDriver, updateDriver } from '../../../Actions/authActions'
// import store from '../../../store';
// import Spinner from '../../../Common/Spinner';
// import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
// import { WithTranslation } from 'react-i18next';
// import { Params } from '../../Dashboard/Dashboard';
// import { Driver, SaveDriverPayload } from '../../../Interfaces/PayloadsAndResponses/Auth';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     maxWidth: '500px',
//     width: '90vw',
//     padding: '0',
//   }
// };

// Modal.setAppElement('#root');
// Modal!.defaultStyles!.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal!.defaultStyles!.overlay!.zIndex = 1050;

// type DriverPopUpProps = {
//   error_driver_update: any;
//   onUpdate: () => void;
//   i18n: WithTranslation | null;
//   onRef: (ref: DriverPopUpRef | null) => void;
//   saveDriver: (id: string | number, driver: SaveDriverPayload, driverSavedCallback: () => void) => void;
//   updateDriver: (id: string | number, driver: SaveDriverPayload, driverId: number, driverUpdatedCallback: () => void) => void;
// } & RouteComponentProps<Params>;

// type DriverPopUpState = {
//   modalIsOpen: boolean,
//   email: string,
//   phone_number_error: string,
//   firstName: string,
//   lastName: string,
//   phoneNumber: string,
//   carColor: string,
//   car: string,
//   CarLicencePlate: string,
//   openModalWithItem: boolean,
//   driverId: string | number,
//   updatingDriver: boolean
// }

// class DriverPopUp extends Component<DriverPopUpProps, DriverPopUpState> {
//   state: DriverPopUpState = {
//     modalIsOpen: false,
//     email: '',
//     phone_number_error: '',
//     firstName: '',
//     lastName: '',
//     phoneNumber: '',
//     carColor: '',
//     car: '',
//     CarLicencePlate: '',
//     openModalWithItem: false,
//     driverId: '',
//     updatingDriver: false
//   }

//   componentDidMount() {
//     this.props.onRef(this);
//   }
//   componentWillUnmount() {
//     this.props.onRef(null)
//   }

//   openModal() {
//     this.setState({ modalIsOpen: true, phone_number_error: '' });
//   }
//   closeModal = () => {
//     store.dispatch({ type: 'INITIALIZE_DRIVER_ON_CLOSE_POPUP', payload: null });
//     this.setState({
//       modalIsOpen: false,
//       firstName: '',
//       lastName: '',
//       email: '',
//       phoneNumber: '',
//       carColor: '',
//       car: '',
//       CarLicencePlate: '',
//       openModalWithItem: false,
//       phone_number_error: ''
//     });
//   }
//   openModalWithItem(driver: Driver) {
//     this.setState({
//       firstName: driver.first_name,
//       lastName: driver.last_name,
//       email: driver.email,
//       phoneNumber: driver.phone_number,
//       carColor: driver.car_color,
//       car: driver.car,
//       CarLicencePlate: driver.car_licence_plate,
//       openModalWithItem: true,
//       driverId: driver.id
//     });
//     this.openModal();
//   }
//   handleChange = ({ target: { type, checked, name, value } }: React.ChangeEvent<HTMLInputElement>) => {
//     const inputValue = type === 'checkbox' ? checked : value;
//     this.setState(prevState => ({ ...prevState, [name]: inputValue }));
//   }
//   driversUpdateHandler = () => {
//     this.setState({ updatingDriver: false });
//     this.closeModal();
//   }
//   onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//     if (e) {
//       e.preventDefault();
//     }
//     const driver = {
//       first_name: this.state.firstName,
//       last_name: this.state.lastName,
//       email: this.state.email,
//       phone_number: this.state.phoneNumber,
//       car: this.state.car,
//       car_licence_plate: this.state.CarLicencePlate,
//       car_color: this.state.carColor,
//     };
//     if ((this.state.phoneNumber && (!this.state.phoneNumber.match(/(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)/)))) {
//       this.setState({ phone_number_error: 'Please match the format of Phone Number as xxx-xxx-xxxx' });
//     } else {
//       const { restaurantId } = this.props.match.params || {};
//       this.setState({ phone_number_error: '' });
//       this.setState({ updatingDriver: true })
//       if (this.state.openModalWithItem && restaurantId && this.state.driverId) {
//         this.props.updateDriver(restaurantId, driver, +this.state.driverId, this.driversUpdateHandler);
//       } else if (restaurantId) {
//         this.props.saveDriver(restaurantId, driver, this.driversUpdateHandler);
//       }
//     }
//   }
//   render() {
//     return (
//       <Modal
//         isOpen={this.state.modalIsOpen}
//         onRequestClose={this.closeModal}
//         style={customStyles}
//         shouldCloseOnOverlayClick={!this.state.updatingDriver}
//       >
//         <div id='show-notification' className='float-right'></div>
//         <div className='order-modal'>
//           <div className='modal-dialog slide'>
//             <div className='modal-content'>
//               <div className='modal-header'>
//                 <h5 className='modal-title' id='modal-title'> {this.state.openModalWithItem ? 'Update Driver' : 'Add Driver'}</h5>
//                 <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
//               </div>
//               <div className='modal-body py-2'>
//                 <div className='col-lg-12 edit-contact-popup px-0'>
//                   <form className='pt-3'>
//                     <div className='mb-4'>
//                       <label htmlFor='firstName'> {this.props.i18n && this.props.i18n.t('First Name')}*</label>
//                       <div className='input-group'>
//                         <input type='text' id='firstName' className='form-control form-control-lg height-46' name='firstName' value={this.state.firstName} onChange={this.handleChange} autoComplete='on' required autoFocus placeholder='First Name' />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='lastName'> {this.props.i18n && this.props.i18n.t('Last Name')}*</label>
//                       <div className='input-group'>
//                         <input type='text' id='lastName' className='form-control form-control-lg height-46' name='lastName' value={this.state.lastName} onChange={this.handleChange} autoComplete='on' required placeholder='Last Name' />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='phoneNumber'> {this.props.i18n && this.props.i18n.t('Phone')}*</label>
//                       <div className='input-group'>
//                         <input type='text' id='phoneNumber' className='form-control form-control-lg height-46' name='phoneNumber' value={this.state.phoneNumber} onChange={this.handleChange} autoComplete='on' required placeholder='Phone' readOnly={this.state.openModalWithItem ? true : false} />
//                       </div>
//                       {this.state.phone_number_error === '' ? '' : <span className='text-danger'>{this.state.phone_number_error}</span>}
//                       {!this.props?.error_driver_update?.message ? '' : <span className='text-danger'>{this.props?.error_driver_update?.message}</span>}
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='email'> {this.props.i18n && this.props.i18n.t('Email')}*</label>
//                       <div className='input-group'>
//                         <input type='email' id='email' className='form-control form-control-lg height-46' name='email' value={this.state.email} onChange={this.handleChange} required />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='car'> {this.props.i18n && this.props.i18n.t('Car')}</label>
//                       <div className='input-group'>
//                         <input type='text' id='car' className='form-control form-control-lg height-46' name='car' value={this.state.car} onChange={this.handleChange} />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='carColor'> {this.props.i18n && this.props.i18n.t('Car color')}</label>
//                       <div className='input-group'>
//                         <input type='text' id='carColor' className='form-control form-control-lg height-46' name='carColor' value={this.state.carColor} onChange={this.handleChange} />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='CarLicencePlate'> {this.props.i18n && this.props.i18n.t('Car licence plate')}</label>
//                       <div className='input-group'>
//                         <input type='text' id='CarLicencePlate' className='form-control form-control-lg height-46' name='CarLicencePlate' value={this.state.CarLicencePlate} onChange={this.handleChange} />
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className='modal-footer'>
//           <button className='btn btn-primary font-weight-medium' type='submit' style={{ height: '50px' }} onClick={this.onSubmit}>
//             {this.state.updatingDriver ? <Spinner /> :
//               <b>  {this.props.i18n && this.props.i18n.t('Submit')} </b>}
//           </button>
//         </div>
//       </Modal>
//     )
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   i18n: state.i18n.i18n,
//   error_driver_update: state.auth.error_driver_update,
// });

// export type DriverPopUpRef = React.ElementRef<typeof DriverPopUp>;

// export default connect(mapStateToProps, { saveDriver, updateDriver })(withRouter(DriverPopUp));;

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../../../Common/Popups/popup.css'
import { saveDriver, updateDriver } from '../../../Actions/authActions'
import Spinner from '../../../Common/Spinner';
import { useParams } from 'react-router-dom';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { Params } from '../../Dashboard/Dashboard';
import { Driver } from '../../../Interfaces/PayloadsAndResponses/Auth';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../Utilities/isEmpty';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '90vw',
    padding: '0',
  }
};

Modal.setAppElement('#root');
Modal!.defaultStyles!.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal!.defaultStyles!.overlay!.zIndex = 1050;

type DriverPopUpProps = {
  onUpdate: () => void;
  driver: Driver[] | undefined;
  selectedDriver?: number;
  modalOpen: boolean;
  handleCloseModal: () => void;
  checkDriver?: boolean;
  selectedIndex: number;
};

const DriverPopUp = ({ driver, modalOpen, handleCloseModal, checkDriver, selectedIndex }: DriverPopUpProps) => {
  const [email, setEmail] = useState<string>('');
  const [phone_number_error, setPhoneNumberError] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [carColor, setCarColor] = useState<string>('');
  const [car, setCar] = useState<string>('');
  const [CarLicencePlate, setCarLicencePlate] = useState<string>('');
  const [openModalItem, setOpenModaItem] = useState<boolean>(false);
  const [driverId, setDriverId] = useState<string | number>('');
  const [updatingDriver, setUpdatingDriver] = useState<boolean>(false);

  const { restaurantId } = useParams<Params>();
  const dispatch = useDispatch<any>();
  const i18n = useSelector((state: ReduxRootState) => state.i18n.i18n);
  const error_driver_update = useSelector((state: ReduxRootState) => state?.auth?.error_driver_update);

  useEffect(()=>{
  if(driver && !isEmpty(driver)){
    openModalWithItem(driver[selectedIndex])
  } else {
    openModalWithItem();
  }
  //eslint-disable-next-line
  },[driver, checkDriver])

  const closeModal = () => {
    dispatch({ type: 'INITIALIZE_DRIVER_ON_CLOSE_POPUP', payload: null });
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setCarColor('');
    setCar('');
    setCarLicencePlate('');
    setOpenModaItem(false);
    setPhoneNumberError('');
  }

  const openModalWithItem = (driver?: Driver) => {
    if(driver && !isEmpty(driver) && !checkDriver) {
      setFirstName(driver.first_name);
      setLastName(driver.last_name);
      setEmail(driver.email);
      setPhoneNumber(driver.phone_number);
      setCarColor(driver.car_color);
      setCar(driver.car);
      setCarLicencePlate(driver.car_licence_plate);
      setOpenModaItem(true);
      setDriverId(driver.id);
    } else {
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setCarColor('');
      setCar('');
      setCarLicencePlate('');
      setOpenModaItem(false);
      setPhoneNumberError('');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { type, checked, name, value } = event.target;
    let inputValue: string = '';
  
    if (type === 'checkbox') {
      inputValue = checked ? 'true' : 'false';
    } else {
      inputValue = value;
    }
    switch (name) {
      case 'email':
        setEmail(inputValue);
        break;
      case 'firstName':
        setFirstName(inputValue);
        break;
      case 'lastName':
        setLastName(inputValue);
        break;
      case 'phoneNumber':
        setPhoneNumber(inputValue);
        break;
      case 'carColor':
        setCarColor(inputValue);
        break;
      case 'car':
        setCar(inputValue);
        break;
      case 'CarLicencePlate':
        setCarLicencePlate(inputValue);
        break;
      default:
        break;
    }
  };
  
  const driversUpdateHandler = () => {
    setUpdatingDriver(false);
    closeModal();
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
    }
    const driver = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      car: car,
      car_licence_plate: CarLicencePlate,
      car_color: carColor,
    };

    if ((phoneNumber && (!phoneNumber.match(/(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)/)))) {
      setPhoneNumberError('Please match the format of Phone Number as xxx-xxx-xxxx');
    } else {
      setPhoneNumberError('');
      setUpdatingDriver(true);
      if (openModalItem && restaurantId && driverId) {
        dispatch(updateDriver(restaurantId, driver, +driverId, driversUpdateHandler));
      } else if (restaurantId) {
        dispatch(saveDriver(restaurantId, driver, driversUpdateHandler));
      }
    }
  };

  return(
      <Modal
        isOpen={modalOpen}
        onRequestClose={handleCloseModal}
        style={customStyles}
        shouldCloseOnOverlayClick={!updatingDriver}
      >
        <div id='show-notification' className='float-right'></div>
        <div className='order-modal'>
          <div className='modal-dialog slide'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='modal-title'> {openModalItem ? 'Update Driver' : 'Add Driver'}</h5>
                <button type='button' onClick={handleCloseModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
              </div>
              <div className='modal-body py-2'>
                <div className='col-lg-12 edit-contact-popup px-0'>
                  <form className='pt-3'>
                    <div className='mb-4'>
                      <label htmlFor='firstName'> {i18n && i18n.t('First Name')}*</label>
                      <div className='input-group'>
                        <input type='text' id='firstName' className='form-control form-control-lg height-46' name='firstName' value={firstName} onChange={handleChange} autoComplete='on' required autoFocus placeholder='First Name' />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='lastName'> {i18n && i18n.t('Last Name')}*</label>
                      <div className='input-group'>
                        <input type='text' id='lastName' className='form-control form-control-lg height-46' name='lastName' value={lastName} onChange={handleChange} autoComplete='on' required placeholder='Last Name' />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='phoneNumber'> {i18n && i18n.t('Phone')}*</label>
                      <div className='input-group'>
                        <input type='text' id='phoneNumber' className='form-control form-control-lg height-46' name='phoneNumber' value={phoneNumber} onChange={handleChange} autoComplete='on' required placeholder='Phone' readOnly={openModalItem ? true : false} />
                      </div>
                      {phone_number_error === '' ? '' : <span className='text-danger'>{phone_number_error}</span>}
                      {!error_driver_update?.message ? '' : <span className='text-danger'>{error_driver_update?.message}</span>}
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='email'> {i18n && i18n.t('Email')}*</label>
                      <div className='input-group'>
                        <input type='email' id='email' className='form-control form-control-lg height-46' name='email' value={email} onChange={handleChange} required />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='car'> {i18n && i18n.t('Car')}</label>
                      <div className='input-group'>
                        <input type='text' id='car' className='form-control form-control-lg height-46' name='car' value={car} onChange={handleChange} />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='carColor'> {i18n && i18n.t('Car color')}</label>
                      <div className='input-group'>
                        <input type='text' id='carColor' className='form-control form-control-lg height-46' name='carColor' value={carColor} onChange={handleChange} />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='CarLicencePlate'> {i18n && i18n.t('Car licence plate')}</label>
                      <div className='input-group'>
                        <input type='text' id='CarLicencePlate' className='form-control form-control-lg height-46' name='CarLicencePlate' value={CarLicencePlate} onChange={handleChange} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary font-weight-medium' type='submit' style={{ height: '50px' }} onClick={onSubmit}>
            {updatingDriver ? <Spinner /> :
              <b>  {i18n && i18n.t('Submit')} </b>}
          </button>
        </div>
      </Modal>
  )
}

export default DriverPopUp;