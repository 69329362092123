// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router';
// import isEmpty from '../../Utilities/isEmpty';
// import './SignUp.css';
// import { Link } from 'react-router-dom';
// import { saveProfile } from '../../Actions/authActions'
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { UserProfileSignupPayload } from '../../Interfaces/PayloadsAndResponses/Auth';

// type ProfileProps = {
//   saveProfile: (profileData: UserProfileSignupPayload, signup: boolean, profileSavedCallback?: () => void) => void
//   profile: ReduxRootState
//   passwordRequired: boolean
// } & RouteComponentProps

// type ProfileState = {
//   first_name: string
//   last_name: string
//   email: string
//   password: string
//   verify_password: string
//   isValidPassword: boolean
//   passwordError: string
//   verifyPasswordError: string
//   loading: boolean
//   phone_number: string
// }
// class Profile extends Component<ProfileProps, ProfileState>  {
//   user = JSON.parse(localStorage.getItem('user') || '{}');
//   company = JSON.parse(localStorage.getItem('company') || '{}');
//   state: ProfileState = {
//     first_name: this.user.first_name,
//     last_name: this.user.last_name,
//     email: this.user.email,
//     password: '',
//     verify_password: '',
//     isValidPassword: false,
//     passwordError: 'Please enter password.',
//     verifyPasswordError: 'Confirm password.',
//     loading: false,
//     phone_number: ''
//   }

//   componentDidMount() {
//     this.user = JSON.parse(localStorage.getItem('user') || '{}');
//     document.title = "Profile Setup";
//     this.validateForm();
//   }
//   onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
//     this.setState({ ...this.state, [e.target.name]: value });
//     this.validateForm(e.target);
//   }
//   componentWillReceiveProps(nextProps: ProfileProps) {
//     if (nextProps.profile.auth.profile_request_type) {
//       this.props.history.push('/')
//     }
//   }
//   shouldComponentUpdate() {
//     return true;
//   }
//   onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     if (e) {
//       e.preventDefault();
//     }
//     const profileData = {
//       user: {
//         first_name: this.state.first_name,
//         last_name: this.state.last_name,
//         password: this.state.password,
//         phone_number: this.state.phone_number,
//       }
//     };
//     this.props.saveProfile(profileData, true);
//   }
//   validateForm = (target?: EventTarget & HTMLInputElement) => {
//     if (target && target.name === "password") {
//       if (target.value) {
//         if (target.value.length > 5) {
//           if (this.state.verify_password === target.value) {
//             this.setState({ isValidPassword: true, passwordError: '', verifyPasswordError: '' });
//           } else {
//             this.setState({ verifyPasswordError: 'Please verify the password.', isValidPassword: false, passwordError: '' });
//           }
//         } else {
//           this.setState({ passwordError: 'Please enter atleast 6 digits.', isValidPassword: false });
//         }
//       } else {
//         this.setState({ passwordError: 'Please enter password.', isValidPassword: false });
//       }
//     }
//     if (target && target.name === "verify_password") {
//       if (target.value && target.value.length > 5 && this.state.password === target.value) {
//         this.setState({ isValidPassword: true, verifyPasswordError: '' });
//       } else {
//         this.setState({ verifyPasswordError: 'Please verify the password.', isValidPassword: false });
//       }
//     }
//   }
//   render() {
//     return (
//       <div className="container-scroller">
//         <div className="container-fluid page-body-wrapper full-page-wrapper">
//           <div className="content-wrapper d-flex align-items-center auth">
//             <div className="row w-100">
//               <div className="col-lg-4 mx-auto">
//                 <div className="auth-form-light text-left p-md-5">
//                   <div className="brand-logo brand-sign-up-logo chowmill-logo-full">
//                     <img src="./imgs/chowmill-logo-full.png" className="logo mt-5 mw-100" alt="logo" />
//                   </div>
//                   <h4>Signup</h4>
//                   <h6 className="font-weight-light"> Already have an account? <Link to="/logout" className="text-primary">Login here</Link></h6>
//                   <form className="pt-3" noValidate onSubmit={this.onSubmit} >
//                     <div className="form-group">
//                       <label htmlFor="inputEmail1">First Name</label>
//                       {/* <input type="text" className="form-control form-control-lg" id="exampleInputUsername1" placeholder="Username" /> */}
//                       <input type="text" id="inputEmail1" className={this.state.first_name ? "form-control form-control-lg" : "form-control form-control-lg is-invalid"} placeholder="First Name" name="first_name" value={this.state.first_name || ''} onChange={this.onChange} required autoFocus />
//                       {isEmpty(this.state.first_name) === true ? <div className="invalid-feedback"> First name required.</div> : ''}
//                     </div>
//                     <div className="form-group">
//                       <label htmlFor="inputEmail2">Last Name</label>
//                       <input type="text" id="inputEmail2" className={this.state.last_name ? "form-control form-control-lg" : "form-control form-control-lg is-invalid"} placeholder="Last Name" name="last_name" value={this.state.last_name || ''} onChange={this.onChange} required />
//                       {isEmpty(this.state.last_name) === true ? <div className="invalid-feedback"> Last name required.</div> : ''}
//                     </div>
//                     <div className="form-group">
//                       <label htmlFor="inputEmail" >Email Address</label>
//                       <input type="email" id="inputEmail" className="form-control form-control-lg" placeholder="Email will be shown here." name="email" value={this.state.email} onChange={this.onChange} autoComplete="on" required readOnly />
//                     </div>
//                     <div className="form-group">
//                       <label htmlFor="inputPassword">Password</label>
//                       <input type="password" id="inputPassword" className={(this.props.passwordRequired === true) ? this.state.password ? "form-control form-control-lg" : "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Password" name="password" value={this.state.password || ''} onChange={this.onChange} autoComplete="current-password" />
//                       <div className="invalid-feedback-password is-invalid"> {this.state.passwordError} </div>
//                     </div>
//                     <div className="form-group">
//                       <label htmlFor="inputPasswordVerification">Confirm password</label>
//                       <input type="password" id="inputPasswordVerification" className={(this.props.passwordRequired === true) ? this.state.verify_password ? "form-control form-control-lg" : "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Confirm password." name="verify_password" value={this.state.verify_password || ''} onChange={this.onChange} autoComplete="current-password" />
//                       <div className="invalid-feedback-password is-invalid"> {this.state.verifyPasswordError} </div>
//                     </div>
//                     <div className="mt-3">
//                       <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={(!this.state.isValidPassword)}><b>Sign up</b></button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   profile: state
// })

// export default connect(mapStateToProps, { saveProfile })(withRouter(Profile));


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import isEmpty from '../../Utilities/isEmpty';
import './SignUp.css';
import { Link } from 'react-router-dom';
import { saveProfile } from '../../Actions/authActions';
import { UserProfileSignupPayload } from '../../Interfaces/PayloadsAndResponses/Auth';

type ProfileProps = {
  passwordRequired: boolean
};

type ProfileState = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  verify_password: string;
  isValidPassword: boolean;
  passwordError: string;
  verifyPasswordError: string;
  loading: boolean;
  phone_number: string;
};

const SignUp = ({ passwordRequired }: ProfileProps) => {
  const { auth } = useSelector((state: ReduxRootState) => state);
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || '{}'));
  // const [company, setCompany] = useState(JSON.parse(localStorage.getItem('company') || '{}'));
  const [profile, setProfile] = useState<ProfileState>({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    password: '',
    verify_password: '',
    isValidPassword: false,
    passwordError: 'Please enter password.',
    verifyPasswordError: 'Confirm password.',
    loading: false,
    phone_number: ''
  });
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  const [isPasswordConfirmShow, setIsPasswordConfirmShow] = useState<boolean>(false);

  useEffect(() => {
    setUser(user)
    document.title = 'Profile Setup';
    validateForm();
    // eslint-disable-next-line
  }, []);


  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.type === 'checkbox' ? e?.target?.checked : e?.target?.value;
    if(e?.target?.name === 'password' || e?.target?.name === 'verify_password'){
      let obj = profile
      obj[e.target.name] = e?.target?.value
      setProfile(obj);
    }else{
      setProfile({...profile, [e.target.name]: value})
    }
    validateForm(e.target);
  };

  useEffect(() => {
    if (auth?.profile_request_type) {
      history.push('/');
    }
  }, [auth, history]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    const profileData: UserProfileSignupPayload = {
      user: {
        first_name: profile?.first_name,
        last_name: profile?.last_name,
        password: profile?.password,
        phone_number: profile?.phone_number,
      },
    };
    dispatch(saveProfile(profileData, true));
  };

  const validateForm = (target?: EventTarget & HTMLInputElement) => {
    if (target && target.name === "password") {
      if (target?.value) {
        if (target?.value?.length > 5) {
          if (profile?.verify_password === target.value) {
            setProfile({ ...profile, isValidPassword: true, passwordError: '', verifyPasswordError: '' });
          } else {
            setProfile({ ...profile, verifyPasswordError: 'Please verify the password.', isValidPassword: false, passwordError: '' });
          }
        } else {
          setProfile({ ...profile, passwordError: 'Please enter atleast 6 digits.', isValidPassword: false });
        }
      } else {
        setProfile({ ...profile, passwordError: 'Please enter password.', isValidPassword: false });
      }
    }
    if (target && target.name === "verify_password") {
      if (target?.value && target?.value?.length > 5 && profile?.password === target?.value) {
        setProfile({ ...profile, isValidPassword: true, verifyPasswordError: '' });
      } else {
        setProfile({ ...profile, verifyPasswordError: 'Please verify the password.', isValidPassword: false });
      }
    }
  }
  const togglePasswordVisibility = () => {
    setIsPasswordShow(!isPasswordShow);
  };
  const togglePasswordConfirmVisibility = () => {
    setIsPasswordConfirmShow(!isPasswordConfirmShow);
  };

    return (
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row w-100">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-md-5">
                  <div className="brand-logo brand-sign-up-logo chowmill-logo-full">
                    <img src="./imgs/chowmill-logo-full.png" className="logo mt-5 mw-100" alt="logo" />
                  </div>
                  <h4>Signup</h4>
                  <h6 className="font-weight-light"> Already have an account? <Link to="/logout" className="text-primary">Login here</Link></h6>
                  <form className="pt-3" noValidate onSubmit={onSubmit} >
                    <div className="form-group">
                      <label htmlFor="inputEmail1">First Name</label>
                      {/* <input type="text" className="form-control form-control-lg" id="exampleInputUsername1" placeholder="Username" /> */}
                      <input type="text" id="inputEmail1" className={profile?.first_name ? "form-control form-control-lg" : "form-control form-control-lg is-invalid"} placeholder="First Name" name="first_name" value={profile?.first_name} onChange={onChange} required autoFocus />
                      {isEmpty(profile?.first_name) === true ? <div className="invalid-feedback"> First name required.</div> : ''}
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputEmail2">Last Name</label>
                      <input type="text" id="inputEmail2" className={profile?.last_name ? "form-control form-control-lg" : "form-control form-control-lg is-invalid"} placeholder="Last Name" name="last_name" value={profile?.last_name} onChange={onChange} required />
                      {isEmpty(profile?.last_name) === true ? <div className="invalid-feedback"> Last name required.</div> : ''}
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputEmail" >Email Address</label>
                      <input type="email" id="inputEmail" className="form-control form-control-lg" placeholder="Email will be shown here." name="email" value={profile?.email} onChange={onChange} autoComplete="on" required readOnly />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputPassword">Password</label>
                      <div className='input-group'>
                        <input type={isPasswordShow ? 'text' : 'password'}
                          id="inputPassword"
                          className={(passwordRequired === true) ? profile?.password ? "form-control form-control-lg" : "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                          placeholder="Password"
                          name="password"
                          value={profile?.password}
                          onChange={onChange}
                        />
                        <div className={'password-icon'} onClick={togglePasswordVisibility}>
                          {isPasswordShow ?
                            <span>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM223.1 149.5L313.4 220.3C317.6 211.8 320 202.2 320 191.1C320 180.5 316.1 169.7 311.6 160.4C314.4 160.1 317.2 159.1 320 159.1C373 159.1 416 202.1 416 255.1C416 269.7 413.1 282.7 407.1 294.5L446.6 324.7C457.7 304.3 464 280.9 464 255.1C464 176.5 399.5 111.1 320 111.1C282.7 111.1 248.6 126.2 223.1 149.5zM320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L177.4 235.8C176.5 242.4 176 249.1 176 255.1C176 335.5 240.5 400 320 400C338.7 400 356.6 396.4 373 389.9L446.2 447.5C409.9 467.1 367.8 480 320 480H320z" /></svg>
                            </span>
                            :
                            <span>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z" /></svg>
                            </span>
                          }
                        </div>
                      </div>
                      {/* <input type="password" id="inputPassword" className={(passwordRequired === true) ? profile?.password ? "form-control form-control-lg" : "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Password" name="password" value={profile?.password} onChange={onChange}  /> */}
                      <div className="invalid-feedback-password is-invalid"> {profile?.passwordError} </div>
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="inputPassword">Password</label>
                      <input type="password" id="inputPassword" className={(passwordRequired === true) ? profile?.password ? "form-control form-control-lg" : "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Password" name="password" value={profile?.password} onChange={onChange}  />
                      <div className="invalid-feedback-password is-invalid"> {profile?.passwordError} </div>
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="inputPassword">Confirm password</label>
                      <div className='input-group'>
                        <input type={isPasswordConfirmShow ? 'text' : 'password'}
                          id="inputPasswordVerification"
                          className={(passwordRequired === true) ? profile?.verify_password ? "form-control form-control-lg" : "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                          placeholder="Confirm password."
                          name="verify_password"
                          value={profile?.verify_password}
                          onChange={onChange}
                          autoComplete="current-password"
                        />
                        <div className={'password-icon'} onClick={togglePasswordConfirmVisibility}>
                          {isPasswordConfirmShow ?
                            <span>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM223.1 149.5L313.4 220.3C317.6 211.8 320 202.2 320 191.1C320 180.5 316.1 169.7 311.6 160.4C314.4 160.1 317.2 159.1 320 159.1C373 159.1 416 202.1 416 255.1C416 269.7 413.1 282.7 407.1 294.5L446.6 324.7C457.7 304.3 464 280.9 464 255.1C464 176.5 399.5 111.1 320 111.1C282.7 111.1 248.6 126.2 223.1 149.5zM320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L177.4 235.8C176.5 242.4 176 249.1 176 255.1C176 335.5 240.5 400 320 400C338.7 400 356.6 396.4 373 389.9L446.2 447.5C409.9 467.1 367.8 480 320 480H320z" /></svg>
                            </span>
                            :
                            <span>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z" /></svg>
                            </span>
                          }
                        </div>
                      </div>
                      {/* <input type="password" id="inputPassword" className={(passwordRequired === true) ? profile?.password ? "form-control form-control-lg" : "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Password" name="password" value={profile?.password} onChange={onChange}  /> */}
                      <div className="invalid-feedback-password is-invalid"> {profile?.verifyPasswordError} </div>

                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="inputPasswordVerification">Confirm password</label>
                      <input type="password" id="inputPasswordVerification" className={(passwordRequired === true) ? profile?.verify_password ? "form-control form-control-lg" : "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Confirm password." name="verify_password" value={profile?.verify_password} onChange={onChange} autoComplete="current-password" />
                      <div className="invalid-feedback-password is-invalid"> {profile?.verifyPasswordError} </div>
                    </div> */}
                    <div className="mt-3">
                      <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={(!profile?.isValidPassword)}><b>Sign up</b></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  export default SignUp