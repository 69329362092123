import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getRestaurantMenusApi } from '../../../Actions/menuActions';
import { GET_RESTAURANT_DETAILS } from '../../../Actions/types';
// import Breadcrumbs from '../../../Common/Breadcrumbs';
import { RestaurantLocation } from '../../../Interfaces/PayloadsAndResponses/GetRestaurants';
import { MenuApiData } from '../../../Interfaces/PayloadsAndResponses/Menu';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import store from '../../../store';
import { Params } from '../../Dashboard/Dashboard';
// import ItemPage from './ItemPage';
// import queryString from 'query-string';
// import { getLocalStorageData } from '../../../Utilities/getLocalStorageData';
import MenuCard from './MenuCard';
import MenuPageSkeleton from './MenuPageSkeleton';
// import MenuSection from './MenuSection';
// import MenuTypeSections from './MenuTypeSections';

const MenuPage = () => {

  const dispatch: any = useDispatch()
  const params = useParams<Params>();
  const history = useHistory();


  // const remember_restaurant: any = localStorage.getItem('remember_restaurant');
  const menuData = useSelector((state: ReduxRootState) => state.menus)
  const restaurant_addresses = useSelector((state: ReduxRootState) => state?.restaurant_data?.restaurant_addresses);
  const restaurant_addressesData = useSelector((state: ReduxRootState) => state?.restaurant_data?.restaurant_details);
  const menuDataLoading = menuData?.menu_loading ?? false

  const menumenu = menuData?.menus as MenuApiData

  const menuAPIData: any = {}
  menumenu?.menus?.forEach((val, index) => {
    if (val?.menu_type === 1) {
      menuAPIData.lunch = menumenu?.menus[index]
    } else if (val?.menu_type === 2) {
      menuAPIData.dinner = menumenu?.menus[index]
    } else if (val?.menu_type === 3) {
      menuAPIData.breakfast = menumenu?.menus[index]
    } else if (val?.menu_type === 4) {
      menuAPIData.buffet = menumenu?.menus[index]
    }
  })

  const { restaurantId } = params;
  let _restaurant_addresses = restaurant_addresses && restaurant_addresses?.addresses;

  const changeRestaurant = (restaurant: RestaurantLocation) => {
    const { id: newSelectedRestaurant, order_presence, enable_self_service, billing_tab } = restaurant;
    let { restaurantId, page } = params;
    if (restaurantId && +restaurantId !== newSelectedRestaurant) {
      // These checks are required because Invoices and Billing Page are Interchangeable (ONE AT A TIME);
      if (page === `billing`) {
        page = enable_self_service ? `invoices` : (billing_tab ? page : ``);
      } else if (page === `invoices`) {
        page = enable_self_service ? page : (billing_tab ? `billing` : ``);
      }
      store.dispatch({
        type: GET_RESTAURANT_DETAILS,
        payload: { address: restaurant }
      });

      localStorage.setItem('remember_restaurant', `${newSelectedRestaurant}`);
      localStorage.setItem('orderPresent', `${order_presence}`);
      localStorage.setItem('isSelfServiceEnable', `${enable_self_service}`);
      const menuPage = page === 'menu' || false;
      const localStorageMenuType = menuPage ? localStorage.getItem('menuType') ? localStorage.getItem('menuType') : 'lunch' : '';
      history.push(`/dashboard/restaurant/${newSelectedRestaurant}${page ? `/${menuPage ? `menu?menu=${localStorageMenuType}` : page}` : ``}`);
    }
  };
  const getSpecificMenuType = (type: string) => {
    localStorage.setItem('menuType', `${type}`)
    // restaurantId && dispatch(getMenuType(type, +restaurantId));
  }
  useEffect(() => {
    if (restaurant_addressesData) {
      dispatch(getRestaurantMenusApi(restaurant_addressesData?.address?.id))
    } else if (restaurantId) {
      dispatch(getRestaurantMenusApi(+restaurantId))
    }
    //eslint-disable-next-line
  }, [restaurant_addressesData?.address?.id])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center" style={{ marginTop: "85px", marginBottom: "63px" }}>
        <h2 className='menu_page_headings m-0'>Menus</h2>
        {_restaurant_addresses && _restaurant_addresses?.length > 1 && <div className='dropdown'>
          <div className='dropdown-toggle font-16 cursor-pointer menu_card_dropdown'
            id='dropdownMenuButton'
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <b><i className="fa-sharp fa-solid fa-store menu_card_shop_icon"></i></b>
            <span className='fs-16 fw-600 m-0'>Select Restaurant</span>
          </div>
          <div
            className='dropdown-menu main_menu mt-2'
            aria-labelledby='dropdownMenuButton'
            style={{ left: -158, top: 46, overflowY: 'scroll', maxHeight: 'calc(100vh - 58vh)', borderRadius: '5px', }}
          >
            {_restaurant_addresses && _restaurant_addresses?.map((restaurant: RestaurantLocation, index: number) => (
              <h6
                key={index}
                className={`dropdown-header px-3 restaurant-names-${parseFloat(`${restaurantId ?? ''}`) === restaurant.id ? 'active' : ''} cursor-pointer`}
                onClick={() => changeRestaurant(restaurant)}
                style={{ whiteSpace: 'normal' }}
              >
                <div className="d-flex align-items-start">
                  <div className="ms-3 fff">
                    {/* Restaurant Name */}
                    <div className='text-medium-black fw-normal fs-16'>{restaurant.name?.split(':')[0]}</div>
                    {/* Location Name */}
                    <div>{restaurant.name?.split(':')[1]}</div>
                    {/* Location */}
                    <div className='text-medium-black fw-normal fs-16'>{restaurant.name?.split(':')[2]}</div>
                  </div>
                </div>
              </h6>
            ))}
          </div>
        </div>
        }
      </div>
      <React.Fragment>
        <div>
          <div className=' main-menu-card' >
            {menuDataLoading ? <MenuPageSkeleton /> : <div className='card-body p-0 row gy-4'>
              {['breakfast', 'lunch', 'dinner', 'buffet'].map((val, index) => {
                return <React.Fragment key={index}>
                  <MenuCard menuType={val} menuData={menuAPIData[val] ?? {}} getSpecificMenuType={getSpecificMenuType} />
                </React.Fragment>
              })}
            </div>
            }
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default MenuPage