import { SEND_FORGOT_PASSWORD_EMAIL, SET_PROFILE_DATA, SIGN_UP_USER, RESET_STATUS, LOG_OUT, SET_PROFILE_REQUEST_TYPE, GET_DRIVERS, SET_DRIVER_LOADING, GET_ANNOUNCEMENTS, SET_CURRENT_USER_AUTHENTICATION } from '../Actions/types';
import { AuthReducer } from '../Interfaces/ReduxInterface/ReduxRootState';
import { Action } from './ActionInterface';

const initialState: AuthReducer = {
  isAuthenticated: false,
  forgot_mail_status: null,
  profile: null,
  announcements: [],
  reset_password_status: null,
  profile_request_type: null,
  driver_list: null,
  driver_loading: false,
  error_driver_update: null,
  driver_loading_list: {}
};

const authReducer = (state: AuthReducer = initialState, action: Action): AuthReducer => {
  switch (action.type) {
    case SET_CURRENT_USER_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.payload,
      }
    case SEND_FORGOT_PASSWORD_EMAIL:
      return {
        ...state,
        forgot_mail_status: action.payload
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        profile: action.payload,
        profile_request_type: 'profile'
      }
    case SIGN_UP_USER:
      return {
        ...state,
        profile_request_type: 'profile'
      }
    case RESET_STATUS:
      return {
        ...state,
        reset_password_status: action.payload,
      }
    case LOG_OUT:
      return {
        ...state,
        isAuthenticated: false,
      }
    case SET_PROFILE_REQUEST_TYPE:
      return {
        ...state,
        profile_request_type: ''
      }
    case GET_DRIVERS:
      return {
        ...state,
        driver_loading: false,
        driver_list: action.payload.data,
        error_driver_update: null,
        driver_loading_list: { ...state.driver_loading_list, [action.payload.driverId]: false }
      }
    case 'INITIALIZE_DRIVER_ON_CLOSE_POPUP':
      return {
        ...state,
        error_driver_update: null,
      }
    case 'GET_DRIVER_LOADING':
      return {
        ...state,
        driver_loading: true,
      }
    case 'ERROR_UPDATE_DRIVER':
      return {
        ...state,
        driver_loading: false,
        error_driver_update: action.payload,
      }
    case SET_DRIVER_LOADING:
      return {
        ...state,
        driver_loading_list: { ...state.driver_loading_list, [action.payload]: true }
      }
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload,
      }
    default:
      return state;
  }
};

export default authReducer;