import React from 'react';
import Skeleton from 'react-loading-skeleton';
const orders = [1, 2, 3, 4]
export const OrderDetailsTableSkelton = () => {
  return <div className="table-responsive">
    <table className="table table-hover">
      <tbody>
        {orders.map((index, orders) => <tr key={index}>
          <td><Skeleton height={46} width={236} /></td>
          <td><Skeleton height={46} width={280} /></td>
          <td><Skeleton height={46} width={126} /></td>
          <td><Skeleton height={46} width={199} /></td>
          <td><Skeleton height={34} width={48} /></td>
        </tr>)}
      </tbody>
    </table>
  </div>
}
export const OrderDetailsSkelton = () => {
  return <React.Fragment>
    <div className='single-order-skeleton'>
      <div className='d-flex align-items-center' style={{ gap: 10 }}>
        <Skeleton width={156} height={20} />
      </div>
      <h3 className='mt-4'>
        <Skeleton width={135} height={25} />
      </h3>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='order-status'>
          <Skeleton width={99} height={28} />
        </span>
        <Skeleton width={119} height={40} />
      </div>
      <div className='delivery-detail-section mt-4 p-4'>
        <div>
          <h6><Skeleton width={141} height={16} /></h6>
          <h6> <Skeleton width={141} height={15} /></h6>
        </div>
        <div>
          <h6><Skeleton width={40} height={16} /></h6>
          <h6><Skeleton width={40} height={15} /></h6>
        </div>
        <div>
          <h6><Skeleton width={120} height={16} /></h6>
          <h6><Skeleton width={120} height={15} /></h6>
        </div>
        <div>
          <h6><Skeleton width={143} height={16} /></h6>
          <h6><Skeleton width={141} height={15} /></h6>
        </div>
      </div>
      <div className='contact-chowmill'>
        <h5><Skeleton width={385} height={16} /></h5>
      </div>
      <div className='delivery-orders-detail-section'>
        <div className='d-flex align-items-center justify-content-between'>
          <h4 className='m-0'><Skeleton width={100} height={18} /></h4>
          <div className='download-btns'>
            {/* <Skeleton width={206} height={40} /> */}
            <Skeleton width={206} height={40} />
            <Skeleton width={119} height={40} />
          </div>
        </div>
      </div>
      <div className='mt-3'>
        <OrderDetailsTableSkelton />
      </div>
    </div>
  </React.Fragment>
}