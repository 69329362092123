import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';

type NavLinkProps = {
  linkURL: string;
  isActive: boolean;
  label: string;
  icon?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> | null,
  isOrder?: boolean;
  meetings?: boolean;
}

const NavLink = ({ linkURL, isActive, label, icon, isOrder,meetings }: NavLinkProps) => {
  const { i18n } = useSelector((state: ReduxRootState) => state.i18n);
  return (
    <li className={`nav-item ${meetings && 'meetings-bell'}`}>
      <Link
        style={isOrder ? { display: 'flex', alignItems: 'center' } : {}}
        to={linkURL}
        className={
          isActive
            ? 'nav-link prim-link mr-4 ml-3 active'
            : 'nav-link prim-link mr-4 ml-3'
        }
      >
        <>
          {i18n && i18n.t(label)} &nbsp;
          {icon && icon}
        </>
      </Link>
    </li>
  );
};

export default NavLink;
