import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ArrayOfLength } from '../../Utilities/ArraysUtilities';

export const GeneralSettingsSkelton = () => {
  return <React.Fragment>
    <div>
      <div className='row border-bottom py-2 mb-3 mx-0'>
        <div className='col-12 col-md-6 order-md-1 order-2 px-0'>
          <h3 className='m-0'>General Settings</h3>
        </div>
        <div className='col-12 col-md-6 order-md-2 order-1 mb-2 mb-md-0 px-0'>
          <h3 className='m-0'>
            <div className='custom-control custom-switch float-right d-inline mr-0 ml-auto'>
              <div className='custom-switch float-right d-flex align-items-center'>
                <Skeleton className='d-flex' width={28} height={16} />
                <Skeleton className='ml-2' width={110} height={24} />
              </div>
            </div>
          </h3>
        </div>
      </div>
      <div className='row  pb-3'>
        <div className='col-md-3'>
          <Skeleton width='100%' height='171px' />
        </div>
        <div className='col-md-5'>
          <div className='py-3 px-2 border-bottom'>
            <p className='text-muted font-weight-bold'>Restaurant Name
              <button className='btn btn-link btn-sm float-right py-1'>
                <Skeleton width={18} height={18} />
              </button>
            </p>
            <strong><Skeleton width={'100%'} height={20} /></strong>
          </div>
          <div className='py-3 px-2'>
            <p className='text-muted font-weight-bold'>Address
              <button className='btn btn-link btn-sm float-right py-1'>
                <Skeleton width={18} height={18} />
              </button>
            </p>
            <div><Skeleton width={'100%'} height={20} /></div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='py-2 px-2 '>
            <p className='text-muted font-weight-bold'>Cuisines
              <button className='btn btn-link btn-sm float-right py-1'>
                <Skeleton width={18} height={18} />
              </button>
            </p>
            <Skeleton className='badge badge-pill m-1 ' width={75} height={25} />
            <Skeleton className='badge badge-pill m-1' width={75} height={25} />
            <Skeleton className='badge badge-pill m-1' width={75} height={25} />
            <Skeleton className='badge badge-pill m-1' width={75} height={25} />
          </div>
        </div>
      </div>
      <hr />
    </div>
  </React.Fragment>
}
export const DeliverySettingsSkelton = () => {
  return <React.Fragment>
    <div>
      <h3 className='border-bottom py-2 mb-3'>Delivery Settings
      </h3>
      <div className='row'>
        <div className='col-md-6'>
          <div className='py-3 px-2  border-bottom' >
            <p className='text-muted font-weight-bold'>Invidiaul Meals Cutoff
              <button className='btn btn-link btn-sm float-right py-1'>
                <Skeleton width={18} height={18} />
              </button>
            </p>
            <p><Skeleton height={18} /></p>
            <Skeleton width={60} height={20} />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='py-3 px-2  border-bottom' >
            <p className='text-muted font-weight-bold'>Buffet Cutoff
              <button className='btn btn-link btn-sm float-right py-1'>
                <Skeleton width={18} height={18} />
              </button>
            </p>
            <p><Skeleton height={18} /></p>
            <Skeleton width={60} height={20} />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
}
const contacts = ArrayOfLength(4)
export const ContactsSkelton = () => {
  return <React.Fragment>
    <h3 className='border-bottom py-2 mt-3 mb-3'>Contacts
      <button className='btn btn-link btn-sm  py-1'>
        <i className='fas fa-plus' aria-hidden='true'></i>
      </button>
    </h3>
    <div className='table-responsive'>
      <table className='table table-hover'>
        <thead className='thead-dark'>
          <tr>
            <th className='w-25'>Name</th>
            <th className='w-25'>Email</th>
            <th className='w-25'>Phone</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact, index) => <tr key={index}>
            <td><Skeleton height={46} width={236} /></td>
            <td><Skeleton height={46} width={280} /></td>
            <td><Skeleton height={46} width={199} /></td>
            <td><Skeleton height={34} width={48} /></td>
            <td><Skeleton height={34} width={48} /></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </React.Fragment>
}
export const DriversSkelton = ({ length }: { length: number | null }) => {
  return <React.Fragment>
    <h3 className='border-bottom py-2 mt-3 mb-3'>Drivers
      <button className='btn btn-link btn-sm  py-1'>
        <i className='fas fa-plus' aria-hidden='true'></i>
      </button>
    </h3>
    <div className='table-responsive'>
      <table className='table table-hover'>
        <thead className='thead-dark'>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ArrayOfLength(length || 5).map((driver, index) => <tr key={index}>
            <td><Skeleton height={25} width={25} circle /></td>
            <td><Skeleton height={34} width={257} /></td>
            <td><Skeleton height={34} width={293} /></td>
            <td><Skeleton height={34} width={133} /></td>
            <td><Skeleton height={34} width={48} /></td>
            <td><Skeleton height={34} width={44} /></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </React.Fragment>
}
const days = ArrayOfLength(7);
export const HoursOfOperationSkeleton = () => {
  return <React.Fragment>
    <div>
      <h3 className='border-bottom py-2 mt-3 mb-3'>Hours of Operation
      </h3>
      <div className='table-responsive'>
        <table className='table table-hover'>
          <thead className='thead-dark'>
            <tr>
              <th className='w-25'>Day</th>
              <th className='w-25'>Closed?</th>
              <th className='w-50'>Open & Close Shifts</th>
            </tr>
          </thead>
          <tbody>
            {days.map((day, index) => <tr key={index}>
              <td className='w-25'><strong className='text-capitalize'><Skeleton width={72} height={20} /></strong></td>
              <td className='w-25'>
                <div className=' custom-switch'>
                  <label className='custom-control-label'><Skeleton width={81} height={14} /></label>
                </div>
              </td>
              <td>
                <div className='row'>
                  <div className='col-3'><Skeleton width={32} height={14} /></div>
                  <div className='col-3'><Skeleton width={15} height={14} /></div>
                  <div className='col-3'><Skeleton width={32} height={14} /></div>
                  <div className='col-3'>
                    <button className='btn btn-link btn-sm text-danger'>
                      <Skeleton width={18} height={18} circle />
                    </button>
                    <button className='btn btn-link btn-sm text-success'>
                      <Skeleton width={18} height={18} circle />
                    </button>
                  </div>
                </div>
              </td>
            </tr>)}
          </tbody>
        </table>
        <Skeleton className='mt-3' height={32} />
      </div>
    </div>
  </React.Fragment>
}