
import axios from "axios";
// import { stringify } from "querystring";
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone";
// import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import { getExistingFoodItems, getMenuSectionsFoodItems, addMenuFoodItem, editMenuFoodItem, } from "../../../Actions/menuActions";
// import { getDietaries, getIngredients, getMenuSections } from "../../../Actions/menuActions";
import { GET_SYSTEM_DIETRIES, GET_SYSTEM_INGREDIENTS } from "../../../Actions/types";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import { Dietary, FoodItem, Ingredient } from "../../../Interfaces/PayloadsAndResponses/Menu";
// import { Dietary, Ingredient } from "../../../Interfaces/PayloadsAndResponses/Menu";
import { ReduxRootState } from "../../../Interfaces/ReduxInterface/ReduxRootState";
import { Params } from '../../../Pages/Dashboard/Dashboard';
import SectionFoodItemCard from "./SectionFoodItemCard";
import MenuSectionsSkeleton from "./MenuSectionsSkeleton";
import Spinner from "../../../Common/Spinner";
import SelectExistingFootItemPopup from "./SelectExistingFoodItemPopup";
import ReactTooltip from "react-tooltip";
import isEmpty from "../../../Utilities/isEmpty";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";

type foodItemFormData = {
  name: string,
  price: string,
  description: string,
  position: number,
  section_visibility: boolean,
  image: any,
  dietary_ids: string[],
  ingredient_ids: string[]
}

const MenuSection = ({ linkListUpdated }: any) => {
  const { restaurantId } = useParams<Params>();
  const dispatch: any = useDispatch()
  const history: any = useHistory();
  const systemDietries = useSelector((state: ReduxRootState) => state.menus?.systemDietries)
  const systemIngredients = useSelector((state: ReduxRootState) => state.menus?.systemIngredients)
  const [systemFoodItemDiet, setSystemFoodItemDiet] = useState<Dietary[]>(systemDietries)
  const [systemFoodItemIng, setSystemFoodItemIng] = useState<Ingredient[]>(systemIngredients)
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';


  const selectedMenuId = localStorage.getItem('menuTypeid') ?? ''
  const getMenutype = localStorage.getItem('menuType')

  const sectionFoodItems = useSelector((state: ReduxRootState) => state.menus?.selectedSectionFoodItems);
  //Select existing fooditems
  const selectExistingFoodItemsData = useSelector((state: ReduxRootState) => state.menus?.selectExistingFoodItems);

  const [checkFoodItem, setCheckedFoodItem] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isEditPopup, setIsEditPopup] = useState<boolean>(false)
  const [selectedFoodItem, setSelectedFoodItem] = useState<any>({
    name: "",
    price: "",
    description: "",
    position: 1,
    section_visibility: false,
    image: selectedImage,
    dietary_ids: [],
    ingredient_ids: []
  })
  const [openExistingFooditem, setOpenExistingFooditem] = useState<boolean>(false)
  const [foodItemFormData, setFoodItemFormData] = useState<foodItemFormData>({
    name: "",
    price: "",
    description: "",
    position: 1,
    section_visibility: false,
    image: selectedImage,
    dietary_ids: [],
    ingredient_ids: [],
  })

  const sectionFoodItemLoading = useSelector((state: ReduxRootState)=>state.menus?.sectionFoodItemLoading)

  const loaderHandler = () => {
    setIsOpen(false)
    setIsLoading(false)
  }
  const loaderChange = () =>{
    setIsEditPopup(false)
    setIsLoading(false)
  }

  const selectedFoodItemHandler = (fooditem: FoodItem) => {
    setIsEditPopup(true)
    let dietry = [...fooditem?.dietaries]
    const updatedDiet = [...systemDietries]
    dietry?.forEach((selectedDietry) => {
      systemDietries?.find((systemFoodItemDietDietry, id) => {
        if (selectedDietry?.id === systemFoodItemDietDietry?.id) {
          updatedDiet[id] = { ...updatedDiet[id], selected: true };
          setSystemFoodItemDiet(updatedDiet)
        }
        return selectedDietry?.id === systemFoodItemDietDietry?.id
      })
      setIsEditPopup(true)
    })
    setSelectedFoodItem(fooditem)
  }

  const itemDetails = (foodItem: FoodItem) => {
    localStorage.setItem('fooditemid', `${foodItem?.id}`)
    localStorage.setItem('fooditem', `${foodItem?.name}`)
    const newURL = `/dashboard/restaurant/${restaurantId}/item/item?item=${foodItem?.name}`;
    history.push(newURL);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    // Handle checkbox inputs separately
    const fieldValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    setFoodItemFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleChangeCheckbox = () => {
    setFoodItemFormData((prevData) => ({
      ...prevData,
      section_visibility: !foodItemFormData?.section_visibility,
    }));
  };

  const handleChangeForEdit = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    // Handle checkbox inputs separately
    const fieldValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    setSelectedFoodItem((prevData: any) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleChangeEditCheckbox = () => {
    setSelectedFoodItem((prevData: any) => ({
      ...prevData,
      section_visibility: !foodItemFormData?.section_visibility,
    }));
  };

  useEffect(()=>{

  },[isLoading, systemDietries, systemIngredients])

  useEffect(() => {
    getDietaries()
    getIngredients()
    restaurantId && dispatch(getMenuSectionsFoodItems(+restaurantId, +selectedMenuId, +sectionid))
    // eslint-disable-next-line
  }, [restaurantId, selectedMenuId,checkFoodItem])

  const getDietaries = () => {
    axios.get(`/vendor/dietaries`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      }
    )
      .then(res => {
        dispatch({
          type: GET_SYSTEM_DIETRIES,
          payload: res.data
        });
      })
      .catch(err =>
        console.log("ERROR", err)
      );
  };

  const getIngredients = () => {
    axios.get(`/vendor/ingredients`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      }
    )
      .then(res => {
        dispatch({
          type: GET_SYSTEM_INGREDIENTS,
          payload: res.data
        });

      })
      .catch(err =>
        console.log("ERROR", err)
      );
  };
  const dietariesListChangeHandler = (index: number) => {
    const updatedDiet = [...systemFoodItemDiet]
    updatedDiet[index] = { ...updatedDiet[index], selected: !updatedDiet[index]?.selected };
    setSystemFoodItemDiet(updatedDiet)
  }
  const ingredientsListChangeHandler = (index: number) => {
    const updatedIngrd = [...systemFoodItemIng]
    updatedIngrd[index] = { ...updatedIngrd[index], selected: !updatedIngrd[index]?.selected };
    setSystemFoodItemIng(updatedIngrd)
  }

  const selectExistingFoodItems = () => {
    setOpenExistingFooditem(true)
    restaurantId && dispatch(getExistingFoodItems(+restaurantId, +selectedMenuId, +sectionid))
  } 

  const selectExistingPopUpHandler = () => {
    setOpenExistingFooditem(false)
  }

  const foodItemImageHandler = (acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string; // Get the Base64 encoded string
        setSelectedImage(
          new File([acceptedFiles[0]], base64String, { type: acceptedFiles[0].type })
        );
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  const handleSubmitFoodItem = () => {
    setIsLoading(true);
    const selectedDietaryIds = systemFoodItemDiet?.filter((item) => item.selected)?.map((item) => item.id);
  const selectedIngredientIds = systemFoodItemIng?.filter((item) => item.selected)?.map((item) => item.id);

  const imageBase64 = selectedImage ? selectedImage.name : null;
    const foodItemPayload = {
      fooditem: {
        name: foodItemFormData?.name,
        ...(getMenutype === 'buffet' ? {} : { price: foodItemFormData?.price }),
        description: foodItemFormData?.description,
        position: foodItemFormData?.position,
        is_hidden: !foodItemFormData?.section_visibility,
        dietary_ids: selectedDietaryIds,
        ingredient_ids: selectedIngredientIds,
        image: imageBase64
      }
    }
    selectedMenuId && restaurantId && dispatch(addMenuFoodItem(foodItemPayload, selectedMenuId, restaurantId, sectionid, loaderHandler))
    setSelectedImage(null)
  }
  const foodItemUpdateSubmitHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
   setIsLoading(true);
   e.preventDefault();
   let selectedDietaries: number[] = [], selectedIngredients: number[] = [];
   systemFoodItemDiet?.forEach((dietary) => { dietary?.selected && selectedDietaries?.push(dietary.id); })
   systemFoodItemIng?.forEach((ingredient) => { ingredient?.selected && selectedIngredients?.push(ingredient.id) });
   const imageBase64 = selectedImage ? selectedImage.name : null;
   const foodItemPayload = {
    fooditem: {
      name: selectedFoodItem?.name,
      ...(getMenutype === 'buffet' ? {} : { price: selectedFoodItem?.price }),
      description: selectedFoodItem?.description,
      is_hidden: selectedFoodItem?.section_visibility,
      dietary_ids: selectedDietaries,
      ingredient_ids: selectedIngredients,
      image: imageBase64
    }
  }
  selectedMenuId && restaurantId && dispatch(editMenuFoodItem(foodItemPayload, selectedMenuId, +restaurantId, sectionid, selectedFoodItem?.id, loaderChange))
  }
  
  const handleResetOnAddNew = () => {
    setSelectedImage(null)
    setIsOpen(true);
    setFoodItemFormData({
      name: "",
      price: "",
      description: "",
      position: 1,
      section_visibility: false,
      image: selectedImage,
      dietary_ids: [],
      ingredient_ids: []
    });
    setSystemFoodItemDiet(systemDietries)
    setSystemFoodItemIng(systemIngredients)
  }

  const handleCheckFoodItem = () =>{
    setCheckedFoodItem(!checkFoodItem)
  }

  return (
    <div className="food-items-page-container">
      <section>
        <div className="mb-1">
          <Breadcrumbs linkList={linkListUpdated} />
        </div>
        <div className="text-white section-container">
          <div className="d-flex align-items-center">
            <Link to={`/dashboard/restaurant/${restaurantId}/sections/`} style={{ color: '#fff'}}>
              <i className='fa-solid fa-angle-left mr-2' ></i>
            </Link>  
            <h3 className="section-food-items-heading section-heading">{sectionData?.name?.charAt(0)?.toUpperCase() + sectionData?.name?.slice(1)}</h3>
            <p className="px-2 ml-2 d-flex  align-items-center ">
              {sectionData?.is_hidden ? <>
                <i className="fas fa-eye-slash hidden-eye-class"></i>
                <p className="fs-18 fw-normal ms-2 mb-0">Hidden</p>
              </>
                :
                <>
                  <i className="fa-thin fa-eye"></i>
                  <p className="fs-18 fw-normal ms-2 mb-0">Visible</p>
                </>
              }
            </p>
          </div>
          <p >This is a section of your menu, customize it any way you want.</p>
        </div>
      </section>
      {
        true ?
          <div className="container px-0 ">
            {(sectionFoodItemLoading || isEmpty(sectionFoodItems)) ? <MenuSectionsSkeleton fromMenuSection={true} /> :
            <div className="bg-white rounded-10 ">
              <div className="header_section d-flex justify-content-between align-items-center px-2 px-md-4 py-3 border-bottom">
                <h3 className="fw-600 text-medium-black">{`${sectionData?.name}'s`} Item(s)</h3>
                <div className="btn-group">
                  <button type="button" className="btn btn-custom-primary rounded-pill dropdown-toggle px-4 py-2" data-bs-toggle="dropdown" aria-expanded="false">
                    Add item
                  </button>
                  <ul className="dropdown-menu">
                    <li><span className="dropdown-item" onClick={handleResetOnAddNew}>New Dish</span></li>
                    <li><span className="dropdown-item" onClick={selectExistingFoodItems} >Select Existing</span></li>
                  </ul>
                </div>

              </div>
                {sectionFoodItems?.fooditems?.map((fooditem: any, index: any) => {
                  return (<React.Fragment key={index}>
                    <SectionFoodItemCard fooditem={fooditem} itemDetails={itemDetails} setCheckedFoodItem={handleCheckFoodItem} selectedFoodItemHandler={selectedFoodItemHandler} />
                      <hr className="menu_seprator" />
                  </React.Fragment>
                  )
                })}
              <div className="px-4 py-4 my-4 mx-4 menu_list_add_area position-relative">
                <i className="fa-light fa-plus fa-lg"></i>
                <div className="d-flex flex-wrap align-items-center justify-content-center menu_list_button_area">
                  <button className="btn btn-lite-primary" onClick={handleResetOnAddNew} >Add Item</button>
                  <button className="btn btn-lite-primary" onClick={selectExistingFoodItems} >Select Existing</button>
                </div>
              </div>
              <hr className="menu_seprator" />
            </div>
            }

            <div>
            </div>

            {isOpen &&
              <CustomPopUp
                popUpClassName="food-item-popup"
                backdropStyles={{ opacity: 1, cursor: 'default' }}
                onPopUpClose={() => setIsOpen(false)}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content ">
                    <div className="menu-item-modal-header">
                      <h3 className="fw-600 m-0 text-medium-black">Add Food Item</h3>
                      <button type="button" className="btn-close modal-close-btn text-dark" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsOpen(false)}></button>
                    </div>
                    <div className="modal-body py-0" style={{ height:'50vh'}}>
                      <p className="fw-normal fs-16 mb-1">Images
                        <span
                          data-tip
                          data-for='tagging'
                          className='text-capitalize'>
                          <i className="fa-thin fa-circle-info fa-sm ms-2 text-secondary-blue"></i>
                        </span>
                        {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                          <ReactTooltip
                            type='dark'
                            place='top'
                            effect='solid'
                            className='react-tooltip'
                            id='tagging'
                          >
                            <span>Section image is auto cropped, and this is how the image will look on your section</span>
                          </ReactTooltip>
                        }
                      </p>
                      <Dropzone
                        onDrop={foodItemImageHandler}
                        // @ts-ignore
                        accept='image/jpg, image/jpeg, image/png'
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className='bg-light d-flex justify-content-center align-items-center'
                            style={{ border: '2px dashed #0087F7', borderRadius: '5px', height: '175px' }}>
                            <div className='h-100 w-100'  {...getRootProps()} >
                              <input {...getInputProps()} />
                              {selectedImage && <div className='fooditem-image h-100 w-100 d-flex justify-content-center align-items-center'>
                              <img 
                                src={URL.createObjectURL(selectedImage)}
                                alt='Selected'
                                style={{ width: "200px", height: "auto" }}
                                />
                              </div>}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <form>
                        <div className="row mb-3 mt-3">
                          <div className="col">
                            <label className="fs-16 fw-600 text-medium-gray m-0">Name</label>
                            <input type="text" name="name" value={foodItemFormData?.name} onChange={(e) => handleChange(e)} className="form-control form-control-sm rounded-1" aria-label="First name" />
                          </div>
                          <div className={getMenutype === 'buffet' ? 'col-12' : 'col'}>
                          {getMenutype !== 'buffet' &&
                          <div>
                            <label className="fs-16 fw-600 text-medium-gray m-0">Price</label>
                              <div className="input-group input-group-sm">
                                <span className="input-group-text" id="basic-addon1">$</span>
                                <input type="number" name="price" value={foodItemFormData?.price} onChange={(e) => handleChange(e)} className="form-control form-control-sm" aria-label="price" aria-describedby="basic-addon1" />
                              </div>
                            </div>
                          }
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="fs-16 fw-600 text-medium-gray m-0">Description</label>
                          <textarea name='description' value={foodItemFormData?.description} onChange={(e) => handleChange(e)} className="form-control form-control-sm rounded-1" rows={4} id="exampleFormControlTextarea1"></textarea>
                        </div>
                        <div className="mb-3">
                          <p className="fs-16 fw-600 text-medium-gray m-0">Dietaries</p>
                          <div className="tag-pills d-flex flex-wrap align-items-center">
                            {systemFoodItemDiet?.map((dietary, index) => {
                              return <span key={index}>
                                <button type='button'
                                  key={dietary?.id}
                                  className={`btn btn-cuisine py-2 px-3 d-flex gap-2 align-items-center m-1 ${dietary?.selected ? 'btn-dark' : 'btn-outline-dark'}`}
                                  name={`${dietary?.name}-${dietary?.id}`}
                                  value={dietary?.id}
                                  onClick={() => dietariesListChangeHandler(index)}
                                  id={`dietary-${dietary?.id}`}>
                                  {dietary?.logo ? (
                                    <div className="d-inline-flex justify-content-center align-items-center ml-1 tag-pills-svgs">
                                      {parse(dietary?.logo)}
                                    </div>
                                  ) : (
                                    <div className="text-uppercase tagging-text">{dietary?.name?.charAt(0)}</div>
                                  )}
                                  {/* <img src="/imgs/beef.svg" className="cuisines-diet-img" alt="dietary" /> */}
                                  {dietary?.name}
                                </button>
                              </span>
                            })}
                          </div>
                          <p className="fs-16 fw-600 text-medium-gray m-0 mt-3">Ingredients</p>
                          <div className="tag-pills d-flex flex-wrap align-items-center">
                            {systemFoodItemIng?.map((ingredient, index) => {
                              return <span key={index}>
                                <button type='button'
                                  key={ingredient?.id}
                                  className={`btn btn-cuisine py-2 px-3 d-flex gap-2 align-items-center m-1 ${ingredient?.selected ? 'btn-dark' : 'btn-outline-dark'}`}
                                  name={`${ingredient?.name}-${ingredient?.id}`}
                                  value={ingredient?.id}
                                  onClick={() => ingredientsListChangeHandler(index)}
                                  id={`ingredient-${ingredient?.id}`}>
                                  {ingredient?.logo ? (
                                    <div className="d-inline-flex justify-content-center align-items-center ml-1 tag-pills-svgs">
                                      {parse(ingredient?.logo)}
                                    </div>
                                  ) : (
                                    <div className="text-uppercase tagging-text">{ingredient?.name?.charAt(0)}</div>
                                  )}
                                  {/* <img src="/imgs/beef.svg" className="cuisines-diet-img" alt="ingredient" /> */}
                                  {ingredient?.name}
                                </button>
                              </span>
                            })}
                          </div>
                        </div>
                        <p className="fs-16 fw-600 text-medium-gray m-0 mb-1">Visibility</p>
                        <div className="mb-3 form-check d-flex align-items-center ps-0">
                        <input type="checkbox" className="form-check-input m-0" id="exampleCheck1" checked={foodItemFormData?.section_visibility}  onChange={handleChangeCheckbox}/>
                          <label className="fs-16 fs-600 text-medium-black ps-4 m-0" >Visible for Customers</label>
                        </div>
                      </form>
                    </div>
                    <div className="menu-item-modal-footer justify-content-end">
                    <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill py-2 px-32" data-bs-dismiss="modal" onClick={() => setIsOpen(false)}>Cancel</button>
                      <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32" onClick={handleSubmitFoodItem}>{!isLoading? 'Add' : <Spinner/>}</button>
                    </div>
                  </div>
                </div>
              </CustomPopUp>
            }

            {isEditPopup &&
              <CustomPopUp
                popUpClassName="food-item-popup"
                backdropStyles={{ opacity: 1, cursor: 'default' }}
                onPopUpClose={() => setIsEditPopup(false)}
                // popUpBodyStyles={{ left: '25%', zIndex: '1101', position: 'fixed',top: '25%', width: '50%',right: '12%', backgroundColor: 'white', borderRadius: "10px" }}
                allowScrolling={true}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content ">
                    <div className="menu-item-modal-header">
                      <h3 className="fw-600 m-0 text-medium-black">Edit Food Item</h3>
                      <button type="button" className="btn-close modal-close-btn text-dark" onClick={() => setIsEditPopup(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body py-0">
                      <p className="fw-normal fs-16 mb-1">Images <i className="fa-thin fa-circle-info fa-sm ms-2 text-secondary-blue" title="Section image is auto cropped, and this is how the image will look on your section."></i></p>
                      {/* <p className='text-muted font-weight-bold'>Images</p> */}
                      <Dropzone
                        onDrop={foodItemImageHandler}
                        // @ts-ignore
                        accept='image/jpg, image/jpeg, image/png'
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className='bg-light d-flex justify-content-center align-items-center'
                            style={{ border: '2px dashed #0087F7', borderRadius: '5px', height: '175px' }}>
                            <div className='h-100 w-100'  {...getRootProps()} >
                              <input {...getInputProps()} />
                              {<div className='fooditem-image h-100 w-100 d-flex justify-content-center align-items-center'>
                              <img 
                                src={selectedImage ? URL.createObjectURL(selectedImage) : selectedFoodItem?.image?.medium?.url}
                                alt='Selected'
                                style={{ width: "200px", height: "auto" }}
                                />
                              </div>
                              }
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <form>
                        <div className="row mb-3 mt-3">
                          <div className="col">
                            <label className="fs-16 fw-600 text-medium-gray m-0">Name</label>
                            <input type="text" name="name" value={selectedFoodItem?.name} onChange={(e) => handleChangeForEdit(e)} className="form-control form-control-sm rounded-1" aria-label="First name" />
                          </div>
                          <div className={getMenutype === 'buffet' ? 'col-12' : 'col'}>
                            {getMenutype !== 'buffet' &&
                              <div>
                                <label className="fs-16 fw-600 text-medium-gray m-0">Price</label>
                                <div className="input-group input-group-sm">
                                  <span className="input-group-text" id="basic-addon1">$</span>
                                  <input type="number" name="price" value={selectedFoodItem?.price} disabled={true} onChange={(e) => handleChangeForEdit(e)} className="form-control form-control-sm" aria-label="price" aria-describedby="basic-addon1" />
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="fs-16 fw-600 text-medium-gray m-0">Description</label>
                          <textarea name='description' value={selectedFoodItem?.description} onChange={(e) => handleChangeForEdit(e)} className="form-control form-control-sm rounded-1" rows={4} id="exampleFormControlTextarea1"></textarea>
                        </div>
                        <div className="mb-3">
                          <p className="fs-16 fw-600 text-medium-gray m-0">Tagging</p>
                          <div className="d-flex flex-wrap align-items-center">
                            {systemFoodItemDiet?.map((dietary, index) => {
                              return <span key={index}>
                                <button type='button'
                                  key={dietary.id}
                                  className={`btn btn-cuisine py-2 px-3 d-flex gap-2 align-items-center m-1 ${dietary?.selected ? 'btn-dark' : 'btn-outline-dark'}`}
                                  name={`${dietary.name}-${dietary.id}`}
                                  value={dietary.id}
                                  onClick={() => dietariesListChangeHandler(index)}
                                  id={`dietary-${dietary.id}`}>
                                  {dietary?.logo ? (
                                    <div className="d-inline-flex justify-content-center align-items-center ml-1 tag-pills-svgs">
                                      {parse(dietary?.logo)}
                                    </div>
                                  ) : (
                                    <div className="text-uppercase tagging-text">{dietary?.name?.charAt(0)}</div>
                                  )}
                                  {/* <img src="/imgs/beef.svg" className="cuisines-diet-img" alt="dietary" /> */}
                                  {dietary.name}
                                </button>
                                {/* <div className="d-flex fs-16 fw-600 ms-2 text-theme-green">
                                {dietry?.name}
                              </div> */}
                              </span>
                            })}
                            {systemFoodItemIng?.map((ingredient, index) => {
                              return <span key={index}>
                                <button type='button'
                                  key={ingredient?.id}
                                  className={`btn btn-cuisine py-2 px-3 d-flex gap-2 align-items-center m-1 ${ingredient?.selected ? 'btn-dark' : 'btn-outline-dark'}`}
                                  name={`${ingredient?.name}-${ingredient?.id}`}
                                  value={ingredient?.id}
                                  onClick={() => ingredientsListChangeHandler(index)}
                                  id={`ingredient-${ingredient?.id}`}>
                                  {ingredient?.logo ? (
                                    <div className="d-inline-flex justify-content-center align-items-center ml-1 tag-pills-svgs">
                                      {parse(ingredient?.logo)}
                                    </div>
                                  ) : (
                                    <div className="text-uppercase tagging-text">{ingredient?.name?.charAt(0)}</div>
                                  )}
                                  {/* <img src="/imgs/beef.svg" className="cuisines-diet-img" alt="ingregient" /> */}
                                  {ingredient?.name}
                                </button>
                              </span>
                            })}
                          </div>

                        </div>
                        <p className="fs-16 fw-600 text-medium-gray m-0 mb-1">Visibility</p>
                        <div className="mb-3 form-check d-flex align-items-center ps-0">
                        <input type="checkbox" className="form-check-input m-0" checked={selectedFoodItem?.section_visibility} onChange={handleChangeEditCheckbox}  id="exampleCheck1" />
                          <label className="fs-16 fs-600 text-medium-black ps-4 m-0" >Visible for Customers</label>
                        </div>
                      </form>
                    </div>
                    <div className="menu-item-modal-footer justify-content-end">
                      <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill py-2 px-32" onClick={() => setIsEditPopup(false)} data-bs-dismiss="modal">Cancel</button>
                      <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32" onClick={(e) => foodItemUpdateSubmitHandler(e)}>{!isLoading ? 'Edit' : <Spinner/>}</button>
                    </div>
                  </div>
                </div>
              </CustomPopUp>
            }
            <div>
              <SelectExistingFootItemPopup
                openExistingFooditem={openExistingFooditem}
                setOpenExistingFooditem={setOpenExistingFooditem}
                selectExistingFoodItemsData={selectExistingFoodItemsData}
                selectExistingPopUpHandler={selectExistingPopUpHandler}
              />
            </div>
          </div>
          :
          <></>
      }
    </div>
  )
}
export default MenuSection