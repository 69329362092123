// import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { Provider } from 'react-redux';
// import store from './store';
// import Login from './Pages/Login/Login';
// import ForgotPassword from './Pages/Password/ForgotPassword';
// import ResetPassword from './Pages/Password/ResetPassword';
// import InvitePage from './Pages/UserSignupInvitation/UserSignupInvitation';
// import SignUp from './Pages/Signup/SignUp';
// import Dashboard from './Pages/Dashboard/Dashboard';
// import Logout from './Pages/Logout/logout';
// import PrivateRoute from './Common/Routes/PrivateRoute';
// import { setCurrentUser } from './Actions/authActions';
// import Admin from './Pages/Login/Admin';
// import Acknowledge from './Pages/AcknowledgeOrders/AcknowledgeOrders';
// import { WithTranslation, withTranslation } from 'react-i18next';
// import { TRANSLATE_I18N } from './Actions/types';
// import ErrorBoundary from './Common/ErrorBoundary';
// import ReactGA from 'react-ga';
// import { hotjar } from 'react-hotjar';
// import dayjs from 'dayjs';
// import duration from 'dayjs/plugin/duration';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import relativeTime from 'dayjs/plugin/relativeTime';
// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';
// import advancedFormat from 'dayjs/plugin/advancedFormat';
// import ShortURL from './Pages/ShortUrl/ShortURL';
// import NotFoundRoutes from './Common/Routes/NotFoundRoutes';
// import UnlockUserAccount from './Pages/UnlockAccount/UnlockAccount';
// import { getLocalStorageData } from './Utilities/getLocalStorageData';
// import isEmpty from './Utilities/isEmpty';
// //CSS
// import "react-loading-skeleton/dist/skeleton.css";

// // Check for headers
// const { headers = null } = getLocalStorageData();
// if (headers) {
//   store.dispatch(setCurrentUser(!isEmpty(headers)));
// }
// class App extends Component<WithTranslation, {}> {

//   constructor(props: WithTranslation) {
//     super(props);
//     dayjs.extend(customParseFormat);
//     dayjs.extend(duration);
//     dayjs.extend(relativeTime);
//     dayjs.extend(timezone);
//     dayjs.extend(utc);
//     dayjs.extend(advancedFormat);
//   }
//   componentDidMount() {
//     if (process.env.REACT_APP_ENVIRONMENT === 'production') {
//       hotjar.initialize(2300925, 6);
//     }
//     if (process.env.REACT_APP_ENVIRONMENT === 'development') {
//       hotjar.initialize(2301268, 6);
//     }
//     console.log('init hotjar', process.env.REACT_APP_ENVIRONMENT)
//     this.props.i18n.changeLanguage(window.navigator.language);
//     store.dispatch({
//       type: TRANSLATE_I18N,
//       payload: this.props
//     });
//     if (process.env.NODE_ENV === 'production') {
//       ReactGA.initialize('G-RRWX1RH09Y');
//       ReactGA.pageview(window.location.pathname + window.location.search);
//     }
//   }
//   render() {
//     return (
//       <Provider store={store}>
//         <Router>
//           <ErrorBoundary i18n={this.props.i18n}>
//             <React.Fragment>
//               <div id='show-notification' className='float-right' />
              // <Switch>
              //   <PrivateRoute exact path='/dashboard/restaurant/:restaurantId?/:page?/:id?' component={Dashboard} />
              //   <Route exact path='/logout' component={Logout} />
              //   <Route exact path='/' component={Login} />
              //   <Route exact path='/forgot_password' component={ForgotPassword} />
              //   <Route exact path='/reset_password' component={ResetPassword} />
              //   <Route exact path='/user/invite/invite_code/:inviteToken' component={InvitePage} />
              //   <Route exact path='/signup' component={SignUp} />
              //   <Route exact path='/admin-login/:token' component={Admin} />
              //   <Route exact path='/acknowledge/:token/:type/:cutoff?' component={Acknowledge} />
              //   <Route exact path='/unlock-account/:token' component={UnlockUserAccount} />
              //   <PrivateRoute exact path='/:token' component={ShortURL} />
              //   <Route exact path='*' component={NotFoundRoutes} />
              // </Switch>
//             </React.Fragment>
//           </ErrorBoundary>
//         </Router>
//       </Provider>
//     );
//   }
// }

// export default withTranslation()(App);


import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from './Pages/Login/Login';
import ForgotPassword from './Pages/Password/ForgotPassword';
import ResetPassword from './Pages/Password/ResetPassword';
import InvitePage from './Pages/UserSignupInvitation/UserSignupInvitation';
import SignUp from './Pages/Signup/SignUp';
import Dashboard from './Pages/Dashboard/Dashboard';
import Logout from './Pages/Logout/logout';
import PrivateRoute from './Common/Routes/PrivateRoute';
import { setCurrentUser } from './Actions/authActions';
import Admin from './Pages/Login/Admin';
import Acknowledge from './Pages/AcknowledgeOrders/AcknowledgeOrders';
import { TRANSLATE_I18N } from './Actions/types';
import ErrorBoundaryWrapper from './Common/ErrorBoundaryWrapper';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ShortURL from './Pages/ShortUrl/ShortURL';
import NotFoundRoutes from './Common/Routes/NotFoundRoutes';
import UnlockUserAccount from './Pages/UnlockAccount/UnlockAccount';
import { getLocalStorageData } from './Utilities/getLocalStorageData';
import isEmpty from './Utilities/isEmpty';
import { useTranslation } from 'react-i18next';
//CSS
import "react-loading-skeleton/dist/skeleton.css";

// Check for headers
const { headers = null } = getLocalStorageData();
if (headers) {
  store.dispatch(setCurrentUser(!isEmpty(headers)));
}

  const App = () => {
    dayjs.extend(customParseFormat);
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    dayjs.extend(timezone);
    dayjs.extend(utc);
    dayjs.extend(advancedFormat);

    const { i18n } = useTranslation();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      hotjar.initialize(2300925, 6);
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      hotjar.initialize(2301268, 6);
    }
    console.log('init hotjar', process.env.REACT_APP_ENVIRONMENT);
    i18n.changeLanguage(window.navigator.language);
    store.dispatch({
      type: TRANSLATE_I18N,
      payload: i18n,
    });
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-RRWX1RH09Y');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [i18n]);

    return (
      <Provider store={store}>
        <Router>
          <ErrorBoundaryWrapper i18n={i18n}>
            <React.Fragment>
              <div id='show-notification' className='float-right' />
              <Switch>
                <PrivateRoute exact path='/dashboard/restaurant/:restaurantId?/:page?/:id?' component={Dashboard} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/' component={Login} />
                <Route exact path='/forgot_password' component={ForgotPassword} />
                <Route exact path='/reset_password' component={ResetPassword} />
                <Route exact path='/user/invite/invite_code/:inviteToken' component={InvitePage} />
                <Route exact path='/signup' component={SignUp} />
                <Route exact path='/admin-login/:token' component={Admin} />
                <Route exact path='/acknowledge/:token/:type/:cutoff?' component={Acknowledge} />
                <Route exact path='/unlock-account/:token' component={UnlockUserAccount} />
                <PrivateRoute exact path='/:token' component={ShortURL} />
                <Route exact path='*' component={NotFoundRoutes} />
              </Switch>
            </React.Fragment>
          </ErrorBoundaryWrapper>
        </Router>
      </Provider>
  );
}

export default App;
