import React, { FC } from 'react';
import Modal from 'react-modal';
import '../invoices.css'
import Spinner from '../../../Common/Spinner';
import { UpdateInvoiceHandler } from  '../InvoiceDetails';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '692px',
    width: '90vw',
    padding: '0',
  }
};

type props = {
  modalIsOpen: boolean,
  closeModal: () => void,
  deleteInvoiceAdjustment: UpdateInvoiceHandler,
  invoiceUpdating: boolean,
}

const DeleteAdjustmentPopup: FC<props> = ({ modalIsOpen, closeModal, deleteInvoiceAdjustment, invoiceUpdating }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Example Modal'>
      <div className='modal-header p-3'>
        <h5 className='modal-title' id='modal-title'>Delete Invoice Adjustment</h5>
        <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
      </div>
      <form className='p-2'>
        <h5 className='text-center my-3'>Are you sure you wish to delete this adjustment ?</h5>
        <div className='text-center py-3'>
          <button type='button' className='btn btn-danger px-5 m-2' onClick={closeModal}><b> NO </b></button>
          <button type='button' className='btn btn-success px-5 m-2 text-white' onClick={() => deleteInvoiceAdjustment('deleteAdjustment')} style={{ minWidth: '121px' }}>
            {invoiceUpdating ?
              <Spinner size='spinner-border-sm' /> :
              <b> YES </b>
            }
          </button>
        </div>
      </form>
    </Modal>)
}

export default DeleteAdjustmentPopup;
