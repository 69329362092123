import i18n from "i18next";

export const meetingTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Quantity": "Quantity",
      "Item": "Item",
      "Price": "Price	",
      "Total": "Total",
      "Accept Orders": "Accept Orders",
      "Accept Modified Orders": "Accept Modified Orders",
      "Download Labels": "Download Labels",
      "Download Order Summary": "Download Order Summary",
      "Print Labels Before Cuttoff": "Labels have not been generated because this delivery time has not reached cutoff. Please try again after cutoff."
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Quantity": "مقدار",
      "Item": "آئٹم",
      "Price": "قیمت",
      "Total": "کل",
      "Accept Orders": "احکامات قبول کریں",
      "Accept Modified Orders": "ترمیم شدہ احکامات قبول کریں",
      "Download Labels": "لیبل ڈاؤن لوڈ کریں",
      "Download Order Summary": "آرڈر کا خلاصہ ڈاؤن لوڈ کریں"
    });
};