import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { Section } from '../../../Interfaces/PayloadsAndResponses/Menu';

type MenuSectionCardProps = {
  dragProps: any;
  sectionItems: (fooditem: any) => void;
  addEditSectionPopup: (type: string, section?: Section) => void;
  section: Section;
  hideSectionHandler: (section: Section) => void;
};

const MenuSectionCard: FC<MenuSectionCardProps> = ({
  dragProps,
  section,
  sectionItems,
  addEditSectionPopup,
  hideSectionHandler,
}) => {
  const handleSectionItemClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click propagation to the card
    sectionItems(section);
  };

  const handleHideButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click propagation to the card
    hideSectionHandler(section);
  };

  const handleDuplicateButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click propagation to the card
    addEditSectionPopup?.('duplicate', section);
  };

  const handleEditButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click propagation to the card
    addEditSectionPopup?.('edit', section);
  };

  return (
    <div className="menu_items_lists d-flex align-items-center py-3 ps-2 pe-4">
      <span className="ps-0 pe-2" {...dragProps}>
        <i className="fa-regular fa-grip-dots-vertical fa-lg drage_buttons"></i>
      </span>
      <div className="menu_inner_wrapper d-flex flex-wrap md:flex-nowrap justify-content-between align-items-center w-100">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={handleSectionItemClick}
        >

          <img
            src="/imgs/menu-page-place-holder1.svg"
            alt="food item"
            className="rounded-10 image-wrapper"
          />
          <div className="pl-3">
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="lead fw-600 m-0 text-nowrap section-food-Item">
                {`${section?.name}`}
              </h5>
              {section?.is_hidden && (
                <span
                  className="ps-2"
                  data-tip
                  data-for="hidden-section-tooltip"
                  onClick={handleHideButtonClick}
                >
                  <i className="fas fa-eye-slash hidden-eye-class"></i>
                </span>
              )}
              {window.navigator.userAgent.match(/MSIE|Trident/) === null && (
                <ReactTooltip
                  place="top"
                  type="dark"
                  id="hidden-section-tooltip"
                  className="react-tooltip"
                >
                  <span>Not Visible to Consumers</span>
                </ReactTooltip>
              )}
            </div>
            <p className="fs-18 fw-normal m-0">{`${section?.items_count} Item(s)`}</p>
          </div>
        </div>
        <div className="d-flex align-items-center menu_cta_button_wrapper sm:w-100 mt-2 mt-md-0 mx-auto mx-md-0 cursor-pointer">
          <button
            className="btn btn-custom-light btn-sm rounded-circle p-2 custom-light-wrapper"
            onClick={handleHideButtonClick}
          >
            {section?.is_hidden ? (
              <span data-tip data-for="un-hide" className="text-capitalize">
                <i className="fa-solid fa-eye menu_icon_size"></i>
                {window.navigator.userAgent.match(/MSIE|Trident/) === null && (
                  <ReactTooltip
                    type="dark"
                    place="top"
                    effect="solid"
                    className="react-tooltip"
                    id="un-hide"
                  >
                    <span>Un-Hide</span>
                  </ReactTooltip>
                )}
              </span>
            ) : (
              <span data-tip data-for="hide" className="text-capitalize">
                <i className="fa-solid fa-eye-slash menu_icon_size"></i>
                {window.navigator.userAgent.match(/MSIE|Trident/) === null && (
                  <ReactTooltip
                    type="dark"
                    place="top"
                    effect="solid"
                    className="react-tooltip"
                    id="hide"
                  >
                    <span>Hide</span>
                  </ReactTooltip>
                )}
              </span>
            )}
          </button>
          <span data-tip data-for="duplicate" className="text-capitalize">
            <button
              className="btn btn-custom-light btn-sm rounded-circle ms-3 p-2 custom-light-wrapper"
              onClick={handleDuplicateButtonClick}
            >
              <i className="fa-solid fa-clone menu_icon_size"></i>
            </button>
            {window.navigator.userAgent.match(/MSIE|Trident/) === null && (
              <ReactTooltip
                type="dark"
                place="top"
                effect="solid"
                className="react-tooltip"
                id="duplicate"
              >
                <span>Duplicate</span>
              </ReactTooltip>
            )}
          </span>
          <button
            className="btn btn-custom-light btn-sm rounded-pill ms-3 px-4"
            onClick={handleEditButtonClick}
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuSectionCard;
