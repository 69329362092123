import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '../../Common/Spinner';
import isEmpty from '../../Utilities/isEmpty';
import { HoursOfOperationSkeleton } from './SettingsPageSkeleton';
import RestaurantCheckBoxFlag from './RestaurantCheckBoxFlag';
import { RestaurantSchedulePayload, RestaurantSchedules } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';
import { WeekDays } from './Settings';
import { updateRestaurantSettings } from '../../Actions/getRestaurantActions';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

type Props = {
  schedules: RestaurantSchedules
}
const weekDays = { monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false }

const HoursOfOperation: FC<Props> = ({ schedules }) => {

  const [restaurantSchedules, setRestaurantSchedules] = useState(schedules);
  const [showInput, setShowInput] = useState<{ [K in WeekDays]: boolean }>({ ...weekDays });
  const [updatingSchedule, setUpdatingSchedule] = useState<boolean>(false);
  const [newShiftErrors, setNewShiftErrors] = useState<{ [K in WeekDays]: boolean }>({ ...weekDays })
  const restaurant = useSelector((state: ReduxRootState) => state?.restaurant_data?.restaurant_details);
  const restaurantTimeZone = restaurant?.address?.time_zone ?? "America/Los_Angeles"
  // const [render_count, setRender_count] = useState<boolean>(false)

  useEffect(() => {
    let addingTimeZone: any = { ...schedules }
    for (const day of Object.keys(addingTimeZone)) {
      addingTimeZone[day]?.forEach((singleDay: any) => {
        singleDay.time_zone = restaurantTimeZone;
        if (singleDay.start_time !== '00:00' || singleDay.end_time !== '00:00') {
          singleDay.start_time = convertTimeToTimezone(singleDay?.start_time, restaurantTimeZone);
          singleDay.end_time = convertTimeToTimezone(singleDay?.end_time, restaurantTimeZone);
        }
      });
    }
    setRestaurantSchedules(addingTimeZone)
    //eslint-disable-next-line
  }, [schedules])

  const dispatch: any = useDispatch();
  const { restaurantId } = useParams<{ restaurantId: string }>();

  useEffect(() => {
    JSON.stringify(schedules) && setRestaurantSchedules(JSON.parse(JSON.stringify(schedules)));
    setShowInput({ ...weekDays })
    setNewShiftErrors({ ...weekDays })
  }, [schedules])

  const newShiftChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>, day: WeekDays) => {
    value > '00:00' && setNewShiftErrors({ ...newShiftErrors, [day]: false });
    const prevState = { ...restaurantSchedules };
    if (prevState[day] && prevState[day][prevState[day]?.length - 1]) {
      //@ts-ignore // Added TS Ignore because name will always be a Key of Shift Object.
      prevState[day][prevState[day]?.length - 1][name] = value;
      setRestaurantSchedules(prevState);
    }
  }
  const editScheduleHandler = (day: WeekDays, index: number | false) => {
    const prevState = { ...restaurantSchedules };
    prevState[day][prevState[day]?.length - 1].time_zone = restaurantTimeZone
    if ((index || index === 0) && prevState[day] && prevState[day][index]) {
      //Remove shift
      prevState[day][index]._destroy = true;
      //Check weather all shifts are deleted
      if (isEmpty(prevState[day]?.filter((sch) => sch?._destroy === false || isEmpty(sch?._destroy)))) {
        prevState[day]?.unshift({ end_time: '00:00', start_time: '00:00', label: day?.charAt(0)?.toUpperCase() + day?.slice(1), })
      }
    }
    else if (index === false && prevState[day]) {
      //Add new shift
      if (prevState[day][prevState[day]?.length - 1]?.start_time === '00:00' && prevState[day][prevState[day]?.length - 1]?.end_time === '00:00' && showInput[day]) {
        setNewShiftErrors({ ...newShiftErrors, [day]: 'Start or end time must be greater than 00:00' });
        return
      }
      if (prevState[day][0]?.start_time === '00:00' && prevState[day][0]?.end_time === '00:00' && !showInput[day]) {
        prevState[day][0]._destroy = true
      }
      prevState[day] = [...prevState[day], { end_time: '00:00', start_time: '00:00', label: day?.charAt(0)?.toUpperCase() + day?.slice(1) }]
      setShowInput({ ...showInput, [day]: true })
    }
    setRestaurantSchedules(prevState)
  }

  const closedAllDayHandler = (prevClosed: boolean, day: WeekDays) => {
    const restaurantPrevSchedule = { ...restaurantSchedules };
    let selectedDayPrevSchedule = restaurantPrevSchedule[day]?.map(shift => ({ ...shift, _destroy: !prevClosed }));
    if (prevClosed) {
      const openShifts = selectedDayPrevSchedule?.filter(shift => !!shift?.id);
      if (openShifts && openShifts?.length > 0) {
        //Set selected day shifts to initial Values if previously shifts present
        restaurantPrevSchedule[day] = [...openShifts];
      } else {
        //Set selected day Open for whole day
        restaurantPrevSchedule[day] = [{ end_time: '23:59', start_time: '00:00', label: day?.charAt(0)?.toUpperCase() + day?.slice(1) }];
      }
    } else {
      //Destroy all previous shift and add new empty one
      restaurantPrevSchedule[day] = [{ end_time: '00:00', start_time: '00:00', label: day?.charAt(0)?.toUpperCase() + day?.slice(1) }, ...selectedDayPrevSchedule];
    }
    setShowInput({ ...showInput, [day]: false })
    setRestaurantSchedules(restaurantPrevSchedule);
  }
  const scheduleUpdatedHandler = () => {
    setUpdatingSchedule(false);
    setShowInput({ ...weekDays });
    setNewShiftErrors({ ...weekDays });
    setRestaurantSchedules(JSON.parse(JSON.stringify(schedules)));
  }
  const submitHandler = () => {
    const updatedSchedule = JSON.parse(JSON.stringify(restaurantSchedules)) as RestaurantSchedules;
    let restaurantNewSchedules: RestaurantSchedulePayload = {} as RestaurantSchedulePayload;
    
    let validCheckedInputs = true;
    Object.keys(updatedSchedule).forEach((key) => {
      const weekDay = key as WeekDays;
      const keyAttribute = `${weekDay}_shifts_attributes` as keyof RestaurantSchedulePayload;

      if (!updatedSchedule[weekDay] || updatedSchedule[weekDay].length === 0) {
        return;
      }

      const lastShiftIndex = updatedSchedule[weekDay].length - 1;
      const lastShift = updatedSchedule[weekDay][lastShiftIndex];

      if (lastShift && lastShift.hasOwnProperty('time_zone')) {
        lastShift.time_zone = restaurantTimeZone;
      }

      const hasValidTimes = (shift: any) => shift && shift?.start_time && shift?.end_time &&
        (shift?.start_time <= '00:00' && shift?.end_time <= '00:00');

      if (hasValidTimes(lastShift)) {
        lastShift._destroy = true;
        restaurantNewSchedules[keyAttribute] = updatedSchedule[weekDay];
      } else {
        restaurantNewSchedules[keyAttribute] = updatedSchedule[weekDay];
      }

      const firstShift = updatedSchedule[weekDay][0];
      if (hasValidTimes(firstShift)) {
        firstShift._destroy = true;
        restaurantNewSchedules[keyAttribute] = updatedSchedule[weekDay];
      }
    });
    if (validCheckedInputs) {
      setUpdatingSchedule(true);
      dispatch(updateRestaurantSettings({ restaurant: restaurantNewSchedules }, 'editSchedule', restaurantId, scheduleUpdatedHandler));
    }
  }

  function convertTimeToTimezone(time: any, targetTimezone: any) {
    const isDSTActive = moment?.tz(targetTimezone)?.isDST();
    // Calculating the dst
    // const currentDate = new Date();
    // Create a date object for the same date and time but with DST disabled (January 1st)
    // const dstDisabledDate = new Date(currentDate.getFullYear(), 0, 1, 0, 0, 0);

    // Get the time zone offset in minutes for the current date
    // const currentOffsetMinutes = currentDate.getTimezoneOffset();

    // Get the time zone offset in minutes for the DST disabled date
    // const dstDisabledOffsetMinutes = dstDisabledDate.getTimezoneOffset();

    // Get the time zone offset in minutes for the current date
    // const currentOffsetMinutes = currentDate.getTimezoneOffset();

    // Compare the offsets to determine if DST is currently in effect
    // const isDST = currentOffsetMinutes < dstDisabledOffsetMinutes;

    let calculatedDST
    if (!isDSTActive) {
      calculatedDST = new Date(`2000-01-01T${time}:00Z`);
    } else {
      calculatedDST = new Date(`2000-07-01T${time}:00Z`);
    }

    // const utcDate = new Date(`2000-01-01T${time}:00Z`);
    const utcDate = calculatedDST
    const userDate = new Date(utcDate.toLocaleString('en-US', { timeZone: targetTimezone }));

    const result = userDate.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit", hour12: false });
    const [hour, minute] = result?.split(':')
    const resultantTime = `${hour === '24' ? '00' : hour}:${minute || '00'}`

    return resultantTime
  }

  return (isEmpty(restaurantSchedules) || updatingSchedule) ? <HoursOfOperationSkeleton /> :
    <React.Fragment>
      <div>
        <h3 className='border-bottom py-2 mt-3 mb-3'>Hours of Operation
        </h3>
        <div className='table-responsive'>
          <table className='table table-hover'>
            <thead className='thead-dark'>
              <tr>
                <th>Day</th>
                <th>Closed?</th>
                <th>Open & Close Shifts</th>
              </tr>
            </thead>
            <tbody>
              {(Object.keys(restaurantSchedules) as WeekDays[]).map(key => {
                let lastShiftIndex: number, closedAllDay = true, noShiftSaved = false;
                restaurantSchedules[key]?.forEach((day, index) => {
                  if (!day?._destroy) {
                    lastShiftIndex = index;
                    if (closedAllDay) {
                      closedAllDay = !day?._destroy && day?.start_time === '00:00' && day?.end_time === '00:00'
                    }
                  }
                  //Check if no shift is saved on backend
                  if (!noShiftSaved) {
                    noShiftSaved = !!day?.id
                  }
                });
                return <tr key={key}>
                  <td className='w-25 text-nowrap'>
                    <strong className='text-capitalize'>{key}</strong>
                  </td>
                  <RestaurantCheckBoxFlag
                    checkboxId={`hours_of_operation_${key}`}
                    flagValueRequired={false}
                    checked={closedAllDay}
                    label={`Closed all day`}
                    updatingFlagLoading={false}
                    updateContactFlag={() => closedAllDayHandler(closedAllDay, key)}
                  />
                  <td>
                    {restaurantSchedules[key]?.map((sch, schIndex) =>
                      <React.Fragment key={schIndex}>
                        {(showInput[key] && schIndex + 1 === restaurantSchedules[key].length) || sch?._destroy ? '' :
                          <div className='row flex-nowrap'>
                            <div className='col-3 d-flex align-items-center'>{sch?.start_time}</div>
                            <div className='col-3 d-flex align-items-center'>to</div>
                            <div className='col-3 d-flex align-items-center'>{sch?.end_time}</div>
                            <div className='col-3 d-flex align-items-center'>
                              <button className='btn btn-link btn-sm text-danger'
                                disabled={sch?.start_time === '00:00' && sch?.end_time === '00:00'}
                                onClick={() => editScheduleHandler(key, schIndex)}
                              >
                                <i className='fas fa-minus-circle' aria-hidden='true'></i>
                              </button>
                              {lastShiftIndex === schIndex ?
                                <button className='btn btn-link btn-sm text-success' onClick={() => editScheduleHandler(key, false)}>
                                  <i className='fas fa-plus-circle' aria-hidden='true'></i>
                                </button> : ''
                              }
                            </div>
                          </div>}
                      </React.Fragment>
                    )}
                    {showInput[key] &&
                      <div className='row flex-nowrap'>
                        <div className='col-3 d-flex flex-column pr-1'>
                          <input type='time'
                            className='form-control px-1 form-control-sm addedUser'
                            name='start_time'
                            style={{ width: 'max-content' }}
                            value={restaurantSchedules[key][restaurantSchedules[key]?.length - 1]?.start_time}
                            onChange={e => newShiftChangeHandler(e, key)}
                            placeholder='Start Time' />
                          {newShiftErrors[key] ? <small className='invalid-feedback-password'>Must be greater than zero.</small> : ''}
                        </div>
                        <div className='col-3 d-flex align-items-center'>to</div>
                        <div className='col-3 d-flex flex-column pr-1'>
                          <input type='time'
                            className='form-control px-1 form-control-sm addedUser'
                            name='end_time'
                            style={{ width: 'max-content' }}
                            value={restaurantSchedules[key][restaurantSchedules[key]?.length - 1]?.end_time}
                            onChange={e => newShiftChangeHandler(e, key)}
                            placeholder='Start Time' />
                          {newShiftErrors[key] ? <small className='invalid-feedback-password'>Must be greater than zero.</small> : ''}
                        </div>
                        <div className='col-3 d-flex align-items-center'>
                          <button className='btn btn-link btn-sm text-danger'
                            onClick={() => { setShowInput({ ...showInput, [key]: false }); setNewShiftErrors({ ...weekDays }); editScheduleHandler(key, restaurantSchedules[key]?.length - 1) }}
                          >
                            <i className='fas fa-minus-circle' aria-hidden='true'></i>
                          </button>
                          <button className='btn btn-link btn-sm text-success' onClick={() => editScheduleHandler(key, false)}>
                            <i className='fas fa-plus-circle' aria-hidden='true'></i>
                          </button>
                        </div>
                      </div>
                    }
                  </td>
                </tr>
              }
              )}
            </tbody>
          </table>
        </div>
        <button
          type='button'
          disabled={updatingSchedule}
          className='btn btn-light btn-sm d-block w-100 mt-3'
          onClick={() => submitHandler()}
        >
          {updatingSchedule ? <Spinner size='spinner-border-sm' /> : 'Save'}
        </button>
      </div>
    </React.Fragment>
}

export default HoursOfOperation;