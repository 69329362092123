// import React, { Component } from 'react';
// import queryString from 'query-string';
// // import DayPicker from 'react-day-picker';
// import { connect } from 'react-redux';
// import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { getBillings } from '../../Actions/billingActions';
// import './billing?.css'
// import { billingTranslations } from '../../Translations/BillingTranslations'
// // import DayPickerRange from '../../Common/date-picker/DayPickerRange';
// import Pagination from '../../Common/Pagination';
// import TableHeader from '../../Common/TableHeader';
// import isEmpty from '../../Utilities/isEmpty';
// import dayjs from 'dayjs';
// import { InvoicesSkeleton } from '../Invoices/InvoicesSkeleton';
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { GetBillings } from '../../Interfaces/PayloadsAndResponses/Billing';
// import { WithTranslation } from 'react-i18next';
// import { Params, DateRange } from '../Dashboard/Dashboard';
// // import { DayClickHandler, DaypickerSearchRange } from '../../Common/date-picker/DayPickerRange';
// import { DateRangePicker, Range } from 'react-date-range';
// import CustomPopUp from '../../Common/Popups/CustomPopUp';
// import { defaultStaticRangesForBilling } from '../../Common/date-picker/rangesList';

// export const BillingPaymentStatus: any = {
//   due: 'danger',
//   final: 'warning',
//   generated: 'secondary',
//   paid: 'success',
// }

// export type QueryParams = {
//   to?: string;
//   from?: string;
//   page?: number;
//   sort_column?: string | string[];
//   sort_direction?: string | string[];
// }

// export type BillingProps = {
//   // mapStateToProps
//   billings: GetBillings | null;
//   billing_loading: boolean;
//   i18n: WithTranslation | null;
//   getBillings: (id: string | number, queryParams: string) => void;
//   // dateRange: DateRange[];
//   // dashboardRef: DashboardRef;
// } & RouteComponentProps<Params>;

// export type BillingState = {
//   // range: {
//   //   from: Date;
//   //   to: Date;
//   // }
//   sortBy: string | string[]
//   sortType: string | string[]
//   currentPage: number,
//   isOpenOrderCalendar: boolean,
//   dateRange: DateRange[];
// }
// class Billing extends Component<BillingProps, BillingState> {
//   constructor(props: BillingProps) {
//     super(props);
//     const { from, to, sort_column, sort_direction, page } = queryString.parse(window.location.search);
//     this.state = {
//       // range: {
//       //   from: (dayjs(`${from}`, 'DD-MM-YYYY').isValid() && dayjs(`${from}`, 'DD-MM-YYYY').toDate()) || new Date(dayjs(new Date()).subtract(6, 'month').toDate().toString()),
//       //   to: (dayjs(`${from}`, 'DD-MM-YYYY').isValid() && dayjs(`${to}`, 'DD-MM-YYYY').toDate()) || new Date()
//       // },
//       dateRange: [
//         {
//           startDate: (dayjs(`${from}`, 'DD-MM-YYYY').isValid() && dayjs(`${from}`, 'DD-MM-YYYY').toDate()) || new Date(dayjs(new Date()).subtract(6, 'month').toDate().toString()),
//           endDate: (dayjs(`${from}`, 'DD-MM-YYYY').isValid() && dayjs(`${to}`, 'DD-MM-YYYY').toDate()) || new Date(),
//           key: 'selection'
//         }
//       ],
//       // @ts-ignore
//       sortBy: sort_column || '', sortType: sort_direction || '',
//       currentPage: page ? +page : 1,
//       isOpenOrderCalendar: false
//     }
//   }

//   getBillingDetail = () => {
//     const { restaurantId } = this.props.match.params;
//     if (restaurantId) {
//       const { startDate: from, endDate: to} = this.state.dateRange[0];

//       // const { range: { from, to } } = this.state;
//       const { page = null, sort_column = null, sort_direction = null } = queryString.parse(this.props.location.search) ?? {};
//       // const { from = startDate, to = endDate, page = null, sort_column = null, sort_direction = null } = queryString.parse(this.props.location.search) ?? {};
//       let fromDate = `${from}`; // converting to string to send in API payload
//       let toDate = `${to}`;  //converting to string to send in API payload
//       const searchParams = {
//         orders_from: fromDate,
//         orders_to: toDate,
//         sort_column,
//         sort_direction,
//         page,
//       }
//       let InvalidDateHandled = {
//       }
//       if (from === null || to === null) {
//         const todayDate = new Date()
//         this.setState({
//           dateRange: [
//             {
//               startDate: todayDate,
//               endDate: todayDate,
//               key: 'selection'
//             }
//           ],
//           // range: {
//           //   from: todayDate,
//           //   to: todayDate
//           // }
//         })
//         InvalidDateHandled = {
//           orders_from: `${todayDate}`,
//           orders_to: `${todayDate}`,
//           sort_column,
//           sort_direction,
//           page,
//         }
//         const queryParams = queryString.stringify(InvalidDateHandled, { skipNull: true, skipEmptyString: true });
//         this.props.getBillings(restaurantId, `?${queryParams}`);
//       } else {
//         const queryParams = queryString.stringify(searchParams, { skipNull: true, skipEmptyString: true });
//         this.props.getBillings(restaurantId, `?${queryParams}`);
//       }
//     }
//   }

//   componentDidMount() {
//     billingTranslations();
//     this.getBillingDetail();
//   }

//   componentDidUpdate(prevProps: BillingProps) {
//     if ((prevProps.location.search !== this.props.location.search && !isEmpty(this.props.location.search)) ||
//       prevProps.match.params.restaurantId !== this.props.match.params.restaurantId) {
//       this.getBillingDetail();
//     }
//   }

//   // handleDayClick: DayClickHandler = (day) => {
//   //     const newRange = DayPicker.DateUtils.addDayToRange(day, this.state.range);
//   //     this.setState({ range: newRange, currentPage: 1 }, () => {
//   //       this.generateQueryParams();
//   //     });
//   // }

//   // customeSearchRanges: DaypickerSearchRange = (count, rangeIdentifier) => {
//   //   $('.show').removeClass('show');
//   //   let startDate = dayjs(new Date()).subtract(count, rangeIdentifier).toDate();
//   //   let endDate = new Date();
//   //   this.setState({
//   //     range: {
//   //       from: startDate,
//   //       to: endDate
//   //     },
//   //     currentPage: 1
//   //   }, () => {
//   //     this.generateQueryParams();
//   //   }) 
//   // }

//   searchBillings = () => {
//     $('.show').removeClass('show');
//   }

//   handleSorting = (sortBy: string) => {
//     let sortType = this.state.sortType === 'ASC' ? 'DESC' : 'ASC';
//     this.setState({ sortBy, sortType }, () => {
//       this.generateQueryParams();
//     });
//   }

//   handlePageClick = (e: { selected: number }) => {
//     this.setState({ currentPage: e.selected + 1 }, () => {
//       this.generateQueryParams();
//     });
//   }

//   generateQueryParams = () => {
//     let parsedValue: QueryParams = {};
//     // const { sortBy, sortType, range: { from, to }, currentPage } = this.state;
//     const { sortBy, sortType, currentPage } = this.state;

//     const { startDate: from, endDate: to} = this.state.dateRange[0];

//     if (sortBy) parsedValue.sort_column = sortBy;
//     if (sortType) parsedValue.sort_direction = sortType;
//     if (from) parsedValue.from = dayjs(from).format('DD-MM-YYYY');
//     if (to) parsedValue.to = dayjs(to).format('DD-MM-YYYY');
//     if (currentPage) parsedValue.page = currentPage;

//     let queryURL = !isEmpty(parsedValue) ? `?${queryString.stringify(parsedValue, { skipNull: true, skipEmptyString: true })}` : '';
//     this.props.history.push(`/dashboard/restaurant/${this.props.match.params.restaurantId}/billing${queryURL}`);
//   }

//   handleSelect = (item: { selection: Range }) => {
//     //const { history, match: { params: { restaurantId } } } = this.props;
//     //@ts-ignore
//     // this.props.dashboardRef.setState({ dateRange: [item.selection] });
//     const { startDate, endDate } = item.selection || {};

//     this.setState({
//       dateRange: [
//         {
//           startDate: startDate?? new Date(),
//           endDate: endDate?? new Date(),
//           key: 'selection'
//         }
//       ],
//       currentPage: 1
//     }, () => {
//       this.generateQueryParams();
//     })

//     // this.setState({
//     //   range: {
//     //     from: startDate?? new Date(),
//     //     to: endDate?? new Date()
//     //   },
//     //   currentPage: 1
//     // }, () => {
//     //   this.generateQueryParams();
//     // })
//   };

//   render() {
//     // const { from, to } = this.state.range;
//     const { currentPage } = this.state;
//     const { billing_loading } = this.props;
//     let billings = this.props?.billings?.billings;
//     let perPage = this.props?.billings?.per_page ?? 0;
//     let totalCount = this.props?.billings?.count ?? 0;
//     const numberOfPages = Math.ceil(totalCount / +perPage);
//     const { restaurantId } = this.props.match.params;
//     const { isOpenOrderCalendar } = this.state;
//     const { startDate, endDate } = this.state.dateRange[0] || {};
//     const popUpBodyStyles = { maxHeight: 'calc(100vh - 200px)', overflow: 'auto', top: 45, boxShadow: '0px 2px 13px #00000019' }

//     const columns = [
//       { label: 'No.', columnName: 'billing_number', sortable: true },
//       { label: 'Items', columnName: 'quantity_total', sortable: true },
//       { label: 'Ordered Between', columnName: 'orders_from', sortable: true },
//       { label: 'Food Total', columnName: 'food_total', sortable: true },
//       { label: 'Commission', columnName: 'commission', sortable: true },
//       { label: 'Total Payout', columnName: 'payout_total', sortable: true },
//       { label: 'Type' },
//       { label: 'Status' },
//       { label: 'Due On', columnName: 'due_date', sortable: true },
//       { label: '' }
//     ];
//     let Billing;
//     Billing = billings ? billings.map((billing, index) => (
//       <tr key={index}>
//         <td>
//           {billing?.billing_number}
//         </td>
//         <td>
//           {billing?.quantity_total}
//         </td>
//         <td>
//           {dayjs(billing?.orders_from).format('MM/DD/YYYY')} - {dayjs(billing?.orders_to).format('MM/DD/YYYY')}
//         </td>
//         <td>
//           {billing?.food_total}
//         </td>
//         <td>
//           {billing?.commission} ({Number(billing?.commission_percentage).toFixed(0)}%)
//         </td>
//         <td>
//           {billing?.payout_total}
//         </td>
//         <td className='text-capitalize'>
//           {billing?.billing_type}
//         </td>
//         <td className='text-center'>
//           {BillingPaymentStatus[billing?.payment_status] ?
//             <label className={`text-capitalize badge badge-${BillingPaymentStatus[billing?.payment_status]}`}>
//               {billing?.payment_status}
//             </label>
//             : null
//           }
//         </td>
//         <td>
//           {dayjs(billing?.due_date).format('MM/D/YY')}
//         </td>
//         <td>
//           <button
//             className='btn btn-light btn-sm text-primary'
//             onClick={() => {
//               this.props.history.push({
//                 pathname: `/dashboard/restaurant/${restaurantId}/billing/${billing?.id}`,
//                 state: window.location.search
//               })
//             }}
//           >
//             <i className='fas fa-chevron-right' />
//           </button>
//         </td>
//       </tr>)
//     ) : '';
//     return (
//       <div className='card'>
//         <div className='card-body p-0'>
//           <div className='d-flex justify-content-between flex-column flex-md-row'>
//             <h2>Billings</h2>
//             <div className='invoice-config'>
//               <div className='p-2 rounded cursor-pointer position-relative'
//                 onClick={() => this.setState({ isOpenOrderCalendar: true })}
//                 // style={{ backgroundColor: '#147BFC', boxShadow: '0px 2px 3px #0000000D' }}>
//                 style={{ backgroundColor: '#147BFC', boxShadow: '0px 2px 3px #0000000D', maxWidth:'250px'}}>
//                 <div className='d-flex align-items-center text-white'>
//                   <i className={`fas fa-solid fa-calendar`} />
//                   <span className='ms-3 me-4'>{dayjs(startDate).format('MM-DD-YYYY')} - {dayjs(endDate).format('MM-DD-YYYY')}</span>
//                   <i
//                     className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenOrderCalendar ? 'down' : 'up'}`}
//                     style={{ fontSize: 15 }}
//                   />
//                 </div>
//                 {isOpenOrderCalendar &&
//                   <CustomPopUp
//                     backdropStyles={{ opacity: 0, cursor: 'default' }}
//                     onPopUpClose={() => this.setState({ isOpenOrderCalendar: false })}
//                     popUpClassName='order-history-range-picker'
//                     popUpBodyStyles={{ ...popUpBodyStyles, right: 0 }}
//                   >
//                     <DateRangePicker
//                       onChange={range => this.handleSelect(range as { selection: Range })}
//                       // showSelectionPreview={true}
//                       showDateDisplay={false}
//                       moveRangeOnFirstSelection={false}
//                       months={1}
//                       ranges={this.state.dateRange}
//                       staticRanges={defaultStaticRangesForBilling}
//                       className='bg-white w-100 overflow-auto'
//                     />
//                   </CustomPopUp>}
//               </div>
//               {/* <DayPickerRange
//                 to={to}
//                 from={from}
//                 defaultRange={{ day: 30, month: 6, year: 1 }}
//                 handleDayClick={this.handleDayClick}
//                 searchRange={this.customeSearchRanges}
//                 searchItems={this.searchBillings}
//               /> */}
//             </div>
//           </div>
//           <p className='muted'>
//             All bills for each week will be appended to the same bill. Each bill becomes <strong>FINAL</strong> at end of week. On each bill’s due date, your bill will be marked due and you can expect payment shortly thereafter.
//           </p>
//           {billing_loading && <span className='order-spinner'> <InvoicesSkeleton /> </span>}
//           {!billing_loading && isEmpty(billings) && <h2 className='d-flex justify-content-center'>No Billing Available.</h2>}
//           {!billing_loading && !isEmpty(billings) &&
//             <div className='col-12 px-0'>
//               <br /><br />
//               <div className='table-responsive'>
//                 <table className='table table-striped'>
//                   <TableHeader
//                     columns={columns}
//                     sortingColumn={this.state.sortBy}
//                     handleSorting={this.handleSorting}
//                     inlineHeader={true}
//                   />
//                   <tbody>
//                     {Billing}
//                   </tbody>
//                 </table>
//               </div>
//               {numberOfPages > 1 &&
//                 <div className='mt-4 mb-3'>
//                   <Pagination
//                     numberOfPages={numberOfPages}
//                     page={currentPage}
//                     handlePageClick={this.handlePageClick}
//                     loading={false}
//                   />
//                 </div>}
//             </div>
//           }
//         </div>
//       </div>
//     )
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   billings: state.billing?.billings,
//   billing_loading: state.billing?.billing_loading,
//   i18n: state.i18n.i18n
// })

// export default connect(mapStateToProps, { getBillings })(withRouter(Billing));


import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
// import DayPicker from 'react-day-picker';
import {  useLocation, useParams, useHistory } from 'react-router-dom';
import { getBillings } from '../../Actions/billingActions';
import './billing.css'
import { billingTranslations } from '../../Translations/BillingTranslations'
// import DayPickerRange from '../../Common/date-picker/DayPickerRange';
import Pagination from '../../Common/Pagination';
import TableHeader from '../../Common/TableHeader';
import isEmpty from '../../Utilities/isEmpty';
import dayjs from 'dayjs';
import { InvoicesSkeleton } from '../Invoices/InvoicesSkeleton';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { Params, DateRange } from '../Dashboard/Dashboard';
// import { DayClickHandler, DaypickerSearchRange } from '../../Common/date-picker/DayPickerRange';
import { DateRangePicker, Range } from 'react-date-range';
import CustomPopUp from '../../Common/Popups/CustomPopUp';
import { defaultStaticRangesForBilling } from '../../Common/date-picker/rangesList';
import { useSelector, useDispatch } from 'react-redux';

export const BillingPaymentStatus: any = {
  due: 'danger',
  final: 'warning',
  generated: 'secondary',
  paid: 'success',
};

export type QueryParams = {
  to?: string;
  from?: string;
  page?: number;
  sort_column?: string | string[];
  sort_direction?: string | string[];
};


const Billing = () => {

  const location = useLocation();
  const params = useParams<Params>();
  const history = useHistory();
  const dispatch: any = useDispatch();
  
  const { from, to, sort_column, sort_direction, page } = queryString.parse(window.location.search);
  const billings = useSelector((state: ReduxRootState) => state?.billing?.billings);
  const billing_loading = useSelector((state: ReduxRootState) => state?.billing?.billing_loading);
  // @ts-ignore
  const [sortBy, setSortBy] = useState<string | string[]>(sort_column || '');
  // @ts-ignore
  const [sortType, setSortType] = useState<string | string[]>(sort_direction || '');
  const [currentPage, setCurrentPage] = useState<number>(page ? +page : 1);
  const [isOpenOrderCalendar, setIsOpenOrderCalendar] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange[]>([
      {
        startDate: (dayjs(`${from}`, 'DD-MM-YYYY')?.isValid() && dayjs(`${from}`, 'DD-MM-YYYY')?.toDate()) || new Date(dayjs(new Date())?.subtract(6, 'month')?.toDate()?.toString()),
        endDate: (dayjs(`${from}`, 'DD-MM-YYYY')?.isValid() && dayjs(`${to}`, 'DD-MM-YYYY')?.toDate()) || new Date(),
        key: 'selection'
      }
  ]);
  const perPage = billings?.per_page ?? 0;
  const totalCount = billings?.count ?? 0;
  const numberOfPages = Math.ceil(totalCount / +perPage);
  const popUpBodyStyles = { maxHeight: 'calc(100vh - 200px)', overflow: 'auto', top: 45, boxShadow: '0px 2px 13px #00000019' }
  

  const getBillingDetail = () => {
    const { restaurantId } = params;
    if (restaurantId) {
      const { startDate: from, endDate: to } = dateRange[0];
      const { page = null, sort_column = null, sort_direction = null } = queryString.parse(location.search) ?? {};

      let fromDate = `${from}`; // converting to string to send in API payload
      let toDate = `${to}`; //converting to string to send in API payload
      const searchParams = {
        orders_from: fromDate,
        orders_to: toDate,
        sort_column,
        sort_direction,
        page,
      }
      let InvalidDateHandled = {};

      if (from === null || to === null) {
        const todayDate = new Date();
        setDateRange([
          {
            startDate: todayDate,
            endDate: todayDate,
            key: 'selection',
          },
        ]);
        InvalidDateHandled = {
          orders_from: `${todayDate}`,
          orders_to: `${todayDate}`,
          sort_column,
          sort_direction,
          page
        }
        const queryParams = queryString.stringify(InvalidDateHandled, {
          skipNull: true,
          skipEmptyString: true,
        });
        dispatch(getBillings(restaurantId, `?${queryParams}`));
      } else {
        const queryParams = queryString.stringify(searchParams, {
          skipNull: true,
          skipEmptyString: true
        });
        dispatch(getBillings(restaurantId, `?${queryParams}`));
      }
    }
  };
  useEffect(() => {
    billingTranslations();
    getBillingDetail();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(location.search)) {
      getBillingDetail();
    }
  //eslint-disable-next-line
  }, [location.search, params.restaurantId]);

  // const searchBillings = () => {
  //   $('.show').removeClass('show');
  // }

  useEffect(() => {
    generateQueryParams();
    //eslint-disable-next-line
  },[dateRange, currentPage]);

  const handleSorting = (_sortBy: string) => {
    let newSortType = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortBy(_sortBy);
    setSortType(newSortType);
    generateQueryParams();
  };

  const handlePageClick = (e: { selected: number }) => {
    setCurrentPage(e.selected + 1);
    generateQueryParams();
  };

  const generateQueryParams = () => {
    let parsedValue: QueryParams = {};
    const { startDate, endDate } = dateRange[0];

    if (sortBy) parsedValue.sort_column = sortBy;
    if (sortType) parsedValue.sort_direction = sortType;
    if (startDate) parsedValue.from = dayjs(startDate).format('DD-MM-YYYY');
    if (endDate) parsedValue.to = dayjs(endDate).format('DD-MM-YYYY');
    if (currentPage) parsedValue.page = currentPage;
    let queryURL = !isEmpty(parsedValue)
      ? `?${queryString.stringify(parsedValue, {
        skipNull: true,
        skipEmptyString: true,
      })}`
      : '';
    history.push(`/dashboard/restaurant/${params.restaurantId}/billing${queryURL}`);
  };

  const handleSelect = (item: { selection: Range }) => {
    const { startDate, endDate } = item.selection || {};
    setDateRange(() => [
      {
        startDate: startDate ?? new Date(),
        endDate: endDate ?? new Date(),
        key: 'selection'
      }
    ],);
    setCurrentPage(1);
  };

  const columns = [
    { label: 'No.', columnName: 'billing_number', sortable: true },
    { label: 'Items', columnName: 'quantity_total', sortable: true },
    { label: 'Ordered Between', columnName: 'orders_from', sortable: true },
    { label: 'Food Total', columnName: 'food_total', sortable: true },
    { label: 'Commission', columnName: 'commission', sortable: true },
    { label: 'Total Payout', columnName: 'payout_total', sortable: true },
    { label: 'Type' },
    { label: 'Status' },
    { label: 'Due On', columnName: 'due_date', sortable: true },
    { label: '' }
  ];

  const Billing = billings
    ? billings?.billings?.map((billing, index) => (
      <tr key={index}>
        <td>{billing?.billing_number}</td>
        <td>{billing?.quantity_total}</td>
        <td>
          {dayjs(billing?.orders_from).format('MM/DD/YYYY')} - {dayjs(billing?.orders_to).format('MM/DD/YYYY')}
        </td>
        <td>{billing?.food_total}</td>
        <td>
          {billing?.commission} ({Number(billing?.commission_percentage)?.toFixed(0)}%)
        </td>
        <td>{billing?.payout_total}</td>
        <td className='text-capitalize'>{billing?.billing_type}</td>
        <td className='text-center'>
          {BillingPaymentStatus[billing?.payment_status] ? (
            <label
              className={`text-capitalize badge badge-${BillingPaymentStatus[billing?.payment_status]}`}
            >
              {billing?.payment_status}
            </label>
          ) : null}
        </td>
        <td>{dayjs(billing?.due_date).format('MM/D/YY')}</td>
        <td>
          <button
            className='btn btn-light btn-sm text-primary'
            onClick={() => {
              history.push({
                pathname: `/dashboard/restaurant/${params.restaurantId}/billing/${billing?.id}`,
                state: window.location.search,
              });
            }}
          >
            <i className='fas fa-chevron-right' />
          </button>
        </td>
      </tr>
    ))
    : null;

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='d-flex justify-content-between flex-column flex-md-row'>
          <h2>Billings</h2>
          <div className='invoice-config'>
            <div
              className='p-2 rounded cursor-pointer position-relative'
              onClick={() => setIsOpenOrderCalendar(!isOpenOrderCalendar)}
              style={{ backgroundColor: '#147BFC', boxShadow: '0px 2px 3px #0000000D', maxWidth: '250px' }}
            >
              <div className='d-flex align-items-center text-white'>
                <i className={`fas fa-solid fa-calendar`} />
                <span className='ms-3 me-4'>
                  {dayjs(dateRange[0].startDate).format('MM-DD-YYYY')} -{' '}
                  {dayjs(dateRange[0].endDate).format('MM-DD-YYYY')}
                </span>
                <span key={isOpenOrderCalendar ? '0' : '1'}>
                  {isOpenOrderCalendar ? (
                    <span>
                      <i className={`fas fa-solid fa-chevron-up`} style={{ fontSize: 15 }} />
                    </span>
                  ) : (
                    <span>
                      <i className={`fas fa-solid fa-chevron-down`} style={{ fontSize: 15 }} />
                    </span>
                  )}
                </span>
              </div>
              {isOpenOrderCalendar && (
                <CustomPopUp
                  backdropStyles={{ opacity: 0, cursor: 'default' }}
                  onPopUpClose={() => setIsOpenOrderCalendar(false)}
                  popUpClassName='order-history-range-picker'
                  popUpBodyStyles={{ ...popUpBodyStyles, right: 0 }}
                >
                  <DateRangePicker
                    onChange={(range) => handleSelect(range as { selection: Range })}
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateRange}
                    staticRanges={defaultStaticRangesForBilling}
                    className='bg-white w-100 overflow-auto'
                  />
                </CustomPopUp>
              )}
            </div>
            {/* <DayPickerRange
                to={to}
                from={from}
                defaultRange={{ day: 30, month: 6, year: 1 }}
                handleDayClick={this.handleDayClick}
                searchRange={this.customeSearchRanges}
                searchItems={this.searchBillings}
              /> */}
          </div>
        </div>
        <p className='muted'>
          All bills for each week will be appended to the same bill. Each bill becomes{' '}
          <strong>FINAL</strong> at end of week. On each bill’s due date, your bill will be
          marked due and you can expect payment shortly thereafter.
        </p>
        {billing_loading && (
          <span className='order-spinner'>
            <InvoicesSkeleton />
          </span>
        )}
        {!billing_loading && isEmpty(billings?.billings) && (
          <h2 className='d-flex justify-content-center'>No Billing Available.</h2>
        )}
        {!billing_loading && !isEmpty(billings?.billings) && (
          <div className='col-12 px-0'>
            <br />
            <br />
            <div className='table-responsive'>
              <table className='table table-striped'>
                <TableHeader
                  columns={columns}
                  sortingColumn={sortBy}
                  handleSorting={handleSorting}
                  inlineHeader={true}
                />
                <tbody>{Billing}</tbody>
              </table>
            </div>
            {numberOfPages > 1 && (
              <div className='mt-4 mb-3'>
                <Pagination
                  numberOfPages={numberOfPages}
                  page={currentPage}
                  handlePageClick={handlePageClick}
                  loading={false}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default Billing