// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
// import MenuLink from './MenuLink';
// import NavLink from './NavLink';
// import './header.css';
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { Restaurant } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';
// import { WithTranslation } from 'react-i18next';
// import { Params } from '../../Pages/Dashboard/Dashboard';
// import { getLocalStorageData } from '../../Utilities/getLocalStorageData';

// type HeaderProps = {
//   locationDropdown: string | JSX.Element;
//   i18n: WithTranslation | null;
//   restaurantDetails: Restaurant | null;
// } & RouteComponentProps<Params>
// class Header extends Component<HeaderProps, {}> {
//   render() {
//     const {
//       isSelfServiceEnabled,
//       vendor: user,
//       ordersPresent
//     } = getLocalStorageData();

//     const bottomLinks = (
//       <>
//         <MenuLink
//           icon='fas fa-sign-out-alt'
//           path='/logout'
//           label='Logout'
//         />
//       </>
//     );

//     const { i18n, locationDropdown } = this.props;
//     const { id, restaurantId, page } = this.props.match.params;
//     const billingTab = this.props?.restaurantDetails?.address?.billing_tab;
//     const activeClass = window.location.pathname === `/dashboard/restaurant/${restaurantId}/menu` || window.location.pathname === `/dashboard/restaurant/${restaurantId}/menu/${id}`

//     return (
//       <React.Fragment>
//         <nav className='navbar navbar-expand-md navbar-light bg-white border-bottom fixed-top'>
//           <Link className='navbar-brand' to={`/dashboard/restaurant/${restaurantId}`}>
//             <img
//               alt='Logo'
//               src='/imgs/chowmill-logo-full-u.png'
//               className='logo-chowmill'
//             />
//           </Link>
//           <i
//             className='fas fa-chevron-circle-down navbar-toggler fa-2x border-0 mob-cal-color'
//             data-bs-toggle='collapse'
//             data-bs-target='#chowmillNavbarPrim'
//             aria-controls='chowmillNavbarPrim'
//             aria-expanded='false'
//             aria-label='Toggle navigation'
//           ></i>
//           <div className='collapse navbar-collapse flex-md-row flex-column' id='chowmillNavbarPrim'>
//             <ul className='navbar-nav me-auto nav-for-mobile  order-2 order-md-1'>
//               <NavLink
//                 linkURL={`/dashboard/restaurant/${restaurantId}`}
//                 label='Settings'
//                 isActive={!page}
//               />
//               <li className='nav-item'>
//                 <div className="dropdown upgrade-bs-class">
//                   <a className={`dropdown-toggle nav-link prim-link `} href="/" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" style={{ 'width': '50px', marginRight: '50px', marginBottom:'15px' }} aria-expanded='false'>
//                     <p className={`${activeClass ? 'menu-active' : 'menu-inactive'}`}>
//                       Menus
//                     </p>
//                   </a>

//                   <ul className="dropdown-menu menu-dropdown-container" aria-labelledby="dropdownMenuLink">
//                     <Link
//                       className={window.location.search === `?menu=breakfast` ? 'dropdown-header p-4 dropdown-option-active noLinkEffect' : 'dropdown-header p-4 dropdown-option noLinkEffect'}
//                       to={`/dashboard/restaurant/${restaurantId}/menu?menu=breakfast`}
//                     >
//                       {i18n && i18n.t('Breakfast')}
//                     </Link>
//                     <div className='dropdown-divider' />
//                     <Link
//                       className={window.location.search === `?menu=lunch` ? 'dropdown-header p-4 dropdown-option-active noLinkEffect' : 'dropdown-header p-4 dropdown-option noLinkEffect'}
//                       to={`/dashboard/restaurant/${restaurantId}/menu?menu=lunch`}
//                     >
//                       {i18n && i18n.t('Lunch')}
//                     </Link>
//                     <div className='dropdown-divider' />
//                     <Link
//                       className={window.location.search === `?menu=dinner` ? 'dropdown-header p-4 dropdown-option-active noLinkEffect' : 'dropdown-header p-4 dropdown-option noLinkEffect'}
//                       to={`/dashboard/restaurant/${restaurantId}/menu?menu=dinner`}
//                     >
//                       {i18n && i18n.t('Dinner')}
//                     </Link>
//                     <div className='dropdown-divider' />
//                     <Link
//                       className={window.location.search === `?menu=buffet` ? 'dropdown-header p-4 dropdown-option-active noLinkEffect' : 'dropdown-header p-4 dropdown-option noLinkEffect'}
//                       to={`/dashboard/restaurant/${restaurantId}/menu?menu=buffet`}
//                     >
//                       {i18n && i18n.t('Buffet')}
//                     </Link>
//                     {/* <li className='menu-dropdown text-muted'><a className="menu-link" href={`/dashboard/restaurant/${restaurantId}/menu?menu=breakfast`}>{i18n && i18n.t('Breakfast')}</a></li>
//                     <li className='menu-dropdown text-muted'><a className="menu-link" href={`/dashboard/restaurant/${restaurantId}/menu?menu=lunch`}> {i18n && i18n.t('Lunch')}</a></li>
//                     <li className='menu-dropdown text-muted'><a className="menu-link" href={`/dashboard/restaurant/${restaurantId}/menu?menu=dinner`}>{i18n && i18n.t('Dinner')}</a></li>
//                     <li className='menu-dropdown text-muted'><a className="menu-link" href={`/dashboard/restaurant/${restaurantId}/menu?menu=buffet`}> {i18n && i18n.t('Buffet')}</a></li> */}
//                   </ul>
//                 </div>
//               </li>
//               {/* <li className='dropdown mr-2'>
//                 <a
//                   href='/'
//                   // className={`dropdown-toggle noLinkEffect prim-link mr-4 ml-3`}
//                   className={`nav-link dropdown-toggle noLinkEffect prim-link mr-4 ml-3 ${activeClass ? 'nav-link prim-link active' : ''}`}
//                   id='dropdownMenuLink'
//                   data-bs-toggle='dropdown'
//                   role="button"
//                   // aria-haspopup='true'
//                   aria-expanded='false'
//                 >
//                   {i18n && i18n.t('Menus')}
//                 </a>
//                 <div
//                   className='dropdown-menu dropdown-shadow p-0'
//                   aria-labelledby='dropdownMenuLink'
//                   style={{
//                     top: '47px',
//                     left: '-46px'
//                   }}
//                 >
//                   <Link
//                     className={window.location.search === `?menu=breakfast` ? 'dropdown-header p-3 dropdown-option-active noLinkEffect' : 'dropdown-header p-3 dropdown-option noLinkEffect'}
//                     to={`/dashboard/restaurant/${restaurantId}/menu?menu=breakfast`}
//                   >
//                     {i18n && i18n.t('Breakfast')}
//                   </Link>
//                   <div className='dropdown-divider' />
//                   <Link
//                     className={window.location.search === `?menu=lunch` ? 'dropdown-header p-3 dropdown-option-active noLinkEffect' : 'dropdown-header p-3 dropdown-option noLinkEffect'}
//                     to={`/dashboard/restaurant/${restaurantId}/menu?menu=lunch`}
//                   >
//                     {i18n && i18n.t('Lunch')}
//                   </Link>
//                   <div className='dropdown-divider' />
//                   <Link
//                     className={window.location.search === `?menu=dinner` ? 'dropdown-header p-3 dropdown-option-active noLinkEffect' : 'dropdown-header p-3 dropdown-option noLinkEffect'}
//                     to={`/dashboard/restaurant/${restaurantId}/menu?menu=dinner`}
//                   >
//                     {i18n && i18n.t('Dinner')}
//                   </Link>
//                   <div className='dropdown-divider' />
//                   <Link
//                     className={window.location.search === `?menu=buffet` ? 'dropdown-header p-3 dropdown-option-active noLinkEffect' : 'dropdown-header p-3 dropdown-option noLinkEffect'}
//                     to={`/dashboard/restaurant/${restaurantId}/menu?menu=buffet`}
//                   >
//                     {i18n && i18n.t('Buffet')}
//                   </Link>
//                 </div>
//               </li> */}
//               <NavLink
//                 isOrder={true}
//                 icon={ordersPresent ? <i className='icon-bell text-danger float-right' /> : null}
//                 linkURL={`/dashboard/restaurant/${restaurantId}/meetings`}
//                 label='Orders'
//                 isActive={page === 'meetings'}
//                 meetings={ordersPresent}
//               />
//               {billingTab && !isSelfServiceEnabled &&
//                 <NavLink
//                   linkURL={`/dashboard/restaurant/${restaurantId}/billing`}
//                   label='Billing'
//                   isActive={page === 'billing'}
//                 />
//               }
//               {isSelfServiceEnabled &&
//                 <NavLink
//                   linkURL={`/dashboard/restaurant/${restaurantId}/invoices`}
//                   label='Invoices'
//                   isActive={page === 'invoices'}
//                 />
//               }
//               {/* Payments Page is temporarily hidden from vendor
//                <NavLink
//                // linkURL={`/dashboard/restaurant/${restaurantId}/payments`}
//                // label='Payments'
//                // isActive={page === 'payments'} 
//               /> */}
//               <NavLink
//                 linkURL={`/dashboard/restaurant/${restaurantId}/reports`}
//                 label='Reports'
//                 isActive={page === 'reports'}
//               />
//               <NavLink
//                 linkURL={`/dashboard/restaurant/${restaurantId}/help`}
//                 label='Help'
//                 isActive={page === 'help'}
//               />
//             </ul>
//             <div className='d-flex align-items-center justify-content-between order-1 order-md-2 vendor-user-and-restaurants'>
//               {locationDropdown}
//               <ul className='navbar-nav'>
//                 <li className='nav-item dropdown'>
//                   <Link
//                     to='/'
//                     className='nav-link dropdown-toggle profilelink p-md-2 p-0'
//                     data-bs-toggle='dropdown'
//                     id='navbarDropdown'
//                   >
//                     <div id='autoThumbnailParent'>
//                       <div id='autoThumbnailChild'>
//                         <span
//                           className='hidden-xs text-capitalize'
//                           id='autoHiddenDrop'
//                         >
//                           {user?.first_name?.charAt(0)}{' '}
//                         </span>
//                       </div>
//                     </div>
//                   </Link>
//                   <div
//                     className={`dropdown-menu dropdown-menu-right profile-dropdown`}
//                     id='profile'
//                     aria-labelledby='navbarDropdown'
//                     style={{
//                       marginTop: '8px',
//                       marginRight: '14px'
//                     }}
//                   >
//                     <div className='dropdown-item profile-dropdown-header-item py-3 px-5 text-center'>
//                       <strong className='text-capitalize'>
//                         {user.first_name ? user.first_name : user.name}{' '}
//                         {user.last_name ? user.last_name : user.name}
//                       </strong>
//                       <br />
//                       <small className='muted'>{user.email}</small>
//                     </div>
//                     <div className='dropdown-divider' />
//                     {bottomLinks}
//                     <div className='p-2 px-3'>
//                       <a
//                         className='text-dark d-inline p-0'
//                         href='https://chowmill.com/privacy-policy/'
//                         target='_blank'
//                         rel='noopener noreferrer'
//                         style={{ textDecoration: 'underline' }}
//                       >
//                         Privacy
//                       </a>
//                       <a
//                         className='text-dark d-inline p-0 ml-3'
//                         href='https://chowmill.com/terms-of-service/'
//                         target='_blank'
//                         rel='noopener noreferrer'
//                         style={{ textDecoration: 'underline' }}
//                       >
//                         Terms
//                       </a>
//                     </div>
//                   </div>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </nav>
//       </React.Fragment>
//     );
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   i18n: state.i18n.i18n,
//   restaurantDetails: state.restaurant_data.restaurant_details
// });

// export default connect(mapStateToProps)(withRouter(Header));

import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import MenuLink from './MenuLink';
import NavLink from './NavLink';
import './header.css';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { Params } from '../../Pages/Dashboard/Dashboard';
import { getLocalStorageData } from '../../Utilities/getLocalStorageData';

type HeaderProps = {
  locationDropdown: string | JSX.Element;
}

const Header = ({locationDropdown}: HeaderProps) => {
  const { restaurantId, page } = useParams<Params>();
  const restaurantDetails = useSelector((state: ReduxRootState) => state.restaurant_data.restaurant_details);
  // const i18n = useSelector((state: ReduxRootState) => state.i18n.i18n);
  const billingTab = restaurantDetails?.address?.billing_tab;
  // const activeClass = window.location.pathname === `/dashboard/restaurant/${restaurantId}/menu` || window.location.pathname === `/dashboard/restaurant/${restaurantId}/menu/${id}`
    const {
      isSelfServiceEnabled,
      vendor: user,
      ordersPresent
    } = getLocalStorageData();

    const bottomLinks = (
      <>
        <MenuLink
          icon='fas fa-sign-out-alt'
          path='/logout'
          label='Logout'
        />
      </>
    );

    return (
      <React.Fragment>
        <nav className='navbar navbar-expand-md navbar-light bg-white border-bottom fixed-top'>
          <Link className='navbar-brand' to={`/dashboard/restaurant/${restaurantId}`}>
            <img
              alt='Logo'
              src='/imgs/chowmill-logo-full-u.png'
              className='logo-chowmill'
            />
          </Link>
          <i
            className='fas fa-chevron-circle-down navbar-toggler fa-2x border-0 mob-cal-color'
            data-bs-toggle='collapse'
            data-bs-target='#chowmillNavbarPrim'
            aria-controls='chowmillNavbarPrim'
            aria-expanded='false'
            aria-label='Toggle navigation'
          ></i>
          <div className='collapse navbar-collapse flex-md-row flex-column' id='chowmillNavbarPrim'>
            <ul className='navbar-nav me-auto nav-for-mobile  order-2 order-md-1'>
              <NavLink
                linkURL={`/dashboard/restaurant/${restaurantId}`}
                label='Settings'
                isActive={!page}
              />
              <NavLink
                  linkURL={`/dashboard/restaurant/${restaurantId}/menu`}
                  label='Menus'
                  isActive={page === 'menu'}
                />
              {/* <li className='dropdown mr-2'>
                <a
                  href='/'
                  // className={`dropdown-toggle noLinkEffect prim-link mr-4 ml-3`}
                  className={`nav-link dropdown-toggle noLinkEffect prim-link mr-4 ml-3 ${activeClass ? 'nav-link prim-link active' : ''}`}
                  id='dropdownMenuButton'
                  // data-bs-toggle='dropdown'
                  // aria-haspopup='true'
                  aria-expanded='false'
                >
                  {i18n && i18n.t('Menus')}
                </a>
                <div
                  className='dropdown-menu dropdown-shadow p-0'
                  aria-labelledby='dropdownMenuLink'
                  style={{
                    top: '47px',
                    left: '-46px'
                  }}
                >
                  <Link
                    className={window.location.search === `?menu=breakfast` ? 'dropdown-header p-3 dropdown-option-active noLinkEffect' : 'dropdown-header p-3 dropdown-option noLinkEffect'}
                    to={`/dashboard/restaurant/${restaurantId}/menu?menu=breakfast`}
                  >
                    {i18n && i18n.t('Breakfast')}
                  </Link>
                  <div className='dropdown-divider' />
                  <Link
                    className={window.location.search === `?menu=lunch` ? 'dropdown-header p-3 dropdown-option-active noLinkEffect' : 'dropdown-header p-3 dropdown-option noLinkEffect'}
                    to={`/dashboard/restaurant/${restaurantId}/menu?menu=lunch`}
                  >
                    {i18n && i18n.t('Lunch')}
                  </Link>
                  <div className='dropdown-divider' />
                  <Link
                    className={window.location.search === `?menu=dinner` ? 'dropdown-header p-3 dropdown-option-active noLinkEffect' : 'dropdown-header p-3 dropdown-option noLinkEffect'}
                    to={`/dashboard/restaurant/${restaurantId}/menu?menu=dinner`}
                  >
                    {i18n && i18n.t('Dinner')}
                  </Link>
                  <div className='dropdown-divider' />
                  <Link
                    className={window.location.search === `?menu=buffet` ? 'dropdown-header p-3 dropdown-option-active noLinkEffect' : 'dropdown-header p-3 dropdown-option noLinkEffect'}
                    to={`/dashboard/restaurant/${restaurantId}/menu?menu=buffet`}
                  >
                    {i18n && i18n.t('Buffet')}
                  </Link>
                </div>
              </li> */}
              <NavLink
                isOrder={true}
                icon={ordersPresent ? <i className='icon-bell text-danger float-right' /> : null}
                linkURL={`/dashboard/restaurant/${restaurantId}/meetings`}
                label='Orders'
                isActive={page === 'meetings'}
                meetings={ordersPresent}
              />
              {billingTab && !isSelfServiceEnabled &&
                <NavLink
                  linkURL={`/dashboard/restaurant/${restaurantId}/billing`}
                  label='Billing'
                  isActive={page === 'billing'}
                />
              }
              {isSelfServiceEnabled &&
                <NavLink
                  linkURL={`/dashboard/restaurant/${restaurantId}/invoices`}
                  label='Invoices'
                  isActive={page === 'invoices'}
                />
              }
              {/* Payments Page is temporarily hidden from vendor
               <NavLink
               // linkURL={`/dashboard/restaurant/${restaurantId}/payments`}
               // label='Payments'
               // isActive={page === 'payments'} 
              /> */}
              <NavLink
                linkURL={`/dashboard/restaurant/${restaurantId}/reports`}
                label='Reports'
                isActive={page === 'reports'}
              />
              <NavLink
                linkURL={`/dashboard/restaurant/${restaurantId}/help`}
                label='Help'
                isActive={page === 'help'}
              />
            </ul>
            <div className='d-flex align-items-center justify-content-between order-1 order-md-2 vendor-user-and-restaurants'>
              {locationDropdown}
              <ul className='navbar-nav'>
                <li className='nav-item dropdown'>
                  <Link
                    to='/'
                    className='nav-link dropdown-toggle profilelink p-md-2 p-0'
                    data-bs-toggle='dropdown'
                    id='navbarDropdown'
                  >
                    <div id='autoThumbnailParent'>
                      <div id='autoThumbnailChild'>
                        <span
                          className='hidden-xs text-capitalize'
                          id='autoHiddenDrop'
                        >
                          {user?.first_name?.charAt(0)}{' '}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <div
                    className={`dropdown-menu dropdown-menu-right profile-dropdown`}
                    id='profile'
                    aria-labelledby='navbarDropdown'
                    style={{
                      marginTop: '8px',
                      marginRight: '14px'
                    }}
                  >
                    <div className='dropdown-item profile-dropdown-header-item py-3 px-5 text-center'  onClick={(e)=> e?.stopPropagation()}>
                      <strong className='text-capitalize'>
                        {user.first_name ? user.first_name : user.name}{' '}
                        {user.last_name ? user.last_name : user.name}
                      </strong>
                      <br />
                      <small className='muted'>{user.email}</small>
                    </div>
                    <div className='dropdown-divider' />
                    {bottomLinks}
                    <div className='p-2 px-3'>
                      <a
                        className='text-dark d-inline p-0'
                        href='https://chowmill.com/privacy-policy/'
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ textDecoration: 'underline' }}
                      >
                        Privacy
                      </a>
                      <a
                        className='text-dark d-inline p-0 ml-3'
                        href='https://chowmill.com/terms-of-service/'
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ textDecoration: 'underline' }}
                      >
                        Terms
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
  );
}

export default Header;
