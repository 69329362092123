import { TRANSLATE_I18N } from '../Actions/types';
import { Action } from './ActionInterface';

const initialState = {
  i18n: null
};

const i18nReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case TRANSLATE_I18N:
      return {
        ...state,
        i18n: action.payload
      };
    default:
      return state;
  }
};

export default i18nReducer;