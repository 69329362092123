import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmpty from '../../Utilities/isEmpty';
import DriverPopUp from './Popups/DriverPopUp';
import { getDrivers, updateDriver } from '../../Actions/authActions';
import store from '../../store';
import { DriversSkelton } from './SettingsPageSkeleton';
import Skeleton from 'react-loading-skeleton';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { Driver } from '../../Interfaces/PayloadsAndResponses/Auth';

type Props = {
  drivers: Driver[] | undefined;
  driverLoading: boolean;
}

const RestaurantDrivers: FC<Props> = ({ drivers, driverLoading }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newDriverModal, setNewDriverModal] = useState<boolean>(false);
  const [checkDriver, setCheckDriver] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const dispatch: any = useDispatch();
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const { driver_loading_list } = useSelector((state: ReduxRootState) => state.auth);

  const onUpdate = () => {
    store.dispatch({ type: 'GET_DRIVER_LOADING', payload: null });
    dispatch(getDrivers(restaurantId))
  }

  const handleModalOpen = () => {
    setModalOpen(true);
    setCheckDriver(true);
  }
  const handleEditModalPopup = (id: number) => {
    setSelectedIndex(id);
    setModalOpen(true);
    setNewDriverModal(true);
    setCheckDriver(false);
  }
    const handleCloseModal = () => {
    setModalOpen(false);
  }

  const deleteDriverHandler = (driver: Driver) => {
    dispatch(updateDriver(restaurantId, { status: 'deleted', ...driver }, driver?.id))
  }

  return <React.Fragment>
    {driverLoading ? <DriversSkelton length={drivers?.length ?? null} /> :
      <React.Fragment>
        <h3 className='border-bottom py-2 mt-3 mb-3'>Drivers
          <button className='btn text-decoration-none btn-light btn-link btn-sm  py-1 ml-2' onClick={() => handleModalOpen()} >
            <i className='fas fa-plus' aria-hidden='true'></i> New
          </button>
        </h3>
        {isEmpty(drivers) ? null :
          <div className='table-responsive'>
            <table className='table table-hover'>
              <thead className='thead-dark'>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {drivers?.map((driver, index) => (
                  driver_loading_list[driver?.id] ?
                    <tr key={index}>
                      <td><Skeleton height={25} width={25} circle /></td>
                      <td><Skeleton height={34} /></td>
                      <td><Skeleton height={34} /></td>
                      <td><Skeleton height={34} /></td>
                      <td><Skeleton height={34} /></td>
                      <td><Skeleton height={34} /></td>
                    </tr> :
                    <tr key={index}>
                      <td className='px-0 px-md-3'>
                        {!(driver?.image?.thumb?.url || driver?.image?.medium?.url || driver?.image?.url) ? '' :
                          <img alt='driver_image' src={driver?.image?.medium?.url ?? driver?.image?.url ?? driver?.image?.url ?? ''}
                            className='img-fluid rounded-circle float-left ' style={{ width: '25px', height: '25px' }} />
                        }
                      </td>
                      <td className='text-nowrap'>{(driver?.first_name ? driver?.first_name : '') + ' ' + (driver?.last_name ? driver?.last_name : '')}</td>
                      <td className='text-nowrap'>{driver?.email}</td>
                      <td className='text-nowrap'>{driver?.phone_number}
                      </td>
                      <td>
                        <button id={'add' + driver?.id} className='btn btn-light btn-sm' onClick={() => handleEditModalPopup(index)} >
                          <i className='fas fa-user-edit'></i>
                        </button>
                      </td>
                      <td>
                        <button className='btn btn-light btn-sm text-danger' onClick={() => deleteDriverHandler(driver)}>
                          <i className='fas fa-minus-circle' aria-hidden='true'></i>
                        </button>
                      </td>
                    </tr>))
                }
              </tbody>
            </table>
          </div>
        }
      </React.Fragment>
    }
    <DriverPopUp
      onUpdate={() => onUpdate}
      driver={!newDriverModal ? [] : drivers}
      modalOpen={modalOpen}
      handleCloseModal={handleCloseModal}
      checkDriver={checkDriver}
      selectedIndex={selectedIndex}
    />
  </React.Fragment>
}

export default RestaurantDrivers;