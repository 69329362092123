import React, { FC, useEffect, useState } from 'react';
import GeneralSettingsPopUp from './Popups/GeneralSettingsPopUp'
import Dropzone from 'react-dropzone';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { showToastNotification } from '../../Common/showToastNotification';
import { getCuisines, updateRestaurantSettings, updateRestaurantAddressDetails } from '../../Actions/getRestaurantActions';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { RestaurantAddress } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';

type Props = {
  restaurant: RestaurantAddress;
}

const GeneralSettings: FC<Props> = ({ restaurant }) => {
  const dispatch: any = useDispatch();

  const [isOpen, setisOpen] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [updatingStatus, setUpdatingStatus] = useState<boolean>(false);
  const [deletedCuisineIndex, setDeletedCuisineIndex] = useState<null | number>();

  const { address_name, street, city, state, zip, status, logo, restaurant_name, cuisines } = restaurant || {}
  const { i18n } = useSelector((state: ReduxRootState) => state?.i18n)

  useEffect(() => {
    dispatch(getCuisines());
  }, [dispatch]);

  const openGeneralSettingPopUp = () => {
    setisOpen(true);
  }
  const closeGeneralSettingPopUp = () => {
    setisOpen(false);
  }
  const deleteCuisineHandler = (index: number) => {
    let updatedCuisines = [...cuisines];
    updatedCuisines.splice(index, 1);
    const newSelectedCuisineList = updatedCuisines.map(cuisine => cuisine.id);
    setDeletedCuisineIndex(index);
    const addressDetail = { name: restaurant_name, cuisine_ids: newSelectedCuisineList }
    //At least one param is required to call UpdateRestaurant setting so status is not updating it is only for API requirement.
    dispatch(updateRestaurantAddressDetails(restaurant?.id, addressDetail, { status }, 'editGeneralSettings', () => {
      setDeletedCuisineIndex(null);
      showToastNotification('General settings updated successfully!', 'Success!')
    }))
  }
  const restaurantStatusHandler = () => {
    const status = restaurant?.status === 'active' ? 'deleted' : 'active';
    setUpdatingStatus(true);
    restaurant?.id && dispatch(updateRestaurantSettings({ restaurant: { status } }, 'editStatus', restaurant?.id, () => {
      showToastNotification('Restaurant status updated successfully!', 'Success!')
      setUpdatingStatus(false);
    }));
  }
  const imageDropHandler: (<T extends File>(acceptedFiles: T[]) => void) = (acceptedFiles) => {
    const logo = new FormData();
    logo.append('restaurant[logo]', acceptedFiles[0]);
    setImageLoading(true)
    restaurant?.id && acceptedFiles[0] && dispatch(updateRestaurantSettings(logo, 'editLogo', restaurant?.id, () => {
      setImageLoading(false);
      showToastNotification('Restaurant logo updated successfully!', 'Success!')
    }));
  }

  const spinnerForRestrictionUpdate = (
    <div
      className={` ml-2 spinner-border spinner-wide-handling`}
      style={{ width: '.8rem', height: '.8rem', fontSize: '8px' }} role='status'
    >
      <span className='sr-only'>Loading...</span>
    </div>
  )

  return (
    <div>
      <div className='row border-bottom py-2 mb-3 mx-0'>
        <div className='col-md-6 order-md-1 order-2 px-0'>
          <h3 className='m-0'>General Settings</h3>
        </div>
        <div className='col-md-6 order-md-2 order-1 mb-2 mb-md-0 px-0 d-none'>
          <h3 className='m-0'>
            <div className='custom-control custom-switch float-right d-inline me-0 ms-auto'>
              {updatingStatus ?
                <div className='custom-switch float-right d-flex align-items-center'>
                  <Skeleton className='d-flex' width={28} height={16} />
                  <Skeleton className='ms-2' width={110} height={24} />
                </div> :
                <React.Fragment>
                  <input type='checkbox'
                    id='deactivateCheck'
                    checked={status !== 'active'}
                    onChange={restaurantStatusHandler}
                    name='deactivateCheck'
                    className=' custom-control-input'
                  />
                  <label className='custom-control-label' htmlFor='deactivateCheck'> Deactivate </label>
                </React.Fragment>
              }
            </div>
          </h3>
        </div>
      </div>
      <div className='row pb-3'>
        <div className='col-md-3'>
          <Dropzone
            onDrop={imageDropHandler}
            // @ts-ignore
            accept='image/jpg, image/jpeg, image/png'
          >
            {({ getRootProps, getInputProps }) => (
              <section className='bg-light d-flex justify-content-center align-items-center'
                style={{ border: '2px dashed #0087F7', borderRadius: '5px', height: '175px' }}>
                <div className='h-100 w-100'  {...getRootProps()} style={imageLoading ? { pointerEvents: 'none' } : {}}>
                  <input {...getInputProps()} />
                  <div className='fooditem-image h-100 w-100 d-flex justify-content-center align-items-center'
                    style={!imageLoading ? { 'backgroundImage': `url(${logo})` } : {}}
                  >
                    {imageLoading ? <div className='w-100 h-100'><Skeleton width='100%' height='99%' /></div> :
                      logo ? '' :
                        <p className='text-center'>{i18n?.t('Upload Logo')}</p>
                    }
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div className='col-md-5' >
          <div className='py-3 px-2 editable-object border-bottom' onClick={openGeneralSettingPopUp}>
            <p className='text-muted font-weight-bold'>Restaurant Name
              <button className='btn btn-link btn-sm float-right py-1'>
                <i className='fas fa-edit float-right'></i>
              </button>
            </p>
            <strong>{restaurant_name}</strong>
          </div>
          <div className='py-3 px-2 editable-object' onClick={openGeneralSettingPopUp}>
            <p className='text-muted font-weight-bold'>Address
              <button className='btn btn-link btn-sm float-right py-1'>
                <i className='fas fa-edit float-right'></i>
              </button>
            </p>
            {address_name ? <div className='pl-3'> {address_name} </div> : ''}
            {street ? <div className='pl-3'> {restaurant.address_line.split(',')[0]} </div> : ''}
            {(city || state || zip) ? <div className='pl-3'> {city ? city : ''}, {state ? state : ''} {zip ? zip : ''} </div> : ''}
          </div>
        </div>
        <div className='col-md-4'>
          <div className='p-2 editable-object' onClick={openGeneralSettingPopUp} style={{ minHeight: '100%' }}>
            <p className='text-muted font-weight-bold'>Cuisines
              <button className='btn btn-link btn-sm float-right py-1'>
                <i className='fas fa-edit float-right'></i>
              </button>
            </p>
            {cuisines?.map((cuisine, index) =>
              <span key={index} className='badge badge-pill badge-dark p-2 m-1'>{cuisine?.name}
                {deletedCuisineIndex === index ? spinnerForRestrictionUpdate :
                  <span onClick={e => { e.stopPropagation(); deleteCuisineHandler(index) }}>
                    <i className="ml-2 far fa-times-circle cursor-pointer" aria-hidden="true" ></i>
                  </span>
                }
              </span>
            )}
          </div>
        </div>
      </div>
      <hr />
      <GeneralSettingsPopUp
        isOpen={isOpen}
        closeGeneralSettingPopUp={closeGeneralSettingPopUp}
        restaurant={restaurant}
      />
    </div>
  )
}

export default GeneralSettings;