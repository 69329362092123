import { User, UserHeaders } from "../Interfaces/PayloadsAndResponses/Auth";

export const getLocalStorageData = () => {
	const headers = JSON.parse(localStorage.getItem('headers') || '{}') as UserHeaders;
	const remembered_restaurant = Boolean(JSON.parse(localStorage.getItem('remember_restaurant') || '{}'));
	const remembered_restaurant_id = JSON.parse(localStorage.getItem('remember_restaurant') || '{}');
	const isSelfServiceEnabled = Boolean(JSON.parse(localStorage.getItem('isSelfServiceEnable') || '{}'));
	const ordersPresent = Boolean(JSON.parse(localStorage.getItem('orderPresent') || '{}'));
	const vendor = JSON.parse(localStorage.getItem('user') || '{}') as User;

	return {
		vendor,
		headers,
		ordersPresent,
		isSelfServiceEnabled,
		remembered_restaurant,
		remembered_restaurant_id
	}
}