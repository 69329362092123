import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translations: {
        "Restaurant": "Restaurant",
        "Orders": "Orders",
        "Billing": "Billing",
        "Help": "Help",
        "Menus": "Menus",
        "Breakfast": "Breakfast",
        "Lunch": "Lunch",
        "Dinner": "Dinner",
        "Select Restaurant": "Select Restaurant",
        "Logout": "Logout",
        "No menu present.": "No menu present."
      }
    },
    ur: {
      translations: {
        "Restaurant": "ریستوراں",
        "Orders": "احکامات",
        "Billing": "بلنگ",
        "Help": "مدد",
        "Menus": "مینو",
        "Breakfast": "ناشتہ",
        "Lunch": "لنچ",
        "Dinner": "ڈنر",
        "Select Restaurant": "ریستوراں کو منتخب کریں",
        "Logout": "لاگ آوٹ",
        "No menu present.": "کوئی مینو موجود نہیں ہے۔"
      }
    }
  },
  fallbackLng: "en",

  // uncomment below line to debuging translations
  // debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },
  react: {
    wait: true
  }
});

export default i18n;

