import React, { FC, useState } from 'react';
import EditContactPopUp from './Popups/EditContactPopUp';
import EditAdminContactPopup from './Popups/EditAdminContactPopup';
import Skeleton from 'react-loading-skeleton';
import RestaurantCheckBoxFlag from './RestaurantCheckBoxFlag';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ContactsSkelton } from './SettingsPageSkeleton';
import { updateRestaurantSettings, updateRestaurantAdmins } from '../../Actions/getRestaurantActions';
import { showToastNotification } from '../../Common/showToastNotification';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { GetRestaurantAdmins, RestaurantAdmin, RestaurantContact, RestaurantContactUpdatePayload } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';


type Props = {
  contacts: RestaurantContact[]
  restaurantAdmins: GetRestaurantAdmins | null;
}

const RestaurantContacts: FC<Props> = ({ contacts, restaurantAdmins }) => {
  const dispatch: any = useDispatch();
  let user = JSON.parse(localStorage.getItem('user') || '{}');
  const { i18n } = useSelector((state: ReduxRootState) => state?.i18n)
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const [updateAdminLoading, setUpdateAdminLoading] = useState<{ [key: number]: boolean | string }>({});
  const [updateContactLoading, setUpdateContactLoading] = useState<{ [key: number]: boolean | string }>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedContactIndex, setSelectedContactIndex] = useState<number>(0);
  const [checkNewContactModal, setCheckNewDriverModal] = useState<boolean>(false);
  const [checkIsContact, setCheckIsContact] = useState<boolean>(false);

  // For Admin Contact Modal
  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleEditAdminModal = (id: number) => {
    setSelectedIndex(id);
    setModalOpen(true);
  }

// For Restaurant Conact Modal
  const handleContactModal = () => {
    setContactModalOpen(true);
    setCheckIsContact(true);
  }

  const handleEditContactModal = (id: number) => {
    setContactModalOpen(true);
    setSelectedContactIndex(id);
    setCheckNewDriverModal(true);
    setCheckIsContact(false)
  }

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  }
  const updateContactHandler = (contact: RestaurantContact, updateType: 'editContact' | 'deleteContact', updateFlagName?: 'email_summary_check') => {
    setUpdateContactLoading((updateContactLoading) => ({ ...updateContactLoading, [contact.id]: updateFlagName || true }));
    let updatedContacts: RestaurantContactUpdatePayload = {} as RestaurantContactUpdatePayload;
    if (updateType === 'deleteContact') {
      updatedContacts = { contacts_attributes: [{ id: contact.id, _destroy: 1 }] }
    } else if (updateFlagName) {
      if (updateFlagName === 'email_summary_check' || updateFlagName === 'email_label_check') {
        const emailFlag = contact?.email_summary_check || contact?.email_label_check;
        updatedContacts = {
          contacts_attributes: [{ id: contact.id, email_summary_check: !emailFlag, email_label_check: !emailFlag }]
        }
      }
      //  else {
      //   updatedContacts = {
      //     contacts_attributes: [{ id: contact.id, [updateFlagName]: !contact[updateFlagName] }]
      //   }
      // }
    }
    dispatch(updateRestaurantSettings({ restaurant: updatedContacts }, updateType, restaurantId, () => {
      setUpdateContactLoading((updateContactLoading) => ({ ...updateContactLoading, [contact.id]: false }));
    }))
  }
  const adminContactUpdatedHandler = (admin: RestaurantAdmin, messsage = '') => {
    setUpdateAdminLoading((updateAdminLoading) => ({ ...updateAdminLoading, [admin.id]: false }));
    showToastNotification(`Admin ${messsage || 'updated'} successfully`, 'Success')
  }

  const updateRestaurantAdmin = (admin: RestaurantAdmin, updateType: 'editAdmin' | 'deleteAdmin', updateFlagName?: 'email_summary_check' | 'rating_email_notification' | 'email_label_check') => {
    setUpdateAdminLoading((updateAdminLoading) => ({ ...updateAdminLoading, [admin.id]: updateFlagName || true }));
    if (updateType === 'deleteAdmin') {
      dispatch(updateRestaurantAdmins(admin?.id, { user: { status: 'deleted' } }, () => adminContactUpdatedHandler(admin, 'deleted')))
    } else if (updateFlagName) {
      let updatedData;
      if (updateFlagName === 'email_summary_check' || updateFlagName === 'email_label_check') {
        const emailFlag = admin?.email_summary_check || admin?.email_label_check;
        updatedData = { user: { email_summary_check: !emailFlag, email_label_check: !emailFlag } }
      } else if (updateFlagName === 'rating_email_notification') {
        const adminRatingFlag = admin?.rating_email_notification
        updatedData = { user: { rating_email_notification: !adminRatingFlag } }
      }
      //  else {
      //   updatedData = { user: { [updateFlagName]: !admin[updateFlagName] } }
      // }
      updatedData && dispatch(updateRestaurantAdmins(admin?.id, updatedData, () => adminContactUpdatedHandler(admin)))
    }
  }

  return !contacts ? <ContactsSkelton /> : <>
    <h3 className='border-bottom py-2 mt-3 mb-3'>Contacts
      <button
        className='btn text-decoration-none btn-link btn-sm py-1'
        onClick={() => handleContactModal()}
      >
        <i className='fas fa-plus' aria-hidden='true' ></i> New
      </button>
    </h3>
    <div className='table-responsive'>
      <table className='table table-hover'>
        <thead className='thead-dark'>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {restaurantAdmins?.admins?.map((admin, index) =>
            updateAdminLoading[admin?.id] === true ?
              <tr key={admin?.id}>
                <td><Skeleton height={36} /></td>
                <td><Skeleton height={36} /></td>
                <td><Skeleton height={36} /></td>
                <td><Skeleton height={36} /></td>
                <td><Skeleton height={36} /></td>
              </tr> :
              <tr key={admin?.id}>
                <td className='text-nowrap'>{(admin?.first_name ? admin?.first_name : '') + ' ' + (admin?.last_name ? admin?.last_name : '')}
                  <br />
                  <label className='badge mt-2 badge-success'>{i18n?.t('Admin')}</label>
                </td>
                <RestaurantCheckBoxFlag
                  flagValue={admin?.email || ''}
                  restaurantAdminData={admin}
                  checkboxId={`admin-email_summary_${admin?.id}`}
                  checked={admin?.email_summary_check || admin?.email_label_check}
                  label={`Email Orders Here`}
                  updatingFlagLoading={updateAdminLoading[admin?.id] === 'email_summary_check'}
                  updateContactFlag={() => updateRestaurantAdmin(admin, 'editAdmin', 'email_summary_check')}
                  updateRatingsFlag={() => updateRestaurantAdmin(admin, 'editAdmin', 'rating_email_notification')}
                />
                <td className='text-nowrap'>{admin?.phone_number}</td>
                <td>
                  <button className='btn btn-light btn-sm' onClick={() => handleEditAdminModal(index)}>
                    <i id={'add' + user?.id} className='fas fa-user-edit'></i>
                  </button>
                </td>
                {user?.id !== admin.id ?
                  <td>
                    <button className='btn btn-light btn-sm text-danger' onClick={() => updateRestaurantAdmin(admin, 'deleteAdmin')}>
                      <i className='fas fa-minus-circle' aria-hidden='true'></i>
                    </button>
                  </td> :
                  <td></td>
                }
              </tr>
          )}
          {contacts?.map((contact, index) => (
            updateContactLoading[contact?.id] === true ?
              <tr key={contact?.id}>
                <td><Skeleton height={36} /></td>
                <td><Skeleton height={36} /></td>
                <td><Skeleton height={36} /></td>
                <td><Skeleton height={36} /></td>
                <td><Skeleton height={36} /></td>
              </tr> :
              <tr key={contact?.id}>
                <td className='text-nowrap'>{contact?.cname}</td>
                <RestaurantCheckBoxFlag
                  flagValue={contact?.email || ''}
                  checkboxId={`email_summary_${contact?.id}`}
                  checked={contact?.email_summary_check || contact?.email_label_check}
                  label={`Email Orders Here`}
                  updatingFlagLoading={updateContactLoading[contact?.id] === 'email_summary_check'}
                  updateContactFlag={() => updateContactHandler(contact, 'editContact', 'email_summary_check')}
                />
                <td className='text-nowrap'>{contact?.phone_number}</td>
                <td>
                  <button className='btn btn-light btn-sm' onClick={() => handleEditContactModal(index)} >
                    <i id={'add' + contact?.id} className='fas fa-user-edit' ></i>
                  </button>
                </td>
                <td>
                  <button className='btn btn-light btn-sm text-danger' onClick={() => updateContactHandler(contact, 'deleteContact')}>
                    <i className='fas fa-minus-circle' aria-hidden='true'></i>
                  </button>
                </td>
              </tr>))
          }
        </tbody>
      </table>
    </div>

    <EditAdminContactPopup
      admin={restaurantAdmins && restaurantAdmins.admins}
      modalOpen={modalOpen}
      handleCloseModal={handleCloseModal}
      selectedIndex={selectedIndex}
    />
    <EditContactPopUp
      contactModalOpen={contactModalOpen}
      handleCloseContactModal={handleCloseContactModal}
      contact= {!checkNewContactModal ? [] : contacts && contacts}
      selectedContactIndex={selectedContactIndex}
      checkIsContact={checkIsContact}
    />

  </>
}

export default RestaurantContacts;