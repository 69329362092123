import { format, utcToZonedTime } from "date-fns-tz";
import enGB from 'date-fns/locale/en-GB';
import isEmpty from "./isEmpty";

export const getZonedTime = (dateToBeConverted: string, formatToBeApplied: string, timeZone: string) => {
  if (window.navigator.userAgent.match(/MSIE|Trident/) === null) {
    const date = new Date(dateToBeConverted);

    const zonedDate = utcToZonedTime(date, timeZone);

    // The time zone name is generated by the Intl API which works best when a locale is also provided
    let formattedDateTime = format(zonedDate, formatToBeApplied, {
      timeZone,
      locale: enGB
    });

    let zoneAbbr = formattedDateTime.split(',')?.[1];
    if (zoneAbbr) {
      return `${formattedDateTime.split(',')[0]} ` + zoneAbbr?.match(/[A-Z]/g)?.join('');
    }
  }
};

export const getOrderTimeZone = (dateToBeConverted: string | Date, formatToBeApplied: string, timeZone?: string, insertAt?: 'insertAtInFormat') => {
  if (window.navigator.userAgent.match(/MSIE|Trident/) === null) {
    const date = new Date(dateToBeConverted);
    const zonedDate = utcToZonedTime(date, timeZone ? timeZone : getUserOrLocalTimeZone().userOrLocalTimeZone);
    // The time zone name is generated by the Intl API which works best when a locale is also provided
    let formattedDateTime = format(zonedDate, formatToBeApplied, { timeZone, locale: enGB });
    let splittedDateTime = formattedDateTime.split(',');
    // let zoneAbbr = formattedDateTime.split(',')?.[1];
    if (splittedDateTime[splittedDateTime.length -1]) {
      if (insertAt) {
        return `${splittedDateTime[0]} at ${splittedDateTime[1]} ${splittedDateTime[splittedDateTime.length -1]?.match(/[A-Z]/g)?.join('')}`;
      } else {
        return `${splittedDateTime[0]} ${splittedDateTime[splittedDateTime.length -1]?.match(/[A-Z]/g)?.join('')}`;
      }
    }
  }
};
export const getUserOrLocalTimeZone = () => {
  const loggedInUser = JSON.parse(localStorage.getItem('user') || '{}');
  const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
  const userOrLocalTimeZone = !isEmpty(loggedInUser) ? loggedInUser?.time_zone : localAddress?.time_zone;
  return {localAddress, userOrLocalTimeZone}
}
