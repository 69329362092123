import React, { FC } from 'react';
// import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type BreadcrumbsProps = {
  linkList: ({
    name: string;
    url?: string;
    show?: boolean;
  })[],
  menu?: boolean;
  payment?: boolean;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ linkList, menu, payment }) => {
  return (
    <>
      {!payment ? <nav aria-label={`breadcrumb  menu ${menu ? 'menu-page' : ''}`} >
        <ol className='breadcrumb'>
          {linkList?.map((link, index) => (
            link?.show &&
            <React.Fragment key={index}>
              {index !== 0 && <i style={{ fontSize: 13, color: '#fff', padding: "0px 10px", }} className='fa-solid fa-angle-right'></i>}
              <li className='breadcrumb-item'>
                {link?.url ? <Link style={{ textDecoration: 'none' }} to={link?.url}>{link?.name}</Link> : link?.name}
              </li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
        :
        <nav aria-label={`breadcrumb  menu `} >
          <ol className='breadcrumb'>
            {linkList?.map((link, index) => (
              link?.show &&
              <React.Fragment key={index}>
                {index !== 0 && <i style={{ fontSize: 13, color: '#007BFF', padding: "0px 10px", }} className='fa-solid fa-angle-right'></i>}
                <li>
                  {link?.url ? <Link style={{ textDecoration: 'none' }} to={link?.url}>{link?.name}</Link> : link?.name}
                </li>
              </React.Fragment>
            ))}
          </ol>
        </nav>
      }
    </>
  )
}

export default Breadcrumbs;