import axios from 'axios';
import { LOADING } from './types';
import { getError } from './errorActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GetInvitedUserForSignup } from '../Interfaces/PayloadsAndResponses/SignupInvitation';
// Get InviteStatus Data

export const getInvitedUserForSignup = (inviteToken: string, redirectToLoginOrSignupPage: (response: GetInvitedUserForSignup) => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios
    .get('/auth/confirmation',
      {
        params:
        {
          confirmation_token: inviteToken,
          redirect_url: axios.defaults.baseURL + '/auth/validate_token'
        }
      }
    )
    .then(res => {
      const userData = res.data as GetInvitedUserForSignup;
      try {
        const newHeaders = {
          'access-token': '',
          client: '',
          expiry: '',
          'token-type': '',
          uid: '',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'en/US'
        };
        Object.keys(res.headers)?.forEach(function (key, index) {
          switch (key) {
            case 'access-token':
            case 'client':
            case 'expiry':
            case 'token-type':
            case 'uid':
              newHeaders[key] = res.headers[key];
              break;
            default:
              break
          }
        })
        //Save to LocalStorage
        localStorage.setItem('headers', JSON.stringify(newHeaders));
        localStorage.setItem('user', JSON.stringify(userData.data));

      } catch (e) {
        console.log('error', e);
      }

      redirectToLoginOrSignupPage(userData)
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
// POST Request for getting Users before confirmation
// export const sendInvite = (inviteData: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//   dispatch(setLoading());
//   axios
//     .post('/users/invite', inviteData,
//       {
//         headers: JSON.parse(localStorage.getItem('headers') || '{}')
//       })
//     .then(res =>
//       dispatch({
//         type: GET_USERS_LIST_FOR_CONFIRMATION,
//         payload: res.data
//       })
//     )
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };

// export const sendConfirmInvites = (inviteData: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//   dispatch(setLoading());
//   axios
//     .put('/users/invite', inviteData,
//       {
//         headers: JSON.parse(localStorage.getItem('headers') || '{}')
//       })
//     .then(res =>
//       dispatch({
//         type: SEND_CONFIRM_INVITES,
//         payload: res.data
//       })
//     )
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };
// Status Loading
export const setLoading = () => {
  return {
    type: LOADING
  };
}
