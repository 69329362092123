import React, { FC } from "react";
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import { customStyles } from "./MenuSectionsPage";
import Spinner from "../../../Common/Spinner";

type OptionGroupDeletePopupProps = {
  openDeletePopup: boolean;
  setOpenDeletePopup: (status: boolean) => void;
  onDeleteConfirmed: () => void;
  loading?: boolean;
}
const OptionGroupDeletePopup: FC<OptionGroupDeletePopupProps> = ({ openDeletePopup, setOpenDeletePopup, onDeleteConfirmed, loading }) => {
  return (
    <div className="container">
      {openDeletePopup &&
        <CustomPopUp
          backdropStyles={{ opacity: 1, cursor: 'default' }}
          onPopUpClose={() => setOpenDeletePopup(false)}
          popUpBodyStyles={{ ...customStyles, top: '25%' }}
          allowScrolling={true}
        >
          <div className="delete_modal_content">
            <div className="delete_modal_header d-flex ml-auto">
              <button type="button" className="btn-close modal-close-btn text-dark ml-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => setOpenDeletePopup(false)}></button>
            </div>
            <div className="delete_modal_body">
              <div className="d-flex justify-content-center flex-column">
                <div className="d-flex justify-content-center pb-4">
                  <i className="fa-regular fa-trash-can text-red-tertiary fa-lg p-3 delete-wrapper rounded-circle"></i>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="pb-3 fw-bold m-0 text-medium-black">Are You Sure?</h3>
                  <p className="fs-18 text-secondary-gray fw-normal text-center">Deleting this option will remove it from all future orders. Current orders would not be effected.</p>
                </div>
                <div className="delete_modal_footer d-flex justify-content-center">
                  <button type="button" className="btn fs-16 fw-600 light-gray me-2 rounded-pill" data-bs-dismiss="modal" onClick={() => setOpenDeletePopup(false)}>No, Don’t Delete</button>
                  <button type="button" className="btn fs-16 fw-600 rounded-pill bg-red-tertiary text-white confirm-delete" onClick={() => onDeleteConfirmed()}>{loading ? <Spinner /> : `Yes, Delete`}</button>
                </div>
              </div>
            </div>
          </div>
        </CustomPopUp>
      }
    </div>
  )
}

export default OptionGroupDeletePopup;