import axios from 'axios';
import { GET_BILLINGS, GET_BILLING, BILLING_LOADING } from './types';
import { getError } from './errorActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export const getBillings = (id: string | number, queryParams: string) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`/vendor/restaurants/${id}/billings${queryParams || ''}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_BILLINGS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const getSingleOrderBilling = (id: string | number, restaurantId: string | number, queryParams = '') => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`/vendor/restaurants/${restaurantId}/billings/${id}${queryParams || ''}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_BILLING,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const setLoading = () => {
  return {
    type: BILLING_LOADING
  };
}