import React, { FC, useState, useEffect } from 'react';
import dayjs, { UnitType } from 'dayjs';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import isEmpty from '../../../Utilities/isEmpty';
import '../invoices.css';
import 'react-datepicker/dist/react-datepicker.css';
import Spinner from '../../../Common/Spinner';
import { UpdateInvoiceHandler } from '../InvoiceDetails';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '692px',
    width: '90vw',
    padding: '0',
  }
};

type props = {
  modalIsOpen: boolean,
  closeModal: () => void,
  addAdjustment: UpdateInvoiceHandler,
  invoiceUpdating: boolean,
}

const AddAdjustmentPopup: FC<props> = ({ modalIsOpen, closeModal, addAdjustment, invoiceUpdating }) => {
  let addTimePeriod: UnitType = 'day';
  const [date, setDate] = useState<Date>(new Date());
  const [datePickerRef, setDatePickerRef] = useState<DatePicker | null>();
  const [adjustmentType, setAdjustmentType] = useState<'addition' | 'discount'>('addition');
  const [adjustmentDescription, setAdjustmentDescription] = useState<string | null>('');
  const [adjustmentAmount, setAdjustmentAmount] = useState<null | string>('');
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      setDatePickerRef(null);
      setAdjustmentType('addition');
      setAdjustmentDescription(null);
      setAdjustmentAmount(null);
      setFormSubmitted(false);
    }
  }, [modalIsOpen])
  const addAdjustmentHandler = () => {
    setFormSubmitted(true);
    if (!isEmpty(adjustmentAmount) && date && !isEmpty(adjustmentType) && !isEmpty(adjustmentDescription)) {
      const adjustmentData = {
        adjustment_type: adjustmentType,
        description: adjustmentDescription!,
        price: adjustmentAmount!,
        adjustment_date: date!
      }
      addAdjustment('addAdjustment', [adjustmentData])
    }
  }
  const outsideClickHandler = (e: any, calendar: DatePicker) => {
    if (e.target.id === 'addAjustmentDateDiv' || e.target.id === 'addAjustmentDateIcon') {
      e.preventDefault()
    } else {
      calendar.setOpen(false);
    }
  }
  const datePickerRefHandler = (calendar: DatePicker) => {
    if (isEmpty(calendar)) return;
    //@ts-ignore
    calendar.handleCalendarClickOutside = (event) => outsideClickHandler(event, calendar)
    setDatePickerRef(calendar)
  }
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Example Modal'>
      <div className='modal-header p-3'>
        <h5 className='modal-title' id='modal-title'>Add Adjustment</h5>
        <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
      </div>
      <div id='mydiv' className='modal-body p-3' style={{ width: '100%' }}>
        <form className='p-2'>
          <div className='form-group'>
            <label><b>Date</b></label>
            <div id={'addAjustmentDateDiv'}
              className='border h-40 invoice-adjustment-datepicker'
              onClick={() => {
                //@ts-ignore
                datePickerRef && !datePickerRef?.state?.open && datePickerRef.setOpen(true)
              }}
            >
              <DatePicker
                ref={datePickerRefHandler}
                selected={date}
                onChange={d => setDate(d as Date)}
                dateFormat={`EEE LLL dd 'at' h:mm aa`}
                minDate={dayjs((new Date()).toString()).add(1, addTimePeriod).toDate()}
              />
            </div>
            {formSubmitted && !date && <p className='text-danger mt-1'>Date field is required.</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='amount'><b>Amount</b></label>
            <input type='number'
              min={0}
              value={adjustmentAmount ?? ''}
              className='form-control h-40'
              id='amount' placeholder='Enter Amount'
              onChange={e => setAdjustmentAmount(e.target.value)} />
            {formSubmitted && isEmpty(adjustmentAmount) && <p className='text-danger mt-1'>Amount field is required.</p>}
          </div>
          <div>
            <label><b>Adjustment Type</b></label>
            <div className='my-3'>
              <input type='radio' id='addition' name='type' onChange={() => setAdjustmentType('addition')} checked={adjustmentType === 'addition'} />
              <label className='mx-2 d-inline-block align-middle' htmlFor='addition'>Addition</label>
              <input type='radio' id='discount' name='type' onChange={() => setAdjustmentType('discount')} checked={adjustmentType === 'discount'} />
              <label className='mx-2 d-inline-block align-middle' htmlFor='discount'>Discount</label>
            </div>
            {formSubmitted && isEmpty(adjustmentType) && <p className='text-danger mt-1'>Type field is required.</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='description'><b>Description</b></label>
            <textarea value={adjustmentDescription ?? ''}
              rows={5}
              id='description'
              className='form-control'
              aria-describedby='amount'
              placeholder='Enter Description'
              onChange={e => setAdjustmentDescription(e.target.value)}>
            </textarea>
            {formSubmitted && isEmpty(adjustmentDescription) && <p className='text-danger mt-1'>Description field is required.</p>}
          </div>
        </form>
      </div>
      <div className='modal-footer p-2'>
        <button type='button' className='btn btn-dark' onClick={addAdjustmentHandler} style={{ minWidth: '75px' }}>
          {invoiceUpdating ?
            <Spinner size='spinner-border-sm' /> :
            <b>Add</b>
          }
        </button>
      </div>
    </Modal>)
}

export default AddAdjustmentPopup;
