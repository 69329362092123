import React from 'react';

const ButtonSpinner = () => {
  return (
    <div className='spinner-border spinner-wide-handling' role='status'>
      <span className='sr-only'>Loading...</span>
    </div>
  );
};

export default ButtonSpinner;