import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

type menuSectionSkeletonprops = {
  fromMenuSection? : boolean,
}
const MenuSectionsSkeleton: FC<menuSectionSkeletonprops> = ({fromMenuSection}) => {
  const skeletonSectionItem = <div className="menu_inner_wrapper d-flex flex-wrap md:flex-nowrap justify-content-between align-items-center w-100" >
    <div className="d-flex align-items-center">
      <Skeleton height={90} width={110} />
      <div className="pl-3">
        <h5 className="lead fw-600 m-0"><Skeleton height={30} width={110} /></h5>
        <p className="fs-18 fw-normal m-0"><Skeleton height={20} width={110} /></p>
      </div>
    </div>
    <hr className="menu_seprator my-2" />
  </div>
  return (
    <div>
      <div className="bg-white rounded-10">
        {fromMenuSection ? <div className="d-flex justify-content-between align-items-center px-2 px-md-4 py-3 border-bottom">
          <Skeleton height={20} width={60} />
          <button className="btn btn-sm btn-custom-primary rounded-pill px-3 "><i className="far fa-plus pe-2"></i> Add Item</button>
        </div>
          : <div className="d-flex justify-content-between align-items-center px-2 px-md-4 py-3 border-bottom">
            <h3 className="fw-600 m-0">Sections</h3>
            <button className="btn btn-sm btn-custom-primary rounded-pill px-3 "><i className="far fa-plus pe-2"></i> New Section</button>
          </div>}
        <div className='mx-4'>
          {[1, 2, 3, 4, 5].map((val, index) => {
            return <React.Fragment key={index}>
              {skeletonSectionItem}
            </React.Fragment>
          })}
        </div>
        <div className="px-4 py-4 ">
          <button className="menu_list_add_button btn btn-block">
            <Skeleton height={20} width={20} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default MenuSectionsSkeleton