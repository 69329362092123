import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { duplicateFoodItem, duplicateSection, editSection } from '../../../Actions/menuActions';
import CustomPopUp from '../../../Common/Popups/CustomPopUp'
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import Spinner from '../../../Common/Spinner';
import { customStyles } from './MenuSectionsPage';

type SectionData = {
  section_name: string,
  section_description: string,
  section_visibility: boolean,
  is_hidden?: boolean,
  position?: number,
  fromSectionFoodItemCard?: boolean
}
const AddEditSectionPopup: FC<any> = ({ section, onPopUpClose, type, sectionFoodItemCard, foodItem, fromSectionFoodItemCard }) => {
  const dispatch: any = useDispatch()

  const restaurant_addressesData = useSelector((state: ReduxRootState) => state.restaurant_data?.restaurant_details);
  const menuTypeLocalStorage = localStorage.getItem('menuType') ?? ''
  const restaurantId = localStorage.getItem('remember_restaurant_id') ?? ''
  const menuIdLocal = localStorage.getItem('menuTypeid') ?? ''
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';

  const [newSectionData, setnewSectionData] = useState<SectionData>({
    section_name: fromSectionFoodItemCard ? (foodItem?.name ?? '') : (section?.name ?? ''),
    section_description: fromSectionFoodItemCard ? (foodItem?.description ?? '') : (section?.description ?? ''),
    section_visibility: fromSectionFoodItemCard ? (!foodItem?.is_hidden ?? false) : (section?.is_hidden ?? false),
  })
  const [isLoading, setIsloading] = useState<boolean>(false)
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e?.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setnewSectionData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };
  const handleChangeCheckbox = (e: any) => {
    setnewSectionData((prevData) => ({
      ...prevData,
      section_visibility: !newSectionData?.section_visibility,
    }));
  };
  const loaderChange = () => {
    onPopUpClose()
    setIsloading(false)
  }
  const handleSubmit = (e: any) => {
    setIsloading(true)
    e?.preventDefault();
    const menuId = localStorage.getItem('menuTypeid')
    const addNewSectionPayload = {
      section: {
        name: newSectionData?.section_name,
        description: newSectionData?.section_description,
        is_hidden: newSectionData?.section_visibility
      }
    }
    // setIsOpenNewSection(false)
    if (type === 'Duplicate') {
      if (sectionFoodItemCard === true) {
        const addNewFoodPayload = {
          fooditem: {
            id: foodItem?.id,
            name: newSectionData?.section_name,
            description: newSectionData?.section_description,
            is_hidden: newSectionData?.section_visibility,
          }
        }
        dispatch(duplicateFoodItem(+restaurantId, +menuIdLocal, sectionid, foodItem.id, addNewFoodPayload, loaderChange))
        onPopUpClose()
      } else {
        const addNewFoodPayload = {
          section: {
            id: section?.id,
            name: newSectionData?.section_name,
            description: newSectionData?.section_description,
            is_hidden: newSectionData?.section_visibility,
          }
        }
        dispatch(duplicateSection(+restaurantId, +menuIdLocal, section?.id, addNewFoodPayload, loaderChange))
        onPopUpClose()
      }
    } else {
      menuId && restaurant_addressesData && dispatch(editSection(addNewSectionPayload, menuId, menuTypeLocalStorage, restaurant_addressesData?.address?.id, section?.id, loaderChange))
    }
  };
  return (
    <CustomPopUp
      backdropStyles={{ opacity: 1, cursor: 'default' }}
      onPopUpClose={onPopUpClose}
      popUpBodyStyles={{ ...customStyles, top: '25%' }}
      allowScrolling={true}
    >
      <div className="modal-content ">
        <div className="menu-item-modal-header">
          <h3 className="fw-600 m-0 text-medium-black">{fromSectionFoodItemCard ? `${type} Food Item` : `${type} Section`}</h3>
          <button type="button" className="btn-close modal-close-btn text-dark" onClick={onPopUpClose} data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body py-0">
          <form>
            <div className="mb-3">
              <label className="fs-16 fw-normal text-medium-gray">{fromSectionFoodItemCard? 'Food Item Name' : 'Section Name'}</label>
              <input type="email" className="form-control form-control-sm rounded-1" id="exampleFormControlInput1" name='section_name' value={newSectionData?.section_name} placeholder="Section Name" onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label className="fs-16 fw-normal text-medium-gray">Description</label>
              <textarea className="form-control form-control-sm rounded-1" rows={4} name='section_description' value={newSectionData?.section_description} id="exampleFormControlTextarea1" onChange={handleChange}></textarea>
            </div>
            <p className="fs-16 fw-normal text-medium-gray">Visibility</p>
            <div className="mb-3 form-check d-flex align-items-center ps-0">
              <input type="checkbox" className="form-check-input m-0" id="section_visibility" name='section_visibility' checked={!newSectionData?.section_visibility} onChange={handleChangeCheckbox} />
              <label className="fs-16 fw-normal text-medium-black ps-4 m-0" htmlFor='section_visibility'>Visible for Customers</label>
            </div>
          </form>
        </div>
        <div className="menu-item-modal-footer justify-content-end">
          <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill px-32" data-bs-dismiss="modal" onClick={onPopUpClose}>Cancel</button>
          {<button type="submit" className="btn btn-md btn-custom-primary rounded-pill py-2 px-32" onClick={handleSubmit} >{!isLoading ? `${type}` : <Spinner />}</button>}
        </div>
      </div>
    </CustomPopUp>
  )
}

export default AddEditSectionPopup