import i18n from "i18next";

export const restaurantTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Location Information": "Location Information",
      "Restaurant Contacts": "Restaurant Contacts",
      "Hours of Operations": "Hours of Operations",
      "Address": "Address",
      "Payout": "Payout",
      "Commission": "Commission",
      "Lunch Capacity": "Lunch Capacity",
      "Dinner Capacity": "Dinner Capacity",
      "Status": "Status",
      "Admin": "Admin",
      "Edit": "Edit",
      "Delete": "Delete",
      "Add Contact": "Add Contact",
      "Submit": "Submit",
      "First name": "First name",
      "Last name": "Last name",
      "Email": "Email",
      "Delivery Drivers": "Delivery Drivers",

      "Name": "Name",
      "Fax": "Fax",
      "Phone Number": "Phone Number",
      "Automatically Email Order Summary": "Automatically Email Order Summary",
      "Automatically Fax Order Summary": "Automatically Fax Order Summary",
      "Automatically send label": "Automatically Send label",
      "Automatically send rating notifications": "Automatically send rating notifications",
      "Automatically send text reminders": "Automatically Send Text Reminders"
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Location Information": "مقام کی معلومات",
      "Restaurant Contacts": "ریستوراں کے رابطے",
      "Hours of Operations": "آپریشن کے اوقات",
      "Address": "پتہ",
      "Payout": "ادائیگی",
      "Commission": "کمیشن",
      "Lunch Capacity": "ظہرانے کی گنجائش",
      "Dinner Capacity": "ڈنر کی اہلیت",
      "Status": "حالت",
      "Admin": "ایڈمن",
      "Edit": "ترمیم",
      "Delete": "حذف کریں",
      "Add Contact": "رابطہ شامل کریں",
      "Submit": "جمع کرائیں",
      "First name": "پہلا نام",
      "Last name": "آخری نام",
      "Email": "ای میل",

      "Name": "نام",
      "Fax": "فیکس",
      "Phone Number": "فون نمبر",
      "Automatically Email Order Summary": "خود بخود ای میل آرڈر کا خلاصہ",
      "Automatically Fax Order Summary": "خودکار طور پر فیکس آرڈر کا خلاصہ",
      "Automatically send label": "خود بخود لیبل بھیجیں",
      "Automatically send rating notifications": "خودکار طور پر درجہ بندی کی اطلاعات بھیجیں۔",
      "Automatically send text reminders": "خود بخود ٹیکسٹ ریمائنڈر بھیجیں۔"
    });
};