import axios from 'axios';
import { LOADING, GET_MENUS, UPDATING_FOODITEM, SET_DISHSIZE_LOADING, REMOVE_DISHSIZE_LOADING, GET_FOOD_ITEMS, GET_MENUS_SECTIONS, UPDATE_SECTIONS, SECTION_LOADING, GET_SYSTEM_DIETRIES, GET_SYSTEM_INGREDIENTS, SECTION_FOODITEMS, SELECT_EXISTING_FOOD_ITEMS, SECTION_FOODITEMS_LOADING, SELECT_EXISTING_OPTION_SETS, ADD_FOOD_ITEM_LOADING } from './types';
import { getError } from './errorActions';
import { showToastNotification } from '../Common/showToastNotification';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { MenuType } from '../Pages/Dashboard/Dashboard';
import { CreateMenuFooditemPayload, CreateRestaurantDishSizesPayload, EditBuffetDishSizePayload, MenuLoadingType, RelateFooditemPayload, UpdateMenuPayload, addBuffetDishSizePayload, nutritionPayloadType, AddExistingFoodItem } from '../Interfaces/PayloadsAndResponses/Menu';
import isEmpty from '../Utilities/isEmpty';

// Get Menu Type
export const getMenuType = (menu_type: string, restaurantId: number, statusMessage?: { msg: string, status: string }, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  let menuType
  if (menu_type === 'lunch') {
    menuType = 1
  } else if (menu_type === 'dinner') {
    menuType = 2
  } else if (menu_type === 'breakfast') {
    menuType = 3
  } else if (menu_type === 'buffet') {
    menuType = 4
  } else if (menu_type === 'dont_care') {
    menuType = 0
  }
  dispatch(setLoading());
  axios.get(`/vendor/restaurants/${restaurantId}/menus?menu_type=${menuType}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_MENUS_SECTIONS,
        payload: res?.data
      });
      closeModal && closeModal();
      localStorage.setItem('menuType', `${menu_type}`)
      statusMessage && showToastNotification(statusMessage?.msg, statusMessage?.status);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
// Get Menu 
export const getRestaurantMenusApi = (restaurantId: number, statusMessage?: { msg: string, status: string }, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  const menuTypeSelected = localStorage.getItem('menuType')
  let menuType: any
  if (menuTypeSelected === 'lunch') {
    menuType = 1
  } else if (menuTypeSelected === 'dinner') {
    menuType = 2
  } else if (menuTypeSelected === 'breakfast') {
    menuType = 3
  } else if (menuTypeSelected === 'buffet') {
    menuType = 4
  } else if (menuTypeSelected === 'dont_care') {
    menuType = 0
  }
  dispatch({
    type: LOADING,
  });
  axios.get(`/vendor/restaurants/${restaurantId}/menus`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_MENUS,
        payload: res?.data
      });

      const newMenuId = res?.data?.menus?.find((menu: any) => menu?.menu_type === menuType)
      localStorage.setItem('menuTypeid', newMenuId?.id)

      const addingNewMenuSection = localStorage.getItem('addingNewMenu') ?? ''
      !isEmpty(addingNewMenuSection) && restaurantId && newMenuId?.id && dispatch(getMenuSections(+restaurantId, +newMenuId?.id))

      closeModal && closeModal();
      statusMessage && showToastNotification(statusMessage?.msg, statusMessage?.status);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

// Get Food Item
export const getFoodItem = (restaurantId: number, menuId: number, section_id: number, foodItemId: number | string | undefined, handleLoading?: (status: boolean) => void, statusMessage?: { msg: string, status: string }, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  handleLoading && handleLoading(true);
  axios.get(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${foodItemId}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {

      dispatch({
        type: GET_FOOD_ITEMS,
        payload: res?.data
      });
      statusMessage && showToastNotification(statusMessage?.msg, statusMessage?.status);
      handleLoading && handleLoading(false);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
// Get Food Item
export const getFoodItemApi = (restaurantId: number, menuId: number, foodItemId: number | string | undefined, statusMessage?: { msg: string, status: string }, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`/vendor/restaurants/${restaurantId}/menus/${menuId}/fooditems`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {

      dispatch({
        type: GET_FOOD_ITEMS,
        payload: res?.data
      });
      statusMessage && showToastNotification(statusMessage?.msg, statusMessage?.status);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

// Get Select Existing Food Item
export const getExistingFoodItems = (restaurantId: number, menuID:number, sectionId:number, statusMessage?: { msg: string, status: string }, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`/vendor/restaurants/${restaurantId}/menus/${menuID}/sections/${sectionId}/existing_fooditems`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {

      dispatch({
        type: SELECT_EXISTING_FOOD_ITEMS,
        payload: res?.data
      });
      statusMessage && showToastNotification(statusMessage?.msg, statusMessage?.status);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getMenuSections = (restaurantId: number, menuId: number, closeModal?: () => void, stoploader?: boolean) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // dispatch(setLoading());
  if(!stoploader) { 
    dispatch({
    type: SECTION_LOADING,
    payload: true
  })
}
  axios.get(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATE_SECTIONS,
        payload: res?.data
      });

      localStorage.removeItem('addingNewMenu')
      dispatch({
        type: SECTION_LOADING,
        payload: false
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getMenuSectionsFoodItems = (restaurantId: number, menuId: number, section_id: number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // dispatch(setLoading());
  dispatch({
    type:SECTION_FOODITEMS_LOADING,
    payload: true
  })
  axios.get(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: SECTION_FOODITEMS,
        payload: res?.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getFoodItems = (restaurantId: number, menuId: number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.get(`/vendor/restaurants/${restaurantId}/menus/${menuId}/fooditems`,
    { headers: JSON.parse(localStorage.getItem('headers') || '{}') }
  )
  .then(res => {
    dispatch({
      type: SECTION_FOODITEMS,
      payload: res?.data
    });
    dispatch({
      type: ADD_FOOD_ITEM_LOADING,
      payload: false
    });
  })
  .catch(err => {
    dispatch(getError(err))
  })
}
export const getDietaries = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.get(`/vendor/dietaries`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_SYSTEM_DIETRIES,
        payload: res?.data
      });
    })
    .catch(err =>
      console.log("ERROR",err)
    );
};

export const getIngredients = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.get(`/vendor/ingredients`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_SYSTEM_INGREDIENTS,
        payload: res?.data
      });
    })
    .catch(err =>
      console.log("ERROR",err)
    );
};

export const createRestaurantDishSizes = (restaurantId: number, menuId: number, dishSizeData: CreateRestaurantDishSizesPayload, type: MenuType, sectionid: number, foodItemId?: number | string) =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const id = dishSizeData?.dishsize?.dishsize_id ? dishSizeData?.dishsize?.dishsize_id : 'new';
    dispatch({ type: SET_DISHSIZE_LOADING, payload: id });
    axios.post(`/vendor/restaurants/${restaurantId}/menus/${menuId}/dishsizes`, dishSizeData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      }
    ).then(res => {
      dispatch(getMenuType(type, restaurantId, { msg: 'Your request have been submitted successfully.', status: 'Success' }, () => {
        dispatch({ type: REMOVE_DISHSIZE_LOADING, payload: id });
      }));
      sectionid && dispatch(getFoodItem(restaurantId, menuId, sectionid, dishSizeData?.dishsize?.fooditem_id))
    })
      .catch(err =>
        dispatch(getError(err))
      );
  };

export const createMenuFooditem = (newFoodItem: CreateMenuFooditemPayload, restaurantId: number, menuId: number | string, sectionid: number, updateType: '', redirectToNewFoodItem: (newFooditemId: number, menuId: number) => void) =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    updateType && dispatch(setFoodItemUpdating(updateType));
    axios.post(`/vendor/restaurants/${restaurantId}/menus/${menuId}/fooditems`, newFoodItem,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      }
    )
      .then(res => {
        redirectToNewFoodItem(res?.data?.fooditem?.id, res?.data?.menu_id)
        dispatch(getFoodItem(restaurantId, res?.data?.menu_id, sectionid, res?.data?.fooditem?.id, ()=>{},{ msg: res?.data?.message, status: 'Success' }))
      })
      .catch(err =>
        dispatch(getError(err))
      );
  };

export const updateMenu = (fooditem: UpdateMenuPayload, restaurantId: number, menuId: number, type: MenuType, updateType: MenuLoadingType, sectionid: number, fooditemId?: number | string | undefined, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setFoodItemUpdating(updateType));
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}`, fooditem,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getMenuType(type, restaurantId, { msg: res?.data?.message, status: 'Success' }, closeModal))
      if (fooditemId !== 'new')
        dispatch(getFoodItem(restaurantId, menuId, sectionid, fooditemId , ()=>{}, { msg: res?.data?.message, status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
  );
};

export const addNewMenuSection = (restaurantId: number, newMenuAndSectionData: any, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SECTION_LOADING });
  axios.post(`/vendor/restaurants/${restaurantId}/menus`, newMenuAndSectionData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATE_SECTIONS,
        payload: res?.data
      })

      loaderHandler && loaderHandler();
      dispatch(getRestaurantMenusApi(+restaurantId))
      // restaurantId && dispatch(getMenuSections(+restaurantId, +menuId))
      // removed as data will be send in section API
      // dispatch(getMenuType(type, restaurantId, { msg: res?.data?.message, status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const addNewSection = (newSectionData :any ,menuId:string,type:string, restaurantId:number,loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SECTION_LOADING });
  axios.post(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections`, newSectionData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATE_SECTIONS,
        payload: res?.data
      })
      loaderHandler && loaderHandler();
      restaurantId && dispatch(getMenuSections(+restaurantId, +menuId))
      // removed as data will be send in section API
      // dispatch(getMenuType(type, restaurantId, { msg: res?.data?.message, status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
// vendor/restaurants/1232/menus/1734/fooditems
export const addNewFoodItem = (fooditemPayload :any ,menuId:string, restaurantId:number,closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SECTION_LOADING });
  axios.post(`/vendor/restaurants/${restaurantId}/menus/${menuId}/fooditems`, fooditemPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      // dispatch({
      //   type: UPDATE_SECTIONS,
      //   payload: res?.data?.sections
      // })
      // removed as data will be send in section API
      // dispatch(getMenuType(type, restaurantId, { msg: res?.data?.message, status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const editSection = (newSectionData :any ,menuId:string,type:string, restaurantId:number,sectionId:string, loaderChange?:() => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SECTION_LOADING });
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${sectionId}`, newSectionData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATE_SECTIONS,
        payload: res?.data
      })
      loaderChange && loaderChange()
      restaurantId && dispatch(getMenuSections(+restaurantId, +menuId))
      // removed as data will be send in section API
      // dispatch(getMenuType(type, restaurantId, { msg: res?.data?.message, status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
        
export const duplicateSection = (restaurant_id: number, menu_id: number, section_id: number, newSectionData: any, loaderHandler?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SECTION_LOADING });
  axios.post(`/vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}/duplicate`, newSectionData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATE_SECTIONS,
        payload: res?.data
      })
      loaderHandler && loaderHandler()
      restaurant_id && dispatch(getMenuSections(+restaurant_id, +menu_id))
      // removed as data will be send in section API
      // dispatch(getMenuType(type, restaurantId, { msg: res?.data?.message, status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const dragAndDropFoodItems = (draganddropFoodItemsPayload: any, menuId:string, restaurantId:number, section_id: number, foodItemId:number,closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({type:SECTION_LOADING});
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${foodItemId}`, draganddropFoodItemsPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATE_SECTIONS,
        payload: res?.data
      })
      dispatch(getMenuSectionsFoodItems(+restaurantId, +menuId, section_id))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const addMenuFoodItem = (fooditemPayload :any ,menuId:string, restaurantId:number |  string, section_id: number, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({type:SECTION_LOADING});
  axios.post(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems`, fooditemPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getMenuSectionsFoodItems(+restaurantId, +menuId, section_id))
      loaderHandler && loaderHandler();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const editMenuFoodItem = (newSectionData:any ,menuId:string, restaurantId:number, section_id: number, fooditemId:string, loaderHandler?:() => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({type:SECTION_LOADING});
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${fooditemId}`, newSectionData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getMenuSectionsFoodItems(+restaurantId, +menuId, section_id))
        dispatch({
          type: GET_FOOD_ITEMS,
          payload: res?.data
        });
        showToastNotification('Food Item Updated', 'Success');
      loaderHandler && loaderHandler();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const hideSection = (newSectionData :any ,menuId:string, restaurantId:number,sectionId:number,closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SECTION_LOADING });
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${sectionId}`, newSectionData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getMenuSections(+restaurantId, +menuId))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const dragAndDropMenuSection = (dragAndDropMenuSectionPayload: number ,menuId:string, restaurantId:number, sectionId:number, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${sectionId}`, dragAndDropMenuSectionPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getMenuSections(+restaurantId, +menuId, () => {},true))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const relateFooditem = (relationData: RelateFooditemPayload, restaurantId: number, menuId: number, type: MenuType, updateType: MenuLoadingType, sectionid: number, fooditemId?: number | string, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setFoodItemUpdating(updateType));
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/relate_fooditem`, relationData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getMenuType(type, restaurantId, { msg: res?.data?.message, status: 'Success' }, closeModal));
      if (fooditemId !== 'new')
      dispatch(getFoodItem(restaurantId, menuId, sectionid, fooditemId, ()=>{}, { msg: res?.data?.message, status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const hiddenFoodItem = (restaurant_id: number, menu_id: number, section_id: number, fooditem_id: number, isHidden: boolean) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  const data = {
    fooditem: {
      is_hidden: isHidden
    }
  }
  axios.put(`/vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}/fooditems/${fooditem_id}`, data,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getMenuSectionsFoodItems(restaurant_id, menu_id, section_id))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const deleteItem = (restaurant_id: number, menu_id: number, section_id: number, id: number, food_item_page_check? : boolean) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SECTION_LOADING });
  axios
    .delete(`/vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}/fooditems/${id}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      if (food_item_page_check === true) {
        dispatch(getMenuSectionsFoodItems(restaurant_id, menu_id, section_id))
      } else {
        window.location.href = `/dashboard/restaurant/${restaurant_id}/items/`
      }
    })
    .catch(err =>
      dispatch(getError(err))
    );
}
export const duplicateFoodItem = (restaurant_id: number, menu_id: number, section_id: number, fooditem_id: number, newSectionData: any, loaderHandler?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.post(`/vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}/fooditems/${fooditem_id}/duplicate`, newSectionData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      loaderHandler && loaderHandler();
      dispatch(getMenuSectionsFoodItems(restaurant_id, menu_id, section_id))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const addOptionSets = (optionSetsPayload :any ,menuId:number, restaurantId:number,section_id: number, foodItemId: number, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: ADD_FOOD_ITEM_LOADING,
    payload: true
  });
  axios.post(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${foodItemId}/optionsets`, optionSetsPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATING_FOODITEM,
        payload: res?.data
      })
      loaderHandler && loaderHandler();
      dispatch(getFoodItem(restaurantId, menuId, section_id, foodItemId, ()=>{}, { msg: 'Option Group Added', status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const editOptionSets = (optionSetsPayload :any ,menuId:number, restaurantId:number,section_id: number, foodItemId: number, optionset_id: any, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: ADD_FOOD_ITEM_LOADING,
    payload: true
  });
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${foodItemId}/optionsets/${optionset_id}`, optionSetsPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATING_FOODITEM,
        payload: res?.data
      })
      loaderHandler && loaderHandler();
      dispatch(getFoodItem(restaurantId, menuId, section_id, foodItemId , ()=>{}, { msg: 'Option Group Updated', status: 'Success' }, closeModal))
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const draganddrop = (draganddropPayload: any ,menuId:string, restaurantId:number, section_id: number, fooditem_id: number, optionset_id: any, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${fooditem_id}/optionsets/${optionset_id}`, draganddropPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getFoodItem(restaurantId, +menuId, section_id, fooditem_id , ()=>{}, { msg: 'Option Group Updated', status: 'Success' }, closeModal))
      loaderHandler && loaderHandler();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};


export const editNutrition = (restaurant_id: number, menu_id: number, section_id: number, fooditem_id: number, editNutritionData: nutritionPayloadType, onPopUpCloseNutritionFacts?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}/fooditems/${fooditem_id}`, editNutritionData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      onPopUpCloseNutritionFacts && onPopUpCloseNutritionFacts()
    })
    .catch(err =>
      dispatch(getError(err))
    );
}

// Get Select Existing Option Sets
export const getSelectExistingOptionsets = (restaurantId: number, menuId:number, foodItemId:number, statusMessage?: { msg: string, status: string }, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`/vendor/restaurants/${restaurantId}/menus/${menuId}/fooditems/${foodItemId}/existing_optionsets`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: SELECT_EXISTING_OPTION_SETS,
        payload: res?.data
      });
      statusMessage && showToastNotification(statusMessage?.msg, statusMessage?.status);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const relateFoodItemOptionsets = (relateFoodItemPayload: any, restaurant_id: number, menu_id: number, sectionId: number, foodItemId: number, loaderHandler?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: ADD_FOOD_ITEM_LOADING,
    payload: true
  });
  axios.put(`vendor/restaurants/${restaurant_id}/menus/${menu_id}/fooditems/${foodItemId}/relate_optionsets`, relateFoodItemPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getFoodItem(restaurant_id, menu_id, sectionId, foodItemId , ()=>{}, { msg: res?.data?.message, status: 'Success' }))
      loaderHandler && loaderHandler()
    })
    .catch(err =>
      dispatch(getError(err))
    );
}

export const unrelateOptionGroup = (restaurant_id: number, menu_id: number, section_id: number, foodItemId: number, optionset_id: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: ADD_FOOD_ITEM_LOADING,
    payload: true
  });
  axios.delete(`vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}/fooditems/${foodItemId}/optionsets/${optionset_id}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getFoodItem(restaurant_id, menu_id, section_id, foodItemId, ()=>{} , { msg: res?.data?.message, status: 'Success' }))
    })
    .catch(err =>
      dispatch(getError(err))
    );
}

export const selectExistingFoodItems = (selectExistingFoodItemsPayload: AddExistingFoodItem, restaurant_id: number, menu_id: number, section_id?: number, loaderHandler?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: ADD_FOOD_ITEM_LOADING,
    payload: true
  });
  axios.put(`vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}/relate_fooditems`, selectExistingFoodItemsPayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      section_id && dispatch(getMenuSectionsFoodItems(+restaurant_id, +menu_id, +section_id))
      loaderHandler && loaderHandler()
    })
    .catch(err =>
      dispatch(getError(err))
    );
}

// export const getTimeZoneByLocation = (lat: any, long: any) => async () => {
//   const location = `${lat},${long}`;
//   const timeStamp = Math.round(new Date().getTime() / 1000);
//   try {
//     const resp = await axios.get(`https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=${timeStamp}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`)
//     return resp;
//   }
//   catch (err) {
//     console.error(err);
//   }
// };

// Status Loading
export const setLoading = () => {
  return {
    type: LOADING
  };
}
// Updting FoodItem
export const setFoodItemUpdating = (updateType: '' | MenuLoadingType) => {
  return {
    type: UPDATING_FOODITEM,
    payload: updateType
  };
}
// Buffet Dish Size Actions
export const addBuffetDishSize = (buffetDishSizePayload: addBuffetDishSizePayload ,menuId: string, restaurantId: number,section_id: number, fooditem_id: number, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.post(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${fooditem_id}/dishsizes`, buffetDishSizePayload,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getFoodItem(restaurantId, +menuId, section_id, fooditem_id , ()=>{} , { msg: 'Dish Size Added', status: 'Success' }))
      loaderHandler && loaderHandler();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const EditBuffetDishSize = (editbuffetDishSizePaylaod: EditBuffetDishSizePayload ,menuId: string, restaurantId: number,section_id: number, fooditem_id: number, dishsize_id: number, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${fooditem_id}/dishsizes/${dishsize_id}`, editbuffetDishSizePaylaod,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getFoodItem(restaurantId, +menuId, section_id, fooditem_id, ()=>{} , { msg: 'Dish Size Updated', status: 'Success' }))
      loaderHandler && loaderHandler();
      closeModal && closeModal()
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const editBuffetDishSizeDragandDrop = (editbuffetDishSizePaylaod: any ,menuId: string, restaurantId: number,section_id: number, fooditem_id: number, dishsize_id: number, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/vendor/restaurants/${restaurantId}/menus/${menuId}/sections/${section_id}/fooditems/${fooditem_id}/dishsizes/${dishsize_id}`, editbuffetDishSizePaylaod,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
  .then(res => {
    dispatch(getFoodItem(restaurantId, +menuId, section_id, fooditem_id, ()=>{} , { msg: res?.data?.message, status: 'Success' }))
    loaderHandler && loaderHandler();
  })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const deleteBuffetDishsize = (restaurant_id: number, menu_id: number, section_id: number, fooditem_id: number, dishsize_id: number, loaderHandler?: () => void, closeModal?: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.delete(`vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}/fooditems/${fooditem_id}/dishsizes/${dishsize_id}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
  .then(res => {
    dispatch(getFoodItem(restaurant_id, menu_id, section_id, fooditem_id , ()=>{} , { msg: res?.data?.message, status: 'Success' }))
    loaderHandler && loaderHandler();
    closeModal && closeModal()
  })
    .catch(err =>
      dispatch(getError(err))
    );
}

export const deleteSection = (restaurant_id: number, menu_id: number, section_id: number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SECTION_LOADING });
  axios.delete(`vendor/restaurants/${restaurant_id}/menus/${menu_id}/sections/${section_id}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch(getMenuSections(restaurant_id, menu_id))
    })
    .catch(err => {
      dispatch(getError(err))
    })
}