// import React, { Component } from 'react';
// import { loginUser } from '../../Actions/authActions';
// import { connect } from 'react-redux';
// import { RouteComponentProps } from 'react-router-dom';
// import { AuthReducer, Errors, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState'
// import { LoginPayload } from '../../Interfaces/PayloadsAndResponses/Auth';
// import './LoginStyle.css';

// type AdminLoginProps = {
//   auth: AuthReducer;
//   errors: Errors;
//   loginUser: (userData: LoginPayload) => void
// } & RouteComponentProps<{ token: string }>;

// class AdminLogin extends Component<AdminLoginProps, {}> {
//   componentDidMount() {
//     const { token } = this.props.match.params;
//     localStorage.clear();
//     const data = {
//       key: token,
//       vendor: true
//     };
//     this.props.loginUser(data);
//   }

//   componentWillReceiveProps(nextProps: AdminLoginProps) {
//     if (nextProps.auth.isAuthenticated) {
//       this.redirectUser();
//     }
//   }

//   redirectUser() {
//     this.props.history.replace('/dashboard/restaurant');
//   }

//   render() {
//     return <div></div>;
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   auth: state.auth,
//   errors: state.errors,
// });

// export default connect(mapStateToProps, { loginUser })(AdminLogin);


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Actions/authActions';
import { useParams } from 'react-router-dom';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { LoginPayload } from '../../Interfaces/PayloadsAndResponses/Auth';
import './LoginStyle.css';

const AdminLogin = () => {
  const dispatch = useDispatch<any>();
  const auth = useSelector((state: ReduxRootState) => state.auth);
  const params = useParams<{ token: string }>();
  const newPageis = window.location.origin


  useEffect(() => {
    const { token } = params;
    // this local storage is required
    // localStorage.clear();

    const data: LoginPayload = {
      key: token,
      vendor: true
    };
    dispatch(loginUser(data));

    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const delay = 1000;

    const timerId = setTimeout(() => {
      if (auth?.isAuthenticated) {
        redirectUser();
      }
    }, delay);

    // Cleaning up the timer to avoid memory leaks
    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line
  }, [auth?.isAuthenticated]);



  const redirectUser = () => {

    window.location.replace(`${newPageis}/dashboard/restaurant`)

  }

  return <div></div>;
};

export default AdminLogin;