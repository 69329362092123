// import React, { Component } from 'react';
// // @ts-ignore
// // import AirbrakeClient from 'airbrake-js';
// import { Notifier } from '@airbrake/browser';
// import { i18n } from 'i18next';
// import { ErrorInfo } from 'react';


// type ErrorBoundaryState = {
//   hasError: boolean;
// }

// type ErrorBoundaryProps = {
//   i18n: i18n;
//   children: React.ReactNode;
// }

// export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
//   airbrake;
//   constructor(props: ErrorBoundaryProps) {
//     super(props);
//     this.state = { hasError: false };
//     if (process.env.NODE_ENV !== 'development') {
//       this.airbrake = new Notifier({
//         projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID ? +process.env.REACT_APP_AIRBRAKE_PROJECT_ID : 0,
//         projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY ?? '',
//         environment: process.env.REACT_APP_ENVIRONMENT
//       });
//     }
//     // if (process.env.NODE_ENV !== 'development') {
//     //   this.airbrake = new AirbrakeClient({
//     //     projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
//     //     projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY
//     //   });
//     // }

//   }
//   componentDidCatch(error: Error, errorInfo: ErrorInfo) {
//     this.setState({ hasError: true });
//     if (process.env.NODE_ENV !== 'development') {
//       this.airbrake?.notify({
//         error: error,
//         params: { info: errorInfo }
//       });
//     }
//   }
//   render() {
//     if (this.state.hasError) {
//       return (
//         <p></p>
//       )
//     }
//     return this.props.children;
//   }
// }

import React, { ErrorInfo } from 'react';
import { Notifier } from '@airbrake/browser';
import { useErrorBoundary } from 'use-error-boundary';
import { i18n } from 'i18next';

type ErrorBoundaryProps = {
  i18n: i18n;
  children: React.ReactNode;
};

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryProps> = ({
  i18n,
  children,
}) => {

  const onDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    if (process.env.NODE_ENV !== 'development') {
    const airbrake = new Notifier({
      projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID
        ? +process.env.REACT_APP_AIRBRAKE_PROJECT_ID
        : 0,
      projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY ?? '',
      environment: process.env.REACT_APP_ENVIRONMENT,
    });

    airbrake?.notify({
      error,
      params: { info: errorInfo },
    });
  }
  };

  const { ErrorBoundary, didCatch } = useErrorBoundary({
    onDidCatch
  });

  return (
    <>
      {didCatch ? (
        <p></p>
      ) : (
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      )}
    </>
  );
};

export default ErrorBoundaryWrapper;