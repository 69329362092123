import { GET_RESTAURANT_DETAILS, LOADING, EMPTY_RESTAURANT_DETAILS, GET_MEETINGS, GET_MEETINGS_LOADING, GET_SINGLE_ORDER_DETAILS, ACCEPT_ORDERS, GET_RESTAURANT_DETAILS_LOADING, GET_RESTAURANTS_LOCATIONS, DESTROY_NOTIFICATION, GET_CUISINES, GET_RESTAURANT_ADMINS, UPDATE_RESTAURANT_ADMINS, DELETE_RESTAURANT_ADMINS, STOP_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING, SET_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING } from '../Actions/types';
import { RestaurantsReducer } from '../Interfaces/ReduxInterface/ReduxRootState';
import { Action } from './ActionInterface';

export const get_multiple_meetings_excel_summary_loading_prefix = 'multiple_meetings_excel_summary-on-';

const initialState: RestaurantsReducer = {
  restaurant_details_loading: false,
  restaurant_details: null,
  cuisines: null,
  single_order_details_loading: false,
  restaurant_addresses: null,
  restaurant_admins: null,
  request_type: null,
  single_order_details: null,
  // drivers: null,
  meetings: null,
  meetings_loading: false,
  get_multiple_meetings_excel_summary_loading: {}
};

const restaurantReducer = (state: RestaurantsReducer = initialState, action: Action): RestaurantsReducer => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        single_order_details_loading: true,
      }
    case GET_RESTAURANT_DETAILS_LOADING:
      return {
        ...state,
        restaurant_details_loading: true
      }
    case GET_MEETINGS_LOADING:
      return {
        ...state,
        meetings_loading: true,
      }
    case GET_RESTAURANT_DETAILS:
      return {
        ...state,
        restaurant_details: action.payload,
        request_type: action.request_type,
        restaurant_details_loading: false
      }
    case EMPTY_RESTAURANT_DETAILS:
      return {
        ...state,
        restaurant_details: null,
        restaurant_addresses: null
      }
    case GET_RESTAURANT_ADMINS:
      return {
        ...state,
        restaurant_admins: action.payload,
        restaurant_details_loading: false
      }
    case UPDATE_RESTAURANT_ADMINS:
      const updatedAdmins = [...(state?.restaurant_admins?.admins ?? [])];
      const index = updatedAdmins?.findIndex(admin => admin.id === action.payload?.id);
      updatedAdmins[index] = action.payload;
      return {
        ...state,
        restaurant_admins: { admins: updatedAdmins }
      }
    case DELETE_RESTAURANT_ADMINS:
      return {
        ...state,
        restaurant_admins: state?.restaurant_admins ? { admins: state?.restaurant_admins?.admins?.filter((admin) => (admin.id !== action.payload)) } : null
      }
    case DESTROY_NOTIFICATION:
      return {
        ...state,
        request_type: null,
      }
    case GET_RESTAURANTS_LOCATIONS:
      return {
        ...state,
        restaurant_addresses: action.payload,
      }
    case GET_MEETINGS:
      return {
        ...state,
        meetings: action.payload,
        meetings_loading: false
      }
    case GET_CUISINES:
      return {
        ...state,
        cuisines: action.payload?.cuisines,
      }
    // case GET_RESTAURANT_DRIVERS:
    //   return {
    //     ...state,
    //     drivers: action.payload
    //   }
    case GET_SINGLE_ORDER_DETAILS:
      return {
        ...state,
        single_order_details: action.payload,
        single_order_details_loading: false
      }
    case ACCEPT_ORDERS:
      return {
        ...state,
        single_order_details_loading: false
      }
    // case UPDATE_MEETING_STATUS:
    //   const updatedMeetings = state.meetings && [...state?.meetings?.runningmenus]?.map(meeting => meeting.id !== action.payload ? meeting : { ...meeting, status: 'Confirmed' });
    //   return {
    //     ...state,
    //     meetings: { runningmenus: updatedMeetings }
    //   }
    case SET_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING:
      return {
        ...state,
        get_multiple_meetings_excel_summary_loading: {
          ...state?.get_multiple_meetings_excel_summary_loading, [get_multiple_meetings_excel_summary_loading_prefix + action.payload]: true
        }
      }
    case STOP_MULTIPLE_MEETING_EXCEL_SUMMARY_LOADING:
      return {
        ...state,
        get_multiple_meetings_excel_summary_loading: {
          ...state.get_multiple_meetings_excel_summary_loading, [get_multiple_meetings_excel_summary_loading_prefix + action.payload]: false
        }
      }
    default:
      return state;
  }
};

export default restaurantReducer;