import { AnyAction } from 'redux';
import { GET_INVOICES, GET_SINGLE_INVOICE_DETAIL, INVOICES_LOADING, GET_INVOICE_EMAIL } from '../Actions/types';
import { InvoiceReducer } from '../Interfaces/ReduxInterface/ReduxRootState';
const initialState: InvoiceReducer = {
  invoices: null,
  invoices_total: null,
  invoices_per_page: null,
  single_invoice_detail: null,
  invoice_email: null,
  invoices_loading: true
};

const invoicesReducers = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.payload.invoice,
        invoices_loading: false,
        invoices_total: action.payload.invoices_total,
        invoices_per_page: action.payload.per_page,
      }
    case GET_SINGLE_INVOICE_DETAIL:
      return {
        ...state,
        single_invoice_detail: action.payload,
        invoices_loading: false
      }
    case INVOICES_LOADING:
      return {
        ...state,
        invoices_loading: true
      }
    case GET_INVOICE_EMAIL:
      return {
        ...state,
        invoice_email: action.payload,
        invoices_loading: false
      }
    default:
      return state;
  }
};

export default invoicesReducers;