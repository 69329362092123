import React, { FC, useState } from 'react';
import { DishSize } from '../../../Interfaces/PayloadsAndResponses/Menu';
import CustomPopUp from '../../../Common/Popups/CustomPopUp';
import { customStyles } from './MenuSectionsPage';
import { buffetDishSizesData, buffetDishSizesErrors } from './ItemPage';
import { useParams } from 'react-router';
import { Params } from '../../Dashboard/Dashboard';
import { EditBuffetDishSize } from '../../../Actions/menuActions';
import { useDispatch } from 'react-redux';
import Spinner from '../../../Common/Spinner';

type BuffetDishSizeEditPopupProps = {
  editBuffetDishSize: boolean;
  setEditBuffetDishSize: (status: boolean) => void;
  dishsizes: DishSize;
  closeModal: () => void;
}

const BuffetDishSizeEditPopup: FC<BuffetDishSizeEditPopupProps> = ({ editBuffetDishSize, setEditBuffetDishSize, dishsizes, closeModal }) => {
  const { restaurantId } = useParams<Params>();
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? ''
  const foodItemId = localStorage.getItem('fooditemid') ?? ''
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';
  const selectedDishSizeId = dishsizes?.id;
  const dispatch = useDispatch<any>();
  const [selectedBuffetDishSize, setSelectedBuffetDishSize] = useState<buffetDishSizesData>({
    title: dishsizes?.title,
    serve_count: dishsizes?.serve_count,
    price: dishsizes?.price,
  });
  const [dishSizeErrors, setDishSizeErrors] = useState<buffetDishSizesErrors>({
    title: '',
    serve_count: '',
    price: '',
  });
  const [loading, setLoading] = useState<boolean>(false)

  const loaderHandler = () => {
    setLoading(false)
  }

  //Handle Change
  const handleInputBuffetDishSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setSelectedBuffetDishSize((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const validateEditBuffetDishSizeData = () => {
    const { title = '', serve_count = '', price = '' } = selectedBuffetDishSize
    const showDishsizeErrors: any = {};
    if (!title) {
      showDishsizeErrors.title = 'Title is required';
    }
    if (!serve_count) {
      showDishsizeErrors.serve_count = 'Serve count is required';
    } else if (+serve_count <= 0) {
      showDishsizeErrors.serve_count = 'Serve count must be greater than 0';
      setLoading(false)
    }
    if (!price) {
      showDishsizeErrors.price = 'Price is required';
    }
    setDishSizeErrors(showDishsizeErrors);
    return Object?.keys(showDishsizeErrors)?.length === 0; // Return true if there are no errors
  };

  //handle submit for buffet dishsizes
  const handleSubmitEditDishsizes = (e: any) => {
    setLoading(true)
    e.preventDefault()
    const { title, serve_count } = selectedBuffetDishSize;
    if (!validateEditBuffetDishSizeData()) {
      return;
    }
    const editbuffetDishsizePayload = {
      dishsize: {
        title: title,
        serve_count: serve_count,
      }
    };
    restaurantId && selectedMenuId && dispatch(EditBuffetDishSize(editbuffetDishsizePayload, selectedMenuId, +restaurantId,sectionid, +foodItemId, selectedDishSizeId, loaderHandler ,closeModal))
  };

  return (
    <>
      {editBuffetDishSize &&
        <CustomPopUp
          backdropStyles={{ opacity: 1, cursor: 'default' }}
          onPopUpClose={() => setEditBuffetDishSize(false)}
          popUpBodyStyles={{ ...customStyles, top: '18%', padding: '0', right: 'unset', width: '35%', left: '32%' }}
          allowScrolling={true}
        >
          <form>
            {/* Header */}
            <div className='menu-item-modal-header'>
              <h3 className='fw-600 m-0 text-medium-black'>Edit Buffet Dish size & Cost
              </h3>
              <button type='button' className='btn-close modal-close-btn text-dark' data-bs-dismiss='modal' aria-label='Close' onClick={() => setEditBuffetDishSize(false)}></button>
            </div>
            {/* Form Fields */}
            <div className='modal-body pt-0'>
              <div className='mb-3'>
                <label htmlFor='title' className='fs-16 fw-400 text-medium-gray m-0'>Title</label>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-sm rounded-1'
                  placeholder='X large'
                  value={selectedBuffetDishSize?.title}
                  onChange={handleInputBuffetDishSize}
                />
                {dishSizeErrors?.title && <div className="invalid-feedback-password">{dishSizeErrors?.title}</div>}
              </div>
              <div className='mb-3'>
                <label htmlFor='serve_count' className='fs-16 fw-400 text-medium-gray m-0'>Serving</label>
                <input
                  min={1}
                  type='number'
                  name='serve_count'
                  className='form-control form-control-sm rounded-1'
                  placeholder='35'
                  value={selectedBuffetDishSize?.serve_count}
                  onChange={handleInputBuffetDishSize}
                />
                {dishSizeErrors?.serve_count && <div className="invalid-feedback-password">{dishSizeErrors?.serve_count}</div>}
              </div>
              <div className='mb-3'>
                <label htmlFor='price' className='fs-16 fw-400 text-medium-gray m-0'>Price</label>
                <input
                  type='text'
                  name='price'
                  className='form-control form-control-sm rounded-1'
                  placeholder='250'
                  value={selectedBuffetDishSize?.price}
                  disabled={true}
                // onChange={handleInputBuffetDishSize}
                />
                {/* {dishSizeErrors.price && <div className="invalid-feedback-password">{dishSizeErrors.price}</div>} */}
              </div>
            </div>
            {/* Footer */}
            <div className='menu-item-modal-footer justify-content-end border-top border-2'>
              <button type='submit' className='btn btn-custom-outline-light me-2 rounded-pill px-4' onClick={() => setEditBuffetDishSize(false)}>Cancel</button>
              <button type='submit' className='btn btn-custom-primary rounded-pill py-2 px-32' onClick={handleSubmitEditDishsizes}>{!loading ? 'Edit' : <Spinner />}</button>
            </div>
          </form>
        </CustomPopUp>}
    </>
  )
}

export default BuffetDishSizeEditPopup;