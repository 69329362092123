import React, { FC, useState } from "react";
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import { useParams } from "react-router";
import { Params } from "../../Dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { relateFoodItemOptionsets } from "../../../Actions/menuActions";
import { ReduxRootState, selectExistingData } from "../../../Interfaces/ReduxInterface/ReduxRootState";
import Spinner from "../../../Common/Spinner";
import { customStyles } from "./MenuSectionsPage";
import isEmpty from "../../../Utilities/isEmpty";


type SelectExistingPopupProps = {
  selectExistingPopUp: boolean;
  setSelectExistingPopUp: (status: boolean) => void;
  selectExistingOptionSets?: selectExistingData[]
}

const SelectExistingPopup: FC<SelectExistingPopupProps> = ({ selectExistingPopUp, setSelectExistingPopUp, selectExistingOptionSets = [] }) => {
  const foodItemPageLoader = useSelector((state: ReduxRootState)=>state.menus?.foodItemPageLoader)
  const dispatch = useDispatch<any>();
  //Get id from params
  const { restaurantId } = useParams<Params>();
  
  //data from local storage
  const foodItemId = localStorage.getItem('fooditemid') ?? ''
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? '';
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]); // Step 1: State for selected options
  const [searchOptionGroup, setSearchOptionGroup] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>("All");

  //check for item present or not
  let itemsFound = false;
  const handleChangeSearchOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchOptionGroup(value);
  };

  const loaderHandler = () => {
    setSelectExistingPopUp(false)
  }

  const handleCheckboxChange = (optionId: string) => {
    //Handle checkbox change event
    setSelectedOptions((prevSelected) => {
      if (prevSelected?.includes(optionId)) {
        return prevSelected?.filter((id) => id !== optionId); // Remove optionId from selectedOptions
      } else {
        return [...prevSelected, optionId]; // Add optionId to selectedOptions
      }
    });
  };

  const handleAddOptions = () => {

    const selectedOptionSets = {
      optionset_ids: selectedOptions?.map((optionId) => +optionId)
    }

    selectedMenuId && restaurantId && (dispatch(relateFoodItemOptionsets(selectedOptionSets, +restaurantId, +selectedMenuId, sectionid, +foodItemId, loaderHandler)))
  };
  //filter apply based on name
  const filteredArray = !isEmpty(selectExistingOptionSets) ? selectExistingOptionSets?.filter((item) =>
    item?.name?.toLowerCase()?.includes(searchOptionGroup?.toLowerCase())
  ) : []

  return (
    <>
      {selectExistingPopUp &&
        <CustomPopUp
          backdropStyles={{ opacity: 1, cursor: 'default' }}
          onPopUpClose={() => setSelectExistingPopUp(false)}
          popUpBodyStyles={{ ...customStyles, top: '15%' }}
          allowScrolling={true}
        >
          <div className="modal-content">
            <div className="menu-item-modal-header">
              <h3 className="fw-600 m-0 text-medium-black">Select Existing Options</h3>
              <button type="button" className="btn-close modal-close-btn text-dark" onClick={() => setSelectExistingPopUp(false)}></button>
            </div>
            <div className="selection_modal_body">
              <form>
                <div className="form-group has-search position-relative ">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input type="text" className="form-control form-control-sm rounded-pill" value={searchOptionGroup} placeholder="Search" onChange={handleChangeSearchOptions} />
                </div>
              </form>
              <nav>
                <div className="nav mb-3" id="nav-tab" role="tablist">
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "All" ? "active" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("All")}
                  >
                    All
                  </button>
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "Breakfast" ? "active selected" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("Breakfast")}
                  >
                    Breakfast
                  </button>
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "Lunch" ? "active selected" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("Lunch")}
                  >
                    Lunch
                  </button>
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "Dinner" ? "active selected" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("Dinner")}
                  >
                    Dinner
                  </button>
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "Buffet" ? "active selected" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("Buffet")}
                  >
                    Buffet
                  </button>
                </div>
              </nav>
              <div className="tab-content border-0 p-0" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                  style={{ height: '400px', overflow: 'scroll' }}
                >
                  {filteredArray?.map((options, index: number) => {
                    //render data based on selectedtabs
                    const shouldRenderOptionSets =
                      selectedTab === 'All' ||
                      (selectedTab === 'Breakfast' && options?.menu_type === 1) ||
                      (selectedTab === 'Lunch' && options?.menu_type === 2) ||
                      (selectedTab === 'Dinner' && options?.menu_type === 3) ||
                      (selectedTab === 'Buffet' && options?.menu_type === 4);
                    //count and option values
                    const optionsCount = options.options ? Object?.keys(options?.options)?.length : 0;
                    const optionValues = Object?.values(options?.options);
                    let minPrice = Number.MAX_VALUE;
                    let maxPrice = 0;
                    optionValues?.forEach((option: any) => {
                      if (option?.price !== undefined) {
                        const numericPrice = parseFloat(option?.price); // Convert to number
                        if (!isNaN(numericPrice)) {
                          if (numericPrice < minPrice) {
                            minPrice = numericPrice;
                          }
                          if (numericPrice > maxPrice) {
                            maxPrice = numericPrice;
                          }
                        }
                      }
                    });
                    //check tab values
                    if (shouldRenderOptionSets) {
                      itemsFound = true;
                      return (
                        <React.Fragment key={index}>
                          <div className="d-flex align-items-center pe-3 coursor-pointer select_existing_options">
                            <div className="form-check pe-3">
                              <input type="checkbox" className="form-check-input coursor-pointer"
                                checked={selectedOptions?.includes(options?.id?.toString())}
                                onChange={() => handleCheckboxChange(options?.id?.toString())} />
                            </div>
                            <div className="menu_inner_wrapper d-flex flex-column justify-content-center align-items-start w-100">
                              <h5 className="lead fw-600 text-medium-black m-0 text-nowrap">{options?.name}</h5>
                              <p className="fs-18 fw-normal text-medium-black m-0">{optionsCount} Options | ${minPrice?.toFixed(2)} - ${maxPrice?.toFixed(2)}</p>
                            </div>
                          </div>
                          <hr className="menu_seprator_for_modal" />
                        </React.Fragment>
                      );
                    }
                    return null; // If no items are found, return null to skip rendering
                  })}
                  {/* show msg if no item found */}
                  {!itemsFound && <span className="text-medium-black">No Item Found</span>}
                </div>
              </div>
            </div>
            <div className="menu-item-modal-footer justify-content-end">
              <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill px-32" data-bs-dismiss="modal" onClick={() => setSelectExistingPopUp(false)}>Cancel</button>
              {selectedOptions.length > 0 ? (
                <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32" onClick={handleAddOptions}>
                  {!foodItemPageLoader ? `Add (${selectedOptions.length})` : <Spinner />}
                </button>
              ) : (
                <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32">
                  {!foodItemPageLoader ? 'Add' : <Spinner />}
                </button>
              )}
            </div>
          </div>
        </CustomPopUp>}
    </>
  )
}

export default SelectExistingPopup