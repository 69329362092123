import { GET_ERRORS } from '../Actions/types';
import { Errors } from '../Interfaces/ReduxInterface/ReduxRootState';
import { Action } from './ActionInterface';
const initialState: Errors = {
    errors: null
};

const errorReducer = (state: Errors = initialState, action: Action): Errors => {
    switch (action.type) {
        case GET_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        default:
            return state;
    }
};

export default errorReducer;