import axios from 'axios';
import {
  GET_RESTAURANT_DETAILS, GET_RESTAURANT_DETAILS_LOADING, GET_RESTAURANTS_LOCATIONS, GET_CUISINES, GET_RESTAURANT_ADMINS, UPDATE_RESTAURANT_ADMINS, DELETE_RESTAURANT_ADMINS, LOADING
} from './types';
import { getError } from './errorActions';
import { showToastNotification } from '../Common/showToastNotification';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GetRestaurantLocations, GetTimeZoneByLocation, RestaurantUpdateType, UpdateAdminPayload, UpdateRestaurantAdressDetailsPayload, UpdateRestaurantSettingsPayload } from '../Interfaces/PayloadsAndResponses/GetRestaurants';

// Get Restaurant Details
export const getRestaurantDetails = (id: string | number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading(GET_RESTAURANT_DETAILS_LOADING));
  axios.get(`/vendor/restaurants/${id}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      localStorage.setItem('isSelfServiceEnable', res.data?.address?.enable_self_service);
      dispatch({
        type: GET_RESTAURANT_DETAILS,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getRestaurantAdmins = (id: string | number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`/vendor/restaurants/${id}/admins`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_RESTAURANT_ADMINS,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const updateRestaurantAdmins = (id: string | number, adminData: UpdateAdminPayload, profileSavedCallback: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/vendor/restaurants/update_admin?id=${id}`, adminData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: UPDATE_RESTAURANT_ADMINS,
        payload: res.data
      })
      if (adminData.user.status === 'deleted') {
        dispatch({
          type: DELETE_RESTAURANT_ADMINS,
          payload: id
        })
      }
      profileSavedCallback && profileSavedCallback();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getCuisines = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.get(`/vendor/restaurants/cuisines`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_CUISINES,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const updateRestaurantAddressDetails = (id: string | number, addressDetail: { name: string; cuisine_ids: number[] }, restaurantDetails: UpdateRestaurantAdressDetailsPayload, type: 'editGeneralSettings', infoUpdatedCallback: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

  axios.put(`/vendor/restaurants/${id}/update_settings`, { addressable: addressDetail, restaurant: restaurantDetails },
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      let data = res.data;
      data.request_type = type;
      infoUpdatedCallback && infoUpdatedCallback();
      dispatch({
        type: GET_RESTAURANT_DETAILS,
        payload: data,
        request_type: type
      })
    })
    .catch(err => {
      infoUpdatedCallback && infoUpdatedCallback();
      dispatch(getError(err));
      showToastNotification(err.response.data.message, 'Oops!');
    }
    );
};

export const updateRestaurantSettings = (updatedData: UpdateRestaurantSettingsPayload, type: RestaurantUpdateType, id: string | number, infoUpdatedCallback: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`/vendor/restaurants/${id}`, updatedData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      let data = res.data;
      data.request_type = type;
      infoUpdatedCallback && infoUpdatedCallback();
      dispatch({
        type: GET_RESTAURANT_DETAILS,
        payload: data,
        request_type: type
      })
    })
    .catch(err => {
      infoUpdatedCallback && infoUpdatedCallback()
      dispatch(getError(err));
      showToastNotification(err?.response?.data?.message, 'Oops!');
    }
    );
};

export const getTimeZoneByLocation = async (lat: number, long: number) => {
  const location = `${lat},${long}`;
  const timeStamp = Math.round(new Date().getTime() / 1000);
  try {
    const resp = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=${timeStamp}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`)
    return resp.data as GetTimeZoneByLocation;
  }
  catch (err) {
    console.error(err);
  }
};

export const getRestaurantsLocations = (successCallback: (restaurantsList: GetRestaurantLocations) => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // dispatch(setLoading());
  axios.get('/vendor/restaurants',
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    }
  )
    .then(res => {
      dispatch({
        type: GET_RESTAURANTS_LOCATIONS,
        payload: res.data
      })
      successCallback?.(res.data as GetRestaurantLocations);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

// export const assignDriverToMeeting = (assignedData: any, driverName: any, restaurantId: string | number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//   axios.post(`/vendor/drivers/meeting_driver?restaurant_id=${restaurantId}`, assignedData,
//     {
//       headers: JSON.parse(localStorage.getItem('headers') || '{}')
//     }
//   )
//     .then(() => {
//       showToastNotification(`${driverName} has been assigned successfully.`, 'Success!')
//     })
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };

// export const getRestaurantDrivers = (restaurantId: string | number, runningmenuId: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//   // dispatch(setLoading());
//   axios.get(`/vendor/drivers?restaurant_id=${restaurantId}${runningmenuId ? `&runningmenu_id=${runningmenuId}` : ''}`, {
//     headers: JSON.parse(localStorage.getItem('headers') || '{}')
//   }
//   )
//     .then(res => {
//       dispatch({
//         type: GET_RESTAURANT_DRIVERS,
//         payload: res.data.drivers
//       })
//     })
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };
// Get Menu Details
// export const getMenuDetails = (id: string | number, menu_type: string) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//   dispatch(setLoading());
//   axios.get(`/vendor/restaurants/${id}?menu_type=${menu_type}`,
//     {
//       headers: JSON.parse(localStorage.getItem('headers') || '{}')
//     }
//   )
//     .then(res => {
//       dispatch({
//         type: GET_MENU_DETAILS,
//         payload: res.data
//       })
//     })
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };

// Update Menu Details
// export const updateMenuDetails = (updatedData: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
//   axios.put('/vendor/restaurants/menu', updatedData,
//     {
//       headers: JSON.parse(localStorage.getItem('headers') || '{}')
//     }
//   )
//     .then(res => {
//       dispatch({
//         type: UPDATE_MENU_DETAILS,
//         payload: res.data
//       })
//     })
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };

// Status Loading
export const setLoading = (type = LOADING) => {
  return {
    type: type
  };
}