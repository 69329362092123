// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import Modal from 'react-modal';
// import '../../../Common/Popups/popup.css'
// import { updateRestaurantSettings } from '../../../Actions/getRestaurantActions';
// import Spinner from '../../../Common/Spinner';
// import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
// import { WithTranslation } from 'react-i18next';
// import { Params } from '../../Dashboard/Dashboard';
// import { RestaurantContact, RestaurantContactUpdatePayload, RestaurantUpdateType, UpdateRestaurantSettingsPayload } from '../../../Interfaces/PayloadsAndResponses/GetRestaurants';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     maxWidth: '500px',
//     width: '90vw',
//     padding: '0',
//   }
// };

// Modal.setAppElement('#root');
// Modal!.defaultStyles!.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal!.defaultStyles!.overlay!.zIndex = 1050;

// type EditContactPopUpProps = {
//   i18n: WithTranslation | null;
//   onRef: (ref: EditContactPopUpRef | null) => void;
//   updateRestaurantSettings: (updatedData: UpdateRestaurantSettingsPayload, type: RestaurantUpdateType, id: string | number, infoUpdatedCallback: () => void) => void;
// } & RouteComponentProps<Params>;

// type EditContactPopUpState = {
//   modalIsOpen: boolean,
//   cname: string,
//   email: string,
//   phone_number: string,
//   contactId: string | null | number,
//   automaticallyEmail: boolean,
//   automaticallyLabel: boolean,
//   automaticallyTextReminders: boolean,
//   phone_number_error: string,
//   type: string,
//   itemName: string,
//   updating_contact: boolean
// }

// class EditContactPopUp extends Component<EditContactPopUpProps, EditContactPopUpState> {
//   state: EditContactPopUpState = {
//     modalIsOpen: false,
//     cname: '',
//     email: '',
//     phone_number: '',
//     contactId: '',
//     automaticallyEmail: true,
//     automaticallyLabel: true,
//     automaticallyTextReminders: true,
//     phone_number_error: '',
//     type: '',
//     itemName: '',
//     updating_contact: false
//   }

//   componentDidMount() {
//     this.props.onRef(this);
//   }
//   componentWillUnmount() {
//     this.props.onRef(null)
//   }
//   componentWillReceiveProps() {
//     this.closeModal();
//   }
//   openModal = () => {
//     this.setState({ modalIsOpen: true });
//   }
  // closeModal = () => {
  //   this.setState({
  //     modalIsOpen: false,
  //     cname: '',
  //     email: '',
  //     phone_number: '',
  //     contactId: '',
  //     type: 'type',
  //     automaticallyEmail: true,
  //     automaticallyLabel: true,
  //     automaticallyTextReminders: true,
  //     phone_number_error: '',
  //   });
  // }
//   openModalWithItem = (contact: null | RestaurantContact, type = 'edit') => {
//     if (type === 'edit') {
//       this.setState({
//         cname: contact?.cname ?? '',
//         email: contact?.email ?? '',
//         phone_number: contact?.phone_number ?? '',
//         contactId: contact?.id ?? null,
//         type: type,
//         automaticallyEmail: contact?.email_summary_check ?? true,
//         automaticallyLabel: contact?.email_label_check ?? true,
//         automaticallyTextReminders: contact?.send_text_reminders ?? true,
//       });
//     }
//     this.openModal();
//   }
//   handleChange = ({ target: { type, checked, name, value } }: React.ChangeEvent<HTMLInputElement>) => {
//     const inputValue = type === 'checkbox' ? checked : value;
//     this.setState(prevState => ({ ...prevState, [name]: inputValue }))
//   }
//   contactUpdatedHandler = () => {
//     this.setState({ updating_contact: false });
//     this.closeModal()
//   }
//   onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//     e.preventDefault();
//     const updatedContacts: RestaurantContactUpdatePayload = {
//       contacts_attributes: [{
//         name: this.state.cname,
//         email: this.state.email,
//         phone_number: this.state.phone_number,
//         email_label_check: this.state.automaticallyLabel,
//         send_text_reminders: this.state.automaticallyTextReminders,
//         email_summary_check: this.state.automaticallyEmail
//       }]
//     }
//     if (this.state.type === 'edit' && this.state.contactId) {
//       updatedContacts.contacts_attributes[0].id = +this.state.contactId;
//     }
//     if ((this.state.phone_number && (!this.state.phone_number.match(/(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)/)))) {
//       if (this.state.phone_number && (!this.state.phone_number.match(/(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)/))) {
//         this.setState({ phone_number_error: 'Please match the formate of Phone Number as xxx-xxx-xxxx' });
//       }
//     } else {
//       const { restaurantId } = this.props.match.params;
//       if (restaurantId) {
//         this.setState({ updating_contact: true });
//         this.props.updateRestaurantSettings({ restaurant: updatedContacts }, 'editContact', restaurantId, this.contactUpdatedHandler);
//       }
//     }
//   }
//   render() {
//     return (
//       <Modal
//         isOpen={this.state.modalIsOpen}
//         onRequestClose={this.closeModal}
//         style={customStyles}
//         shouldCloseOnOverlayClick={!this.state.updating_contact}
//       >
//         <div id='show-notification' className='float-right'></div>
//         <div className='order-modal'>
//           <div className='modal-dialog slide'>
//             <div className='modal-content'>
//               <div className='modal-header'>
//                 <h5 className='modal-title' id='modal-title'> {this.state.type === 'edit' ? 'Edit Contact' : 'Add Contact'}</h5>
//                 <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
//               </div>
//               <div className='modal-body p-2'>
//                 <div className='col-lg-10 edit-contact-popup'>
//                   <form className='pt-3'>
//                     <div className='mb-4'>
//                       <label htmlFor='editContactName'> {this.props.i18n && this.props.i18n.t('Name')}</label>
//                       <div className='input-group'>
//                         <input type='text' id='editContactName' className='form-control form-control-lg height-46' name='cname' value={this.state.cname} onChange={this.handleChange} autoComplete='on' required autoFocus placeholder='Name' />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='editContactEmail'> {this.props.i18n && this.props.i18n.t('Email')}</label>
//                       <div className='input-group'>
//                         <input type='email' id='editContactEmail' className='form-control form-control-lg height-46' name='email' value={this.state.email} onChange={this.handleChange} autoComplete='on' required placeholder='Email' />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='editContactNumber'> {this.props.i18n && this.props.i18n.t('Phone Number')}</label>
//                       <div className='input-group'>
//                         <input type='text' id='editContactNumber' className='form-control form-control-lg height-46' name='phone_number' value={this.state.phone_number} onChange={this.handleChange} autoComplete='on' placeholder='Phone Number' />
//                       </div>
//                       {this.state.phone_number_error === '' ? '' : <span className='text-danger'>{this.state.phone_number_error}</span>}
//                     </div>
//                     <div className='form-check'>
//                       <label className='form-check-label text-muted'>
//                         <input type='checkbox' className='form-check-input' name='automaticallyEmail' checked={this.state.automaticallyEmail} onChange={this.handleChange} />
//                         {this.props.i18n && this.props.i18n.t('Automatically Email Order Summary')}
//                         <i className='input-helper'></i>
//                       </label>
//                     </div>
//                     <div className='form-check'>
//                       <label className='form-check-label text-muted'>
//                         <input type='checkbox' className='form-check-input' name='automaticallyLabel' checked={this.state.automaticallyLabel} onChange={this.handleChange} />
//                         {this.props.i18n && this.props.i18n.t('Automatically send label')}
//                         <i className='input-helper'></i>
//                       </label>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className='modal-footer'>
//           <button className='btn btn-primary font-weight-medium' type='submit' style={{ height: '50px' }} onClick={this.onSubmit}>
//             {this.state.updating_contact ? <Spinner /> :
//               <b>  {this.props.i18n && this.props.i18n.t('Submit')} </b>}
//           </button>
//         </div>
//       </Modal>
//     )
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   i18n: state.i18n.i18n
// });

// export type EditContactPopUpRef = React.ElementRef<typeof EditContactPopUp>;

// export default connect(mapStateToProps, { updateRestaurantSettings })(withRouter(EditContactPopUp));



import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import '../../../Common/Popups/popup.css';
import { updateRestaurantSettings } from '../../../Actions/getRestaurantActions';
import Spinner from '../../../Common/Spinner';
import { useParams } from 'react-router-dom';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { Params } from '../../Dashboard/Dashboard';
import { RestaurantContact, RestaurantContactUpdatePayload } from '../../../Interfaces/PayloadsAndResponses/GetRestaurants';
import isEmpty from '../../../Utilities/isEmpty';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '90vw',
    padding: '0',
  }
};

Modal.setAppElement('#root');
Modal!.defaultStyles!.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal!.defaultStyles!.overlay!.zIndex = 1050;

type EditContactPopUpProps = {
  contactModalOpen: boolean;
  handleCloseContactModal: () => void;
  contact: RestaurantContact[];
  selectedContactIndex: number;
  checkIsContact?: boolean
};

const EditContactPopUp: React.FC<EditContactPopUpProps> = ({ contactModalOpen, handleCloseContactModal, contact, selectedContactIndex, checkIsContact }) => {
  const [cname, setCname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone_number, setPhoneNumber] = useState<string>('');
  const [contactId, setContactId] = useState<string | null | number>('');
  const [automaticallyEmail, setAutomaticallyEmail] = useState<boolean>(true);
  const [automaticallyLabel, setAutomaticallyLabel] = useState<boolean>(true);
  const [automaticallyTextReminders, setAutomaticallyTextReminders] = useState<boolean>(true);
  const [phone_number_error, setPhoneNumberError] = useState<string>('');
  const [type, setType] = useState<string>('type');
  const [updating_contact, setUpdatingContact] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const { restaurantId } = useParams<Params>();
  const i18n = useSelector((state: ReduxRootState) => state?.i18n?.i18n);

  useEffect(() => {
    if(contact && !isEmpty(contact)){
      openModalWithItem(contact[selectedContactIndex]);
    } else {
      openModalWithItem();
    }
    //eslint-disable-next-line
  }, [contact, checkIsContact])

  const openModalWithItem = (contact?: null | RestaurantContact, type = 'edit') => {
    if (type === 'edit' && contact && !isEmpty(contact) && !checkIsContact) {
      setCname(contact.cname ?? '');
      setEmail(contact.email ?? '');
      setPhoneNumber(contact.phone_number ?? '');
      setContactId(contact.id ?? null);
      setType(type);
      setAutomaticallyEmail(contact.email_summary_check ?? true);
      setAutomaticallyLabel(contact.email_label_check ?? true);
      setAutomaticallyTextReminders(contact.send_text_reminders ?? true);
    } else {
        setCname('');
        setEmail('');
        setPhoneNumber('');
        setContactId('');
        setType('type');
        setAutomaticallyEmail(true);
        setAutomaticallyLabel(true);
        setAutomaticallyTextReminders(true);
        setPhoneNumberError('');
      }
  }

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { type, checked, name, value } = target;
    let inputValue: string | boolean = type === "checkbox" ? checked : value;
  
    if (name === "automaticallyLabel" || name === "automaticallyTextReminders" || name === "automaticallyEmail") {
      inputValue = !!inputValue;
    }

    if (name === "cname") {
      setCname(inputValue as string);
    } else if (name === "email") {
      setEmail(inputValue as string);
    } else if (name === "phone_number") {
      setPhoneNumber(inputValue as string);
    } else if (name === "automaticallyLabel") {
      setAutomaticallyLabel(inputValue as boolean);
    } else if (name === "automaticallyTextReminders") {
      setAutomaticallyTextReminders(inputValue as boolean);
    } else if (name === "automaticallyEmail") {
      setAutomaticallyEmail(inputValue as boolean);
    }
  };

  const contactUpdatedHandler = () => {
    setUpdatingContact(false);
    handleCloseContactModal();
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const updatedContacts: RestaurantContactUpdatePayload = {
      contacts_attributes: [{
        name: cname,
        email: email,
        phone_number: phone_number,
        email_label_check: automaticallyLabel,
        send_text_reminders: automaticallyTextReminders,
        email_summary_check: automaticallyEmail
      }]
    }
    if (type === 'edit' && contactId) {
      updatedContacts.contacts_attributes[0].id = +contactId;
    }
    if ((phone_number && (!phone_number.match(/(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)/)))) {
      if (phone_number && (!phone_number.match(/(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)/))) {
        setPhoneNumberError('Please match the format of Phone Number as xxx-xxx-xxxx');
      }
    } else {
      if (restaurantId) {
        setUpdatingContact(true);
        dispatch(updateRestaurantSettings({ restaurant: updatedContacts }, 'editContact', restaurantId, contactUpdatedHandler));
      }
    }
  };

  return (
      <Modal
        isOpen={contactModalOpen}
        onRequestClose={handleCloseContactModal}
        style={customStyles}
        shouldCloseOnOverlayClick={!updating_contact}
      >
        <div id='show-notification' className='float-right'></div>
        <div className='order-modal'>
          <div className='modal-dialog slide'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='modal-title'> {type === 'edit' ? 'Edit Contact' : 'Add Contact'}</h5>
                <button type='button' onClick={handleCloseContactModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
              </div>
              <div className='modal-body p-2'>
                <div className='col-lg-10 edit-contact-popup'>
                  <form className='pt-3'>
                    <div className='mb-4'>
                      <label htmlFor='editContactName'> {i18n && i18n.t('Name')}</label>
                      <div className='input-group'>
                        <input type='text' id='editContactName' className='form-control form-control-lg height-46' name='cname' value={cname} onChange={handleChange} autoComplete='on' required autoFocus placeholder='Name' />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='editContactEmail'> {i18n && i18n.t('Email')}</label>
                      <div className='input-group'>
                        <input type='email' id='editContactEmail' className='form-control form-control-lg height-46' name='email' value={email} onChange={handleChange} autoComplete='on' required placeholder='Email' />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='editContactNumber'> {i18n && i18n.t('Phone Number')}</label>
                      <div className='input-group'>
                        <input type='text' id='editContactNumber' className='form-control form-control-lg height-46' name='phone_number' value={phone_number} onChange={handleChange} autoComplete='on' placeholder='Phone Number' />
                      </div>
                      {phone_number_error === '' ? '' : <span className='text-danger'>{phone_number_error}</span>}
                    </div>
                    <div className='form-check'>
                      <label className='form-check-label text-muted'>
                        <input type='checkbox' className='form-check-input' name='automaticallyEmail' checked={automaticallyEmail} onChange={handleChange} />
                        {i18n && i18n.t('Automatically Email Order Summary')}
                        <i className='input-helper'></i>
                      </label>
                    </div>
                    <div className='form-check'>
                      <label className='form-check-label text-muted'>
                        <input type='checkbox' className='form-check-input' name='automaticallyLabel' checked={automaticallyLabel} onChange={handleChange} />
                        {i18n && i18n.t('Automatically send label')}
                        <i className='input-helper'></i>
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary font-weight-medium' type='submit' style={{ height: '50px' }} onClick={onSubmit}>
            {updating_contact ? <Spinner /> :
              <b>  {i18n && i18n.t('Submit')} </b>}
          </button>
        </div>
      </Modal>
  )
}

export default EditContactPopUp;