// import React, { Component } from 'react';
// import { getFreshDeskLogs, postHelpAndSupport } from '../../Actions/helpActions';
// import { connect } from 'react-redux';
// import '../Menus/menu.css'
// import { showToastNotification } from '../../Common/showToastNotification';
// import { HelpSkeleton } from './HelpSkelton';
// import store from '../../store';
// import Spinner from '../../Common/Spinner';
// import { POST_LOGS_LOADING } from '../../Actions/types';
// import { HelpReducer, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { HelpAndSupportPayload } from '../../Interfaces/PayloadsAndResponses/Help';

// type HelpProps = {
//   help: HelpReducer;
//   getFreshDeskLogs: () => void;
//   postHelpAndSupport: (support: HelpAndSupportPayload) => void;
// }
// type HelpState = {
//   email: string,
//   subject: string,
//   description: string,
//   submitHelp: boolean,
// }
// class Help extends Component<HelpProps, HelpState> {
//   state = {
//     email: '',
//     subject: '',
//     description: '',
//     submitHelp: false,
//   }
//   user = JSON.parse(localStorage.getItem('user') || '{}');
//   componentDidMount() {
//     this.props.getFreshDeskLogs();
//     this.setState({ email: this.user.email })
//   }
//   componentWillReceiveProps(nextProps: HelpProps) {
//     if (nextProps.help.logs && this.state.submitHelp) {
//       this.setState({ submitHelp: false, email: this.user.email, subject: '', description: '' });
//       showToastNotification('Thank you. We will be in touch shortly!', 'Success');
//     }
//   }
//   handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     this.setState({ ...this.state, [e.target.name]: e.target.value })
//   }
//   onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     if (this.props.help.post_logs_loading === false) {
//       store.dispatch({ type: POST_LOGS_LOADING, payload: null });
//     }
//     let helpAndSupport = {
//       email: this.state.email,
//       description: this.state.description,
//       subject: this.state.subject
//     }
//     this.setState({ submitHelp: true })
//     this.props.postHelpAndSupport(helpAndSupport);
//   }
//   render() {
//     const loading = this.props.help.help_loading;
//     const logs = this.props.help.logs;
//     return (
//       <div>
//         <div id='show-notification' className='float-right'></div>
//         <div className='card'>
//           <div className='card-body '>
//             <b>Help & Support</b> <br />
//             <form className='pt-3' onSubmit={this.onSubmit}>
//               <div className='mb-4'>
//                 <label htmlFor='exampleInputEmail'>Email address<b className='help-required'>*</b></label>
//                 <div className='input-group '>
//                   <input type='email' id='inputEmail' className='form-control form-control-lg  height-46' name='email' value={this.state.email} onChange={this.handleChange} autoComplete='on' required placeholder='Email' readOnly />
//                 </div>
//               </div>
//               <div className='mb-4'>
//                 <label htmlFor='exampleInputEmail'>Subject<b className='help-required'>*</b></label>
//                 <div className='input-group '>
//                   <input type='text' id='inputSubject' className='form-control form-control-lg  height-46' name='subject' value={this.state.subject} onChange={this.handleChange} autoComplete='on' required autoFocus placeholder='Subject' />
//                 </div>
//               </div>
//               <div className='mb-4'>
//                 <label htmlFor='exampleInputEmail'>Description<b className='help-required'>*</b></label>
//                 <div className='input-group '>
//                   <textarea rows={3} id='description' className='form-control form-control-lg  height-46' name='description' value={this.state.description} onChange={this.handleChange} autoComplete='on' required placeholder='Description' />
//                 </div>
//               </div>
//               <div className='my-3'>
//                 <button className='btn btn-block btn-primary btn-lg  font-weight-medium auth-form-btn col-md-3 float-right' type='submit'>
//                   {!this.props.help.post_logs_loading ? <b> Submit </b> : <Spinner size='spinner-border-sm' />}
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//         <br /><br />
//         <div>
//           <div className='card-body'>
//             {loading ? <HelpSkeleton /> :
//               <span className='row'>
//                 {logs && logs.logs.map((log, index) => (
//                   <div className='col-md-4 grid-margin stretch-card' key={index} >
//                     <div className='card'>
//                       <div className='card-body'>
//                         <div className='d-flex flex-row flex-wrap'>
//                           <div className='ml-3'>
//                             <p className='text-capitalize'><b>Name: </b> {log.name} </ p>
//                             <p><b>Email:</b> {log.email}</p>
//                             <p className='text-capitalize'><b>Subject:</b> {log.subject} </p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))
//                 }
//               </span>
//             }
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   help: state.help,
// });
// export default connect(mapStateToProps, { getFreshDeskLogs, postHelpAndSupport })(Help);


import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFreshDeskLogs, postHelpAndSupport } from '../../Actions/helpActions';
import '../Menus/menu.css';
import { showToastNotification } from '../../Common/showToastNotification';
import { HelpSkeleton } from './HelpSkelton';
import Spinner from '../../Common/Spinner';
import { POST_LOGS_LOADING } from '../../Actions/types';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { HelpAndSupportPayload } from '../../Interfaces/PayloadsAndResponses/Help';

const Help: FC = () => {
  const dispatch = useDispatch<any>();
  const help = useSelector((state: ReduxRootState) => state?.help);
  const loading = help?.help_loading;
  const logs = help?.logs;

  const [email, setEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [submitHelp, setSubmitHelp] = useState<boolean>(false);

  useEffect(() => {
    setEmail(JSON.parse(localStorage.getItem('user') || '{}').email);
  }, [submitHelp]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'subject') {
      setSubject(value);
    } else if (name === 'description') {
      setDescription(value);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!help.post_logs_loading) {
      dispatch({ type: POST_LOGS_LOADING, payload: null });
    }
    const helpAndSupport: HelpAndSupportPayload = {
      email,
      description,
      subject,
    };
    setSubmitHelp(true);
    dispatch(postHelpAndSupport(helpAndSupport));
  }

  useEffect(() => {
    dispatch(getFreshDeskLogs());
  }, [dispatch]);

  useEffect(() => {
    if (help?.logs && submitHelp) {
      setSubmitHelp(false);
      setEmail('');
      setSubject('');
      setDescription('');
      showToastNotification('Thank you. We will be in touch shortly!', 'Success');
    }
  }, [help?.logs, submitHelp, setEmail, setSubject, setDescription]);

  return (
    <div>
        <div id='show-notification' className='float-right'></div>
        <div className='card'>
          <div className='card-body '>
            <b>Help & Support</b> <br />
            <form className='pt-3' onSubmit={onSubmit}>
              <div className='mb-4'>
                <label htmlFor='exampleInputEmail'>Email address<b className='help-required'>*</b></label>
                <div className='input-group '>
                  <input type='email' id='inputEmail' className='form-control form-control-lg  height-46' name='email' value={email} onChange={handleChange} autoComplete='on' required placeholder='Email' readOnly />
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='exampleInputEmail'>Subject<b className='help-required'>*</b></label>
                <div className='input-group '>
                  <input type='text' id='inputSubject' className='form-control form-control-lg  height-46' name='subject' value={subject} onChange={handleChange} autoComplete='on' required autoFocus placeholder='Subject' />
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='exampleInputEmail'>Description<b className='help-required'>*</b></label>
                <div className='input-group '>
                  <textarea rows={8} id='description' className='form-control form-control-lg' name='description' value={description} onChange={handleChange} autoComplete='on' required placeholder='Description' />
                </div>
              </div>
              <div className='my-3'>
                <button className='btn btn-block btn-primary btn-lg  font-weight-medium auth-form-btn col-md-3 float-right' type='submit'>
                  {!help.post_logs_loading ? <b> Submit </b> : <Spinner size='spinner-border-sm' />}
                </button>
              </div>
            </form>
          </div>
        </div>
        <br /><br />
        <div>
          <div className='card-body'>
            {loading ? <HelpSkeleton /> :
              <span className='row'>
                {logs && logs?.logs?.map((log, index) => (
                  <div className='col-md-4 grid-margin stretch-card' key={index+log?.name} >
                    <div className='card'>
                      <div className='card-body'>
                        <div className='d-flex flex-row flex-wrap'>
                          <div className='ml-3'>
                            <p className='text-capitalize'><b>Name: </b> {log?.name} </ p>
                            <p><b>Email:</b> {log?.email}</p>
                            <p className='text-capitalize'><b>Subject:</b> {log?.subject} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                }
              </span>
            }
          </div>
        </div>
      </div>
    );
  }

export default Help;
