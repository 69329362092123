import axios from 'axios';
import { GET_STATISTICS_REPORT, GET_STATISTICS_REPORT_LOADING } from './types';
import { getError } from './errorActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export const getStatisticsReports = (restaurantId: string) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: GET_STATISTICS_REPORT_LOADING,
    payload: true
  });
  axios
    .get(`vendor/restaurants/${restaurantId}/reports`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_STATISTICS_REPORT,
        payload: res.data
      })
    }
    )
    .catch(err =>
      dispatch(getError(err))
    );
};