// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import Modal from 'react-modal';
// import '../../../Common/Popups/popup.css'
// import Spinner from '../../../Common/Spinner';
// import { updateRestaurantAdmins } from '../../../Actions/getRestaurantActions';
// import { showToastNotification } from '../../../Common/showToastNotification';
// import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
// import { SaveProfile } from '../../../Interfaces/PayloadsAndResponses/Auth';
// import { WithTranslation } from 'react-i18next';
// import { RestaurantAdmin, UpdateAdminPayload } from '../../../Interfaces/PayloadsAndResponses/GetRestaurants';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     maxWidth: '500px',
//     width: '90vw',
//     padding: '0',
//     maxHeight: '90vh',
//   }
// };

// Modal.setAppElement('#root');
// Modal!.defaultStyles!.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal!.defaultStyles!.overlay!.zIndex = 1050;

// type ProfilePopupProps = {
//   profile: SaveProfile | null;
//   i18n: WithTranslation | null;
//   onRef: (ref: EditAdminContactPopupRef | null) => void;
//   updateRestaurantAdmins: (id: string | number, profileData: UpdateAdminPayload, profileSavedCallback: () => void) => void;
// }

// type ProfilePopupState = {
//   modalIsOpen: boolean,
//   adminFirstName: string,
//   adminLastName: string,
//   formSubmitted: boolean,
//   user_id: string | number,
//   email: string,
//   password: string,
//   confirm_password: string,
//   orderSaved: boolean,
//   itemName: boolean,
//   updating_contact: boolean,
//   automaticallyEmail: boolean,
//   automaticallyLabel: boolean,
//   automaticallyTextReminders: boolean,
// }

// class EditAdminContactPopup extends Component<ProfilePopupProps, ProfilePopupState> {
//   state: ProfilePopupState = {
//     modalIsOpen: false,
//     adminFirstName: '',
//     adminLastName: '',
//     formSubmitted: false,
//     user_id: '',
//     email: '',
//     password: '',
//     confirm_password: '',
//     orderSaved: false,
//     itemName: false,
//     updating_contact: false,
//     automaticallyEmail: true,
//     automaticallyLabel: true,
//     automaticallyTextReminders: true,
//   }
//   componentDidMount() {
//     this.props.onRef(this);
//   }
//   componentWillUnmount() {
//     this.props.onRef(null)
//   }
//   componentWillReceiveProps() {
//     this.closeModal();
//   }
//   openModal = () => {
//     this.setState({ modalIsOpen: true });
//   }
//   closeModal = () => {
//     this.setState({ modalIsOpen: false, orderSaved: false });
//     this.setState({
//       adminFirstName: '',
//       adminLastName: '',
//       email: '',
//       formSubmitted: false,
//       user_id: '',
//       password: '',
//       confirm_password: '',
//       automaticallyEmail: true,
//       // automaticallyFax: true,
//       automaticallyLabel: true,
//       automaticallyTextReminders: true,
//     });
//   }
//   openModalWithItem = (user: RestaurantAdmin) => {
//     this.setState({
//       user_id: user?.id,
//       adminFirstName: user.first_name,
//       adminLastName: user.last_name,
//       email: user.email,
//       password: '',
//       confirm_password: '',
//       automaticallyEmail: user?.email_summary_check,
//       automaticallyLabel: user?.email_label_check,
//       // automaticallyFax: user?.fax_summary_check,
//       automaticallyTextReminders: user?.send_text_reminders,
//     });
//     this.openModal();
//   }
//   handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { target: { name, value, type, checked } } = e || {};
//     //To avoid change in text type input value by browser event itself
//     //@ts-ignore
//     if (!e?.nativeEvent?.inputType && e?.nativeEvent?.type === 'input') {
//       return
//     }
//     const inputValue = type === 'checkbox' ? checked : value;
//     this.setState(prevState => ({ ...prevState, [name]: inputValue }));
//   }
//   contactUpdatedHandler = () => {
//     showToastNotification('Admin updated successfully', 'Success')
//     this.setState({ updating_contact: false });
//     this.closeModal()
//   }
//   onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//     if (e) {
//       e.preventDefault();
//     }
//     const { password, confirm_password, user_id } = this.state;
//     if (password !== confirm_password) {
//       this.setState({ formSubmitted: true });
//       return;
//     }

//     const updatedAdmin = {
//       first_name: this.state.adminFirstName,
//       last_name: this.state.adminLastName,
//       password: password,
//       email_label_check: this.state.automaticallyLabel,
//       send_text_reminders: this.state.automaticallyTextReminders,
//       email_summary_check: this.state.automaticallyEmail
//     }
//     this.setState({ updating_contact: true });

//     user_id && this.props.updateRestaurantAdmins(user_id, { user: updatedAdmin }, this.contactUpdatedHandler)
//   }

//   render() {
//     return (
//       <Modal
//         isOpen={this.state.modalIsOpen}
//         onRequestClose={this.closeModal}
//         style={customStyles}
//         shouldCloseOnOverlayClick={!this.state.updating_contact}
//       >
//         <div id='show-notification' className='float-right'></div>
//         <div className='order-modal'>
//           <div className='modal-dialog slide'>
//             <div className='modal-content'>
//               <div className='modal-header'>
//                 <h5 className='modal-title' id='modal-title'>Edit Admin Profile</h5>
//                 <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
//               </div>
//               <div>
//                 <div className='col-lg-10 edit-contact-popup'>
//                   <form className='pt-3'>
//                     <div className='mb-4'>
//                       <label htmlFor='adminFirstName'> {this.props.i18n && this.props.i18n.t('First name')}</label>
//                       <div className='input-group'>
//                         <input type='text' id='adminFirstName' className='form-control form-control-lg height-46' name='adminFirstName' value={this.state.adminFirstName} onChange={this.handleChange} autoComplete='on' required autoFocus placeholder='First name' />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='adminLastName'> {this.props.i18n && this.props.i18n.t('Last name')}</label>
//                       <div className='input-group'>
//                         <input type='text' id='adminLastName' className='form-control form-control-lg height-46' name='adminLastName' value={this.state.adminLastName} onChange={this.handleChange} autoComplete='on' required placeholder='Last name' />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='editContactEmail'> {this.props.i18n && this.props.i18n.t('Email')}</label>
//                       <div className='input-group'>
//                         <input type='email' id='editContactEmail' className='form-control form-control-lg height-46' name='email' value={this.state.email} onChange={this.handleChange} required readOnly />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='djkfhksjhfksdhfksdhfkdshfds'> {this.props.i18n && this.props.i18n.t('Password')}</label>
//                       <div className='input-group'>
//                         <input autoComplete='off' type='password' id='djkfhksjhfksdhfksdhfkdshfds' className='form-control form-control-lg height-46' name='password' value={this.state.password} onChange={this.handleChange} />
//                       </div>
//                     </div>
//                     <div className='mb-4'>
//                       <label htmlFor='editContactConfirmPassword'>{this.props.i18n && this.props.i18n.t('Confirm Password')}</label>
//                       <div className='input-group'>
//                         <input autoComplete='off' type='password' id='editContactConfirmPassword' className='form-control form-control-lg height-46' name='confirm_password' value={this.state.confirm_password} onChange={this.handleChange} />
//                       </div>
//                       {this.state?.formSubmitted && this.state?.password !== this.state?.confirm_password ?
//                         <small className='invalid-feedback-password'>Password should be same.</small> : ''
//                       }
//                     </div>
//                     <div className='form-check'>
//                       <label className='form-check-label text-muted'>
//                         <input type='checkbox' className='form-check-input' name='automaticallyEmail' checked={this.state.automaticallyEmail} onChange={this.handleChange} />
//                         {this.props.i18n && this.props.i18n.t('Automatically Email Order Summary')}
//                         <i className='input-helper'></i>
//                       </label>
//                     </div>
//                     <div className='form-check'>
//                       <label className='form-check-label text-muted'>
//                         <input type='checkbox' className='form-check-input' name='automaticallyLabel' checked={this.state.automaticallyLabel} onChange={this.handleChange} />
//                         {this.props.i18n && this.props.i18n.t('Automatically send label')}
//                         <i className='input-helper'></i>
//                       </label>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className='modal-footer'>
//           <button className='btn btn-primary font-weight-medium' type='submit' style={{ minWidth: '95px', height: '44px' }} onClick={this.onSubmit}>
//             {this.state.updating_contact ? <Spinner /> :
//               <b>  {this.props.i18n && this.props.i18n.t('Submit')} </b>}
//           </button>
//         </div>
//       </Modal>
//     )
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   profile: state.auth.profile,
//   i18n: state.i18n.i18n
// });

// export type EditAdminContactPopupRef = React.ElementRef<typeof EditAdminContactPopup>;

// export default connect(mapStateToProps, { updateRestaurantAdmins })(EditAdminContactPopup);


import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import '../../../Common/Popups/popup.css';
import Spinner from '../../../Common/Spinner';
import { updateRestaurantAdmins } from '../../../Actions/getRestaurantActions';
import { showToastNotification } from '../../../Common/showToastNotification';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { RestaurantAdmin } from '../../../Interfaces/PayloadsAndResponses/GetRestaurants';
import isEmpty from '../../../Utilities/isEmpty';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '90vw',
    padding: '0',
    maxHeight: '90vh',
  }
};

Modal.setAppElement('#root');
Modal!.defaultStyles!.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal!.defaultStyles!.overlay!.zIndex = 1050;

type ProfilePopupProps = {
  modalOpen: boolean;
  handleCloseModal: () => void;
  admin: RestaurantAdmin[] |  null;
  selectedIndex: number;
}

const EditAdminContactPopup: FC<ProfilePopupProps> = ({ modalOpen, handleCloseModal, admin, selectedIndex }) => {

const [adminFirstName, setAdminFirstName] = useState<string>('');
const [adminLastName, setAdminLastName] = useState<string>('');
const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
const [user_id, setUser_id] = useState<string | number>('');
const [email, setEmail] = useState<string>('');
const [password, setPassword] = useState<string>('');
const [confirmPassword, setConfirmPassword] = useState<string>('');
const [updating_contact, setUpdatingContact] = useState<boolean>(false);
const [automaticallyEmail, setAutomaticallyEmail] = useState<boolean>(true);
const [automaticallyLabel, setAutomaticallyLabel] = useState<boolean>(true);
const [autoRatingEmailNotifications, setAutoRatingEmailNotifications] = useState<boolean>(true)
const [automaticallyTextReminders, setAutomaticallyTextReminders] = useState<boolean>(true);
const dispatch = useDispatch<any>();
const { i18n } = useSelector((state: ReduxRootState) => ({
  profile: state?.auth?.profile,
  i18n: state?.i18n?.i18n
  }));

  useEffect(() => {
    if (admin && !isEmpty(admin)) {
      openModalWithItem(admin[selectedIndex]);
    } else {
      openModalWithItem();
    }
    //eslint-disable-next-line
  }, [admin, selectedIndex])

  const openModalWithItem = (user?: RestaurantAdmin) => {
    if (user && !isEmpty(user)) {
      setUser_id(user?.id || '');
      setAdminFirstName(user?.first_name || '');
      setAdminLastName(user?.last_name || '');
      setEmail(user?.email || '');
      setPassword('');
      setConfirmPassword('');
      setAutomaticallyEmail(user?.email_summary_check ?? true);
      setAutomaticallyLabel(user?.email_label_check ?? true);
      setAutomaticallyTextReminders(user?.send_text_reminders ?? true);
      setAutoRatingEmailNotifications(user?.rating_email_notification ?? true)
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e?.target;
    //To avoid change in text type input value by browser event itself
    //@ts-ignore
    if (!e?.nativeEvent?.inputType && e?.nativeEvent?.type === 'input') {
      return;
    }
    const inputValue: string | boolean = type === 'checkbox' ? checked : value;
    setFormSubmitted(false);
    if (name === 'adminFirstName') {
      setAdminFirstName(inputValue as string);
    } else if (name === 'adminLastName') {
      setAdminLastName(inputValue as string);
    } else if (name === 'password') {
      setPassword(inputValue as string);
    } else if (name === 'confirm_password') {
      setConfirmPassword(inputValue as string);
    } else if (name === 'automaticallyLabel') {
      setAutomaticallyLabel(inputValue as boolean);
    } else if (name === 'automaticallyTextReminders') {
      setAutomaticallyTextReminders(inputValue as boolean);
    } else if (name === 'autoRatingEmailNotifications') {
      setAutoRatingEmailNotifications(inputValue as boolean)
    } else if (name === 'automaticallyEmail') {
      setAutomaticallyEmail(inputValue as boolean);
    }
  };

  const contactUpdatedHandler = () => {
    showToastNotification('Admin updated successfully', 'Success');
    setUpdatingContact(false);
    handleCloseModal()
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setFormSubmitted(true);
      return;
    }

    const updatedAdmin = {
      first_name: adminFirstName,
      last_name: adminLastName,
      password: password,
      email_label_check: automaticallyLabel,
      rating_email_notification: autoRatingEmailNotifications,
      send_text_reminders: automaticallyTextReminders,
      email_summary_check: automaticallyEmail
    };
    setUpdatingContact(true);
    user_id && dispatch(updateRestaurantAdmins(user_id, { user: updatedAdmin }, contactUpdatedHandler));
  };

return (
       <Modal
        isOpen={modalOpen}
        onRequestClose={handleCloseModal}
        style={customStyles}
        shouldCloseOnOverlayClick={!updating_contact}
      >
        <div id='show-notification' className='float-right'></div>
        <div className='order-modal'>
          <div className='modal-dialog slide'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='modal-title'>Edit Admin Profile</h5>
                <button type='button' onClick={handleCloseModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
              </div>
              <div>
                <div className='col-lg-10 edit-contact-popup'>
                  <form className='pt-3'>
                    <div className='mb-4'>
                      <label htmlFor='adminFirstName'> {i18n && i18n.t('First name')}</label>
                      <div className='input-group'>
                        <input type='text' id='adminFirstName' className='form-control form-control-lg height-46' name='adminFirstName' value={adminFirstName} onChange={handleChange} autoComplete='on' required autoFocus placeholder='First name' />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='adminLastName'> {i18n && i18n.t('Last name')}</label>
                      <div className='input-group'>
                        <input type='text' id='adminLastName' className='form-control form-control-lg height-46' name='adminLastName' value={adminLastName} onChange={handleChange} autoComplete='on' required placeholder='Last name' />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='editContactEmail'> {i18n && i18n.t('Email')}</label>
                      <div className='input-group'>
                        <input type='email' id='editContactEmail' className='form-control form-control-lg height-46' name='email' value={email} onChange={handleChange} required readOnly />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='unique-password'> {i18n && i18n.t('Password')}</label>
                      <div className='input-group'>
                        <input autoComplete='off' type='password' id='unique-password' className='form-control form-control-lg height-46' name='password' value={password} onChange={handleChange} />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='editContactConfirmPassword'>{i18n && i18n.t('Confirm Password')}</label>
                      <div className='input-group'>
                        <input autoComplete='off' type='password' id='editContactConfirmPassword' className='form-control form-control-lg height-46' name='confirm_password' value={confirmPassword} onChange={handleChange} />
                      </div>
                      {formSubmitted && password !== confirmPassword ?
                        <small className='invalid-feedback-password'>Password should be same.</small> : ''
                      }
                    </div>
                    <div className='form-check'>
                      <label className='form-check-label text-muted'>
                        <input type='checkbox' className='form-check-input' name='automaticallyEmail' checked={automaticallyEmail} onChange={handleChange} />
                        {i18n && i18n.t('Automatically Email Order Summary')}
                        <i className='input-helper'></i>
                      </label>
                    </div>
                    <div className='form-check'>
                      <label className='form-check-label text-muted'>
                        <input type='checkbox' className='form-check-input' name='automaticallyLabel' checked={automaticallyLabel} onChange={handleChange} />
                        {i18n && i18n.t('Automatically send label')}
                        <i className='input-helper'></i>
                      </label>
                    </div>
                    <div className='form-check'>
                      <label className='form-check-label text-muted'>
                        <input type='checkbox' className='form-check-input' name='autoRatingEmailNotifications' checked={autoRatingEmailNotifications} onChange={handleChange} />
                        {i18n && i18n.t('Automatically send rating notifications')}
                        <i className='input-helper'></i>
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary font-weight-medium' type='submit' style={{ minWidth: '95px', height: '44px' }} onClick={onSubmit}>
            {updating_contact ? <Spinner /> :
              <b>  {i18n && i18n.t('Submit')} </b>}
          </button>
        </div>
      </Modal>
)
}
export default EditAdminContactPopup