import React, { FC, useState } from "react";
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import { useParams } from "react-router";
import { Params } from "../../Dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { selectExistingFoodItems } from "../../../Actions/menuActions";
import { ReduxRootState } from "../../../Interfaces/ReduxInterface/ReduxRootState";
import Spinner from "../../../Common/Spinner";
import { customStyles } from "./MenuSectionsPage";
import isEmpty from "../../../Utilities/isEmpty";
import { AddExistingFoodItem } from "../../../Interfaces/PayloadsAndResponses/Menu";


type SelectExistingPopupProps = {
  openExistingFooditem: boolean;
  setOpenExistingFooditem: (status: boolean) => void;
  selectExistingFoodItemsData: any;
  selectExistingPopUpHandler: () => void;
}

const SelectExistingFootItemPopup: FC<SelectExistingPopupProps> = ({ openExistingFooditem, setOpenExistingFooditem, selectExistingFoodItemsData, selectExistingPopUpHandler }) => {
  const dispatch = useDispatch<any>();
  //Get id from params
  const { restaurantId } = useParams<Params>();
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}');
  const selectedSectionid = sectionData.id || '';
  //data from local storage
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? '';
  const [selectBuffetDishSizes, setSelectBuffetDishSizes] = useState<string[]>([]); // Step 1: State for selected options
  const [searchBuffetDishSizes, setSearchOptionGroup] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const sectionFoodItemLoading = useSelector((state: ReduxRootState)=>state.menus?.sectionFoodItemLoading)

  //check for item present or not
  let itemsFound = false;
  const handleChangeSearchOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchOptionGroup(value);
  };

  const handleCheckboxChange = (optionId: string) => {
    //Handle checkbox change event
    setSelectBuffetDishSizes((prevSelected) => {
      if (prevSelected?.includes(optionId)) {
        return prevSelected?.filter((id) => id !== optionId); // Remove optionId from selectBuffetDishSizes
      } else {
        return [...prevSelected, optionId]; // Add optionId to selectBuffetDishSizes
      }
    });
  };

  const handleAddOptions = () => {

    const addExistingFoodItemsPayload: AddExistingFoodItem = {
      fooditem_ids: selectBuffetDishSizes?.map((fooditemid) => +fooditemid)
    }

    selectedMenuId && restaurantId && (dispatch(selectExistingFoodItems(addExistingFoodItemsPayload, +restaurantId, +selectedMenuId, selectedSectionid, selectExistingPopUpHandler)))
  };
  //filter apply based on name
  const filteredArray = selectExistingFoodItemsData?.filter((item: any) =>
    item?.name?.toLowerCase()?.includes(searchBuffetDishSizes?.toLowerCase())
  )

  return (
    <>
      {openExistingFooditem &&
        <CustomPopUp
          backdropStyles={{ opacity: 1, cursor: 'default' }}
          onPopUpClose={() => setOpenExistingFooditem(false)}
          popUpBodyStyles={{ ...customStyles, top: '15%' }}
          allowScrolling={true}
        >
          <div className="modal-content">
            <div className="menu-item-modal-header">
              <h3 className="fw-600 m-0 text-medium-black">Select Existing items</h3>
              <button type="button" className="btn-close modal-colose-btn text-dark" onClick={() => setOpenExistingFooditem(false)}></button>
            </div>
            <div className="selection_modal_body">
              <form>
                <div className="form-group has-search position-relative ">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input type="text" className="form-control form-control-sm rounded-pill" value={searchBuffetDishSizes} placeholder="Search" onChange={handleChangeSearchOptions} />
                </div>
              </form>
              <nav>
                <div className="nav mb-3" id="nav-tab" role="tablist">
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "All" ? "active" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("All")}
                  >
                    All
                  </button>
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "Breakfast" ? "active selected" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("Breakfast")}
                  >
                    Breakfast
                  </button>
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "Lunch" ? "active selected" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("Lunch")}
                  >
                    Lunch
                  </button>
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "Dinner" ? "active selected" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("Dinner")}
                  >
                    Dinner
                  </button>
                  <button
                    className={`tabs-button fs-18 fw-normal ${selectedTab === "Buffet" ? "active selected" : "text-secondary-gray"}`}
                    onClick={() => setSelectedTab("Buffet")}
                  >
                    Buffet
                  </button>
                </div>
              </nav>
              <div className="tab-content border-0 p-0" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                  style={{ height: '400px', overflow: 'scroll' }}
                >
                  {filteredArray?.map((items: any, index: number) => {
                    //render data based on selectedtabs
                    const shouldRenderOptionSets =
                      selectedTab === 'All' ||
                      (selectedTab === 'Breakfast' && items?.menu_type === 3) ||
                      (selectedTab === 'Lunch' && items?.menu_type === 1) ||
                      (selectedTab === 'Dinner' && items?.menu_type === 2) ||
                      (selectedTab === 'Buffet' && items?.menu_type === 4);
                    //count
                    const buffetDishSizeCount = items?.price
                    //check tab values
                    if (shouldRenderOptionSets) {
                      itemsFound = true;
                      return (
                        <React.Fragment key={index}>
                          <div className="d-flex align-items-center pe-3 coursor-pointer select_existing_options">
                            <div className="form-check pe-3">
                              <input type="checkbox" className="form-check-input coursor-pointer"
                                checked={selectBuffetDishSizes?.includes(items?.id?.toString())}
                                onChange={() => handleCheckboxChange(items?.id?.toString())} />
                            </div>
                            <div>
                            {!isEmpty(items?.image?.image_medium_url ?? items?.image?.image?.url ?? items?.image?.image_url) ? <img alt='food item' src={items?.image?.image_medium_url ?? items?.image?.image_url ?? items?.image?.image_url ?? ''}
                              className='rounded-10 img-fluid image-wrapper'  /> :
                              <img src="/imgs/menu-page-place-holder1.svg" alt="food item" className="rounded-10 img-fluid image-wrapper " />
                            }
                            </div>
                            <div className="menu_inner_wrapper d-flex flex-column justify-content-center align-items-start w-100 ml-3">
                              <h5 className="lead fw-600 text-medium-black m-0 text-nowrap">{items?.name}</h5>
                              <p className="fs-18 fw-normal text-medium-black m-0">${buffetDishSizeCount}</p>
                            </div>
                          </div>
                          <hr className="menu_seprator_for_modal" />
                        </React.Fragment>
                      );
                    }
                    return null; // If no items are found, return null to skip rendering
                  })}
                  {/* show msg if no item found */}
                  {!itemsFound && <span className="text-medium-black">No Item Found</span>}
                </div>
              </div>
            </div>
            <div className="menu-item-modal-footer justify-content-end">
              <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill px-32" data-bs-dismiss="modal" onClick={() => setOpenExistingFooditem(false)}>Cancel</button>
              {selectBuffetDishSizes?.length > 0 ? (
                <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32" onClick={handleAddOptions}>
                  {!sectionFoodItemLoading ? `Add (${selectBuffetDishSizes?.length})` : <Spinner />}
                </button>
              ) : (
                <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32">
                  {!sectionFoodItemLoading ? 'Add' : <Spinner />}
                </button>
              )}
            </div>
          </div>
        </CustomPopUp>}
    </>
  )
}

export default SelectExistingFootItemPopup