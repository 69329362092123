import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import ModalHeader from '../../../Common/ModalHeader';
import isEmpty from '../../../Utilities/isEmpty';
import GooglePlacesAutocomplete from '../../../Common/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { updateRestaurantAddressDetails } from '../../../Actions/getRestaurantActions';
import Spinner from '../../../Common/Spinner';
import { showToastNotification } from '../../../Common/showToastNotification';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { Cuisine, RestaurantAddress, UpdateRestaurantAdressDetailsPayload } from '../../../Interfaces/PayloadsAndResponses/GetRestaurants';

const settingErrors = {
  restaurantName: '',
  addressLine: '',
}

export type RestaurantSettingAddress = {
  address_line: string;
  street?: string;
  street_number?: string;
  city?: string;
  state?: string;
  zip?: string;
  longitude?: string | number;
  latitude?: string | number;
  timezone?: string;
}

type Props = {
  isOpen: boolean,
  restaurant: RestaurantAddress
  closeGeneralSettingPopUp: () => void,
}
const customStyles = {
  content: {
    maxWidth: '700px',
    width: '85vw',
    padding: '0',
    position: 'unset' as 'unset',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

const GeneralSettingsPopUp = ({ isOpen, closeGeneralSettingPopUp, restaurant }: Props) => {
  const dispatch: any = useDispatch();

  const allCuisines = useSelector((state: ReduxRootState) => state?.restaurant_data?.cuisines) ?? [];
  const restaurantCuisines = restaurant?.cuisines;
  const { city, state, street, street_number, zip, address_line } = restaurant ?? {};
  const [restaurantName, setRestaurantName] = useState<string>(restaurant?.restaurant_name);
  const [locationName, setLocationName] = useState<string>(restaurant?.address_name);
  const [address, setAddress] = useState<RestaurantSettingAddress>({ address_line, street, street_number, city, state, zip, longitude: '', latitude: '', timezone: '' })
  const [selectedCuisines, setSelectedCuisines] = useState<(Cuisine & { selected?: boolean })[]>(getSelectedCuisines(allCuisines, restaurantCuisines))
  const [updatingGeneralSettings, setUpdatingGeneralSettings] = useState<boolean>(false);
  const [generalSettingsErrors, setGeneralSettingsErrors] = useState<typeof settingErrors>({ ...settingErrors });

  function getSelectedCuisines(allCuisinesList: Cuisine[], selectedCuisinesList: Cuisine[]) {
    if (isEmpty(selectedCuisinesList)) {
      return allCuisinesList
    }
    return allCuisinesList?.map((cuisine) => {
      let updatedCuisine;
      for (let selectedCuisine of selectedCuisinesList) {
        if (selectedCuisine.id === cuisine.id) {
          updatedCuisine = { ...cuisine, selected: true };
          break;
        }
      }
      return updatedCuisine ? updatedCuisine : { ...cuisine, selected: false }
    })
  }
  const selectedCuisinesChangeHandler = (index: number) => {
    let prevCuisines = [...selectedCuisines]
    if (prevCuisines[index]) {
      prevCuisines[index].selected = !prevCuisines[index].selected
    }
    setSelectedCuisines(prevCuisines)
  }
  const settingsErrorHandler = (id: string, error: string) => {
    setGeneralSettingsErrors({ ...generalSettingsErrors, [id]: error });
  }
  const validateSettings = () => {
    let valid = true;
    if (isEmpty(restaurantName)) {
      settingsErrorHandler('restaurantName', 'Please enter restaurant name.');
      valid = false;
    }
    if (isEmpty(address?.address_line)) {
      settingsErrorHandler('addressLine', 'Please enter an address.');
      valid = false;
    }
    return valid;
  }
  const submitHandler = () => {
    let isValidForm = validateSettings();
    if (!isValidForm) {
      return
    }
    let selectedCuisinesIds: number[] = [];
    selectedCuisines?.forEach((cuisine) => {
      cuisine?.selected && selectedCuisinesIds.push(cuisine.id)
    });
    const { city, state, zip, street_number, street, address_line, longitude, latitude, timezone = 'America/Los_Angeles' } = address;
    const addressDetail = { name: restaurantName, cuisine_ids: selectedCuisinesIds }
    const restaurantDetails: UpdateRestaurantAdressDetailsPayload = {
      address_line,
      street_number,
      street,
      city,
      state,
      zip,
      longitude,
      latitude,
      timezone: timezone,
      address_name: locationName
    }
    !longitude && delete restaurantDetails.longitude;
    !latitude && delete restaurantDetails.latitude;
    setUpdatingGeneralSettings(true);
    dispatch(updateRestaurantAddressDetails(restaurant?.id, addressDetail, restaurantDetails, 'editGeneralSettings', () => {
      setUpdatingGeneralSettings(false);
      showToastNotification('General settings updated successfully!', 'Success!')
      closeGeneralSettingPopUp();
    }))
  }
  const closeModal = () => {
    const { city, state, street, zip, address_line } = restaurant ?? {};
    setAddress({ address_line, street_number: '', street, city, state, zip, longitude: '', latitude: '', timezone: '' });
    setRestaurantName(restaurant?.restaurant_name);
    setGeneralSettingsErrors({ ...settingErrors });
    setLocationName(restaurant?.address_name);
    setSelectedCuisines(getSelectedCuisines(allCuisines, restaurantCuisines));
    closeGeneralSettingPopUp()
  }
  useEffect(() => {
    const { restaurant_name, address_name, city, state, street, zip, address_line }: RestaurantAddress = restaurant ?? {};
    setRestaurantName(restaurant_name);
    setLocationName(address_name);
    setGeneralSettingsErrors({ ...settingErrors });
    setAddress({ address_line, street_number: '', street, city, state, zip, longitude: '', latitude: '', timezone: '' });
  }, [restaurant])

  useEffect(() => {
    setSelectedCuisines(getSelectedCuisines(allCuisines, restaurantCuisines));
    //This Comment is added to overcome useEffect Dependancy Array Warning
    // eslint-disable-next-line
  }, [allCuisines.length, restaurantCuisines]);

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel='Edit general settings.'
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={!updatingGeneralSettings}
    >
      <ModalHeader
        label={`Edit general settings`}
        onModalClose={closeModal}
      />
      <div id='mydiv' className='modal-body p-3' style={{ width: '100%' }}>
        <form className='p-2'>
          <div className='mb-4'>
            <label htmlFor='restaurantName'><b>Restaurant Name</b></label>
            <input
              className='form-control mr-sm-2'
              name='restaurantName'
              value={restaurantName}
              type='text'
              onChange={e => { !isEmpty(e.target.value) && settingsErrorHandler('restaurantName', ''); setRestaurantName(e.target.value) }}
            />
            {generalSettingsErrors?.restaurantName ? <small className='invalid-feedback-password'>{generalSettingsErrors?.restaurantName}</small> : ''}
          </div>
          <div className='mb-4'>
            <label htmlFor='restaurantName'><b>Location Name</b></label>
            <input className='form-control mr-sm-2' name='restaurantName' value={locationName} type='text' onChange={e => setLocationName(e.target.value)} />
          </div>
          <div className='mb-4'>
            <label htmlFor='restaurantAddress'><b>Address</b></label>
            <GooglePlacesAutocomplete
              handleAddress={(address) => { !isEmpty(address?.address_line) && settingsErrorHandler('addressLine', ''); setAddress(address) }}
              value={address?.address_line}
            />
            {generalSettingsErrors?.addressLine ? <small className='invalid-feedback-password'>{generalSettingsErrors?.addressLine}</small> : ''}
          </div>
          <div className='mb-4 mb-1'>
            <label htmlFor='address'><b>Cuisines</b></label>
            <div>
              {selectedCuisines?.map((cuisine, index) =>
                <span key={index}
                  className={`btn btn-cuisine px-3 py-2 ${cuisine?.selected ? 'btn-dark' : 'btn-outline-dark'}`}
                  onClick={() => selectedCuisinesChangeHandler(index)}
                >
                  {cuisine?.name}
                </span>)
              }
            </div>
          </div>
        </form>
      </div>
      <div className='modal-footer p-2'>
        <button type='button' className='btn btn-dark' onClick={submitHandler} style={{ minWidth: '80px', minHeight: '46px' }}>
          {updatingGeneralSettings ?
            <Spinner size='spinner-border-sm' /> :
            <b>Save</b>
          }
        </button>
      </div>
    </Modal>
  );
};

export default GeneralSettingsPopUp;