import React, { FC, useState } from "react";
import { useParams } from "react-router";
import { Params } from "../../Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import { addOptionSets, getSelectExistingOptionsets } from "../../../Actions/menuActions";
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import Spinner from "../../../Common/Spinner";
// import OptionGroupDeletePopup from "../MenuPages/OptionGroupDeletePopup";
import SelectExistingPopup from "../MenuPages/SelectExistingPopup";
import { ReduxRootState } from "../../../Interfaces/ReduxInterface/ReduxRootState";
import { useSelector } from "react-redux";
import { customStyles } from "../MenuPages/MenuSectionsPage";
import ReactTooltip from "react-tooltip";

type OptionSetFormData = {
  optionGroupMinValue: string;
  optionGroupMaxValue: string;
  optionGroupName: string;
  options: {
    optionsName: string;
    optionsValue: string | number;
    
  }[];
};

type OptionGroupFormError = {
  optionGroupName: string,
  optionGroupMinValue: string,
  optionGroupMaxValue: string,
  options: {
    optionsNameError: string;
    optionsValueError: string;

  }[],
  minimunRequiredOptions: string
}

type OptionGroupPopupProp = {
  onClose: () => void;
  onAddOptionGroup: (status: boolean) => void;
  // onSelectExistingOptionGroup: () => void;
  onEditDelete: () => void;
  foodItemIdforOptionSet?: number;
  optionsPopUp: boolean
}

const OptionGroupPopup: FC<OptionGroupPopupProp> = ({ optionsPopUp, onAddOptionGroup, onEditDelete, foodItemIdforOptionSet }) => {
  const dispatch = useDispatch<any>();
  //Get id from params
  const { restaurantId } = useParams<Params>();
  //data from local storage
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? '';
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const sectionid = sectionData?.id || '';
  const selectExistingOptionSets = useSelector((state: ReduxRootState) => state?.menus?.selectExistingOptionsets)
  //States
  const [optionSetsFormData, setOptionSetsFormData] = useState<OptionSetFormData>({
    optionGroupMinValue: '',
    optionGroupMaxValue: '',
    optionGroupName: '',
    options: [
      {
        optionsName: '',
        optionsValue: 0
      }
    ]
  })
  const [optionGroupErrors, setOptionGroupErrors] = useState<OptionGroupFormError>({
    optionGroupName: "",
    optionGroupMinValue: "",
    optionGroupMaxValue: "",
    options: [],
    minimunRequiredOptions: ""
  });
  // const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  // const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [selectExistingPopUp, setSelectExistingPopUp] = useState<boolean>(false)
  const foodItemId = localStorage.getItem('fooditemid') ?? ''
  const foodItemPageLoader = useSelector((state: ReduxRootState)=>state.menus?.foodItemPageLoader)

  const loaderHandler = () => {
    onAddOptionGroup(false)
  }
  const handleSelectExistingOptionSets = () => {
    setSelectExistingPopUp(true);
    restaurantId && selectedMenuId && foodItemId &&  dispatch(getSelectExistingOptionsets(+restaurantId, +selectedMenuId, +foodItemId))
  }
  // Function to show the delete popup for a specific index
  // const showDeletePopup = (index: number) => {
  //   setDeleteIndex(index);
  //   setOpenDeletePopup(true);
  // };

  // Function to hide the delete popup
  // const hideDeletePopup = () => {
  //   setDeleteIndex(null);
  //   setOpenDeletePopup(false);
  // };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number | null) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setOptionSetsFormData((prevData) => {
      //Set value for optionset_attribute
      if (name === 'optionsName' || name === 'optionsValue') {
        const updatedOptions = prevData?.options?.map((option, i) => {
          if (index === i) {
            return {
              ...option,
              [name]: fieldValue
            };
          }
          return option;
        });
        return {
          ...prevData,
          options: updatedOptions
        };
      }
      //Set value for name min and max
      return {
        ...prevData,
        [name]: fieldValue
      };
    });
  };

  //Handle Add Div for Option Sets
  const handleAddOptionSets = () => {
    setOptionSetsFormData((prevData) => ({
      ...prevData,
      options: [...prevData.options, { optionsName: '', optionsValue: 0 }]
    }));
  };

  const handleDeleteDiv = (index: number) => {
    const updatedOptions = optionSetsFormData?.options?.filter((value, i) => i !== index);
    setOptionSetsFormData((prevData) => ({
      ...prevData,
      options: updatedOptions
    }));
  };

  // const handleDeleteConfirmed = () => {

  //   if (deleteIndex !== null) {
  //     handleDeleteDiv(deleteIndex);
  //     hideDeletePopup();
  //   }
  // };

  // Validation function for the option sets form data
  const validateOptionSetsFormData = (optionSetsFormData: any) => {
    const { optionGroupMinValue, optionGroupMaxValue, optionGroupName, options } = optionSetsFormData;
    const errors: any = {};

    // Validate Option Group Name
    if (!optionGroupName) {
      errors.optionGroupName = "Option group name is required.";
    }

    // Validate Minimum Value
    if (optionGroupMinValue === "") {
      errors.optionGroupMinValue = "Minimum value is required.";
    }

    // Validate Maximum Value
    if (optionGroupMaxValue === "") {
      errors.optionGroupMaxValue = "Maximum value is required.";
    } else if (parseFloat(optionGroupMaxValue) < parseFloat(optionGroupMinValue)) {
      errors.optionGroupMaxValue = "Maximum value must be greater than the minimum value.";
    }

    if (options.length < optionGroupMaxValue) {
      errors.minimunRequiredOptions = `At least ${optionGroupMaxValue} options are required.`
    }


    let errorArray: any = []
    optionSetsFormData?.options?.forEach((opt: any, index: any) => {
      const optionsNameError = opt?.optionsName === "" ? `name required.` : '';
      const optionsValueError = opt?.optionsValue === "" ? `value required.` : '';
      if (optionsNameError !== '' || optionsValueError !== '') {
        errorArray?.push({
          optionsNameError,
          optionsValueError,
        });
      } else {
        errorArray?.push({})
      }
    });

    const testoptionsArray = errorArray?.filter((obj: any) => (Object?.keys(obj)?.length > 0))
    if (testoptionsArray?.length > 0) {
      errors.options = errorArray
    } else {
      delete errors.options
    }

    return errors;
  };

  //Handle Submit For Edit
  const handleSubmit = () => {
    const { optionGroupMinValue, optionGroupMaxValue, optionGroupName, options } = optionSetsFormData;
    const optionGroupErrors = validateOptionSetsFormData(optionSetsFormData);
    // If there are any errors, update the state with the error messages and return
    if (Object?.keys(optionGroupErrors)?.length > 0) {
      setOptionGroupErrors(optionGroupErrors);
      return;
    }
    //check if options are empty then send empty [] other wise send object
    const optionsData = (options && options?.length > 0 && (options[0]?.optionsName)) ? options : [];
    const optionsAttributesData = optionsData?.map((option, index) => ({
      description: option?.optionsName,
      price: '' + (option?.optionsValue ?? 0),
      position: index + 1
    }));

    const optionSetPayload = {
        optionset: {
          name: optionGroupName,
          start_limit: optionGroupMinValue,
          end_limit: optionGroupMaxValue,
          fooditem_id: foodItemIdforOptionSet,
          options_attributes: optionsAttributesData,
          parent_status: 0,
          add_fooditem: true,
          position: 1
        }
    };
    selectedMenuId && restaurantId && (dispatch(addOptionSets(optionSetPayload, +selectedMenuId, +restaurantId, sectionid, +foodItemId, loaderHandler, ()=> {} )))
    setOptionSetsFormData({
      optionGroupMinValue: '',
      optionGroupMaxValue: '',
      optionGroupName: '',
      options: [
        {
          optionsName: '',
          optionsValue: ''
        }
      ]
    });
  };

  return (
    <>
      {optionsPopUp &&
        <CustomPopUp
          backdropStyles={{ opacity: 1, cursor: 'default' }}
          onPopUpClose={() => onAddOptionGroup(false)}
          popUpBodyStyles={{ ...customStyles, top: '25%' }}
          allowScrolling={true}
        >
          <div className="modal-content ">
            <div className="menu-item-modal-header border-bottom border-2">
              <h3 className="fw-600 m-0 text-medium-black">Add Option Group</h3>
              <button type="button" className="btn-close modal-close-btn text-dark" data-bs-dismiss="modal" aria-label="Close" onClick={() => onAddOptionGroup(false)}></button>
            </div>
            <div className="selection_modal_body">
              <div className="row">
                <div className="col-lg-4 border-end border-2 option_group">
                  <h5 className="fs-18 fw-600 pb-4 m-0">Option Group</h5>
                  <form>
                    <div className="mb-3">
                      <label htmlFor="optionGroupName" className="form-label menu-item-modal-labels">Option Group Name</label>
                      <input
                        type="email"
                        name="optionGroupName"
                        className="form-control form-control-sm rounded-1"
                        id="optionGroupName"
                        placeholder="Cheese Topping"
                        value={optionSetsFormData.optionGroupName}
                        onChange={(e) => handleInputChange(e, null)}
                      />
                      {optionGroupErrors.optionGroupName && <div className="invalid-feedback-password">{optionGroupErrors.optionGroupName}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="optionGroupMinValue" className="form-label menu-item-modal-labels">Must Select (Min)</label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control form-control-sm rounded-1"
                          name='optionGroupMinValue'
                          min={0}
                          value={optionSetsFormData?.optionGroupMinValue}
                          onChange={(e) => handleInputChange(e, null)}
                        />
                        {optionGroupErrors.optionGroupMinValue && <div className="invalid-feedback-password">{optionGroupErrors?.optionGroupMinValue}</div>}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="optionGroupMaxValue" className="form-label menu-item-modal-labels">Upto (Max)</label>
                      <div>
                        <input
                          type="number"
                          className="form-control form-control-sm rounded-1"
                          name='optionGroupMaxValue'
                          min={0}
                          value={optionSetsFormData?.optionGroupMaxValue}
                          onChange={(e) => handleInputChange(e, null)}
                        />
                        {optionGroupErrors.optionGroupMaxValue && <div className="invalid-feedback-password">{optionGroupErrors.optionGroupMaxValue}</div>}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-8 edit_menu_options edit-option-popup">
                  <div className="d-flex justify-content-between align-items-start pb-4 m-0">
                    <h5 className="fs-18 fw-600 m-0">Options</h5>
                    <div className="btn-group">
                      <button type="button" className="px-2 py-1 btn btn-custom-outline-light rounded-pill dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => onAddOptionGroup(true)}>
                        New Option
                      </button>
                      <ul className="dropdown-menu">
                        <li><span className="dropdown-item" onClick={handleAddOptionSets}>Add item</span></li>
                        <li><span className="dropdown-item" onClick={handleSelectExistingOptionSets}>Select Existing</span></li>
                      </ul>
                    </div>
                  </div>
                  <div className="row ">
                    <form>
                      {/* Options set added */}
                      {optionSetsFormData?.options?.map((option, index) => {
                        return (
                          <div key={index} className="row d-flex align-items-baseline pb-3 option_wrapper">
                            <div className="col-lg-1 form-check mb-4 d-flex justify-content-center">
                              <span>
                                <i className="fa-regular fa-circle-dot"></i>
                              </span>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <input
                                  type="text"
                                  className="form-control form-control-sm rounded-1"
                                  id="optionsName"
                                  name='optionsName'
                                  placeholder="Derby"
                                  value={option?.optionsName}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </div>
                        {optionGroupErrors?.options?.[index]?.optionsNameError && <div className="invalid-feedback-password my-2 column px-2">{optionGroupErrors?.options?.[index]?.optionsNameError}</div>}

                            </div>
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <input
                                  type="number"
                                  className="form-control form-control-sm rounded-1"
                                  placeholder="$ 3.00"
                                  name='optionsValue'
                                  min={0}
                                  value={option?.optionsValue ?? 0}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </div>
                        {optionGroupErrors?.options?.[index]?.optionsValueError && <div className="invalid-feedback-password my-2 column px-2">{optionGroupErrors?.options?.[index]?.optionsValueError}</div>}

                            </div>
                            <div className="col-lg-1 px-0 d-flex justify-content-center">
                              <span
                                data-tip
                                data-for='remove'
                                className='text-capitalize'>
                                <button type="button" className="btn rounded-circle p-2 delete-button" title="Delete" data-bs-dismiss="modal" onClick={() => handleDeleteDiv(index)}>
                                  <span><i className="fa-regular fa-trash-can"></i></span>
                                </button>
                              </span>
                              {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                                <ReactTooltip
                                  type='dark'
                                  place='top'
                                  effect='solid'
                                  className='react-tooltip'
                                  id='remove'
                                >
                                  <span>Remove</span>
                                </ReactTooltip>
                              }
                            </div>
                            <div className="col-lg-1">
                              <span><i className="fa-solid fa-grip-dots-vertical"></i></span>
                            </div>
                          </div>
                        )
                      })}
                        {optionGroupErrors?.minimunRequiredOptions &&  <div className="invalid-feedback-password my-2 column px-2">{optionGroupErrors?.minimunRequiredOptions}</div> }
                      {/* Confirmation is not needed because we are adding a new option its not attached to any food-item yet  */}
                      {/* <OptionGroupDeletePopup
                        openDeletePopup={openDeletePopup}
                        setOpenDeletePopup={setOpenDeletePopup}
                        onDeleteConfirmed={handleDeleteConfirmed}
                      /> */}
                    </form>
                    <div>
                      <div className=" p-4 menu_list_add_area edit_menu position-relative">
                        <svg className="svg-inline--fa fa-plus fa-lg" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 256C432 264.8 424.8 272 416 272h-176V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"></path></svg>
                        <div className="d-flex align-items-center justify-content-center menu_list_button_area">
                          <button className="btn btn-lite-primary-sm" onClick={handleAddOptionSets}>Add Item</button>
                          <button className="btn btn-lite-primary-sm" onClick={handleSelectExistingOptionSets}>Select Existing</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-item-modal-footer justify-content-end border-top border-2">
              <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill px-4" data-bs-dismiss="modal" onClick={() => onAddOptionGroup(false)}>Cancel</button>
              <button type="button" className="btn btn-custom-primary rounded-pill py-2 px-32" onClick={handleSubmit}>{!foodItemPageLoader ? 'Add' : <Spinner />}</button>
            </div>
          </div>
          {/* Select Existing Popup */}
          <SelectExistingPopup 
           selectExistingPopUp={selectExistingPopUp}
           setSelectExistingPopUp={setSelectExistingPopUp}
           selectExistingOptionSets={selectExistingOptionSets}
          />
        </CustomPopUp>
      }
    </>
  )
}

export default OptionGroupPopup;