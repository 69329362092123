import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ArrayOfLength } from '../../Utilities/ArraysUtilities';
let invoice = ArrayOfLength(6);
export const InvoicesSkeleton = () => {
  return <React.Fragment>
    <div className='container m-auto mt-3 text-left'>
      <div style={{ position: 'relative' }} >
        <div className='row'>
          <div className='col-12 m-auto text-left px-0' >
            <div className='table-responsive'>
              <br /> <br />
              <table className='table table-hover'>
                <tbody>
                  {invoice.map((invoice) =>
                    <tr key={invoice}>
                      <td><Skeleton height={25} width={60} /></td>
                      <td><Skeleton height={25} width={100} /></td>
                      <td><Skeleton height={25} width={50} /></td>
                      <td><Skeleton height={25} width={60} /></td>
                      <td><Skeleton height={25} width={50} /></td>
                      <td><Skeleton height={25} width={70} /></td>
                      <td><Skeleton height={25} width={50} /></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
}