import axios from 'axios';
import { GET_INVOICES, GET_SINGLE_INVOICE_DETAIL, INVOICES_LOADING, GET_INVOICE_EMAIL } from './types';
import { getError } from './errorActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { InvoiceForwadedEmailPayload, UpdateInvoicePayload } from '../Interfaces/PayloadsAndResponses/Invoice';

export const getInvoices = (restaurantId: string | number, from: string, to: string, activePage: number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading())
  axios.get(`vendor/restaurants/${restaurantId}/invoices?from=${from}&&to=${to}&&page=${activePage}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_INVOICES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const getSingleInvoiceDetail = (invoiceId: string | number, restaurantId: string | number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`vendor/restaurants/${restaurantId}/invoices/${invoiceId}`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_SINGLE_INVOICE_DETAIL,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const updateInvoice = (adjustmentData: UpdateInvoicePayload, invoiceId: string | number, restaurantId: string | number, invoiceUpdatedCallback: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.put(`vendor/restaurants/${restaurantId}/invoices/${invoiceId}`, { invoice: adjustmentData },
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_SINGLE_INVOICE_DETAIL,
        payload: res.data
      });
      invoiceUpdatedCallback && invoiceUpdatedCallback();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const getInvoiceEmailData = (invoiceId: string | number, restaurantId: string | number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setLoading());
  axios.get(`vendor/restaurants/${restaurantId}/invoices/${invoiceId}/forward_invoice`,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_INVOICE_EMAIL,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
}
export const sendInvoiceEmail = (emailData: InvoiceForwadedEmailPayload, invoiceId: string | number, restaurantId: string | number, emailSubmittedCallback: () => void) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios.post(`vendor/restaurants/${restaurantId}/invoices/${invoiceId}/forwarded_invoice`, { invoice: emailData },
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      emailSubmittedCallback && emailSubmittedCallback();
    })
    .catch(err =>
      dispatch(getError(err))
    );
}
export const setLoading = () => {
  return {
    type: INVOICES_LOADING
  };
}