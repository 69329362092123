import i18n from "i18next";

export const authTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Login": "Login",
      "Not currently registered?": "Not currently registered?",
      "Email us": "Email us",
      "Your password has been reset. Please login below using your new password.": "Your password has been reset. Please login below using your new password.",
      "Email address": "Email address",
      "Password": "Password",
      "Forgot Password?": "Forgot Password?",
      "Keep me signed in": "Keep me signed in",
      "Sign in": "Sign in",
      "© Copyright": " © Copyright ",
      "Chowmill": " Chowmill ",
      "Submit": "Submit",

      "Reset Your Password": "Reset Your Password",
      "New Password": "New Password",
      "Confirm Password": "Confirm Password",
      "Please insert at least 6 characters.": "Please insert at least 6 characters.",
      "Login as a Vendor": "Login as a Vendor"
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Login": "لاگ ان کریں",
      "Not currently registered?": "فی الحال رجسٹرڈ نہیں ہے؟",
      "Email us": "ہمیں ای میل کریں",
      "Your password has been reset. Please login below using your new password.": "آپ کا پاس ورڈ دوبارہ ترتیب دیا گیا ہے۔ براہ کرم اپنا نیا پاس ورڈ استعمال کرکے نیچے لاگ ان کریں۔",
      "Email address": "ای میل اڈریس",
      "Password": "پاس ورڈ",
      "Forgot Password?": "پاسورڈ بھول گے",
      "Forgot Password": "پاسورڈ بھول گے",
      "Keep me signed in": "مجھے لاگ ان رکھیں",
      "Sign in": "سائن ان",
      "© Copyright": " © کاپی رائٹ ",
      "Chowmill": " چومیل ",
      "Submit": "جمع کرائیں",

      "Reset Your Password": "آپ کا پاس ورڈ دوبارہ ترتیب دیں",
      "New Password": "نیا پاس ورڈ",
      "Confirm Password": "پاس ورڈ کی تصدیق کریں",
      "Please insert at least 6 characters.": "براہ کرم کم از کم 6 حرف داخل کریں۔",
      "Login as a Vendor": "فروش کے طور پر لاگ ان کریں"
    });
};