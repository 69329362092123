import React from 'react';

type Props = {
  size?: string
}

const Spinner = ({ size }: Props) => {
  return (
    <div className='d-flex justify-content-center'>
      <div className={`spinner-border spinner-wide-handling ${size ? size : ''}`} role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;