import React from 'react';
import Skeleton from 'react-loading-skeleton';
let invoice = [1, 2, 3, 4, 5, 6]
export const InvoicesDetailsSkeleton = () => {
  return <React.Fragment>
    <div className="container m-auto mt-3 text-left">
      <div style={{ position: 'relative' }} >
        <div className="container-fluid px-0">
          <h2 className="mt-4"><span className="text-secondary cursor-pointer"><Skeleton height={20} width={30} /></span> <Skeleton height={10} width={160} /></h2>
          <p className="muted mb-4"><Skeleton height={10} width={160} /></p>
        </div>
        <div className="container-fluid px-0 d-flex justify-content-between">
          <div className="col-lg-3 pl-0">
            <p className="mb-2"><b><Skeleton height={10} width={100} /></b></p>
            <p className='btn-link d-inline text-decoration-none' style={{ cursor: 'pointer' }} ><Skeleton height={10} width={160} /></p>
          </div>
          <div className="col-lg-3 pr-0">
            <p className="mb-2 text-right"><b><Skeleton height={10} width={100} /></b></p>
            <p className="btn-link float-right d-inline text-decoration-none" style={{ cursor: 'pointer' }}><Skeleton height={10} width={160} /></p>
          </div>
        </div>
        <div className="container-fluid px-0 d-flex justify-content-between">
          <div className="col-lg-3 pl-0">
            <p className="mb-0 mt-3"><Skeleton height={10} width={100} /></p>
            <p><Skeleton height={10} width={100} /></p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 m-auto text-left px-0" >
            <div className="table-responsive">
              <table className="table table-hover">
                <br /> <br /> <br /> <br />
                <tbody>
                  {invoice.map((invoice, index) => <tr key={index}>
                    <td><Skeleton height={25} width={60} /></td>
                    <td><Skeleton height={25} width={100} /></td>
                    <td><Skeleton height={25} width={50} /></td>
                    <td><Skeleton height={25} width={60} /></td>
                    <td><Skeleton height={25} width={50} /></td>
                    <td><Skeleton height={25} width={70} /></td>
                    <td><Skeleton height={25} width={50} /></td>
                  </tr>)}
                </tbody>
              </table>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
}